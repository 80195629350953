import { estimateMaxPriorityFeePerGas } from "viem/actions";
import { sign_and_call, view_call, make_random_address } from "./core";

let contract_name = "Token"

// ---- Public Functions ----

export async function increase_stake(token_amount, callback) {
    let response = await sign_and_call(contract_name, "increase_stake", [token_amount], 0, callback);
    return response;
}

export async function decrease_stake(token_amount, callback) {
    let response = await sign_and_call(contract_name, "decrease_stake", [token_amount], 0, callback);
    return response;
}

export async function claim_rewards(callback) {
    let response = await sign_and_call(contract_name, "claim_rewards", [], 0, callback);
    return response;
}

export async function burn(amount, callback) {
    let response = await sign_and_call(contract_name, "burn", [amount], 0, callback);
    return response;
}


// export async function stake_tokens(token_amount, lock_period, callback) {
//     let stake_address = make_random_address();
//     let response = await sign_and_call(contract_name, "stake_tokens", [token_amount, lock_period, stake_address], 0, callback);
//     return response;
// }

// export async function unstake_tokens(stake_address, callback) {
//     let response = await sign_and_call(contract_name, "unstake_tokens", [stake_address], 0, callback);
//     return response;
// }

// export async function claim_unstaked_tokens(stake_address, callback) {
//     let response = await sign_and_call(contract_name, "claim_unstaked_tokens", [stake_address], 0, callback);
//     return response;
// }

// export async function claim_daily_rewards(stake_address, callback) {
//     let response = await sign_and_call(contract_name, "claim_daily_rewards", [stake_address], 0, callback);
//     return response;
// }

export async function transfer_by_username(username, amount, callback) {
    let response = await sign_and_call(contract_name, "transfer_by_username", [username, amount], 0, callback);
    return response;
}

export async function transfer(recipient, amount, callback) {
    let response = await sign_and_call(contract_name, "transfer", [recipient, amount], 0, callback);
    return response;
}

// ---- Getter Functions ----

export async function get_exchange_rate() {
    let response = await view_call(contract_name, "get_exchange_rate", []);
    return response;
}

export async function get_tokens_for_usd(usd_value) {
    let response = await view_call(contract_name, "get_tokens_for_usd", [usd_value]);
    return response;
}

export async function get_next_price_increase() {
    let response = await view_call(contract_name, "get_next_price_increase", []);
    return response;
}

export async function get_minting_history(page_number, transactions_per_page) {
    let response = await view_call(contract_name, "get_minting_history", [page_number, transactions_per_page]);
    return response;
}

// export async function calculate_rewards(stake_address) {
//     let response = await view_call(contract_name, "calculate_rewards", [stake_address]);
//     return response;
// }

export async function get_stake(user_address) {
    let response = await view_call(contract_name, "get_stake", [user_address]);
    return response;
}

// export async function get_user_stakes(user_address) {
//     let response = await view_call(contract_name, "get_user_stakes", [user_address]);
//     return response;
// }

export async function get_profile_statistics(user_address) {
    let response = await view_call(contract_name, "get_profile_statistics", [user_address]);
    return response;
}

export async function balance_of(user_address) {
    let response = await view_call(contract_name, "balanceOf", [user_address]);
    return response;
}

export async function get_is_banned(user_address) {
    let response = await view_call(contract_name, "get_is_banned", [user_address]);
    return response;
}