// ---- Icons ----
import analytics_insight_icon from "../press_icons/analytics_insight.png";
import finbold_icon from "../press_icons/finbold.png";
import crypto_news_flash_icon from "../press_icons/crypto_news_flash.png";
import coinspeaker_icon from "../press_icons/coinspeaker.png";
import publish_0x_icon from "../press_icons/publish_0x.png";
import coingape_icon from "../press_icons/coingape.png";
import techpoint_africa_icon from "../press_icons/techpoint_africa.png";
import disrupt_africa_icon from "../press_icons/disrupt_africa.png";
import captain_altcoin_icon from "../press_icons/captain_altcoin.png";

export let press = [
    {
        name: "analytics_insight",
        icon: analytics_insight_icon
    },
    {
        name: "finbold",
        icon: finbold_icon
    },
    {
        name: "crypto_news_flash",
        icon: crypto_news_flash_icon
    },
    // {
    //     name: "coinspeaker",
    //     icon: coinspeaker_icon
    // },
    // {
    //     name: "publish_0x",
    //     icon: publish_0x_icon
    // },
    // {
    //     name: "coingape",
    //     icon: coingape_icon
    // },
    {
        name: "disrupt_africa_icon",
        icon: disrupt_africa_icon
    },
    {
        name: "captain_altcoin_icon",
        icon: captain_altcoin_icon
    },
    {
        name: "techpoint_africa",
        icon: techpoint_africa_icon
    }
]