import { useEffect, useState, useRef } from "react";
import DOMPurify from 'dompurify';

// ---- Icons ----
import down_icon from "../../assets/icons/down.png";

// ---- Services -----
import {  get_article } from "../../services/contract_functions/news";
import { short_date } from "../../services/helper_functions";

// ---- Components ----
import SEO from "../../components/seo";

function Article({ close, parameters, set_parameters }) {

    let [article_data, set_article_data] = useState(parameters.full_data);

    useEffect(() => {

        if (parameters.full_data) {
            set_article_data(parameters.full_data);
        }
        else {
            (async function() {

                let article = await get_article(parameters.article_address);
                set_article_data(article);
    
            })();    
        }

        
    },[parameters]);
    

    function format_article(html_string) {
        html_string = html_string.replaceAll("</li>","</li><div class='medium_spacer'></div>");
        html_string = html_string.replaceAll("</p>","</p><div class='medium_spacer'></div>");
        html_string = html_string.replaceAll("</h3>","</h3><div class='medium_spacer'></div>");
        return DOMPurify.sanitize(html_string)
    }

    return (
        <>
            {
                article_data && article_data.title &&
                <SEO parameters={
                    {
                        title: article_data.title,
                        description: article_data.description,
                        url: "https://jetbolt.io/explore",
                        sharing_img: article_data.image_url
                    }
                }/>
            }
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family" style={{opacity:0.25}}>Article Summary</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                    </div>
                    <div className="medium_spacer"></div>
                </div>
                
                {
                    article_data && article_data.title &&
                    <>
                        <h2 className="title_font_family">{article_data.title}</h2>
                        <div className="small_spacer"></div>
                        <p>Date: {short_date(Number(article_data.date))}</p>
                        <p>Sentiment: <a style={{color: article_data.sentiment == "Negative"?"var(--orange_text)":article_data.sentiment == "Positive"?"var(--green_text)":"var(--purple_text)"}}>{article_data.sentiment}</a></p>
                        <p>Source: {article_data.source_name}</p>
                        <div className="medium_spacer"></div>
                        <img className="news_image_main" src={article_data.image_url}/>
                        <div className="medium_spacer"></div>
                        <div dangerouslySetInnerHTML={{ __html: format_article(article_data.content) }}></div>
                        <div className="medium_spacer"></div>
                        <u className="underlined_text" onClick={()=>window.open(article_data.news_url)}>Read the Original Article</u>
                        <div className="small_spacer"></div>
                        <i className="small_text" style={{opacity:0.25}}>Please note that the information content provided on our explore page is generated by Artificial Intelligence (AI) and is intended for informational and educational purposes only. We cannot guaranty that the content is accurate, complete, unbiased, fair, non-discriminatory, appropriate, ethical, current, up to date, or free of rights, even though our systems have been designed to use trustworthy sources and verify the content. None of the information we provide constitutes financial advice, and we strongly encourage users to conduct their own research and consult with a financial advisor before making any investment decisions.</i>
                    </>
                }
            </div>
        </>
    );

}

export default Article;