import { useEffect, useRef } from "react";

// ---- Icons ----
import logo_dark_icon from "../assets/icons/logo_dark.png";

// ---- Flags ----
import en_flag from "../assets/flags/en.png";

// ---- Modals ----
import { useLanguageSelector } from "../modals/language_selector";
import { usePurchaseFunnel } from "../modals/purchase_funnel";

function Header({ hard_stop, geo_error }) {

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function buy_direct() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "buy_direct"});
        }
    }

    // ---- Modals ----
    let { open_language_selector } = useLanguageSelector();

    // ---- Refs ----
    let header_ref = useRef(null);
    let header_text_ref = useRef(null);

    // ---- Hooks ----
    useEffect(() => {

        window.addEventListener('scroll', function() {

            if (header_ref.current && header_text_ref.current) {

                let header = header_ref.current;
                let header_text = header_text_ref.current;

                if (window.scrollY > 0) {
                    header.className = "header_container header_container_scrolled";
                    header_text.className = "header_text_logo title_font_family hide_title_text";
                }
                else {
                    header.className = "header_container";
                    header_text.className = "header_text_logo title_font_family";
                }

            }

        });

    },[]);

    return (
        <div className="header_container" ref={header_ref}>
            <div className="page_container_no">
                <div className="safe_inner">
                    <img className="header_logo" src={logo_dark_icon}/>
                    <div className="header_text_logo title_font_family notranslate" ref={header_text_ref}>JetBolt</div>
                    {/* <div className="header_language_selector gray_button_hover" onClick={open_language_selector}>
                        <img className="header_flag_icon" src={en_flag}/>
                        <div className="header_language_label notranslate">EN</div>
                    </div> */}
                    <div className="header_wallet gray_button_hover" onClick={()=>window.location="/profile"}>APP</div>
                    <div className={`header_buy_now blue_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>
                        <a className="only_mobile large_text_big">BUY</a>
                        <a className="only_desktop large_text_big">BUY NOW</a>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default Header;