import React, { createContext, useContext, useState, useEffect } from "react";

// ---- Components ----
import Reveive_Tokens from "./app/receive_tokens";
import Send_Tokens from "./app/send_tokens";
import Add_Friend from "./app/add_friend";
import Approve_Transaction from "./app/approve_transaction";
import Profile from "./app/profile";
import Staking_Duration from "./app/staking_duration";
import Staking_Amount from "./app/stake_amount";
import Stake_Preview from "./app/stake_preview";
import Change_Username from "./app/change_username";
import Show_Seed from './app/show_seed';
import QR_Scanner from "./app/qr_scanner";
import Unstake_Amount from "./app/unstake_amount";
import Email_Preferences from "./app/email_preferences"
import Email_Time from "./app/email_time"
import Article from "./app/article";
import Cryptocurrency from "./app/cryptocurrency";
import Approve_IFrame_Transaction from "./app/approve_iframe_transaction";
import Burn_Amount from "./app/burn_amount";
import Staking_Guide from "./app/staking_guide";
import Show_Private_Key from "./app/show_private_key";

// ---- Icons ----
import logo_dark from "../assets/icons/logo_dark.png";

const ModalContext = createContext();

export function useAppModals() {
    return useContext(ModalContext);
}

export function App_Modals({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);

    let open_app_modal = (modal_parameters) => {
        document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_open_app_modal = () => {
        set_animate_out(true);

        // setTimeout(()=>{
        //     try { parameters.close_callback() } catch (error) { }
        // },2500);
        try { parameters.close_callback() } catch (error) { }
        
        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);

        if (parameters.type == "staking_guide") {
            localStorage.setItem("skip_staking_guide", "true");
        }
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
    }

    let context_value = {
        open_app_modal,
        close_open_app_modal
    }

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">

                    {
                        window.location.pathname == "/iframe" &&
                        <>
                            <div className="jetpaw_iframe_header"><img className="inline_iframe_logo" src={logo_dark}/>JetBolt</div>
                        </>
                    } 
                    {
                        parameters.type != "approve_iframe_transaction" &&
                        <div className="outer_modal_click" onClick={()=>{
                            close_open_app_modal();
                        }}></div>
                    }
                    <div className={`language_selector_container yellow_glow ${animate_out ? 'hide' : ''}`}>
                        {
                            parameters.type == "receive_tokens" &&
                            <Reveive_Tokens close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "send_tokens" &&
                            <Send_Tokens close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "add_friend" &&
                            <Add_Friend close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "approve_transaction" &&
                            <Approve_Transaction close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "profile" &&
                            <Profile close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "staking_duration" &&
                            <Staking_Duration close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "stake_amount" &&
                            <Staking_Amount close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "stake_preview" &&
                            <Stake_Preview close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "change_username" &&
                            <Change_Username close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "show_seed" &&
                            <Show_Seed close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "qr_scanner" &&
                            <QR_Scanner close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "unstake_amount" &&
                            <Unstake_Amount close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "email_preferences" &&
                            <Email_Preferences close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "email_time" &&
                            <Email_Time close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "article" &&
                            <Article close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "cryptocurrency" &&
                            <Cryptocurrency close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "approve_iframe_transaction" &&
                            <Approve_IFrame_Transaction close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "burn_amount" &&
                            <Burn_Amount close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "staking_guide" &&
                            <Staking_Guide close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "show_private_key" &&
                            <Show_Private_Key close={close_open_app_modal} parameters={parameters} set_parameters={set_parameters}/>
                        }
                    </div>
                </div>
            }
        </ModalContext.Provider>
    );

}