import React, { createContext, useContext, useState, useEffect } from "react";

// ---- Icons ----
import right_icon from "../assets/icons/right.png";
import down_icon from "../assets/icons/down.png";

// ---- Data ----
import { currencies } from "../assets/data/currencies";

const ModalContext = createContext();

export function useCurrencySelector() {
    return useContext(ModalContext);
}

export function Currency_Selector({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);

    let open_currency_selector = (modal_parameters) => {
        document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_currency_selector = () => {
        set_animate_out(true);
        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
    }

    function select_currency(index) {
        parameters.set(index);
        close_currency_selector();
    }

    let context_value = {
        open_currency_selector,
        close_currency_selector
    }

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">
                    <div className="outer_modal_click" onClick={close_currency_selector}></div>
                    <div className={`language_selector_container white_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="stick_text">
                            <div className="medium_spacer"></div>
                            <div style={{position: "relative"}}>
                                <h2 className="central_text_indicator title_font_family">Select Currency</h2>
                                <img className="back_icon opacity_hover" style={{left: "20px"}} src={down_icon} onClick={close_currency_selector}/>
                            </div>
                            <div className="medium_spacer"></div>
                            <p className="error_text_content">Select your preferred payment currency from the list below</p>
                            <div className="medium_spacer"></div>
                        </div>
                        <div className="language_list_container notranslate">
                            {
                                currencies.map((currency, index) => (
                                    <>
                                    {
                                        // index != 0 && 
                                        <div key={index} className="language_item gray_button_hover" onClick={()=>select_currency(index)}>
                                            <img className="language_icon" src={currency.icon}/>
                                            <div className="language_amount_label">{currency.name}</div>
                                            <img className="language_arrow_right" src={right_icon}/>
                                        </div>
                                    }
                                    </>
                                ))
                            }
                        </div>
                        <div className="small_spacer"></div>
                    </div>
                </div>
            }
        </ModalContext.Provider>
    )

}