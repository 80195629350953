import { useEffect, useState } from "react";



// ---- Components ----
import SEO from "../components/seo";
import Header from "../components/header";
import Sales_Widget from "../components/sales_widget";
import Top_Content from "../components/top_content";
import Featured_In from "../components/featured_in";
import Features from "../components/features";
import Live_Sales from "../components/live_sales";
import Earn from "../components/earn";
import Looboxes from "../components/lootboxes";
import Tokenomics from "../components/tokenomics";
import How_To_Buy from "../components/how_to_buy";
import Why_JetPaw from "../components/why_jetpaw";
import Develop from "../components/develop";
import FAQ from "../components/faq";
import Footer from "../components/footer";
import Telegram_Support from "../components/telegram_support"

// ---- Assets ----
import hero_cat_image from "../assets/images/hero_cat.png";
import hero_cat_overlay_image from "../assets/images/hero_cat_overlay.png";

// ---- Servies ----
import useToken from "../services/wallet/useToken";
import token_instance from "../services/wallet/token";
import { initialize_translator, set_translation } from "../services/language";
import { check_if_location_blocked } from "../services/location";

// ---- Modals ----
import { useErrorPopUp } from "../modals/error_pop_up";

function Home_Page() {

    let token = useToken();

    useEffect(()=> {

        (async function() {
            if (window.matchMedia('(display-mode: standalone)').matches) {

                // if (!token) {
                //     let x = await token_instance.backup_get();
                //     alert(x);
                // }
    
                window.location.href = '/profile';
                
            }
        })()
        
    },[])

    useEffect(()=>{

        // window.addEventListener("load", initialize_translator);

    },[]);

    // ---- Error Modal ----
    let { throw_error } = useErrorPopUp();

    // ---- Maximum Enforced Geo Block ----
    // let [hard_stop, set_hard_stop] = useState(null);
    let [hard_stop, set_hard_stop] = useState(false);

    function geo_error() {
        let params = {
            title: "Oh No!",
            content: "It looks like you are trying to make a purchase from a country where our service is currently unavailable.",
            buttons: [
                {
                    title: "Okay",
                }
            ]
        }
        throw_error(params);
    }

    // useEffect(()=>{
    //     (async function() {
    //         try {
    //             let { is_forced } = await check_if_location_blocked();
    //             if (is_forced) {
    //                 set_hard_stop(true);
    //             }
    //             else {
    //                 set_hard_stop(false);
    //             }
    //         } catch (error) {
    //             set_hard_stop(false);
    //         }
    //     })();
    // },[])
      
    return (
        <>
            <SEO/>
            <Header hard_stop={hard_stop} geo_error={geo_error}/>
            <div className="big_big_spacer"></div>
            <div className="page_container">
                <div className="safe_inner">
                    <img className="hero_cat_image" src={hero_cat_image}/>
                    <img className="hero_cat_image hero_cat_flowing_image" src={hero_cat_overlay_image}/>
                    {/* <img className="hero_cat_image hero_cat_overlay_image" src={hero_cat_overlay_image}/> */}
                    <Top_Content/>
                    <Sales_Widget hard_stop={hard_stop} geo_error={geo_error}/>
                    <Featured_In/>
                    
                </div>
            </div>
            <Features hard_stop={hard_stop} geo_error={geo_error}/> 
            <Live_Sales  hard_stop={hard_stop} geo_error={geo_error}/>
            <Earn hard_stop={hard_stop} geo_error={geo_error}/>
            <Why_JetPaw hard_stop={hard_stop} geo_error={geo_error}/>
            <Looboxes hard_stop={hard_stop} geo_error={geo_error}/>
            <Tokenomics hard_stop={hard_stop} geo_error={geo_error}/>
            <How_To_Buy hard_stop={hard_stop} geo_error={geo_error}/>
            <Develop hard_stop={hard_stop} geo_error={geo_error}/>
            <FAQ/>
            <Footer/>
            {/* <Telegram_Support/> */}
        </>
    )
}

export default Home_Page;