import { useState, useEffect } from "react";

// ---- Icons ----
import logo_dark_icon from "../assets/icons/logo_dark.png";
import profile_icon from "../assets/icons/profile.png";
import stake_icon from "../assets/icons/stake.png"
import explore_icon from "../assets/icons/explore.png";
import friends_icon from "../assets/icons/friends.png";
import history_icon from "../assets/icons/history.png";
import more_icon from "../assets/icons/more.png";
import settings_icon from "../assets/icons/settings.png";
import plus_black_icon from "../assets/icons/plus_black.png";

// ---- Modals ----
import { usePurchaseFunnel } from "../modals/purchase_funnel";

// ---- External Links ----
import external_links from "../configs/external_links.json";
import { useErrorPopUp } from "../modals/error_pop_up";

// ---- Services ----
import { check_if_location_blocked } from "../services/location";

let nav_items = [
    {
        name: "Profile",
        icon: profile_icon,
        link: "/profile",
        index: 0
    },
    {
        name: "Explore",
        icon: explore_icon,
        link: "/explore",
        index: 0
    },
    {
        name: "Staking",
        icon: stake_icon,
        link: "/staking",
        index: 0
    },
    {
        name: "Friends",
        icon: friends_icon,
        link: "/friends",
        index: 0
    },
    {
        name: "History",
        icon: history_icon,
        link: "/history",
        index: 0
    },
    {
        name: "Settings",
        icon: settings_icon,
        link: "/settings",
        index: 0
    },
]

let nav_items_mobile = [
    {
        name: "Profile",
        icon: profile_icon,
        link: "/profile",
        index: 0
    },
    {
        name: "Staking",
        icon: stake_icon,
        link: "/staking",
        index: 0
    },
    {
        name: "Buy",
        icon: plus_black_icon,
        link: "/more",
        index: 0
    },
    {
        name: "Friends",
        icon: friends_icon,
        link: "/friends",
        index: 0
    },
    // {
    //     name: "History",
    //     icon: history_icon,
    //     link: "/history",
    //     index: 0
    // }
    {
        name: "Explore",
        icon: explore_icon,
        link: "/explore",
        index: 0
    },
]

function App_Nav({ children, selected_index, selected_index_mobile }) {

    // ---- Error Modal ----
    let { throw_error } = useErrorPopUp();

    // ---- Maximum Enforced Geo Block ----
    // let [hard_stop, set_hard_stop] = useState(null);
    let [hard_stop, set_hard_stop] = useState(false);

    function geo_error() {
        let params = {
            title: "Oh No!",
            content: "It looks like you are trying to make a purchase from a country where our service is currently unavailable.",
            buttons: [
                {
                    title: "Okay",
                }
            ]
        }
        throw_error(params);
    }

    // useEffect(()=>{
    //     (async function() {
    //         try {
    //             let { is_forced } = await check_if_location_blocked();
    //             if (is_forced) {
    //                 set_hard_stop(true);
    //             }
    //             else {
    //                 set_hard_stop(false);
    //             }
    //         } catch (error) {
    //             set_hard_stop(false);
    //         }
    //     })();
    // },[])

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    async function buy_direct() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "buy_direct"});
        }
    }

    function share_jetpaw() {
        if (navigator.share) {
            navigator.share({
                title: "JetBolt",
                text: "Check out JetBolt a game changer new cryptocurrency.",
                url: "https://jetbolt.io"
            })
        }
    }

    return (
        <>  
            {/* <div className="extra_left"></div> */}
            <div className="page_container">
                <div className="fixed_right"></div>
                <div className="left_nav">
                    <div className="safe_inner">
                        <img className="header_logo opacity_hover" src={logo_dark_icon} onClick={()=>window.location="/"}/>
                        <div className="header_text_logo title_font_family notranslate opacity_hover" onClick={()=>window.location="/"}>JetBolt</div>
                        <div className="large_spacer"></div>
                        <div className="large_spacer"></div>

                        {
                            nav_items.map((item, index) => (
                                <>
                                {
                                    index == selected_index ?
                                    <div key={index}>
                                        <div className="small_spacer"></div>
                                        <div className="left_nav_item left_nav_item_selected app_nav_special_hover_selected" onClick={()=>window.location.reload()}>
                                            <img className="left_nav_icon left_nav_icon_selected" src={item.icon}/>
                                            <div className="left_nav_label left_nav_label_selected">{item.name}</div>
                                        </div>
                                    </div> :
                                    <div key={index}>
                                        <div className="small_spacer"></div>
                                        <div className="left_nav_item app_nav_special_hover" onClick={()=>window.location=item.link}>
                                            <img className="left_nav_icon" src={item.icon}/>
                                            <div className="left_nav_label">{item.name}</div>
                                        </div>
                                    </div>
                                }
                                </>
                            ))
                        }
                    </div>
                    <div className="left_nav_footer notranslate">
                        <div className={`full_width_button yellow_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>Buy JetBolt</div>
                        <div className="medium_spacer"></div>
                        <p className="small_text grayed_text">Copyright 2024 All Rights Reserved</p>
                        <div className="medium_spacer"></div>
                        <p className="small_text grayed_text"><u className="underlined_text" onClick={()=>window.open(external_links.disclaimer)}>Disclaimer</u> | <u className="underlined_text" onClick={()=>window.open(external_links.terms)}>Terms</u> | <u className="underlined_text" onClick={()=>window.open(external_links.privacy)}>Privacy</u></p>
                        <div className="medium_spacer"></div>
                    </div>
                </div>
                <div className="bottom_nav_shader"></div>
                <div className="bottom_nav">
                    
                    {
                        nav_items_mobile.map((item, index) => (
                            <>
                            {
                                index == 2 ?
                                <div className={`buy_bottom_item yellow_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>
                                    <img className="buy_bottom_icon" src={item.icon}/>
                                    <div className="buy_bottom_label">{item.name}</div>
                                </div> :
                                    index == selected_index_mobile ?
                                    <div key={index} className="bottom_nav_item bottom_nav_item_selected">
                                        <img className="bottom_nav_item_icon bottom_nav_item_icon_selected" src={item.icon}/>
                                        <div className="bottom_nav_item_label bottom_nav_item_label_selected">{item.name}</div>
                                    </div> :
                                    <div key={index} className="bottom_nav_item bottom_nav_item_hover" onClick={()=>window.location=item.link}>
                                        <img className="bottom_nav_item_icon" src={item.icon}/>
                                        <div className="bottom_nav_item_label">{item.name}</div>
                                    </div>
                            }
                            </>
                        ))
                    }
                </div>
                <div className="app_content">
                    <div className="extra_header">
                        <img className="extra_header_logo opacity_hover" src={logo_dark_icon} onClick={()=>window.location="/"}/>
                        <div className="extra_header_text_logo notranslate opacity_hover" onClick={()=>window.location="/"}>JetBolt</div>
                        {
                            navigator.share &&
                            <div className="install_app_button gray_button_hover" onClick={share_jetpaw}>Share</div>
                        }
                        
                    </div>
                    <div className="safe_inner">
                        { children }
                    </div>
                </div>
            </div>
        </>
    )

}

export default App_Nav;