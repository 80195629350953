import { ethers } from "ethers";

export function format_dmy(timestamp) {
    const date = new Date(timestamp * 1000);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear() % 100;

    const formattedDate = `${String(day).padStart(2, '0')}/${String(month).padStart(2, '0')}/${String(year).padStart(2, '0')}`;
    return formattedDate;
}

export function is_valid_email_address(email) {
  const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regexPattern.test(email);
}

export function group_transactions_by_date(transactions) {
  const grouped = {};

  transactions.forEach(transaction => {
    const date = new Date(transaction.date * 1000).toLocaleDateString();
    if (!grouped[date]) {
      grouped[date] = [];
    }
    grouped[date].push(transaction);
  });

  return Object.values(grouped);
}

export function shorten_crypto_string(input_string, start_length=6, end_length=4) {
  if (!input_string || typeof input_string !== 'string') {
    throw new Error('Input must be a valid string');
  }

  if (input_string.length <= start_length + end_length) {
    return input_string;
  }

  const start = input_string.slice(0, start_length);
  const end = input_string.slice(-end_length);
  return `${start}...${end}`;
}

export function get_labels_for_time_scale(time_scale_index) {
  const date = new Date(); // Today's date
  let labels = [];

  switch (time_scale_index) {
      case 0: // "1D" - Generate hourly labels for the past 24 hours
          for (let i = 0; i < 24; i++) {
              const hour = new Date(date);
              hour.setHours(date.getHours() - i);
              labels.unshift(hour.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false }));
          }
          break;
      case 1: // "7D" - Generate daily labels for the past week
          for (let i = 0; i < 7; i++) {
              const day = new Date(date);
              day.setDate(day.getDate() - i);
              labels.unshift(day.toLocaleDateString('en-US', { weekday: 'long' }));
          }
          break;
      case 2: // "1M" - Generate daily labels for the past 30 days
          for (let i = 0; i < 30; i++) {
              const day = new Date(date);
              day.setDate(day.getDate() - i);
              labels.unshift(day.toLocaleDateString('en-US', { day: 'numeric', month: 'short' }));
          }
          break;
      case 3: // "1Y" - Generate monthly labels for the past year
          for (let i = 0; i < 12; i++) {
              const month = new Date(date);
              month.setMonth(date.getMonth() - i);
              labels.unshift(month.toLocaleDateString('en-US', { month: 'long' }));
          }
          break;
      default:
          labels = ['Invalid time scale index'];
  }

  return labels;
}

export function format_time_mm_ss(timestamp) {
  // Convert the timestamp from seconds to a Date object
  const date = new Date(timestamp * 1000);

  // Extract minutes and seconds
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const seconds = date.getUTCSeconds();

  // Format the minutes and seconds with leading zeros if necessary
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  // Return the formatted time string
  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}


export function is_valid_username(username) {
  let regex = /^[a-zA-Z0-9_]+$/;
  return regex.test(username) && username.length >= 3;
}

export function is_valid_ethereum_address(address) {
  try {
      return ethers.isAddress(address);
  } catch (error) {
      console.error("Error validating address:", error);
      return false;
  }
}

export function format_timestamp(timestamp) {
  const date = new Date(timestamp * 1000); // Convert the timestamp to milliseconds
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the time with leading zeros if necessary
  const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  return formattedTime;
}

// export function increment_ref(input_ref) {
//   let value = input_ref.current.value;
//   if (value.includes('.')) {
//       let parts = value.split('.');
//       let integerPart = parseInt(parts[0], 10);
//       let decimalPart = parseInt(parts[1], 10) + 1; // increment the entire decimal part as a number
//       let decimalPlaces = parts[1].length; // get the length of the decimal part to maintain leading zeros

//       if (decimalPart >= Math.pow(10, decimalPlaces)) { // check if it overflows
//           decimalPart = 0; // reset decimal part
//           integerPart += 1; // increment the integer part
//       }

//       // pad the decimal part with leading zeros if necessary
//       input_ref.current.value = `${integerPart}.${decimalPart.toString().padStart(decimalPlaces, '0')}`;
//   } else {
//       input_ref.current.value = (parseInt(value, 10) + 1).toString();
//   }
// }

export function increment_ref(input_ref, offset = 0) {

  let value = input_ref.current.value;

  if (value.includes('.')) {
    let parts = value.split('.');
    let integer_part = parseInt(parts[0], 10);
    let decimal_part = parseInt(parts[1], 10);
    let decimal_places = parts[1].length;

    if (offset === 0) {
      decimal_part += 1;
    } else {
      decimal_part += Math.pow(10, offset);
    }

    if (decimal_part >= Math.pow(10, decimal_places)) {
      integer_part += Math.floor(decimal_part / Math.pow(10, decimal_places));
      decimal_part = decimal_part % Math.pow(10, decimal_places);
    }

    input_ref.current.value = `${integer_part}.${decimal_part.toString().padStart(decimal_places, '0')}`;
  } else {
    let integer_part = parseInt(value, 10);

    if (offset === 0) {
      integer_part += 1;
    } else {
      integer_part += Math.pow(10, offset);
    }

    input_ref.current.value = integer_part.toString();
  }
}


// export function decrement_ref(input_ref) {
//   let value = input_ref.current.value;
//   if (value.includes('.')) {
//       let parts = value.split('.');
//       let integerPart = parseInt(parts[0], 10);
//       let decimalPart = parseInt(parts[1], 10) - 1; // decrement the entire decimal part as a number
//       let decimalPlaces = parts[1].length; // get the length of the decimal part to maintain leading zeros

//       if (decimalPart < 0) {
//           decimalPart = Math.pow(10, decimalPlaces) - 1; // wrap around the decimal part
//           if (integerPart > 0) {
//               integerPart -= 1; // decrement the integer part if possible
//           }
//       }

//       // pad the decimal part with leading zeros if necessary
//       input_ref.current.value = `${integerPart}.${decimalPart.toString().padStart(decimalPlaces, '0')}`;
//   } else {
//       input_ref.current.value = (parseInt(value, 10) - 1).toString();
//   }
// }

export function decrement_ref(input_ref, offset = 0) {
  let value = input_ref.current.value;

  if (value.includes('.')) {
    let parts = value.split('.');
    let integer_part = parseInt(parts[0], 10);
    let decimal_part = parseInt(parts[1], 10);
    let decimal_places = parts[1].length;

    if (offset === 0) {
      decimal_part -= 1;
    } else {
      decimal_part -= Math.pow(10, offset);
    }

    if (decimal_part < 0) {
      decimal_part += Math.pow(10, decimal_places);
      if (integer_part > 0) {
        integer_part -= 1;
      }
    }

    input_ref.current.value = `${integer_part}.${decimal_part.toString().padStart(decimal_places, '0')}`;

  } else {
    let integer_part = parseInt(value, 10);

    if (offset === 0) {
      integer_part -= 1;
    } else {
      integer_part -= Math.pow(10, offset);
    }

    input_ref.current.value = integer_part.toString();
  }

  if (input_ref.current.value < 0) {
    input_ref.current.value = 0
  }
}

export function is_number(value) {
  return typeof value === 'number' && !isNaN(value);
}

export function short_format_number(number) {
  number = parseFloat(number);

  if (!number || isNaN(number)) { return 0; } // Simplified check for non-number values

  if (number < 1) {
    return Math.floor(number * 100) / 100; // Rounds down to 2 decimal places
  }

  const suffixes = ['', 'K', 'M', 'B', 'T'];
  const suffixIndex = Math.floor(Math.log10(number) / 3); // Determines the suffix to use

  if (suffixIndex === 0) {
    return Math.floor(number * 100) / 100; // Rounds down to 2 decimal places for small numbers
  }

  const shortNumber = number / Math.pow(10, suffixIndex * 3);
  const roundedDownNumber = Math.floor(shortNumber * 10) / 10; // Rounds down to 1 decimal place

  return `${roundedDownNumber}${suffixes[suffixIndex]}`; // Concatenates the number with the appropriate suffix
}

export function on_focus_input_ref(input_ref) {
  if (input_ref && input_ref.current) {
    input_ref.current.style.border = '2px solid var(--primary_blue)';
  }
}

export function on_blur_input_ref(input_ref) {
  if (input_ref && input_ref.current) {
    input_ref.current.style.border = '';
  }
}

export function select_input_ref(input_ref, outer_ref) {
  if (input_ref && input_ref.current && outer_ref && outer_ref.current) {
    outer_ref.current.style.border = '2px solid var(--primary_blue)';
    input_ref.current.focus();
  }
}


export function convert_12_hr_format(index) {
  if (index < 0 || index > 24) {
    throw new Error("Index out of range");
  }

  let period = index < 12 ? "AM" : "PM";
  let hour = index % 12;
  hour = hour === 0 ? 12 : hour; // Convert hour 0 to 12 for 12 AM/PM

  return `${hour} ${period}`;
}


export function append_query_params(url_string, new_params) {
  const url = new URL(url_string);
  const params = new URLSearchParams(url.search);
  Object.entries(new_params).forEach(([key, value]) => {
    params.set(key, value);
  });
  url.search = params.toString();
  return url.toString();
}

export function short_date(timestamp) {
  const date = new Date(timestamp * 1000);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear(); 
  return `${day} ${month} ${year}`;
}

export function sleep (time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

// export function add_thousand_separator(number) {
//   const numberStr = number.toString();
//   const reversedStr = numberStr.split('').reverse().join('');
//   const withCommas = reversedStr.replace(/(\d{3})(?=\d)/g, '$1,');
//   return withCommas.split('').reverse().join('');
// }

export function add_thousand_separator(number) {
  // Convert the number to a string
  const numberStr = number.toString();

  // Split the number into integer and decimal parts
  const parts = numberStr.split('.');
  const integerPart = parts[0];
  const decimalPart = parts[1];

  // Reverse the integer part, add commas, then reverse back
  const reversedStr = integerPart.split('').reverse().join('');
  const withCommas = reversedStr.replace(/(\d{3})(?=\d)/g, '$1,');
  const formattedInteger = withCommas.split('').reverse().join('');

  // Combine the integer and decimal parts back into one string
  // Only add the decimal part if it exists
  return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
}


export function append_utm_without_reloading(utm_params) {
  const currentUrl = new URL(window.location.href);
  const searchParams = new URLSearchParams(currentUrl.search);

  utm_params.split('&').forEach(param => {
      const [key, value] = param.split('=');
      searchParams.set(key, value);
  });

  const newUrl = `${currentUrl.origin}${currentUrl.pathname}?${searchParams.toString()}${currentUrl.hash}`;

  window.history.pushState({ path: newUrl }, '', newUrl);
}

export function remove_param_without_reloading(param_to_remove) {
  const current_url = new URL(window.location.href);
  const search_params = new URLSearchParams(current_url.search);

  search_params.delete(param_to_remove);

  const new_url = `${current_url.origin}${current_url.pathname}?${search_params.toString()}${current_url.hash}`;

  window.history.pushState({ path: new_url }, '', new_url);
}

export function is_web_view() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const is_os_webview = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(userAgent);
  const is_android_webview = userAgent.indexOf('wv') > -1 && /Version\/\d+\.\d+\.\d+ Chrome/.test(userAgent);
  return { is_os_webview, is_android_webview };
}

export async function check_web_auth_support() {
  if (!window.PublicKeyCredential) {
      return false;
  }

  // if (window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable) {
  //     try {
  //         const isAvailable = await window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable();
  //         if (!isAvailable) {
  //             return false;
  //         }
  //     } catch (error) {
  //         return false;
  //     }
  // }

  return true;
}

export function is_desktop() {
  const userAgent = navigator.userAgent.toLowerCase();
  const isDesktopOS = /windows|macintosh|linux|ubuntu/.test(userAgent);
  const isLargeScreen = window.innerWidth > 1024;
  const isNonTouch = !('ontouchstart' in window) || navigator.maxTouchPoints <= 1;

  return isDesktopOS && (isLargeScreen || isNonTouch);
}
