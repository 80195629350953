import React, { useEffect, useState, useRef } from "react";


// ---- Data ----
import { currencies } from "../assets/data/currencies";

// ---- Services ----
import { get_minting_history } from "../services/contract_functions/token";
import { format_timestamp, short_format_number, add_thousand_separator } from "../services/helper_functions";

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Modals ----
import { usePurchaseFunnel } from "../modals/purchase_funnel";

function Live_Sales({ hard_stop, geo_error }) {

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function buy_direct() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "buy_direct"});
        }
    }

    // ---- Refs ----
    let section_ref = useRef(null);
    let refresh_button = useRef(null);

    // ---- Hooks ----
    let [sales, set_sales] = useState([]);
    let [is_loaded, set_is_loaded] = useState(false);
    let [is_loading, set_is_loading] = useState(false);

    // ---- Onload ----
    async function load_live_sales() {

        if (is_loading) { return; }
        set_is_loading(true);

        if (refresh_button.current) {
            refresh_button.current.style = "pointer-events: none; opacity: 0.25;";
        }

        let minting_history = await get_minting_history(0, 10);
        let sales = [];
        
        for (let i = 0; i < minting_history.length; i++) {
            try {

                let tx = minting_history[i];
                let currency = currencies.find(cur => cur.symbol.toUpperCase() === tx.source_currency_name.toUpperCase());
                let amount = add_thousand_separator(parseFloat(ethers.formatUnits(tx.source_currency_amount)).toFixed(currency.sig_figs));
                let timestamp = format_timestamp(Number(tx.timestamp));

                sales.push({ currency, amount, timestamp });

            } catch (error) {
                console.log(error)
            }
        }

        set_sales([]);
        
        sales.reverse().forEach((sale, index) => {
            setTimeout(() => {
                set_sales(prev => [sale, ...prev]);
            }, index * 100);
        });

        setTimeout(()=>{
            set_is_loading(false);
            if (refresh_button.current) {
                refresh_button.current.style = "pointer-events: auto; opacity: 1;";
            }
        }, sales.length*100);
    }

    useEffect(() => {

        if (!is_loaded) {
            const observer = new IntersectionObserver((entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    load_live_sales();
                    set_is_loaded(true);
                    observer.disconnect();
                }
            }, {
                rootMargin: '50px',
                threshold: 0.1
            });

            if (section_ref.current) {
                observer.observe(section_ref.current);
            }

            return () => {
                if (section_ref.current) {
                    observer.unobserve(section_ref.current);
                }
            };
        }

    },[is_loaded]);


    return (
        <div className="page_container">
            <div className="safe_inner">
                <div className="radial_glow_extra_top_blue"/>
                <div className="radial_glow_extra_bottom_blue"/>
                <div className="trapeze_container_top">
                    <div className="live_sales_description_container">
                        <h1 className="title_font_family">Live Sales</h1>
                        <div className="medium_spacer"></div>
                        <p className="large_text">You can purchase JetBolt tokens with a wide range of payment options. Join the growing number of early adopters on the JetBolt ecosystem.</p>
                        <div className="medium_spacer"></div>
                        <div className={`inline_button_left blue_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>Buy Now</div>
                        <div className="inline_button_right gray_button_hover" onClick={load_live_sales} ref={refresh_button}>Refresh</div>
                    </div>
                    <div className="live_sales_container">
                        <div className="live_sales_inner_container" ref={section_ref}>
                            {
                                sales.map((item, index) => (
                                    <div className="live_sales_item" key={index}>
                                        <img className="live_sales_icon" src={item.currency.icon}/>
                                        <div className="live_sales_amount_label">{item.amount} {item.currency.symbol}</div>
                                        {/* <div className="live_sales_time_label">{item.timestamp}</div> */}
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="middle_line"></div>
                    <div className="top_box_decor"></div>
                    {/* <div className="bottom_box_decor"></div> */}
                </div>
                {/* <div className="large_spacer"></div> */}
               
            </div>
        </div>
    )
}

export default Live_Sales;