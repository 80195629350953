import { useRef, useEffect } from "react";

// ---- Data ----
import { press } from "../assets/data/press";

function Featured_In() {

    let images = [1,2,3,4,5,6,7,8,9];

    // ---- Refs ----
    let container_ref = useRef(null);

    // ---- Hooks ----
    useEffect(()=> {

        const container = container_ref.current;
        const interval = setInterval(() => {
            if (!container) return;
            container.scrollLeft += 2;
            if (container.scrollWidth - container.scrollLeft <= container.clientWidth * 2) {
                container.append(...container.cloneNode(true).children);
            }
        }, 20);
    
        return () => clearInterval(interval);

    },[])

    return (
        <>
            <div className="large_spacer"></div>
            <div className="large_spacer"></div>
            <h2 className="central_text_indicator title_font_family">Featured In</h2>
            <div className="large_spacer"></div>
            <div className="featured_scroll_container" ref={container_ref}>
                {
                    press.map((item, index) => (
                        <img key={index} className="featured_image" src={item.icon}/>
                    ))
                }
            </div>
            <div className="medium_spacer"></div>
        </>
    )

}

export default Featured_In;