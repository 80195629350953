import { Helmet } from 'react-helmet';

function SEO({ parameters }) {

    let title = "JetBolt - Offical Website";
    let description = "JetBolt aims to power the next generation of crypto apps. Join now and be part of the future of crypto.";
    let url = "https://jetbolt.io";
    let sharing_img = "https://jetbolt.io/share.png";
    let canon_url = "https://jetbolt.io";
    let keywords = "JetBolt token, cryptocurrency, crypto, blockchain, defi, web3, crypto presale, next generation crypto apps, zero gas fees, mobile-first web wallet, non-custodial wallet, fast transaction speeds, Web2 to Web3 transition, Skale Network integration, Web3 user onboarding, play-to-earn games, NFT projects, DeFi protocols, blockchain solutions, crypto presale, cryptocurrency applications, blockchain efficiency, crypto ecosystem, decentralized finance, NFT marketplace, token sale";
    let author = "JetBolt";

    if (parameters) {
        title = parameters.title || title;
        description = parameters.description || description;
        url = parameters.url || url;
        sharing_img = parameters.sharing_img || sharing_img;
    }

    return (
        <Helmet>
            {/* Primary Meta Tags */}
            <title>{title}</title>
            <meta name="title" content={title} />
            <meta name="description" content={description} />

            {/* Open Graph / Facebook Meta Tags */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={sharing_img} />
            
            {/* Twitter Meta Tags */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:url" content={url} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={sharing_img} />
            
            {/* Canonical URL */}
            <link rel="canonical" href={canon_url} />
            
            {/* Robots Meta Tags */}
            <meta name="robots" content="index, follow" />
            
            {/* Author Meta Tag */}
            <meta name="author" content={author} />
            
            {/* Additional Meta Tags */}
            <meta name="keywords" content={keywords} />
        </Helmet>
    );
}

export default SEO;
