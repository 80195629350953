import { hooks, TweedFrontendSdkProvider } from '@paytweed/frontend-sdk-react';
import { useEffect, useState, useCallback } from 'react';
import useCustomer from "../../services/wallet/useCustomer";
import axios from "axios";
import { append_utm_without_reloading } from "../../services/helper_functions";
import { ethers } from "ethers";

function Tweed_Internal({ close, parameters, set_parameters }) {

    let customer = useCustomer();
    let frontendSDK = hooks.useTweedFrontendSDK();

    useEffect(()=>{
        (async function() {
            let wallet = ethers.Wallet.createRandom();
            let response = await axios.post("https://api.jetpaw.co/minting/pay_tweed", { amount: parameters.hint_amount, customer_id: customer.customer_id });
            let order_id = response.data.order_id;
            await frontendSDK.token.buyWithFiat({
                tokenId: '1',
                toWalletAddress: wallet.address,
                amount: 1,
                data: { customer_id: customer.customer_id, order_id },
                callbacks: {
                  onSuccess: (payload) => {
                    append_utm_without_reloading(`type=tweed&payment_id=${order_id}&pending=true`);
                    window.location.reload();
                  },
                  onError: (payload) => {
                    // console.log('error', payload);
                  },
                  onClose: (payload) => {
                    // console.log('close', payload);
                    close();
                  },
                },
            });
        })();
    },[])
    
    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Buy Token</h2>
                        {/* <img className="back_icon opacity_hover" src={down_icon} onClick={close}/> */}
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>

                    <div className="medium_spacer"></div>

                    <div className="loading_container">
                        <div className="loading_wheel"></div>
                        <p className="loading_text">Loading</p>
                    </div>
                </div>
            </div>
        </>
    );
}

function Pay_Tweed({ close, parameters, set_parameters }) {
    const sendMessageToBackend = useCallback(async (message) => {
        const response = await fetch('https://api.jetpaw.co/minting/message_tweed', {
            body: JSON.stringify({ message }),
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
        })
        const { answer } = await response.json()
        return answer
      }, [])
      return (  
        <>
          <TweedFrontendSdkProvider
          sendMessageToBackend={sendMessageToBackend}
          defaultBlockchainIds={['arbitrum']}
          theme="dark"
          >
            <Tweed_Internal close={close} parameters={parameters} set_parameters={set_parameters}/>
          </TweedFrontendSdkProvider>
        </>
    );
}

export default Pay_Tweed;