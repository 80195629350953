

// ---- Icons ----
import help_icon from "../../assets/icons/help.png";
import left_icon from "../../assets/icons/left.png";

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import { init_wallet } from "../../services/wallet/core";
import { is_web_view, check_web_auth_support } from "../../services/helper_functions";

function Login_Required({ parameters, set_parameters, mode=0 }) {

    let token = useToken();

    async function create_wallet(event) {
        
        event.target.style = "opacity: 0.25; pointer-events: none;";


        let { is_os_webview, is_android_webview } = is_web_view();
        if (is_os_webview || is_android_webview) {
            set_parameters({type: "force_browser_transfer"});
            return;
        }
        else if (!window.PublicKeyCredential) {
            set_parameters({type: "force_browser_transfer", mode: 1});
            return;
        }

        if (!token) {
            init_wallet();
        }

        if (!token) {
            set_parameters({type:"set_username"});
        }
        else if (!token.username) {
            set_parameters({type:"set_username"});
        }
        else if (!token.credential_id) {
            set_parameters({type:"create_authenticator"});
        }
        else if (!token.confirmed) {
            set_parameters({type:"save_seed_phrase"});
        }

        event.target.style = "opacity: 1; pointer-events: auto;";
        
    }

    function sign_in_w_seed() {

        let { is_os_webview, is_android_webview } = is_web_view();
        if (is_os_webview || is_android_webview) {
            set_parameters({type: "force_browser_transfer"});
            return;
        }
        else if (!window.PublicKeyCredential) {
            set_parameters({type: "force_browser_transfer", mode: 1});
            return;
        }

        set_parameters({type:"sign_in"});
    }

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">{
                            mode == 0 ?
                            "Account Required" :
                            "Claim Tokens"
                        }</h2>

                        {
                            mode == 0 &&
                            <img className="back_icon opacity_hover" src={left_icon} onClick={()=>window.location="/"}/>
                        }
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                    <div className="medium_spacer"></div>
                    {
                        mode == 0 ?
                        <p>Please create an account to access this page.</p> :
                        mode == 1 ?
                        <p>Congragulations on your purchase. Create an account to claim your tokens.</p> :
                        <p>Please complete your account setup.</p> 
                    }
                    <div className="medium_spacer"></div>
                    <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={create_wallet}>Create Wallet</div>
                    <div className="medium_spacer"></div>
                    <p>Already have a wallet? <u className="underlined_text" onClick={sign_in_w_seed}>Sign in with seed phrase</u></p>
                </div>
            </div>
        </>
    );

}

export default Login_Required;