import React, { createContext, useContext, useState, useEffect, useRef } from "react";

// ---- Icons ----
import right_icon from "../assets/icons/right.png";
import down_icon from "../assets/icons/down.png";
import paste_icon from "../assets/icons/paste.png";
import email_icon from "../assets/icons/email.png";
import help_icon from "../assets/icons/help.png";
import marketing_icon from "../assets/icons/marketing.png";
import developer_icon from "../assets/icons/developer.png";
import bug_report_icon from "../assets/icons/bug_report.png";
import more_icon from "../assets/icons/more.png";

// ---- Services ----
import { on_focus_input_ref, on_blur_input_ref, is_valid_email_address } from "../services/helper_functions";
import { make_inquiry } from "../services/api";
import useToken from "../services/wallet/useToken";
import useCustomer from "../services/wallet/useCustomer";

// ---- External Links ----
import external_links from "../configs/external_links.json";

const ModalContext = createContext();

export function useContactUs() {
    return useContext(ModalContext);
}

let topics = [
    {
        subject: "Customer Service",
        icon: help_icon
    },
    {
        subject: "Marketing Inquiry",
        icon: marketing_icon
    },
    {
        subject: "Developer Support",
        icon: developer_icon
    },
    {
        subject: "Bug Report",
        icon: bug_report_icon
    },
    {
        subject: "Other",
        icon: more_icon
    }
]

export function Contact_Us({ children }) {

    let token = useToken();
    let customer = useCustomer();

    // ---- Refs ----
    let email_address_ref = useRef(null);
    let email_address_outer_ref = useRef(null);
    let message_ref = useRef(null);

    let [state, set_state] = useState(-1);

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [is_loading, set_is_loading] = useState(false);
    let [parameters, set_parameters] = useState(null);

    let open_contact_us = (modal_parameters) => {
        document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_contact_us = () => {
        set_animate_out(true);
        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
    }

    async function send_contact_request() {
        set_is_loading(true);
        try {
            let email_address = email_address_ref.current.value;
            let message = message_ref.current.value;
            let customer_id = "";
            let wallet_address = "";
            if (customer && customer.customer_id) {
                customer_id = customer.customer_id;
            }
            if (token && token.wallet_address) {
                wallet_address = token.wallet_address;
            }
            await make_inquiry(email_address, customer_id, wallet_address, topics[state].subject, message);
            close_contact_us();
        } catch (error) {}
        set_is_loading(false);
    }

    let context_value = {
        open_contact_us,
        close_contact_us
    }

    // ---- Input Value Changes ----
    let [is_valid_email, set_is_valid_email] = useState(false);
    let [is_valid_message, set_is_valid_message] = useState(false);

    function email_changed() {
        if (email_address_ref.current) {
            let value = email_address_ref.current.value;
            set_is_valid_email(is_valid_email_address(value));
        }
    }

    function message_changed() {
        if (message_ref.current) {
            let value = message_ref.current.value;
            set_is_valid_message(value.length > 10);
        }
    }
    
    // ---- Paste ----
    async function paste(ref) {
        try {
            let text = await navigator.clipboard.readText();
            ref.current.value = text;
            email_changed();
        } catch (error) {}
    }

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">
                    <div className="outer_modal_click" onClick={close_contact_us}></div>
                    <div className={`language_selector_container white_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="stick_text">
                            <div className="medium_spacer"></div>
                            <div style={{position: "relative"}}>
                                <h2 className="central_text_indicator title_font_family">Contact Us</h2>
                                <img className="back_icon opacity_hover" style={{left: "20px"}} src={down_icon} onClick={close_contact_us}/>
                            </div>

                            <div className="medium_spacer"></div>
                        </div>
                        <div className="purchase_funnel_container">
                            

                            {
                                state == -1 &&
                                <>
                                    <p className="error_text_content">Select the topic of your inquiry</p>
                                    <div className="medium_spacer"></div>
                                    {topics.map((item,index)=>(
                                        <div key={index} className="friend_list_item gray_button_hover" onClick={()=>set_state(index)}>
                                            <img className="live_sales_icon" src={item.icon}/>
                                            <div className="live_sales_amount_label">{item.subject}</div>
                                            <div className="decline_button">
                                                <img className="inner_icon" src={right_icon}/>
                                            </div>
                                        </div>
                                    ))}

                                </>
                            }
                            {
                                state >= 0 &&
                                <>
                                    <p className="small_text">Email Address:</p>
                                    <div className="small_spacer"></div>
                                    <div className="payment_input_container notranslate" ref={email_address_outer_ref}>
                                        <div className="payment_input_currency_selector">
                                            <img className="payment_input_selector_icon" src={email_icon}/>
                                            <img className="payment_input_selector_extra_icon" src={right_icon}/>
                                        </div>
                                        <div className="paste_input_button gray_button_hover" onClick={()=>paste(email_address_ref)}>
                                            <img className="paste_icon" src={paste_icon}/>
                                            <div className="paste_text_inner">Paste</div>
                                        </div>
                                        <input className="wallet_address_input" placeholder="Email address" ref={email_address_ref} onChange={email_changed}
                                        type="email"
                                        onFocus={()=>on_focus_input_ref(email_address_outer_ref)} onBlur={()=>on_blur_input_ref(email_address_outer_ref)}/>
                                    </div>
                                    <div className="medium_spacer"></div>
                                    <p className="small_text">Inquiry Subject:</p>
                                    <div className="small_spacer"></div>
                                    <div className="friend_list_item gray_button_hover" onClick={()=>set_state(-1)}>
                                        <img className="live_sales_icon" src={topics[state].icon}/>
                                        <div className="live_sales_amount_label">{topics[state].subject}</div>
                                        <div className="decline_button">
                                            <img className="inner_icon" src={right_icon}/>
                                        </div>
                                    </div>

                                    <div className="small_spacer"></div>
                                    <p className="small_text">Message:</p>
                                    <div className="small_spacer"></div>
                                    <textarea ref={message_ref} className="inquiry_message" placeholder="Minimum 10 characters" onChange={message_changed}>

                                    </textarea>

                                    <div className="small_spacer"></div>
                                    <div className={`payment_button title_font_family yellow_button_hover ${(!is_valid_email || !is_valid_message || is_loading)?"unselectable":""}`} onClick={send_contact_request}>
                                        {
                                            is_loading ? "Sending..." : "Send"
                                        }
                                    </div>

                                    <div className="small_spacer"></div>
                                    <p className="grayed_text small_text">By contacting us through this form, you hereby consent to our <u className="underlined_text" onClick={()=>window.open(external_links.privacy)}>Privacy Policy</u>.</p>
                                </>
                            }

                                
                                
                            </div>

                            
                        
                    </div>
                </div>
            }
        </ModalContext.Provider>
    )

}