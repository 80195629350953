import { useEffect, useState, useRef } from "react";


// ---- Icons ----
import down_icon from "../../assets/icons/down.png";
import t_hrs_yellow from "../../assets/icons/24_hrs_yellow.png";
import money_yellow from "../../assets/icons/money_yellow.png";
import bonus_yellow from "../../assets/icons/bonus_yellow.png";
import friends_yellow from "../../assets/icons/friends_yellow.png";

import staking_sample_chart from "../../assets/images/staking_sample_chart.png";
import unstake_anytime from "../../assets/images/unstake_anytime.png";

import external_links from "../../configs/external_links.json";

function Staking_Guide({ close, parameters, set_parameters }) {

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Staking Guide</h2>
                        <img className="back_icon" src={down_icon} onClick={close}/>
                    </div>
                    <div className="medium_spacer"></div>
                </div>
                
                <h3 className="title_font_family yellow_text">How Staking Works?</h3>
                <div className="small_spacer"></div>
                <p>JetBolt staking works using an algorithm called Proof of Attendance and Worth (PAW). Below are some key points about how it works:</p>
                <div className="medium_spacer"></div>
                <div className="bullet_special_component">
                    <div className="bullet_image_container">
                        <img className="why_image" src={t_hrs_yellow}/>
                    </div>
                    <div className="bullet_text">You can claim rewards once every 24 hours. Staking rewards active users who login daily the most.</div>
                </div>
                <div className="small_spacer"></div>
                <div className="bullet_special_component">
                    <div className="bullet_image_container">
                        <img className="why_image" src={money_yellow}/>
                    </div>
                    <div className="bullet_text">The more tokens you stake, the higher your rewards. You can unstake tokens anytime.</div>
                </div>
                <div className="small_spacer"></div>
                <div className="bullet_special_component">
                    <div className="bullet_image_container">
                        <img className="why_image" src={bonus_yellow}/>
                    </div>
                    <div className="bullet_text">The longer your login streak, the higher the rewards. Earn up to 2x more this way.</div>
                </div>
                <div className="small_spacer"></div>
                <div className="bullet_special_component">
                    <div className="bullet_image_container">
                        <img className="why_image" src={friends_yellow}/>
                    </div>
                    <div className="bullet_text">Friends listed as favorites earn rewards when you claim, and vice versa.</div>
                </div>

                <div className="medium_spacer"></div>
                <h3 className="title_font_family yellow_text">How Much Can I Earn?</h3>
                <div className="small_spacer"></div>
                <p>Many factors will determine how much you earn by staking. The most important factors are how much you stake and how often you stake. The chart below gives an overview of how staking rewards evolve over 10 years.</p>
                <div className="medium_spacer"></div>
                <img className="staking_sample_chart" src={staking_sample_chart}/>
                <div className="small_spacer"></div>
                <p>It's worth keeping in mind that a substantial amount of JetBolt tokens have been allocated for staking rewards. In the first year the theoretical maximum APR exceeds 50%.</p>
                <div className="medium_spacer"></div>
                <h3 className="title_font_family yellow_text">When can I unstake my tokens?</h3>
                <div className="small_spacer"></div>
                <p>Staked tokens can be unstaked at any time. There is no lockup period. You can unstake them all or a partial amount.</p>
                <div className="medium_spacer"></div>
                <img className="unstake_anytime" src={unstake_anytime}/>
                <div className="small_spacer"></div>
                <p>It is worth noting however, that staking and unstake will reset your next claim time, so we do not recommend doing this too often.</p>
                <div className="medium_spacer"></div>
                <h3 className="title_font_family yellow_text">Want to learn more about staking?</h3>
                <div className="small_spacer"></div>
                <p>Much more detailed information is available in our whitepaper, including mathematical formulas and charts.</p>
                <div className="small_spacer"></div>
                <div className="read_wp_button opacity_hover" onClick={()=>window.open(external_links.whitepaper)}>Read Whitepaper</div>


            </div>
        </>
    );

}

export default Staking_Guide;