// ---- Services ----
import useToken from "../../services/wallet/useToken";
import { init_wallet, authorized_referrer, block_referrer } from "../../services/wallet/core";

function IFrame_Login({ parameters, set_parameters, close }) {

    let token = useToken();

    function create_wallet() {

        if (!token || (!token.username && !token.mnemonic && !token.credential_id && !token.wallet_address)) {
            init_wallet();
        }

        authorized_referrer(document.referrer);

        if (!token) {
            set_parameters({type:"set_username", close_callback: parameters.close_callback});
        }
        else if (!token.username) {
            set_parameters({type:"set_username", close_callback: parameters.close_callback});
        }
        else if (!token.credential_id) {
            set_parameters({type:"create_authenticator", close_callback: parameters.close_callback});
        }
        else if (!token.confirmed) {
            set_parameters({type:"save_seed_phrase", close_callback: parameters.close_callback});
        }
        
    }

    function accept_auth() {
        authorized_referrer(document.referrer);
        // window.parent.postMessage({response:{status:"success",status_code:200,wallet_address:token.wallet_address,session_address:token.session_address}}, document.referrer);
        close();
    }

    function decline_auth() {
        block_referrer(document.referrer);
        // window.parent.postMessage({response:{status:"fail",status_code:401}}, document.referrer);
        close();
    }

    function cancel_auth() {
        // window.parent.postMessage({response:{status:"fail",status_code:401}}, document.referrer);
        close();
    }

    function sign_in_w_seed() {
        set_parameters({type:"sign_in", referrer: document.referrer, close_callback: parameters.close_callback});
    }

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">
                            {
                                !token || !(token.confirmed && token.credential_id && token.username) ?
                                "Create Account" :
                                "Authorize App"
                            }
                        </h2>
                    </div>
                    <div className="medium_spacer"></div>
                    {
                        token && token.authorized_referrers && token.authorized_referrers[document.referrer] == false &&
                        <>
                            <p><b className="warning_text">Warning: you have previously blocked this site.</b></p>
                            <div className="small_spacer"></div>
                        </>
                    }
                    {
                        !token || !(token.confirmed && token.credential_id && token.username) ?
                        <>
                            <p>Create a JetBolt account and authorize this site to view your wallet address and request transactions on your wallet.</p>
                            <div className="medium_spacer"></div>
                            <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={create_wallet}>Create Wallet</div>
                            <div className="medium_spacer"></div>
                            <div className="title_font_family payment_button_dull opacity_hover" onClick={cancel_auth}>Cancel</div>
                            <div className="medium_spacer"></div>
                            <p>Already have a wallet? <u className="underlined_text" onClick={sign_in_w_seed}>Sign in with seed phrase</u></p>
                        </> :
                        <>
                            <p>Authorize this site to view your wallet address and request transactions on your JetBolt wallet.</p>
                            <div className="medium_spacer"></div>
                            <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={accept_auth}>Authorize</div>
                            <div className="medium_spacer"></div>
                            <div className="title_font_family payment_button_dull opacity_hover" onClick={decline_auth}>Block Site</div>
                        </>
                    }
                </div>
            </div>
        </>
    );

}

export default IFrame_Login;