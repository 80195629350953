import React, { createContext, useContext, useState, useEffect, useRef } from "react";

// ---- Icons ----
import down_icon from "../assets/icons/down.png";

import external_links from "../configs/external_links.json";

import { remove_param_without_reloading } from "../services/helper_functions";

let qna = [
    {
        question: "Someone with an admin user name calls me on Telegram. What should I do?",
        correct_index: 3,
        answers: [
            "Answer the call",
            "Wait for instructions",
            "Do what they say",
            "Decline and block them"
        ],
        extra_text: "The JetBolt team will never call you on Telegram. Attackers may replicate admin profiles in order to deceive users and steal their funds."
    },
    {
        question: "I receive a direct / personal message from an admin. What should I do?",
        correct_index: 2,
        answers: [
            "Answer",
            "Click the link",
            "Block and report them",
            "Have a nice chat"
        ],
        extra_text: "The JetBolt team will never message you directly Telegram. Attackers may replicate admin profiles in order to deceive users and steal their funds."
    },
    {
        question: "I notice there are now two JetBolt Telegram groups. What should I do?",
        correct_index: 1,
        answers: [
            "Use the newer group",
            "Visit jetbolt.io and rejoin to check which group is real",
            "Use the group with more members",
            "Use both groups"
        ],
        extra_text: "Attackers may create duplicate groups designed to imitate the JetBolt group. It can be very hard to tell which one is real and which is fake."
    },
    {
        question: "An admin asks me for my seed phrase or private key. What should I do?",
        correct_index: 0,
        answers: [
            "Block and report them",
            "Give it to them",
            "Check it is a real admin",
            "Have a nice chat"
        ],
        extra_text: "You should never under any circumstances share your seed phrase or private keys with anyone. The JetBolt team will never ask you for this."
    },
    {
        question: "An admin asks me to go claim an extra secret token bonus. What should I do?",
        correct_index: 2,
        answers: [
            "Click the link",
            "Check it is a real admin",
            "Block and report them",
            "Give them my seed phrase"
        ],
        extra_text: "There are no secret bonuses. Always check you are on jetbolt.io and not a copy site. This is a common tactic employed by attackers. Never do anything in a hurry."
    }
]

const ModalContext = createContext();

export function useTelegram() {
    return useContext(ModalContext);
}

export function Telegram({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);

    let [question_index, set_question_index] = useState(-1);
    let [show_extra, set_show_extra] = useState(false);

    let open_telegram = (modal_parameters) => {
        document.body.style.overflow = 'hidden';
        set_question_index(-1)

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_telegram = () => {
        set_animate_out(true);
        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
        remove_param_without_reloading("open_telegram");
    }

    useEffect(() => {

        let url = new URL(window.location.href);
        let url_params = url.searchParams;
        let sopen_telegram = url_params.get("open_telegram");
        if (sopen_telegram) {
            open_telegram();
            try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
        }

    },[]);

    function start_quizz() {
        set_question_index(0);
    }

    function next_question() {
        let friend_list_items = document.querySelectorAll(".quizz_list_item");
        for (let i = 0; i < friend_list_items.length; i++) {
            friend_list_items[i].style.border = "none";
        }
        set_show_extra(false);
        if (question_index + 1 < qna.length) {
            set_question_index(question_index + 1);
        }
        else {
            set_question_index(-2)
        }
    }
    function select_answer(e, i) {
        e.currentTarget.style.border = i == qna[question_index].correct_index ? "1px solid var(--green_text)":"1px solid var(--orange_text)";
        if (i == qna[question_index].correct_index) {
            setTimeout(() => next_question(), 500);
        }
        else {
            set_show_extra(true);
        }
    }

    function open_tg() {
        window.open(external_links.telegram);
    }

    let context_value = {
        open_telegram,
        close_telegram
    }

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">
                    <div className="outer_modal_click" onClick={close_telegram}></div>
                    <div className={`language_selector_container white_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="stick_text">
                            <div className="medium_spacer"></div>
                            <div style={{position: "relative"}}>
                                <h2 className="central_text_indicator title_font_family">Join Telegram</h2>
                                <img className="back_icon opacity_hover" style={{left: "20px"}} src={down_icon} onClick={close_telegram}/>
                            </div>

                            <div className="medium_spacer"></div>
                        </div>
                        <div className="purchase_funnel_container">

                            {
                                question_index == -1 &&
                                <>
                                    <p>To ensure your safety we recommend you take a quick quizz before joining our Telegram group.</p>
                                    <div className="medium_spacer"></div>
                                    <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={start_quizz}>Start Quizz</div>
                                    <div className="medium_spacer"></div>
                                    <p>Already a pro? <u className="underlined_text" onClick={open_tg}>Join directly</u></p>
                                </>
                            }
                            {
                                question_index >= 0 &&
                                <>
                                    <p>{qna[question_index].question}</p>
                                    {
                                        show_extra &&
                                        <>
                                            <div className="small_spacer"></div>
                                            <p className="warning_text">{qna[question_index].extra_text}</p>
                                        </>
                                    }
                                    <div className="medium_spacer"></div>
                                    {
                                        qna[question_index].answers.map((item,index)=>(
                                            <div key={index} className="quizz_list_item gray_button_hover" onClick={(e)=>select_answer(e, index)}>
                                                {/* <img className="live_sales_icon" src={item}/> */}
                                                <div className="live_sales_icon" style={{backgroundColor: "black", borderRadius: "5px", textAlign: "center"}}>{["A","B","C","D"][index]}</div>
                                                <div className="quizz_label">{item}</div>
                                                <div className="decline_button">
                                                    {/* <img className="inner_icon" src={right_icon}/> */}
                                                </div>
                                            </div>
                                        ))
                                    }
                                    
                                </>
                            }

                            {
                                question_index == -2 &&
                                <>
                                    <p>You can not join our Telegram group. Always remain vigilant.</p>
                                    <div className="medium_spacer"></div>
                                    <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={open_tg}>Join Telegram</div>
                                </>
                            }
                            
                        </div>
                    </div>
            
                </div>
            }
        </ModalContext.Provider>
    );
}