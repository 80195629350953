import { useEffect, useState } from 'react';

import WertWidget from "@wert-io/widget-initializer";
import { signSmartContractData } from '@wert-io/widget-sc-signer';
import { ethers } from 'ethers';

// ---- Icons ----
import down_icon from "../../assets/icons/down.png";

// ---- Servies ----
import useCustomer from "../../services/wallet/useCustomer";
import { trigger_event } from "../../services/analytics";
import { pay_wert, wert_status } from "../../services/api";
import { append_utm_without_reloading } from "../../services/helper_functions";
import configs from "../../configs/config.json";
import useToken from "../../services/wallet/useToken";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// reset -----
// reset localhost api -

// reset on news -
// "local": "true", - 
// add back news route -
// send_news_letter -

// deploy procedure ----
// deploy contract on base - 
// change network on server side - 
// change rpc on server - 
// change keys and ids server -
// change id on website
// delete sandbox flag

let partner_id = configs.wert_partner_id;

let can_open = true;
let dont_close = false;
let can_animated_text = true;

function Pay_Wert({ close, parameters, set_parameters }) {

    let token = useToken();

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    function payment_error() {
        let params = {
            title: "Oh No!",
            content: "An error has occured during your purchase. Please try again late.",
            buttons: [
                {
                    title: "Okay",
                    callback: close
                },
            ]
        }
        throw_error(params);
    }

    let customer = useCustomer();

    let [loading_text, set_loading_text] = useState("Loading...");

    let [extra_text, set_extra_text] = useState(false);

    useEffect(()=>{
        trigger_event("begin_checkout", parameters.hint_amount);
    },[parameters]);

    let text_list = ["Checking for update...", "Please wait...", "Checking for update...", "Almost there...", "Just a sec..."]

    function animate_text() {
        if (can_animated_text) {
            can_animated_text = false;
            let anim_index = 0;
            set_loading_text("Transaction pending...")

            let inter = setInterval(function() {
                if (anim_index == text_list.length) {
                    clearInterval(inter);
                }
                else {
                    set_loading_text(text_list[anim_index]);
                }
                anim_index++;
            },4000);
        }
    }

    useEffect(()=>{

        if (can_open && parameters && parameters.hint_amount && customer && customer.customer_id) {
            can_open = false;

            (async function() {
                
                let { signed_data, order_id } = await pay_wert(parameters.hint_amount, customer.customer_id);

                // console.log(signed_data, order_id);
 
                let extra_options = {
                    // origin: "https://sandbox.wert.io",
                    partner_id,
                
                    theme: "dark",
                    buttons_border_radius: 10,
                    is_crypto_hidden: true,
                    is_warranty_disabled: true,
                    color_background: "#061420",
                    color_buttons: "#ffff00",
                    color_buttons_text: "black",
                    color_secondary_buttons: "#010305",
                    color_secondary_buttons_text: "white",
                    color_links: "#00c8ff",
                    color_success: "#00bc97",
                    color_warning: "#00c8ff",
                    color_error: "#ff4220"
                }
            
                let item_info = {
                    author_image_url: "https://jetbolt.io/logo192.png",
                    author: "JetBolt",
                    image_url: "https://i.imgur.com/Oqy6Ag1.png",
                    name: "JetBolt Tokens",
                    category: "Cryptocurrency",
                    header: "JetBolt"
                }

                // let signed_data = signSmartContractData(options, private_key);

                let wert_widget;

                async function step_changed(step) {
                    console.log(step);
                }

                // let check_inter = setInterval(()=>{
                //     let iframes = document.querySelectorAll("iframe");
                //     for (let i = 0; i < iframes.length; i++) {
                //         let iframe = iframes[i];
                //         if (iframe.src.includes("wert")) {
                //             console.log("SRC", iframe.src);
                //             console.log("HERE", iframe.src.includes("commodity_id=eth.sc.arbitrum"))

                //             if (iframe.src.includes("commodity_id=eth.sc.arbitrum")) {
                //                 console.log("PASS CHECK");
                //                 iframe.style.display = "none";
                //                 set_extra_text(true);
                //             }
                //         }
                //     }
                // },1000);

                async function listener(data) {

                    // console.log("HERE", data);

                    let { status, tx_id } = data;

                    if (status && status == "pending") {
                        // let iframes = document.querySelectorAll("iframe");
                        // for (let i = 0; i < iframes.length; i++) {
                        //     let iframe = iframes[i];
                        //     if (iframe.src.includes("wert")) {
                        //         // iframe.style.display = "none";
                        //         console.log("SRC", iframe.src);
                        //         console.log("HERE", iframe.src.includes("commodity_id=eth.sc.arbitrum"))

                        //         if (iframe.src.includes("commodity_id=eth.sc.arbitrum")) {
                        //             console.log("PASS CHECK");
                        //             clearInterval(check_inter);
                        //             iframe.style.display = "none";
                        //             set_extra_text(true);
                        //         }
                        //     }
                        // }
                        if (tx_id) {
                            // console.log("HERE",tx_id)
                            // clearInterval(check_inter);
                            append_utm_without_reloading(`type=wert&payment_id=${order_id}&hash=${tx_id}&pending=true`);
                            set_parameters({type: "pending_evm", hint_amount: parameters.hint_amount, currency_index: 0, payment_id: order_id, is_wert: true, hash: tx_id});
                            dont_close = true;
                            wert_widget.close();
                            
                        }

                        // animate_text();
                    }
                    else if (status && ["failed", "failover"]) {
                        dont_close = true;
                        wert_widget.close();
                        payment_error();
                    }
                }

                wert_widget = new WertWidget({
                    ...signed_data,
                    ...extra_options,
                    extra: { item_info },
                    listeners: {
                      'payment-status': listener,
                      'close': function() {
                        if (dont_close == false) {
                            close();
                        }
                      },
                      'position': step_changed
                    },
                });
                
                wert_widget.open();

            })();
        }

    },[parameters])

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Buy Token</h2>
                        {/* <img className="back_icon opacity_hover" src={down_icon} onClick={close}/> */}
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>

                    <div className="medium_spacer"></div>

                    <div className="loading_container">
                        <div className="loading_wheel"></div>
                        <p className="loading_text">{loading_text}</p>
                    </div>
                    
                    {
                        extra_text &&
                        <>
                            <div className="medium_spacer"></div>
                            {
                                (!token || !(token.confirmed && token.credential_id && token.username)) ?
                                <p>If this takes a long time you can leave this page and return later. You will be automatically prompted to setup your JetBolt wallet and claim your tokens.</p> :
                                <p>Tokens will automatically be deposited into @{token.username}. If this takes a long time you can leave this page and return later.</p>
                            }
                            
                        </>
                    }
                </div>
            </div>
        </>
    );

}

export default Pay_Wert;