import { ethers } from "ethers";
import token_instance from "./token";
import customer_instance from "./customer";

import { create_credential, assert_credential } from "./webauthn";
import { generate_encryption_key, encrypt, decrypt } from "./encryption";
import { request_gas } from "./request_gas";

import { sign_and_send_transaction } from "./transaction_builder";

import { call_contract_with_private_key } from "../contract_functions/core";

import { get_username } from "../contract_functions/friends";

import { generate_key_pair, encrypt_with_public_key, decrypt_with_private_key } from "./asymmetric_encryption"

// Standard derivation path for Ethereum
let derivation_path = "m/44'/60'/0'/0/";

export function wallet_from_seed(mnemonic) {

    // Generate the first wallet from the seed phrase
    let master_node = ethers.HDNodeWallet.fromPhrase(mnemonic);

    let purpose_node = master_node.deriveChild(44);   // equivalent to 44'
    let coin_type_node = purpose_node.deriveChild(60); // equivalent to 60'
    let account_node = coin_type_node.deriveChild(0);  // equivalent to 0'
    let change_node = account_node.deriveChild(0);    // equivalent to 0
    let address_node = change_node.deriveChild(0);    // equivalent to 0

    let wallet_address = address_node.address;
    let private_key = address_node.privateKey;

    return { wallet_address, private_key }
}

export async function init_wallet(mnemonic) {
    
    // Generate a random seed phrase if one is not provided
    if (!mnemonic) { mnemonic = ethers.Wallet.createRandom().mnemonic.phrase; }

    // Initialize instance of token such that mnemonic is temporarily retrievable
    token_instance.init(mnemonic);

    // Generate the first wallet address from the seed phrase
    let { wallet_address } = wallet_from_seed(mnemonic);

    // Return wallet address for convenince
    return wallet_address;
}

export async function check_for_username() {

    // Get the current wallet token
    let token = token_instance.get();

    // Retreive temporary mnemonic
    let mnemonic = token.mnemonic;

    // Generate the first wallet private key from the seed phrase
    let { wallet_address } = wallet_from_seed(mnemonic);

    // Check if a username has been registered for this user
    let username = await get_username(wallet_address);

    return username;

}

export async function add_username(username, save=true, callback) {

    // Need to use temporary mnemonic to save username on contract
    if (save) {

        // Get the current wallet token
        let token = token_instance.get();

        // Retreive temporary mnemonic
        let mnemonic = token.mnemonic;

        // Generate the first wallet private key from the seed phrase
        let { wallet_address, private_key } = wallet_from_seed(mnemonic);

        // Call save on contract
        await call_contract_with_private_key(wallet_address, private_key, "Friends", "set_username", [username], 0, callback)

    }

    // Append username to token
    token_instance.update_username(username);

}

export async function init_session(callback) {

    // Get the current wallet token
    let token = token_instance.get();

    // Retreive temporary mnemonic
    let mnemonic = token.mnemonic;

    // Generate the first wallet private key from the seed phrase
    let { wallet_address, private_key } = wallet_from_seed(mnemonic);

    // Create a new session wallet
    let session_wallet = ethers.Wallet.createRandom();

    // Extract private key and address from session wallet
    let session_address = session_wallet.address;
    let session_private_key = session_wallet.privateKey;

    // Append session credentials to token
    token_instance.update_sessions(session_address, session_private_key);

    // Call save on contract
    await call_contract_with_private_key(wallet_address, private_key, "Sessions", "create_session", [session_address, 131400000], 0, callback)

}

export async function add_credential() {

    // Get the current wallet token
    let token = token_instance.get();

    // Retreive temporary mnemonic
    let mnemonic = token.mnemonic;

    // Retreive username
    let username = token.username;

    // Generate the first wallet address from the seed phrase
    let { wallet_address } = wallet_from_seed(mnemonic);

    // Generate an encryption key and encrypto the mnemonic
    let encryption_key = generate_encryption_key();
    let encrypted_mnemonic = encrypt(mnemonic, encryption_key);

    // Create a WebAuthN Credential with the encryption key stored securely as user PII
    let credential_id = await create_credential(username, encryption_key);

    // Save credential in localStorage
    token_instance.update_credential(credential_id, encrypted_mnemonic, wallet_address);
    
}

export async function confirm_seed() {

    // Delete temporary mnemonic
    token_instance.confirm();    

}

export async function unlock_wallet() {

    // Get the current wallet token
    let { credential_id, encrypted_mnemonic } = token_instance.get();

    // Use WebAuthN to retrieve encryption key securely stored in the user's PII
    let encryption_key = await assert_credential(credential_id);

    // Decrypt the mnemonic
    let mnemonic = decrypt(encrypted_mnemonic, encryption_key);

    // Generate the first wallet from the seed phrase
    return wallet_from_seed(mnemonic);

}

export async function reveal_seed() {

    // Get the current wallet token
    let { credential_id, encrypted_mnemonic } = token_instance.get();

    // Use WebAuthN to retrieve encryption key securely stored in the user's PII
    let encryption_key = await assert_credential(credential_id);

    // Decrypt the mnemonic
    let mnemonic = decrypt(encrypted_mnemonic, encryption_key);

    return mnemonic;
    
}

export async function reveal_private_key() {

    // Retrieve active wallet information
    let { private_key } = await unlock_wallet();

    return private_key;

}

// Equivalent of log out where we delete the user's token
export async function forget_wallets() {

    // Just delete current token
    token_instance.delete();

    // Delete local customer records too
    customer_instance.delete();

}

// Signs an arbitrary transaction. These transactions use the actual wallet of the user.
export async function sign_wallet_transaction(unsigned_transaction) {

    // Retrieve active wallet information
    let { private_key, wallet_address } = await unlock_wallet();

    // Sign and send the transaction to the blockchain
    let transaction_response = await sign_and_send_transaction(private_key, wallet_address, unsigned_transaction)

    return transaction_response;

}

export async function create_referrer_key() {
    let { public_key, private_key } = await generate_key_pair();
    token_instance.create_referrer_key(private_key);
    return public_key;
}

export async function encrypt_token(public_key) {
    let shared_key = generate_encryption_key();
    let encrypted_shared_key = await encrypt_with_public_key(public_key, shared_key);
    let token = token_instance.get();
    let token_string = JSON.stringify(token);
    let token_base64 = Buffer.from(token_string).toString('base64');
    let encrypted_token = await encrypt(token_base64, shared_key);
    return { encrypted_shared_key, encrypted_token };
}

export async function load_referrer_token(encrypted_shared_key, encrypted_token) {
    let old_token = token_instance.get();
    let shared_key = await decrypt_with_private_key(old_token.referrer_key, encrypted_shared_key);
    let token_base64 = await decrypt(encrypted_token, shared_key);
    let token_string = Buffer.from(token_base64, 'base64').toString('utf8');
    let token = JSON.parse(token_string);
    token_instance.save(token);    
}

export function authorized_referrer(referrer) {
    token_instance.authorized_referrer(referrer);
}

export function block_referrer(referrer) {
    token_instance.block_referrer(referrer);
}