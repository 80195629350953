import React, { createContext, useContext, useState, useEffect } from "react";

// ---- Icons ----
import right_icon from "../assets/icons/right.png";

// ---- Components ----
import Widget from "./purchase_funnel/widget";
import EVM from "./purchase_funnel/evm";
import Manual_EVM from "./purchase_funnel/manual_evm";
import Pending_EVM from "./purchase_funnel/pending_evm";
import Set_Username from "./sign_up/set_username";
import Create_Authenticator from "./sign_up/create_authenticator";
import Save_Seed_Phrase from "./sign_up/save_seed_phrase";
import Confirm_Seed_Phrase from "./sign_up/confirm_seed_phrase";
import Sign_In from "./sign_up/sign_in";
import Pay_EVM from "./purchase_funnel/pay_evm";
import Buy_Direct from "./purchase_funnel/buy_direct";
import Lootbox_Currency from "./purchase_funnel/lootbox_currency";
import Pay_Card from "./purchase_funnel/pay_card";
import Force_Browser_Transfer from "./sign_up/force_browser_transfer";
import Pay_Wert from "./purchase_funnel/pay_wert";
import Pay_Tweed from "./purchase_funnel/pay_tweed";

// ---- Services ----
import { remove_param_without_reloading, is_web_view } from "../services/helper_functions";
import { get_order_info } from "../services/api";
import useCustomer from "../services/wallet/useCustomer";
import customer_instance from "../services/wallet/customer";
import { forget_wallets } from "../services/wallet/core";

const ModalContext = createContext();

export function usePurchaseFunnel() {
    return useContext(ModalContext);
}

export function Purchase_Funnel({ children }) {

    let customer = useCustomer();

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);

    let open_purchase_funnel = (modal_parameters) => {
        document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_purchase_funnel = () => {
        remove_purchase_params();
        set_animate_out(true);
        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);

        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
        
    }

    let context_value = {
        open_purchase_funnel,
        close_purchase_funnel
    }

    function close_special() {
        if (!["set_username", "create_authenticator", "save_seed_phrase", "confirm_seed_phrase", "sign_in", "pending_evm", "force_browser_transfer"].includes(parameters.type)) {
            close_purchase_funnel();
        }
    }

    useEffect(()=>{

        (async function() {

            let url = new URL(window.location.href);
            let url_params = url.searchParams;
            let load_from = url_params.get("load_from");

            let { is_os_webview, is_android_webview } = is_web_view();

            if (load_from) {
                let force_load_customer = load_from.split("$delim$")[0];
                let payment_id = load_from.split("$delim$")[1];
                customer_instance.force_load(force_load_customer);
                let cur_customer = customer_instance.get();
                remove_param_without_reloading("load_from");
                let { hint_amount, payin_address, currency_index, deadline_at, has_payins } = await get_order_info(cur_customer.customer_id, payment_id);
                if (has_payins) {
                    open_purchase_funnel({type:"pending_evm", payment_id, hint_amount, currency_index });
                }
                else {
                    open_purchase_funnel({type: "pay_evm", hint_amount, payin_address, payment_id, currency_index, deadline_at});
                }
            }
            // else if ((is_os_webview || is_android_webview) && customer && customer.id) {
            //     customer_instance.delete();
            // }
            else if (customer && customer.customer_id) {
            
                let url = new URL(window.location.href);
                let url_params = url.searchParams;
                let type = url_params.get("type");
                let payment_id = url_params.get("payment_id");
                let pending = url_params.get("pending");
                let hash = url_params.get("hash");

                if (type && type == "crypto" && payment_id) {

                    let { hint_amount, payin_address, currency_index, deadline_at, has_payins } = await get_order_info(customer.customer_id, payment_id);
                    if (has_payins || pending) {
                        open_purchase_funnel({type:"pending_evm", payment_id, hint_amount, currency_index });
                    }
                    else {
                        open_purchase_funnel({type: "pay_evm", hint_amount, payin_address, payment_id, currency_index, deadline_at});
                    }
                    
                }

                if (type && type == "wert" && payment_id && hash) {
                    let { hint_amount } = await get_order_info(customer.customer_id, payment_id);
                    open_purchase_funnel({type: "pending_evm", hint_amount, currency_index: 0, payment_id, is_wert: true, hash});
                }

                if (type && type == "tweed" && payment_id) {
                    let { hint_amount } = await get_order_info(customer.customer_id, payment_id);
                    open_purchase_funnel({type: "pending_evm", hint_amount, currency_index: 0, is_tweed: true, payment_id});
                }
            }

            let logout = url_params.get("logout");
            if (logout) {
                forget_wallets();
                alert("Customer info deleted");
            }

        })();

    },[]);

    function remove_purchase_params() {
        remove_param_without_reloading("type");
        remove_param_without_reloading("payment_id");
        
    }

    useEffect(()=> {
        if (parameters && parameters.type) {
            if (["set_username", "create_authenticator", "save_seed_phrase", "confirm_seed_phrase", "sign_in", "pay_card"].includes(parameters.type)) {
                remove_purchase_params();
            }
        }
    },[parameters])

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">
                    <div className="outer_modal_click" onClick={close_special}></div>
                    <div className={`language_selector_container yellow_glow ${animate_out ? 'hide' : ''}`}>
                        
                        {
                            parameters.type == "widget" &&
                            <Widget close={close_purchase_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "pay_evm" &&
                            <Pay_EVM close={close_purchase_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "evm" &&
                            <EVM parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "manual_evm" &&
                            <Manual_EVM parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "pending_evm" &&
                            <Pending_EVM parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "set_username" &&
                            <Set_Username parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "create_authenticator" &&
                            <Create_Authenticator parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "save_seed_phrase" &&
                            <Save_Seed_Phrase parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "confirm_seed_phrase" &&
                            <Confirm_Seed_Phrase close={close_purchase_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "sign_in" &&
                            <Sign_In parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "buy_direct" &&
                            <Buy_Direct close={close_purchase_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "lootbox_currency" &&
                            <Lootbox_Currency close={close_purchase_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "pay_card" &&
                            <Pay_Card close={close_purchase_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "force_browser_transfer" &&
                            <Force_Browser_Transfer close={close_purchase_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "pay_wert" &&
                            <Pay_Wert close={close_purchase_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "pay_tweed" &&
                            <Pay_Tweed close={close_purchase_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                    </div>
                </div>
            }
        </ModalContext.Provider>
    )

}