import { useState } from "react";

// ---- Images ----
import second_cat_image from "../assets/images/second_cat.png";
// import phone_animation from "../assets/videos/phone_animation.webm";
import phone_animation from "../assets/videos/phone_animation.mp4";
import token_image from "../assets/images/token.png";


// ---- Modals ----
import { usePurchaseFunnel } from "../modals/purchase_funnel";

// ---- External Links ----
import external_links from "../configs/external_links.json";

function How_To_Buy({ hard_stop, geo_error }) {

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function buy_direct() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "buy_direct"});
        }
    }

    // ---- States ----
    let [tab, set_tab] = useState(0);

    return (
        <div className="page_container">
            <div className="safe_inner">
                <div className="hide_overflow_container">
                    <div className="radial_glow_extra_top_blue right"/>
                </div>
                <div className="extra_connector">
                    <div className="horitzontal_line"></div>
                    <div className="left_box_decor"></div>
                    <div className="right_box_decor"></div>
                    <div className="center_box_decor"></div>
                </div>
                <div className="trapeze_container_bottom">
                    
                    <div className="earn_description_container">

                        <h1 className="title_font_family">How to Buy</h1>
                        <div className="small_spacer"></div>
                        <div className="tab_selector">
                            <div className={`tab_inner ${tab==0?"tab_selected":"tab_special_hover"}`} onClick={()=>set_tab(0)}>Credit Card</div>
                            <div className={`tab_inner ${tab==1?"tab_selected":"tab_special_hover"}`} onClick={()=>set_tab(1)}>Cryptocurrency</div>
                        </div>
                        <div className="medium_spacer"></div>
                        {/* <div className="small_spacer"></div> */}

                        {
                            tab == 1 &&
                            <>
                                <div className="step_container">
                                    <div className="step_indicator_container">1</div>
                                    <div className="step_text_container">Select your payment cryptocurrency and purchase amount</div>
                                </div>
                                <div className="step_container">
                                    <div className="step_indicator_container">2</div>
                                    <div className="step_text_container">Connect your Web3 wallet and pay or send funds to the unique address generated</div>
                                </div>
                                <div className="step_container">
                                    <div className="step_indicator_container">3</div>
                                    <div className="step_text_container">Follow the instructions on screen to create a JetBolt wallet</div>
                                </div>
                            </>
                        }

                        {
                            tab == 0 &&
                            <>
                                <div className="step_container">
                                    <div className="step_indicator_container">1</div>
                                    <div className="step_text_container">Select purchase amount and set to card payment option</div>
                                </div>
                                <div className="step_container">
                                    <div className="step_indicator_container">2</div>
                                    <div className="step_text_container">Pay via our secured partner payment gateway.</div>
                                </div>
                                <div className="step_container">
                                    <div className="step_indicator_container">3</div>
                                    <div className="step_text_container">Follow the instructions on screen to create a JetBolt wallet</div>
                                </div>
                            </>
                        }

                        <div className="small_spacer"></div>
                        <div className={`inline_button_left blue_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>Buy Now</div>
                        <div className="inline_button_right gray_button_hover" onClick={()=>window.open(external_links.guides)}>Full Guide</div>
                        
                    </div>
                    <div className="earn_container">

                        <div className="token_ill_container">


                            <div className="full_inner">
                                <div className="token_ill_inner_container">
                                    <div className="radial_glow_extra_coin"></div>
                                    <video src={phone_animation} className="full_video" autoPlay muted loop playsInline></video>
                                    {/* <div className="fade_out_gray"></div> */}
                                    <div className="hook_left_blue"></div>
                                    <div className="hook_left_blue hook_transform_top_right"></div>
                                    <div className="hook_left_blue hook_transform_bottom_right"></div>
                                    <div className="hook_left_blue hook_transform_bottom_left"></div>
                                </div>
                            </div>
                        </div>               

                        {/* <div className="token_ill_container">
                            <div className="lined_background radial_mask">
                                    <video src={phone_animation} className="full_video" autoPlay muted loop playsInline></video>

                            </div>

                            <div className="full_inner">
                                <div className="token_ill_inner_container">
                                    <img className="token_image" src={second_cat_image}/>
                                    <div className="hook_left_blue"></div>
                                    <div className="hook_left_blue hook_transform_top_right"></div>
                                    <div className="hook_left_blue hook_transform_bottom_right"></div>
                                    <div className="hook_left_blue hook_transform_bottom_left"></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="middle_line"></div>
                    {/* <div className="top_box_decor"></div> */}
                    <div className="bottom_box_decor"></div>
                </div>
                <div className="large_spacer"></div>
               
            </div>
        </div>
    )
}

export default How_To_Buy;