import { useState, useEffect } from "react";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png"
import profile_icon from "../../assets/icons/profile.png";
import down_icon from "../../assets/icons/down.png";
import paste_icon from "../../assets/icons/paste.png";
import show_seed_icon from "../../assets/icons/show_seed.png";

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import { reveal_private_key } from "../../services/wallet/core";

function Show_Private_Key({ close, parameters, set_parameters }) {

    // ---- Wallet Token ----
    let token = useToken();
    
    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    // ---- Hooks ----
    let [mnemonic, set_mnemonic] = useState(null);

    async function show_seed(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";
        try {
            let seed = await reveal_private_key();
            set_mnemonic(seed);
        } catch (error) {

        }
        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    return (
        <>
            <div className="purchase_funnel_container">

                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Show Private Key</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                    <div className="medium_spacer"></div>
                </div>

                {
                    mnemonic == null ?
                    <>
                        <p>Warning: Never disclose your private key. Anyone with your private key can steal any assets held in your account.</p>
                        <div className="medium_spacer"></div>
                        <div className="payment_button yellow_button_hover title_font_family" onClick={show_seed}>Show Private Key</div>
                    </> :
                    <>
                        <p>Save your seed private key. You are solely responsible for securely storing this information. We have no way to recover this for you.</p>
                        <div className="medium_spacer"></div>
                        <div className="payment_input_container">
                            <div className="payment_input_currency_selector">
                                <img className="payment_input_selector_icon" src={show_seed_icon}/>
                                <img className="payment_input_selector_extra_icon" src={right_icon}/>
                                
                            </div>
                                <div className="paste_input_button gray_button_hover" onClick={()=>navigator.clipboard.writeText(mnemonic)}>
                                    <img className="paste_icon" src={paste_icon}/>
                                    <div className="paste_text_inner">Copy</div>
                                </div>
                                <input className="wallet_address_input" value={mnemonic}
                                />
                        </div>
                    </>
                }
            </div>
        </>
    );

}

export default Show_Private_Key;