import { useState, useEffect, useRef } from "react";

// ---- Lotie ----
import Lottie from 'lottie-react';

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png"
import profile_icon from "../../assets/icons/profile.png";
import animated_tick from "../../assets/icons/animated_tick.json";

import red_cross_icon from "../../assets/icons/red_cross.png";
import green_tick_icon from "../../assets/icons/green_tick.png";

// ---- Services ----
import { add_username } from "../../services/wallet/core";
import { check_username_availability } from "../../services/contract_functions/friends";
import { on_focus_input_ref, on_blur_input_ref } from "../../services/helper_functions";

function Set_Username({ parameters, set_parameters }) {

    // ---- Hooks ----
    let [can_register, set_can_register] = useState(false);
    let [is_loading, set_is_loading] = useState(false);
    let [success_animation, set_success_animation] = useState(false);

    // ---- Refs ----
    let username_outer_input_ref = useRef(null);
    let username_input_ref = useRef(null);
    let username_max_length_ref = useRef(null);
    let username_length_ref = useRef();
    let username_characters_ref = useRef();
    let username_available_ref = useRef();
    let last_call_id = useRef(0);

    let username_max_length_ref_icon = useRef(null);
    let username_length_ref_icon = useRef(null);
    let username_characters_ref_icon = useRef(null);
    let username_available_ref_icon = useRef(null);

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    async function register_username() {
        if (username_input_ref.current) {
            set_is_loading(true);
            set_tx_pending(true);
            set_tx_status({status:"Connecting", amount:0});
            let username = username_input_ref.current.value.toLowerCase();
            try {
                await add_username(username,true,update_transaction_status);
                set_success_animation(true);
                setTimeout(()=>set_parameters({type:"create_authenticator", close_callback: parameters.close_callback}), 500);
            } catch (error) {
                console.log(error);
            }
            set_is_loading(false);
            set_tx_pending(false);
        }
    }

    function is_valid_username(username) {
        let regex = /^[a-zA-Z0-9_]+$/;
        return regex.test(username);
    }

    async function username_changed() {
        if (username_input_ref.current) {
            let username = username_input_ref.current.value.toLowerCase();
            let call_id = Math.random();
            last_call_id.current = call_id

            set_can_register(false);
            
            if (username.length >= 3) {
                username_length_ref.current.style.color = "#00bc97";
                username_length_ref_icon.current.style.display = "inline-block";
                username_length_ref_icon.current.src = green_tick_icon;
            }
            else {
                username_length_ref.current.style.color = "#ff4220";
                username_length_ref_icon.current.style.display = "inline-block";
                username_length_ref_icon.current.src = red_cross_icon;
            }

            if (username.length <= 30) {
                username_max_length_ref.current.style.color = "#00bc97";
                username_max_length_ref_icon.current.style.display = "inline-block";
                username_max_length_ref_icon.current.src = green_tick_icon;
            }
            else {
                username_max_length_ref.current.style.color = "#ff4220";
                username_max_length_ref_icon.current.style.display = "inline-block";
                username_max_length_ref_icon.current.src = red_cross_icon;
            }

            let is_valid = is_valid_username(username);

            if (is_valid) {
                username_characters_ref.current.style.color = "#00bc97";
                username_characters_ref_icon.current.style.display = "inline-block";
                username_characters_ref_icon.current.src = green_tick_icon;
            }
            else {
                username_characters_ref.current.style.color = "#ff4220";
                username_characters_ref_icon.current.style.display = "inline-block";
                username_characters_ref_icon.current.src = red_cross_icon;
            }

            let is_available = await check_username_availability(username);

            if (last_call_id.current != call_id) {
                return;
            }
            
            if (is_available) {
                username_available_ref.current.style.color = "#00bc97";
                username_available_ref_icon.current.style.display = "inline-block";
                username_available_ref_icon.current.src = green_tick_icon;
            }
            else {
                username_available_ref.current.style.color = "#ff4220";
                username_available_ref_icon.current.style.display = "inline-block";
                username_available_ref_icon.current.src = red_cross_icon;
            }

            if (username.length >= 3 && is_valid && is_available) {
                set_can_register(true);
            }
        }
    }

    // ---- Tx Status ----
    let [tx_pending, set_tx_pending] = useState(false);
    let [tx_status, set_tx_status] = useState({status:"Connecting", amount:0})

    function update_transaction_status(status, amount) {
        set_tx_status({status, amount})
    }

    return (
        <>
            <div className="purchase_funnel_container">

                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Register Username</h2>
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                    <div className="medium_spacer"></div>
                </div>

                <div className="payment_input_container" ref={username_outer_input_ref}>
                    <div className="payment_input_currency_selector">
                        <img className="payment_input_selector_icon" src={profile_icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                    </div>
                    <input ref={username_input_ref} onChange={username_changed} className="full_width_input" placeholder="Username" onFocus={()=>on_focus_input_ref(username_outer_input_ref)} onBlur={()=>on_blur_input_ref(username_outer_input_ref)}
                    autoComplete="off" autoCorrect="off" spellCheck="false" autoCapitalize="none" />
                </div>

                <div className="small_spacer"></div>
                <p className="small_text" ref={username_length_ref}>
                    <img src={green_tick_icon} className="small_img_indicator" ref={username_length_ref_icon}/>
                    Minimum 3 characters
                </p>
                <div className="small_spacer"></div>
                <p className="small_text" ref={username_max_length_ref}>
                    <img src={green_tick_icon} className="small_img_indicator" ref={username_max_length_ref_icon}/>
                    Maximum 30 characters
                </p>
                <div className="small_spacer"></div>
                <p className="small_text" ref={username_characters_ref}>
                    <img src={green_tick_icon} className="small_img_indicator" ref={username_characters_ref_icon}/>
                    Only letters, numbers, and underscores
                </p>
                <div className="small_spacer"></div>
                <p className="small_text" ref={username_available_ref}>
                    <img src={green_tick_icon} className="small_img_indicator" ref={username_available_ref_icon}/>
                    Available
                </p>
                
                <div className="medium_spacer"></div>
                <div className={`payment_button title_font_family yellow_button_hover ${(!can_register || is_loading)?"unselectable":""}`} onClick={register_username}>
                    {success_animation ?  <Lottie animationData={animated_tick} loop={false} direction={-1} style={{ position: "absolute", paddingTop: "10px", left: "calc(50% - 25px)", width: "50px", height: "50px" }}/>
                    :
                        is_loading ?
                        "Registering...":
                        "Register"
                    }
                </div>

                {
                    tx_pending &&
                    <>
                        <div className="medium_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar" style={{width: `${tx_status.amount*100}%`}}></div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text">Status: {tx_status.status}</p>
                        <div className="small_spacer"></div>
                        <p className="small_text">First JetBolt transaction may be slow</p>
                    </>
                }

            </div>
        </>
    );

}

export default Set_Username;