import React, { createContext, useContext, useState, useEffect, useRef } from "react";

// ---- Icons ----
import right_icon from "../assets/icons/right.png";
import down_icon from "../assets/icons/down.png";

// ---- Data ----
import { currencies } from "../assets/data/currencies";
import { lootboxes } from "../assets/data/lootboxes";
import { loadState } from "viem/actions";

const ModalContext = createContext();

export function useDiscountCharts() {
    return useContext(ModalContext);
}

export function Discount_Charts({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);

    let open_discount_charts = (modal_parameters) => {
        document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_discount_charts = () => {
        set_animate_out(true);
        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
    }

    let context_value = {
        open_discount_charts,
        close_discount_charts
    }


    function set_discount(currency_index, usd_threshold) {
        let amount = (usd_threshold / parameters.exchange_rates[currencies[currency_index].symbol]).toFixed(currencies[currency_index].sig_figs);
        parameters.set(currency_index, amount);
        close_discount_charts();
    }

    // ---- Exchange Rates ----
    let [currency_index, set_currency_index] = useState(0);
    let payment_list_scrollspace = useRef(null);

    let current_symbol = currencies[currency_index].symbol;

    useEffect(()=> {

        let temp_currency_index = currency_index; // - 1;

        if (payment_list_scrollspace.current) {
            let payment_method_items = payment_list_scrollspace.current.querySelectorAll(".payment_method_item")
            let payment_method_item = payment_method_items[temp_currency_index];
            let item_position = payment_method_item.offsetLeft - 22;
            payment_list_scrollspace.current.scrollTo({
                left: item_position,
                behavior: 'smooth'
            });
        }
    },[currency_index]);

    useEffect(()=> {

        if (parameters && parameters.currency_index) {
            set_currency_index(parameters.currency_index);
        }

    },[parameters]);

    // ---- Dragging ----
    const [is_dragging, set_is_dragging] = useState(false);
    const [start_pos, set_start_pos] = useState(0);
    const [scroll_pos, set_scroll_pos] = useState(0);
    const [is_dragging_moved, set_is_dragging_moved] = useState(false);

    const on_mouse_down_s = (e) => {
        if (!is_touch_device()) {
            set_is_dragging(true);
            set_start_pos(e.pageX - payment_list_scrollspace.current.offsetLeft);
            set_scroll_pos(payment_list_scrollspace.current.scrollLeft);
            set_is_dragging_moved(false);
        }
    };

    const on_mouse_leave_s = () => {
        if (!is_touch_device()) {
            set_is_dragging(false);
        }
    };

    const on_mouse_up_s = (e) => {
        if (!is_touch_device()) {
            if (is_dragging_moved) {
                e.preventDefault();
                e.stopPropagation();
            }
            set_is_dragging(false);
        }
    };

    const on_mouse_move_s = (e) => {
        if (!is_touch_device()) {
            if (!is_dragging) return;
            const x = e.pageX - payment_list_scrollspace.current.offsetLeft;
            const walk = (x - start_pos) * 2;
            payment_list_scrollspace.current.scrollLeft = scroll_pos - walk;
            if (walk != 0) set_is_dragging_moved(true);
        }
    };

    const is_touch_device = () => {
        return ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    };

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">
                <div className="outer_modal_click" onClick={close_discount_charts}></div>
                    <div className={`language_selector_container yellow_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="stick_text">
                            <div className="medium_spacer"></div>
                            <div style={{position: "relative"}}>
                                <h2 className="central_text_indicator title_font_family">Discount Charts</h2>
                                <img className="back_icon opacity_hover" style={{left: "20px"}} src={down_icon} onClick={close_discount_charts}/>
                            </div>
                            <div className="medium_spacer"></div>
                        </div>
                        <div className="purchase_funnel_container">
                            

                            <h3 className="title_font_family yellow_text">Choose payment method</h3>
                            <div className="small_spacer"></div>
                            <p>Select from the list below which payment method and currency you would like to pay in from the list below:</p>
                            <div className="payment_method_container notranslate" ref={payment_list_scrollspace}
                                onMouseDown={on_mouse_down_s}
                                onMouseLeave={on_mouse_leave_s}
                                onMouseUp={on_mouse_up_s}
                                onMouseMove={on_mouse_move_s}
                                style={{cursor: is_dragging ? 'grabbing' : 'grab'}}
                                >
                                <div className="payment_method_inner" style={{width: "806px"}}>
                                    {
                                        currencies.map((currency,index)=>(<>
                                            {
                                                // index != 0 &&
                                                // 701px
                                                <>
                                                <div key={index} className={`payment_method_item non_selectable gray_button_hover ${currency_index==index?"selected":""}`} onClick={()=>set_currency_index(index)}>
                                                    <img className="payment_method_icon" src={currency.icon}/>
                                                    <div className="payment_method_label">{currency.short_display}</div>
                                                </div>
                                                </>
                                            }</>
                                        ))
                                    }
                                    
                                </div>
                            </div>

                            <div className="medium_spacer"></div>
                            <h3 className="title_font_family yellow_text">Choose purchase tier</h3>
                            <div className="small_spacer"></div>
                            <p>The higher the purchase value, the higher the discount. Explore our discount tiers below.</p>
                            <div className="small_spacer"></div>
                            <div className="slight_line"></div>
                            
                            {
                                lootboxes.map((lootbox, index) => (
                                    // <div key={index} className={!(index >= 3 && currency_index == 0) ?  "app_nav_special_hover left":""} style={{position: "relative"}} onClick={()=>{
                                    //     if (!(index >= 3 && currency_index == 0)) {
                                    //         set_discount(currency_index,lootbox.usd_threshold)
                                    //     }   
                                    // }}>
                                    <div key={index} className="app_nav_special_hover left" style={{position: "relative"}} onClick={()=>{
                                            set_discount(currency_index,lootbox.usd_threshold)
                                    }}>
                                        <div className="small_spacer"></div>
                                        <div className="bullet_special_component">
                                            <img className="lb_image_container" src={lootbox.image}/>
                                            <div className="bullet_text">
                                                <b className="title_font_family">{lootbox.title}</b><br/>
                                                {current_symbol} {(lootbox.usd_threshold / parameters.exchange_rates[current_symbol]).toFixed(currencies[currency_index].sig_figs) } <strike className="warning_text">{current_symbol} {(lootbox.usd_threshold / parameters.exchange_rates[current_symbol] * ((100+lootbox.discount_amount)/100)).toFixed(currencies[currency_index].sig_figs)}</strike><br/>
                                                <a className="green_text">Get {lootbox.discount} Extra Tokens<br/></a>
                                                {/* <div className="inline_small_button yellow_button_hover"
                                                onClick={()=>set_discount(currency_index,lootbox.usd_threshold)}
                                                >Choose Tier</div> */}
                                            </div>
                                        </div>
                                        <div className="small_spacer" style={{height: "5px"}}></div>
                                        <div className="slight_line"></div>
                                        <img className="right_forced_button" src={right_icon}/>
                                        {/* {
                                            index >= 3 && currency_index == 0 &&
                                            <div className="unavailable_overlay">
                                                <div className="padded_unavail">Unavailable with<br/>Credit Card</div>
                                            </div>
                                        } */}
                                    </div>
                                ))
                            }
                            
                            {/* <div className="discount_table_container notranslate">
                                <table className="discount_table">
                                    <tr>
                                        <th className="translate">Tier Name</th>
                                        <th className="translate">Discount</th>
                                        <th>US$</th>
                                        <th>ETH</th>
                                        <th>BTC</th>
                                        <th>SOL</th>
                                        <th>MATIC</th>
                                        <th>USDT</th>
                                        <th>USDC</th>
                                    </tr>
                                    {
                                        lootboxes.map((lootbox, index) => (
                                            <tr key={index}>
                                                <td>{lootbox.title}</td>
                                                <td>{lootbox.discount} OFF</td>
                                                <td><u className="underlined_text" onClick={()=>set_discount(0,lootbox.usd_threshold)}>{lootbox.usd_threshold} &#8594;</u></td>
                                                <td><u className="underlined_text" onClick={()=>set_discount(1,lootbox.usd_threshold)}>{(lootbox.usd_threshold / parameters.exchange_rates["ETH"]).toFixed(currencies[1].sig_figs)} &#8594;</u></td>
                                                <td><u className="underlined_text" onClick={()=>set_discount(2,lootbox.usd_threshold)}>{(lootbox.usd_threshold / parameters.exchange_rates["BTC"]).toFixed(currencies[2].sig_figs)} &#8594;</u></td>
                                                <td><u className="underlined_text" onClick={()=>set_discount(3,lootbox.usd_threshold)}>{(lootbox.usd_threshold / parameters.exchange_rates["SOL"]).toFixed(currencies[3].sig_figs)} &#8594;</u></td>
                                                <td><u className="underlined_text" onClick={()=>set_discount(4,lootbox.usd_threshold)}>{(lootbox.usd_threshold / parameters.exchange_rates["MATIC"]).toFixed(currencies[4].sig_figs)} &#8594;</u></td>
                                                <td><u className="underlined_text" onClick={()=>set_discount(0,lootbox.usd_threshold)}>{lootbox.usd_threshold} &#8594;</u></td>
                                                <td><u className="underlined_text" onClick={()=>set_discount(0,lootbox.usd_threshold)}>{lootbox.usd_threshold} &#8594;</u></td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            </div> */}
                        </div>
                    </div>
                </div>
            }
        </ModalContext.Provider>);
}