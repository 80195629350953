import React, { useRef, useEffect, useState } from 'react';

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Components ----
import SEO from "../components/seo";
import App_Nav from "../components/app_nav";

// ---- Icons ----
import search_icon from "../assets/icons/search.png";
import right_icon from "../assets/icons/right.png";

import mint_icon from "../assets/icons/mint.png";
import transfer_icon from "../assets/icons/transfer.png";
import add_friend_icon from "../assets/icons/add_friend.png";
import stake_icon from "../assets/icons/stake.png";
import unstake_icon from "../assets/icons/unstake.png";
import claim_icon from "../assets/icons/claim.png";
import create_icon from "../assets/icons/create.png";
import rename_icon from "../assets/icons/rename.png";
import unfriend_icon from "../assets/icons/unfriend.png";
import favorite_icon from "../assets/icons/favorite.png";
import unfavorite_icon from "../assets/icons/unfavorite.png";
import left_icon from "../assets/icons/left.png";
import burn_tokens_icon from "../assets/icons/burn_tokens.png"
import banned_icon from "../assets/icons/banned.png"
import unbanned_icon from "../assets/icons/unbanned.png"

// ---- Services ----
import useToken from "../services/wallet/useToken";
// import { get_transaction_history } from "../services/contract_functions/logs"
import { format_dmy, group_transactions_by_date, short_format_number } from "../services/helper_functions";
import { get_transaction_history } from "../services/block_explorer";

let icon_map = {
    mint_tokens: mint_icon,
    stake_tokens: stake_icon,
    unstake_tokens: unstake_icon,
    claim_unstaked_tokens: unstake_icon,
    claim_tokens: claim_icon,
    reward_friend: claim_icon,
    transfer: transfer_icon,
    transfer_from: transfer_icon,
    set_username: rename_icon,
    add_friend: add_friend_icon,
    accept_friend: add_friend_icon,
    remove_friend: unfriend_icon,
    decline_friend: unfriend_icon,
    favorite: favorite_icon,
    unfavorite: unfavorite_icon,
    burn_tokens: burn_tokens_icon,
    banned: banned_icon,
    unbanned: unbanned_icon
}

function History_Page() {

    // ---- Const ----
    let items_per_page = 20;

    // ---- Hooks ----
    let [grouped_transactions, set_grouped_transactions] = useState([]);
    let [transactions, set_transactions] = useState([]);
    let [page, set_page] = useState(0);
    let [has_more, set_has_more] = useState(true);

    // ---- Token ----
    let token = useToken();

    // ---- Load Transactions ----
    async function load_transactions() {

        if (token && token.wallet_address && has_more) {

            let history = await get_transaction_history(token.wallet_address, page, items_per_page);

            // if (history.length < items_per_page) {
            //     set_has_more(false);
            // }

            // if (history.length > 0) {

            //     let new_transactions = [];

            //     for (let i = 0; i < history.length; i++) {
            //         let transaction_raw = history[i];

            //         let transaction = {
            //             function_name: transaction_raw.function_name,
            //             icon: icon_map[transaction_raw.function_name],
            //             extra: transaction_raw.balance_change == 0n ? transaction_raw.extra : `${transaction_raw.incremented_balance?"+":"-"}${short_format_number(ethers.formatUnits(transaction_raw.balance_change))}`,
            //             date: Number(transaction_raw.timestamp)
            //         }

            //         new_transactions.push(transaction);
            //     }

            //     let all_transactions = [...transactions, ...new_transactions];

            //     set_transactions(all_transactions);
            //     set_page(page+1);

            // }
        }
    }

    // ---- On Load ----
    useEffect(()=> {
        if (token && token.wallet_address) {
            load_transactions();
        }
    },[token]);

    useEffect(()=> {
        set_grouped_transactions(group_transactions_by_date(transactions));
    }, [transactions]);
/*
    useEffect(() => {

        let cur_page = page;

        let handle_scroll = () => {

            if (has_more) {
                
                let threshold = 100;
                let scroll_top = document.documentElement.scrollTop;
                let scroll_height = document.documentElement.scrollHeight;
                let client_height = document.documentElement.clientHeight;
                
                if (scroll_top + client_height >= scroll_height - threshold) {
                    if (cur_page == page) {
                        load_transactions();
                        cur_page++;
                    }
                    
                }
            }

        };
        
        window.addEventListener('scroll', handle_scroll);
        return () => window.removeEventListener('scroll', handle_scroll);

    }, [has_more, page, token]);
*/

    useEffect(()=> {

        (async function() {
            if (token && token.wallet_address) {
                let txs = await get_transaction_history(token.wallet_address);
                set_transactions(txs);
            }
        })();

    },[token])

    return (
        <>
            <SEO parameters={
                {
                    title: "JetBolt History",
                    description: "Track all your JetBolt transaction history. View your recent transactions.",
                    url: "https://jetbolt.io/history"
                }
            }/>
            <App_Nav selected_index={4} selected_index_mobile={0}>
                <div className="small_spacer"></div>
                <div className="title_relative_container">
                    <h1 className="title_font_family">
                        <div className="extra_back_button gray_button_hover" onClick={()=>window.location="/profile"}>
                            <img className="extra_back_button_icon" src={left_icon}/>
                        </div>
                        History
                    </h1>
                </div>
                <div className="medium_spacer"></div>

                {
                    grouped_transactions.map((group, index) => (
                        <div key={index}>
                            <h3>{format_dmy(group[0].date)}</h3>
                            <div className="medium_spacer"></div>
                            {
                                group.map((item, idx) => (
                                    <div key={idx} className="history_list_item">
                                        <img className="history_icon" src={item.icon}/>
                                        <div className="live_sales_amount_label">{item.function_name}</div>
                                        <div className="extra_list_label">confirmed</div>
                                        <div className="list_label_right">{item.extra}</div>
                                        <div className="extra_list_label_right">{format_dmy(item.date)}</div>
                                    </div>
                                ))
                            }
                            <div className="medium_spacer"></div> 
                        </div>
                    ))
                }

               
                
            </App_Nav>
        </>
    )
}

export default History_Page;