import { useRef, useState, useEffect } from "react";

import { useSwipeable } from 'react-swipeable';

// ---- Videos ----
import speed_video from "../assets/videos/speed.mp4";
import money_video from "../assets/videos/money.mp4";
import rocket_video from "../assets/videos/rocket.mp4";
import blockchain_video from "../assets/videos/blockchain.mp4";
import code_video from "../assets/videos/code.mp4";

// ---- Video Overlays ----
import arm_overlay from "../assets/video_overlay/arm.png";
import floating_coin_overlay from "../assets/video_overlay/floating_coin.png";
import earn_overlay from "../assets/video_overlay/earn.png";
import presale_overlay from "../assets/video_overlay/presale.png";
import wallet_overlay from "../assets/video_overlay/wallet.png";
import dev_overlay from "../assets/video_overlay/dev.png";

// ---- Icons ----
import left_arrow_icon from "../assets/icons/left_arrow_black.png";
import right_arrow_icon from "../assets/icons/right_arrow_black.png";

// ---- Modals ----
import { usePurchaseFunnel } from "../modals/purchase_funnel";

let features = [
    {
        title: "JetBolt Token",
        feature_0: "Zero Gas",
        feature_1: "Instant Finality",
        feature_2: "Lightning Fast",
        video: speed_video,
        overlay: arm_overlay,
        overlay_class: "slight_rotation"
    },
    {
        title: "Stake & Earn",
        feature_0: "Instant Staking",
        feature_1: "Share And Earn More",
        feature_2: "Easy To Use",
        video: money_video,
        overlay: earn_overlay,
        overlay_class: "shine_overlay"
    },
    {
        title: "The Presale",
        feature_0: "Instant Self-Custody",
        feature_1: "Audited Contract",
        feature_2: "Finished Product",
        video: rocket_video,
        overlay: presale_overlay,
        overlay_class: "rocket_overlay_spin"
    },
    {
        title: "Built-In Wallet",
        feature_0: "Easiest Web3 Wallet",
        feature_1: "Fully Client Side",
        feature_2: "Audited Code",
        video: blockchain_video,
        overlay: wallet_overlay,
        overlay_class: "spinning_overlay_w_z"
    },
    {
        title: "Develop",
        feature_0: "Integrate in Seconds",
        feature_1: "Plug and Play",
        feature_2: "Sample Code Available",
        video: code_video,
        overlay: dev_overlay,
        overlay_class: "spinning_overlay"
    }
]

function Featured_Item({index, selected_index, set_selected_index, item_content}) {

    // ---- Refs ----
    let video_ref = useRef(null);

    // ---- On Change ----

    useEffect(()=> {

        if (video_ref.current) {
            if (index == selected_index) {
                video_ref.current.play();
            }
            else {
                video_ref.current.pause();
            }
        }

    }, [selected_index]);

    return (
        <div className={`features_item ${(index==selected_index+1||index==selected_index-1)?"features_item_unselected":""} ${(index>=selected_index+2||index<=selected_index-2)?"features_item_unselected_far":""} ${index>=selected_index+2?"fade_item_right":""} ${index<=selected_index-2?"fade_item_left":""} ${index==selected_index?"yellow_glow":"black_glow"}`}
            onClick={()=>set_selected_index(index)}
            >
            <div className="features_item_title title_font_family">{item_content.title}</div>
            <div className="feature_main_image">
                <video className="feature_video" src={item_content.video} autoPlay={index==selected_index} muted loop ref={video_ref} playsInline></video>
                {/* <div className="hook_left"></div>
                <div className="hook_left hook_transform_top_right"></div>
                <div className="hook_left hook_transform_bottom_right"></div>
                <div className="hook_left hook_transform_bottom_left"></div> */}
                {
                    item_content.overlay &&
                    <div className="forced_feature_crop">
                        <img className={`feature_image ${item_content.overlay_class}`} src={item_content.overlay}/>
                    </div>
                }
                {
                    index == 2 &&
                    <>
                        <div className="presale_tracking_animated">{"> Launch Successful <"}</div>
                        <div className="presale_tracking_animated top">{"Destination: The Moon"}</div>
                    </>
                }

                {
                    index == 0 &&
                    <img className={`feature_image floating_coin_overlay`} src={floating_coin_overlay}/>
                }
                
            </div>
            <div className="feature_text_item_container">
                <div className="feature_text_item">{item_content.feature_0}</div>
                <div className="feature_text_item">{item_content.feature_1}</div>
                <div className="feature_text_item">{item_content.feature_2}</div>
            </div>
            
        </div>
    )
}


function Features({ hard_stop, geo_error }) {

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function buy_direct() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "buy_direct"});
        }
    }

    const is_small_device = () => {
        return window.innerWidth <= 550;
    };

    let [selected_index, set_selected_index] = useState(is_small_device() ? 0:2);

    


    function increment_index() {
        if (selected_index < features.length-1) {
            set_selected_index(selected_index+1);
        }
    }

    function decrement_index() {
        if (selected_index > 0) {
            set_selected_index(selected_index-1);
        }
    }

    // ---- Swipes ----
    const swipe_handlers = useSwipeable({
        onSwipedLeft: () => {
            increment_index();
        },
        onSwipedRight: () => {
            decrement_index();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });


    // ---- Refs ----
    let scroll_view_ref = useRef(null);

    // ---- On Change ----

    function handle_carousel() {
        if (scroll_view_ref.current) {
            scroll_view_ref.current.scroll({
                left: (2655-scroll_view_ref.current.offsetWidth)/2 + 295*(selected_index-2) - 10,
                behavior: 'smooth'
            });
        }
    }

    useEffect(()=> {
        handle_carousel();

    }, [selected_index]);

    useEffect(() => {
        let prev_width = 0;
        window.addEventListener('resize', function() {

            if (prev_width == window.innerWidth) {
                return;
            }

            prev_width = window.innerWidth;
            handle_carousel();

        });
    },[]);

    useEffect(() => {
        if (scroll_view_ref.current) {

            let div = scroll_view_ref.current;

            const prevent_scroll = (event) => {
                event.preventDefault();
            };

            let startX = 0;

            const handleWheel = (event) => {
                if (Math.abs(event.deltaX) > 0) {
                    event.preventDefault();
                }
            };

            const handleTouchStart = (event) => {
                startX = event.touches[0].pageX;
            };

            const handleTouchMove = (event) => {
                const moveX = event.touches[0].pageX;
                const diffX = moveX - startX;

                if (Math.abs(diffX) > Math.abs(event.touches[0].pageY - event.touches[0].clientY)) {
                    event.preventDefault();
                }
            };

            const handleKeyDown = (event) => {
                if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
                    event.preventDefault();
                }
            };

            // Add event listeners
            div.addEventListener('wheel', handleWheel, { passive: false });
            div.addEventListener('touchstart', handleTouchStart, { passive: false });
            div.addEventListener('touchmove', handleTouchMove, { passive: false });
            div.addEventListener('keydown', handleKeyDown, { passive: false });

            // Cleanup function
            return () => {
                div.removeEventListener('wheel', handleWheel);
                div.removeEventListener('touchstart', handleTouchStart);
                div.removeEventListener('touchmove', handleTouchMove);
                div.removeEventListener('keydown', handleKeyDown);
            };
        }
    })


    return (
        <>
            <div className="large_spacer"></div>
            <div className="lined_background_light">
                <div className="diagonal_background">
                    <div className="page_container">
                        <div className="safe_inner">
                            
                            <div className="large_spacer"></div>
                            <h1 className="title_font_family center_mobile">What is JetBolt?</h1>
                            <div className="medium_spacer"></div>
                            <p className="large_text center_mobile">JetBolt provides an invisible layer between applications and the blockchain. Discover some of the amazing features packed into this lightning fast new token.</p>
                            <div className="large_spacer"></div>

                            <div {...swipe_handlers}>
                            
                                <div className="features_container" ref={scroll_view_ref}>
                                    <div className="features_container_inner" style={{width: "2635px"}}>
                                        <div className="features_item zero_opacity"></div>
                                        <div className="features_item zero_opacity"></div>
                                        {
                                            features.map((item, index) => (
                                                <Featured_Item key={index} index={index} selected_index={selected_index} set_selected_index={set_selected_index} item_content={item}/>
                                            ))
                                        }
                                        <div className="features_item zero_opacity"></div>
                                        <div className="features_item zero_opacity"></div>
                                    </div>
                                    
                                </div>

                                <div className="features_outer">
                                    <div className="overlay_hooks_container">
                                        <div className="hook_left"></div>
                                        <div className="hook_left hook_transform_top_right"></div>
                                        <div className="hook_left hook_transform_bottom_right"></div>
                                        <div className="hook_left hook_transform_bottom_left"></div>
                                    </div>
                                    <div className={`overlay_buy_button title_font_family yellow_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>BUY NOW</div>
                                    <div className={`overlay_left_button yellow_button_hover ${selected_index == 0?"button_deselected":""}`} onClick={decrement_index}>
                                        <img className="inner_arrow_icon" src={left_arrow_icon}/>
                                    </div>
                                    <div className={`overlay_right_button yellow_button_hover ${selected_index == 4?"button_deselected":""}`} onClick={increment_index}>
                                        <img className="inner_arrow_icon" src={right_arrow_icon}/>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="large_spacer"></div>
                    
                </div>
                <div className="fade_grid"></div>
            </div>
            <div className="large_spacer"></div>
        </>
    )
}

export default Features;