import React, { createContext, useContext, useState, useEffect } from "react";

// ---- Services ----
import { check_if_location_blocked } from "../services/location";

// ---- External Links ----
import external_links from "../configs/external_links.json";

const ModalContext = createContext();

export function useGeoBlock() {
    return useContext(ModalContext);
}

export function Geo_Block({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);
    let [login_mode, set_login_mode] = useState(0);

    let [country, set_country] = useState("");

    let open_geo_block = (modal_parameters) => {
        // document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_geo_block = () => {
        set_animate_out(true);

        let one_week_from_now = new Date().getTime() + (7 * 24 * 60 * 60 * 1000);
        localStorage.setItem("skip_geo_block", one_week_from_now);

        setTimeout(() => {
            // document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
    }

    let context_value = {
        open_geo_block,
        close_geo_block
    }

    useEffect(()=> {
        (async function() {

            if (window.location.pathname != "/iframe") {
                let expiry_time = localStorage.getItem("skip_geo_block");
                if (!expiry_time || new Date().getTime() > expiry_time) {

                    try {
                        let { is_blocked, country } = await check_if_location_blocked();
                        if (is_blocked) {
                            open_geo_block();
                            set_country(country);
                        }
                    } catch (error) {
                        // console.log(error);
                    }
                }
            }

        })();
    },[]);


    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="empty_shade">
                    <div className={`small_pop_up_container white_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="purchase_funnel_container">
                            <div className="stick_text">
                                <div className="small_spacer"></div>
                                <div style={{position: "relative"}}>
                                    <h3 className="central_text_indicator title_font_family">Website Disclaimer</h3>
                                </div>
                                <div className="small_spacer"></div>
                            </div>

                            <p className="small_text" style={{pointerEvents: "all"}}>We have detected that you may be attempting to access our website from a country where it is unavailable.</p>
                            <div className="small_spacer"></div>
                            <div className="small_button_pop yellow_glow title_font_family yellow_button_hover" onClick={close_geo_block}>Continue</div>
                            <div className="title_font_family small_button_pop dull opacity_hover" onClick={()=>window.location="https://google.com"}>Exit Site</div>
                        </div>
                    </div>
                    
                </div>
            }

            {/* { show_modal &&
                <div className="background_shader">
                    <div className={`language_selector_container yellow_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="purchase_funnel_container">
                            <div className="stick_text">
                                <div className="medium_spacer"></div>
                                <div style={{position: "relative"}}>
                                    <h2 className="central_text_indicator title_font_family">Geo Block</h2>
                                </div>
                                <div className="medium_spacer"></div>
                            </div>

                            <p>We have detected that you may be attempting to access our website from a country where it is unavailable.</p>
                            <div className="small_spacer"></div>
                            <p>Country Detected: {country}</p>
                            <div className="small_spacer"></div>
                            <p>If this is an error you may continue to our site. However, please note that by continuing you hereby certify that you are not a citizen or resident of any banned country. A complete list of banned countries is <u className="underlined_text" onClick={()=>window.open(external_links.blocked_countries)}>available here.</u></p>
                            <div className="medium_spacer"></div>
                            <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={close_geo_block}>Continue Anyway</div>
                            <div className="medium_spacer"></div>
                            <div className="title_font_family payment_button_dull opacity_hover" onClick={()=>window.location="https://google.com"}>Exit Site</div>
                        </div>
                    </div>
                    
                </div>
            } */}
        </ModalContext.Provider>
    )


}