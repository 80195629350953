import React, { createContext, useContext, useState, useEffect } from "react";

const ModalContext = createContext();

export function useErrorPopUp() {
    return useContext(ModalContext);
}

export function Error_Pop_Up({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [parameters, set_parameters] = useState(null);

    let throw_error = (modal_parameters) => {
        document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_error = () => {
        document.body.style.overflow = 'visible';
        
        set_show_modal(false);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
    }

    let context_value = {
        throw_error,
        close_error
    };

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">
                    <div className="error_container blue_glow">
                        <div className="large_spacer"></div>
                        <h2 className="central_text_indicator title_font_family">{parameters.title}</h2>
                        <div className="medium_spacer"></div>
                        <p className="error_text_content">{parameters.content}</p>
                        <div className="large_spacer"></div>

                        {
                            parameters.buttons.length == 1 ?
                            <div className="single_error_button blue_button_hover" onClick={
                                () => {
                                    if (parameters.buttons[0].callback) {
                                        parameters.buttons[0].callback();
                                        close_error();
                                    }
                                    else {
                                        close_error();
                                    }
                                }
                            }>{parameters.buttons[0].title}</div> :
                            <>
                                <div className="dual_error_button_0 blue_button_hover" onClick={
                                    () => {
                                        if (parameters.buttons[0].callback) {
                                            parameters.buttons[0].callback();
                                            close_error();
                                        }
                                        else {
                                            close_error();
                                        }
                                    }
                                }>{parameters.buttons[0].title}</div>
                                <div className="dual_error_button_1 gray_button_hover" onClick={
                                    () => {
                                        if (parameters.buttons[1].callback) {
                                            parameters.buttons[1].callback();
                                            close_error();
                                        }
                                        else {
                                            close_error();
                                        }
                                    }
                                }>{parameters.buttons[1].title}</div>
                            </>
                        }

                        <div className="large_spacer"></div>
                    </div>
                </div>
            }
        </ModalContext.Provider>
    );

}