import React, { createContext, useContext, useState, useEffect } from "react";

// ---- Components ----
import Login_Required from "./sign_up/login_required";
import Set_Username from "./sign_up/set_username";
import Create_Authenticator from "./sign_up/create_authenticator";
import Save_Seed_Phrase from "./sign_up/save_seed_phrase";
import Confirm_Seed_Phrase from "./sign_up/confirm_seed_phrase";
import Sign_In from "./sign_up/sign_in";
import IFrame_Login from "./sign_up/iframe_login";
import Force_Browser_Transfer from "./sign_up/force_browser_transfer";

// ---- Services ----
import useToken from "../services/wallet/useToken";
import useCustomer from "../services/wallet/useCustomer";
import { has_unclaimed_tokens, add_destination_address, add_sources, get_order_info } from "../services/api";
import customer_instance from "../services/wallet/customer";
import useAttribution from "../services/useAttribution";
import { remove_param_without_reloading } from "../services/helper_functions";
// import { usePurchaseFunnel } from "./purchase_funnel";

// ---- Icons ----
import logo_dark from "../assets/icons/logo_dark.png";

// ---- Modals ----
import { useErrorPopUp } from "./error_pop_up";

const ModalContext = createContext();

export function useLogin() {
    return useContext(ModalContext);
}

export function Login({ children }) {

    let { sources } = useAttribution();

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);
    let [login_mode, set_login_mode] = useState(0);

    let open_login_funnel = (modal_parameters) => {
        document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_login_funnel = () => {
        set_animate_out(true);
        try { parameters.close_callback() } catch (error) { }
        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
    }

    let context_value = {
        open_login_funnel,
        close_login_funnel
    }

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    function account_linked() {
        let params = {
            title: "Account Linked",
            content: "We have successfully linked your account. If you have made a purchase, the tokens will become visible on your profile in a few minutes.",
            buttons: [
                {
                    title: "Visit Profile",
                    callback: function() {
                        window.location = "/profile"
                    }
                },
                {
                    title: "Dismiss",
                }
            ]
        }
        throw_error(params);
    }

    // let { open_purchase_funnel } = usePurchaseFunnel();

    let token = useToken();
    let customer = useCustomer();

    useEffect(()=> {

        let url = new URL(window.location.href);
        let url_params = url.searchParams;
        let force_load_customer = url_params.get("force_load_customer");
        let payment_id = url_params.get("payment_id");
        let hash = url_params.get("hash");
        let is_tweed = url_params.get("is_tweed");
        if (force_load_customer) {
            (async function() {
                customer_instance.force_load(force_load_customer);
                
                let cur_customer = customer_instance.get();

                if (sources.length > 0) {
                    try {
                        await add_sources(cur_customer.customer_id, sources);
                    } catch (error) {}
                }

                if (token && token.wallet_address && token.confirmed) {
                    await add_destination_address(cur_customer.customer_id, cur_customer.secret_code, token.wallet_address);
                    customer_instance.add_destination_address(token.wallet_address);                    
                }


                if (payment_id) {
                    // let { hint_amount, payin_address, currency_index, deadline_at, has_payins } = await get_order_info(cur_customer.customer_id, payment_id);
                    // if (has_payins) {
                    //     open_purchase_funnel({type:"pending_evm", payment_id, hint_amount, currency_index });
                    // }
                    // else {
                    //     open_purchase_funnel({type: "pay_evm", hint_amount, payin_address, payment_id, currency_index, deadline_at});
                    // }
                    // remove_param_without_reloading("payment_id");
                    if (hash) {
                        window.location = `https://jetbolt.io/?type=wert&payment_id=${payment_id}&hash=${hash}&pending=true`;
                    }
                    else if (is_tweed) {
                        window.location = `https://jetbolt.io/?type=tweed&payment_id=${payment_id}&pending=true`;
                    }
                    else {
                        window.location = `https://jetbolt.io/?type=crypto&payment_id=${payment_id}&pending=true`;
                    }
                    
                }
                else {
                    if (token && token.wallet_address && token.confirmed) {
                        account_linked();
                    }
                    else {
                        set_login_mode(2);
                        open_login_funnel({type: "login_required"});
                    }
                }

                remove_param_without_reloading("force_load_customer");

            }) ();
            
            return;
        }

        if (["/profile", "/staking", "/friends", "/history", "/settings"].includes(window.location.pathname)) {
            if (!token || !(token.confirmed && token.credential_id && token.username)) {
                open_login_funnel({type: "login_required"});
            }
        }

        // if (customer && customer.has_purchased && !customer.destination_address) {
        //     set_login_mode(1);
        //     open_login_funnel({type: "login_required"});
        // }
        // else 
        
        if (customer && customer.customer_id && !customer.destination_address && !(token && token.wallet_address && token.confirmed)) {
            (async function() {
                let response = await has_unclaimed_tokens(customer.customer_id);
                if (response.unclaimed_count > 0) {
                    set_login_mode(1);
                    open_login_funnel({type: "login_required"});
                }
            })();
        }
        
    },[]);

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">

                    {
                        window.location.pathname == "/iframe" &&
                        <>
                            <div className="jetpaw_iframe_header"><img className="inline_iframe_logo" src={logo_dark}/>JetBolt</div>
                        </>
                    }

                    {/* <div className="outer_modal_click" onClick={close_login_funnel}></div> */}
                    <div className={`language_selector_container yellow_glow ${animate_out ? 'hide' : ''}`}>
                        {
                            parameters.type == "login_required" &&
                            <Login_Required parameters={parameters} set_parameters={set_parameters} mode={login_mode}/>
                        }
                        {
                            parameters.type == "set_username" &&
                            <Set_Username parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "create_authenticator" &&
                            <Create_Authenticator parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "save_seed_phrase" &&
                            <Save_Seed_Phrase parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "confirm_seed_phrase" &&
                            <Confirm_Seed_Phrase parameters={parameters} set_parameters={set_parameters} close={close_login_funnel}/>
                        }
                        {
                            parameters.type == "sign_in" &&
                            <Sign_In parameters={parameters} set_parameters={set_parameters}/>
                        }
                        {
                            parameters.type == "iframe_login" &&
                            <IFrame_Login parameters={parameters} set_parameters={set_parameters} close={close_login_funnel}/>
                        }
                        {
                            parameters.type == "force_browser_transfer" &&
                            <Force_Browser_Transfer close={close_login_funnel} parameters={parameters} set_parameters={set_parameters}/>
                        }
                    </div>
                </div>
            }
        </ModalContext.Provider>
    );


}