// import { QRCodeSVG } from 'qrcode.react';

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import down_icon from "../../assets/icons/down.png";
import edit_icon from "../../assets/icons/edit.png";
import wallet_icon from "../../assets/icons/wallet.png";
import paste_icon from "../../assets/icons/paste.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png";
import profile_icon from "../../assets/icons/profile.png";

// ---- Services ----
import useToken from "../../services/wallet/useToken";

function Reveive_Tokens({ close, parameters, set_parameters }) {

    let token = useToken();

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Receive</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                        {/* <img className="back_icon" src={left_icon} onClick={()=>set_parameters({type:"evm", tab:1, is_edit: true})}/> */}
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                </div>
                {/* <div className="medium_spacer"></div>
                <QRCodeSVG value="https://reactjs.org/" bgColor={"white"} fgColor={"var(--background_color)"} width={"100%"} size={250}/> */}

                <div className="medium_spacer"></div>
                <p className="small_text">Your Handle:</p>
                <div className="small_spacer"></div>
                <div className="payment_input_container notranslate">
                    <div className="payment_input_currency_selector_short">
                        <img className="payment_input_selector_icon" src={profile_icon}/>
                    </div>
                    <div className="paste_input_button gray_button_hover" onClick={()=>navigator.clipboard.writeText(`@${token.username}`)}>
                        <img className="paste_icon" src={paste_icon}/>
                        <div className="paste_text_inner">Copy</div>
                    </div>
                    <input className="long_input_w_paste" value={`@${token.username}`}/>
                </div>
                <div className="medium_spacer"></div>

                <p className="small_text">Your Address:</p>
                <div className="small_spacer"></div>
                <div className="payment_input_container notranslate">
                    <div className="payment_input_currency_selector_short">
                        <img className="payment_input_selector_icon" src={wallet_icon}/>
                    </div>
                    <div className="paste_input_button gray_button_hover" onClick={()=>navigator.clipboard.writeText(token.wallet_address)}>
                        <img className="paste_icon" src={paste_icon}/>
                        <div className="paste_text_inner">Copy</div>
                    </div>
                    <input className="long_input_w_paste" value={token.wallet_address}/>
                </div>

            </div>
        </>
    );
}

export default Reveive_Tokens;