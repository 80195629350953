import { useState, useEffect, useRef } from "react";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png"
import profile_icon from "../../assets/icons/profile.png";

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import { set_username, check_username_availability } from "../../services/contract_functions/friends";

function Change_Username({ parameters, set_parameters }) {

    // ---- Hooks ----
    let token = useToken();
    let [can_register, set_can_register] = useState(false);
    let [is_loading, set_is_loading] = useState(false);

    // ---- Refs ----
    let username_input_ref = useRef(null);
    let username_length_ref = useRef();
    let username_characters_ref = useRef();
    let username_available_ref = useRef();

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    async function register_username() {
        if (token && token.wallet_address && username_input_ref.current) {
            set_is_loading(true);
            let username = username_input_ref.current.value;
            try {
                await set_username(username);
            } catch (error) {
                // console.log(error);
            }
            set_is_loading(false);
        }
    }

    function is_valid_username(username) {
        let regex = /^[a-zA-Z0-9_]+$/;
        return regex.test(username);
    }

    async function username_changed() {
        if (username_input_ref.current) {
            let username = username_input_ref.current.value;

            set_can_register(false);
            
            if (username.length >= 3) {
                username_length_ref.current.style.color = "#00bc97";
            }
            else {
                username_length_ref.current.style.color = "#ff4220";
            }

            let is_valid = is_valid_username(username);

            if (is_valid) {
                username_characters_ref.current.style.color = "#00bc97";
            }
            else {
                username_characters_ref.current.style.color = "#ff4220";
            }

            let is_available = await check_username_availability(username);
            
            if (is_available) {
                username_available_ref.current.style.color = "#00bc97";
            }
            else {
                username_available_ref.current.style.color = "#ff4220";
            }

            if (username.length >= 3 && is_valid && is_available) {
                set_can_register(true);
            }
        }
    }

    return (
        <>
            <div className="purchase_funnel_container">

                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Register Username</h2>
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                    <div className="medium_spacer"></div>
                </div>

                <div className="payment_input_container">
                    <div className="payment_input_currency_selector">
                        <img className="payment_input_selector_icon" src={profile_icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                    </div>
                    <input ref={username_input_ref} onChange={username_changed} className="full_width_input" placeholder="Username"/>
                </div>

                <div className="small_spacer"></div>
                <p className="small_text" ref={username_length_ref}>Minimum 3 characters</p>
                <div className="small_spacer"></div>
                <p className="small_text" ref={username_characters_ref}>Only letters, numbers, and underscores</p>
                <div className="small_spacer"></div>
                <p className="small_text" ref={username_available_ref}>Available</p>
                
                <div className="medium_spacer"></div>
                <div className={`payment_button title_font_family ${(!can_register || is_loading)?"unselectable":""}`} onClick={register_username}>{
                    is_loading ?
                        "Registering...":
                        "Register"
                }</div>

            </div>
        </>
    );

}

export default Change_Username;