import React, { useRef, useEffect, useState } from 'react';

// ---- Components ----
import SEO from "../components/seo";
import App_Nav from "../components/app_nav";

// ---- Icons ----
import search_icon from "../assets/icons/search.png";
import right_icon from "../assets/icons/right.png";
import tick_icon from "../assets/icons/tick.png";
import cross_icon from "../assets/icons/cross.png";

// ---- Images ----
import second_cat_image from "../assets/images/second_cat.png";

// ---- Jazzicon ----
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

// ---- Modals ----
import { useAppModals } from "../modals/app_modals";

// ---- Services ----
import useToken from "../services/wallet/useToken";
import { get_friend_requests, get_friends, get_favorites, accept_friend, decline_friend, get_outgoing_friend_requests } from "../services/contract_functions/friends";
import { on_focus_input_ref, on_blur_input_ref, shorten_crypto_string } from "../services/helper_functions";

function Friends_Page() {

    let search_bar_outer_ref = useRef(null);

    // ---- Modals ----
    let { open_app_modal, close_open_app_modal } = useAppModals();

    // ---- Tx Status ----
    let [tx_pending, set_tx_pending] = useState(false);
    let [tx_status, set_tx_status] = useState({status:"Waiting for approval", amount:0})
    let [details, set_details] = useState("");

    function update_transaction_status(status, amount, success_tx=false) {
        set_tx_status({status, amount});
        open_app_modal({type: "approve_transaction", details, cb: {tx_pending: true, tx_status: {status, amount}}, success_tx});
    }

    // ---- Functions ----

    function add_friend() {
        open_app_modal({type:"add_friend", close_callback: load_friends});
    }

    async function accept_friend_request(requester_address) {
        set_tx_pending(true);
        set_details("Accept user's friend request.");
        set_tx_status({status:"Waiting for approval", amount:0});
        open_app_modal({type: "approve_transaction", details: "Accept user's friend request.", cb: {tx_pending: true, tx_status: {status:"Waiting for approval", amount:0}}});
        try {
            await accept_friend(requester_address, update_transaction_status);
            update_transaction_status("",1,true);
            setTimeout(()=>{
                close_open_app_modal();
                load_friends();
            },500);
        } catch (error) {
            close_open_app_modal();
        }
        set_tx_pending(false);
    }

    async function decline_friend_request(requester_address) {
        set_tx_pending(true);
        set_details("Decline user's friend request.");
        set_tx_status({status:"Waiting for approval", amount:0});
        open_app_modal({type: "approve_transaction", details: "Decline user's friend request.", cb: {tx_pending: true, tx_status: {status:"Waiting for approval", amount:0}}});
        try {
            await decline_friend(requester_address, update_transaction_status);
            update_transaction_status("",1,true);
            setTimeout(()=>{
                close_open_app_modal();
                load_friends();
            },500);
        } catch (error) {
            close_open_app_modal();
        }
        set_tx_pending(false);
    }

    function open_profile(wallet_address) {
        open_app_modal({type: "profile", wallet_address, close_callback: load_friends})
    }

    // ---- Token ----
    let token = useToken();

    // ---- Hooks ----
    let [is_loading, set_is_loading] = useState(true);
    let [outgoing_friend_requests, set_outgoing_friend_requests] = useState([]);
    let [friend_requests, set_friend_requests] = useState([]);
    let [favorites, set_favorites] = useState([]);
    let [friends, set_friends] = useState([]);
    let [search_input, set_search_input] = useState('');

    // ---- Load friends ----
    async function load_friends() {
        if (token && token.wallet_address) {

            let outgoing_friend_requests_raw = await get_outgoing_friend_requests(token.wallet_address);

            let outgoing_friend_requests = [];

            for (let i = 0; i < outgoing_friend_requests_raw.length; i++) {
                outgoing_friend_requests.push({
                    wallet_address: outgoing_friend_requests_raw[i].wallet_address,
                    username: outgoing_friend_requests_raw[i].username
                })
            }

            set_outgoing_friend_requests(outgoing_friend_requests);

            let friend_requests_raw = await get_friend_requests(token.wallet_address);

            let friend_requests = [];

            for (let i = 0; i < friend_requests_raw.length; i++) {
                friend_requests.push({
                    wallet_address: friend_requests_raw[i].wallet_address,
                    username: friend_requests_raw[i].username
                })
            }
            
            set_friend_requests(friend_requests)

            let favorites_raw = await get_favorites(token.wallet_address);
            let favorites = [];

            for (let i = 0; i < favorites_raw.length; i++) {
                favorites.push({
                    wallet_address: favorites_raw[i].wallet_address,
                    username: favorites_raw[i].username
                })
            }

            set_favorites(favorites);

            let friends_raw = await get_friends(token.wallet_address);
            let friends = [];

            for (let i = 0; i < friends_raw.length; i++) {
                friends.push({
                    wallet_address: friends_raw[i].wallet_address,
                    username: friends_raw[i].username
                })
            }

            friends.sort((a, b) => a.username.localeCompare(b.username));

            set_friends(friends);
            set_is_loading(false)
        }
    }

    // ---- On Load ----
    useEffect(()=> {

        load_friends();

    },[token]);

    // ---- Search Filters ----
    let filtered_friends = friends.filter(friend => 
        friend.username.startsWith(search_input) || friend.wallet_address.startsWith(search_input));
    let filtered_favorites = favorites.filter(favorite => 
        favorite.username.startsWith(search_input) || favorite.wallet_address.startsWith(search_input));
    let filtered_friend_requests = friend_requests.filter(request => 
        request.username.startsWith(search_input) || request.wallet_address.startsWith(search_input));
    let filtered_outgoing_friend_requests = outgoing_friend_requests.filter(request => 
        request.username.startsWith(search_input) || request.wallet_address.startsWith(search_input));

    return (
        <>
            <SEO parameters={
                {
                    title: "JetBolt Friends",
                    description: "Manage your JetBolt friends and favorites. Earn more from staking by connecting with friends.",
                    url: "https://jetbolt.io/friends"
                }
            }/>
            <App_Nav selected_index={3} selected_index_mobile={3}>
                <div className="small_spacer"></div>
                <div className="title_relative_container">
                    <h1 className="title_font_family">Friends</h1>
                    <div className="right_align_button yellow_button_hover" onClick={add_friend}>Add Friend</div>
                </div>
                <div className="small_spacer"></div>
                <div className="search_input_container" ref={search_bar_outer_ref}>
                    <div className="payment_input_currency_selector">
                        <img className="payment_input_selector_icon" src={search_icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                    </div>
                    <input className="search_input" placeholder="Search by username or wallet address"
                        onChange={(e) => set_search_input(e.target.value)}
                        onFocus={()=>on_focus_input_ref(search_bar_outer_ref)} onBlur={()=>on_blur_input_ref(search_bar_outer_ref)}
                        autoComplete="off" autoCorrect="off" spellCheck="false" autoCapitalize="none"
                    />
                </div>

                {
                    filtered_outgoing_friend_requests.length > 0 &&
                    <>
                        <div className="medium_spacer"></div>
                        <h3>Outgoing Friend Requests</h3>
                        <div className="medium_spacer"></div>
                        {
                            filtered_outgoing_friend_requests.map((item,index)=>(
                                <div key={index} className="friend_list_item gray_button_hover" onClick={()=>open_profile(item.wallet_address)}>
                                    <div className="live_sales_icon">
                                        <Jazzicon diameter={30} seed={jsNumberForAddress(item.wallet_address)}/>
                                    </div>
                                    <div className="live_sales_amount_label">{item.username ? item.username : shorten_crypto_string(item.wallet_address)}</div>
                                    <div className="decline_button">
                                        <img className="inner_icon" src={right_icon}/>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                }
                

                {
                    filtered_friend_requests.length > 0 &&
                    <>
                        <div className="medium_spacer"></div>
                        <h3>Friend Requests</h3>
                        <div className="medium_spacer"></div>
                        {
                            filtered_friend_requests.map((item,index)=>(
                                <div key={index} className="friend_list_item gray_button_hover" onClick={()=>open_profile(item.wallet_address)}>
                                    <div className="live_sales_icon">
                                        <Jazzicon diameter={30} seed={jsNumberForAddress(item.wallet_address)}/>
                                    </div>
                                    <div className="live_sales_amount_label">{item.username ? item.username : shorten_crypto_string(item.wallet_address)}</div>
                                    <div className="accept_button opacity_hover" onClick={(e)=>{ e.stopPropagation(); accept_friend_request(item.wallet_address) }}>
                                        <img className="inner_icon" src={tick_icon}/>
                                    </div>
                                    <div className="decline_button opacity_hover" onClick={(e)=>{ e.stopPropagation(); decline_friend_request(item.wallet_address) }}>
                                        <img className="inner_icon" src={cross_icon}/>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                }

                {
                    filtered_favorites.length > 0 &&
                    <>
                        <div className="medium_spacer"></div>
                        <h3>Favorite Friends</h3>
                        <div className="medium_spacer"></div>
                        {
                            filtered_favorites.map((item,index)=>(
                                <div key={index} className="friend_list_item gray_button_hover" onClick={()=>open_profile(item.wallet_address)}>
                                    <div className="live_sales_icon">
                                        <Jazzicon diameter={30} seed={jsNumberForAddress(item.wallet_address)}/>
                                    </div>
                                    <div className="live_sales_amount_label">{item.username ? item.username : shorten_crypto_string(item.wallet_address)}</div>
                                    <div className="decline_button">
                                        <img className="inner_icon" src={right_icon}/>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                }

                <div className="medium_spacer"></div>
                {
                    friends.length > 0 ?
                    <>
                        <h3>All Friends</h3>
                        <div className="medium_spacer"></div>
                        {
                            filtered_friends.map((item,index)=>(
                                <div key={index} className="friend_list_item gray_button_hover" onClick={()=>open_profile(item.wallet_address)}>
                                    <div className="live_sales_icon">
                                        <Jazzicon diameter={30} seed={jsNumberForAddress(item.wallet_address)}/>
                                    </div>
                                    <div className="live_sales_amount_label">{item.username ? item.username : shorten_crypto_string(item.wallet_address)}</div>
                                    <div className="decline_button">
                                        <img className="inner_icon" src={right_icon}/>
                                    </div>
                                </div>
                            ))
                        }
                    </> :
                    <>
                        { !is_loading && friend_requests.length == 0 && filtered_outgoing_friend_requests.length == 0 && <>
                            <h3>No Friends Found</h3>
                            <div className="medium_spacer"></div>
                            <p>You don't have any friends yet. Add friends and favorites to start earning more JetPaw tokens.</p>
                            </>
                        }
                    </>
                }
                
                <div className="medium_spacer"></div>
            </App_Nav>
        </>
    )
}

export default Friends_Page;