import { useState, useEffect } from "react";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";
import { useCurrencySelector } from "../currency_selector";

// ---- Icons ----
import logo_dark_icon from "../../assets/icons/logo_dark.png";
import right_icon from "../../assets/icons/right.png";
import edit_icon from "../../assets/icons/edit.png";
import wallet_icon from "../../assets/icons/wallet.png";
import paste_icon from "../../assets/icons/paste.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png"

// ---- Data ----
import { currencies } from "../../assets/data/currencies";

// ---- WAGMI ----
import { useAccount, useConnect } from 'wagmi';
import { wagmi_config, Wagmi_Client } from "../../configs/wagmi_config";
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { disconnect } from '@wagmi/core';

// ---- Jazzicon ----
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

function EVM_Component({ parameters, set_parameters }) {

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();
    let { open_currency_selector } = useCurrencySelector();

    // ---- States ----
    let [tab, set_tab] = useState(0);
    let [is_edit, set_is_edit] = useState(false);
    let [manual_step, set_manual_step] = useState(0);

    // ---- Wallet Connection ----
    const { isConnected, address, chain }  = useAccount();
    const { connect, connectors, isLoading } = useConnect();

    const injected_connector = connectors[0];
    const web3Modal = useWeb3Modal();

    async function connect_wallet() {
        if (window.ethereum) {
            await connect({ connector: injected_connector });
        }
        else {
            await web3Modal.open();
        }
    }

    async function handle_con() {
        if (isConnected) {
            await disconnect(wagmi_config);
        }
        else {
            await connect_wallet()
        }
    }

    // ---- Pay Wallet ----
    function pay_handler() {
        if (!isConnected) {
            connect_wallet();
        }
        else {
            set_parameters({type:"pending_evm"})
        }
    }

    // ---- Editing ----
    function edit() {
        if (!is_edit) {
            set_is_edit(true);
        }
    }

    // ---- Hooks ----
    useEffect(() => {
        if (!isLoading && !isConnected) {
            connect_wallet();
        }
        
    },[]);

    useEffect(() => {

        if (parameters.tab) {
            set_tab(parameters.tab)
        }

        if (parameters.is_edit) {
            set_is_edit(parameters.is_edit)
        }

    }, [parameters])

    return (
        <>
            <div className="purchase_funnel_container">

                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Buy Token</h2>
                        <img className="back_icon" src={left_icon} onClick={()=>set_parameters({type:"widget"})}/>
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                    <div className="small_spacer"></div>
                    <div className="tab_selector">
                        <div className={`tab_inner ${tab==0?"tab_selected":""}`} onClick={()=>set_tab(0)}>Pay in Wallet</div>
                        <div className={`tab_inner ${tab==1?"tab_selected":""}`} onClick={()=>set_tab(1)}>Pay Manually</div>
                    </div>
                </div>

                {
                    tab == 0 &&
                    <>
                        <div className="medium_spacer"></div>
                        <p className="small_text">Your Wallet:</p>
                        <div className="small_spacer"></div>
                        <div className="payment_input_container">
                            <div className="payment_input_currency_selector_short" onClick={open_currency_selector}>

                                {isConnected ?
                                    <div className="payment_input_selector_icon">
                                        <Jazzicon diameter={30} seed={jsNumberForAddress(address)}/>
                                    </div> :
                                    <img className="payment_input_selector_icon" src={wallet_icon}/>
                                }
                                
                                {/* <img className="payment_input_selector_extra_icon" src={right_icon}/> */}
                            </div>
                            {
                                <div className="disconnect_input_button" onClick={handle_con}>
                                    <img className="paste_icon" src={paste_icon}/>
                                    <div className="paste_text_inner">{isConnected?"Disconnect":"Connect"}</div>
                                </div>
                            }

                            <div className="wallet_indicator">{
                                isConnected ?
                                    address :
                                    ""
                            }</div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text" style={{opacity: `${is_edit?"1":"0.5"}`}}>You Pay:</p>
                        <div className="small_spacer"></div>
                        <div className={`${is_edit?"payment_input_container":"payment_input_container_short"}`} onClick={edit}>
                            <div className={`${is_edit?"payment_input_currency_selector":"payment_input_currency_selector_short"}`} onClick={open_currency_selector}>
                                <img className="payment_input_selector_icon" src={currencies[1].icon}/>
                                <img className="payment_input_selector_extra_icon" src={right_icon}/>
                            </div>
                            <img className="payment_edit_icon" src={edit_icon} style={{display:`${is_edit?"none":"block"}`}}/>
                            {
                                is_edit ?
                                    <>
                                        <input className="value_input" value={"0.1"}/>
                                        <div className="currency_label">ETH</div>
                                        <div className="up_input_button">+</div>
                                        <div className="down_input_button">-</div>
                                    </> :
                                    <>
                                        <div className="value_indicator">0.1 ETH</div>
                                    </>
                            }
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text" style={{opacity: `${is_edit?"1":"0.5"}`}}>You Receive:</p>
                        <div className="small_spacer"></div>
                        <div className={`${is_edit?"payment_input_container":"payment_input_container_short"}`} onClick={edit}>
                            <div className={`${is_edit?"payment_input_currency_selector":"payment_input_currency_selector_short"}`} onClick={open_currency_selector}>
                                <img className="payment_input_selector_icon" src={logo_dark_icon}/>
                                <img className="payment_input_selector_extra_icon" src={right_icon}/>
                            </div>
                            <img className="payment_edit_icon" src={edit_icon} style={{display:`${is_edit?"none":"block"}`}}/>
                            {
                                is_edit ?
                                    <>
                                        <input className="value_input" value={"0.1"}/>
                                        <div className="currency_label">CAT</div>
                                        <div className="up_input_button">+</div>
                                        <div className="down_input_button">-</div>
                                    </> :
                                    <>
                                        <div className="value_indicator">0.1 CAT</div>
                                    </>
                            }
                        </div>

                        {
                            isConnected ?
                            <>
                                <div className="small_spacer"></div>
                                <div className="medium_spacer"></div>
                                <div className="payment_button title_font_family" onClick={pay_handler}>Pay Now</div>
                            </> :
                            <>
                                <div className="small_spacer"></div>
                                <div className="medium_spacer"></div>
                                <div className="payment_button title_font_family" onClick={pay_handler}>{
                                    isLoading ?
                                        "Connecting..." :
                                        "Connect Wallet"
                                }
                                </div>
                            </>
                        }

                        
                    </>
                }

                {
                    tab == 1 &&
                    <>
                        <div className="medium_spacer"></div>
                        <p className="small_text">You Pay From:</p>
                        <div className="small_spacer"></div>
                        <div className="payment_input_container">
                            <div className="payment_input_currency_selector" onClick={open_currency_selector}>
                                <img className="payment_input_selector_icon" src={wallet_icon}/>
                                <img className="payment_input_selector_extra_icon" src={right_icon}/>
                            </div>
                            <div className="paste_input_button">
                                <img className="paste_icon" src={paste_icon}/>
                                <div className="paste_text_inner">Paste</div>
                            </div>
                            <input className="wallet_address_input" placeholder="0xb794f5ea0ba39494ce839613fffba74279579268"/>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text" style={{opacity: `${is_edit?"1":"0.5"}`}}>You Pay:</p>
                        <div className="small_spacer"></div>
                        <div className={`${is_edit?"payment_input_container":"payment_input_container_short"}`} onClick={edit}>
                            <div className={`${is_edit?"payment_input_currency_selector":"payment_input_currency_selector_short"}`} onClick={open_currency_selector}>
                                <img className="payment_input_selector_icon" src={currencies[1].icon}/>
                                <img className="payment_input_selector_extra_icon" src={right_icon}/>
                            </div>
                            <img className="payment_edit_icon" src={edit_icon} style={{display:`${is_edit?"none":"block"}`}}/>
                            {
                                is_edit ?
                                    <>
                                        <input className="value_input" value={"0.1"}/>
                                        <div className="currency_label">ETH</div>
                                        <div className="up_input_button">+</div>
                                        <div className="down_input_button">-</div>
                                    </> :
                                    <>
                                        <div className="value_indicator">0.1 ETH</div>
                                    </>
                            }
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text" style={{opacity: `${is_edit?"1":"0.5"}`}}>You Receive:</p>
                        <div className="small_spacer"></div>
                        <div className={`${is_edit?"payment_input_container":"payment_input_container_short"}`} onClick={edit}>
                            <div className={`${is_edit?"payment_input_currency_selector":"payment_input_currency_selector_short"}`} onClick={open_currency_selector}>
                                <img className="payment_input_selector_icon" src={logo_dark_icon}/>
                                <img className="payment_input_selector_extra_icon" src={right_icon}/>
                            </div>
                            <img className="payment_edit_icon" src={edit_icon} style={{display:`${is_edit?"none":"block"}`}}/>
                            {
                                is_edit ?
                                    <>
                                        <input className="value_input" value={"0.1"}/>
                                        <div className="currency_label">CAT</div>
                                        <div className="up_input_button">+</div>
                                        <div className="down_input_button">-</div>
                                    </> :
                                    <>
                                        <div className="value_indicator">0.1 CAT</div>
                                    </>
                            }
                        </div>

                        <div className="small_spacer"></div>
                        <div className="medium_spacer"></div>
                        <div className="payment_button title_font_family" onClick={()=>set_parameters({type:"manual_evm"})}>Pay Now</div>
                    </>
                }

            </div>
        </>
    );

}

function EVM({ parameters, set_parameters }) {
    return (
        <Wagmi_Client>
            <EVM_Component parameters={parameters} set_parameters={set_parameters}/>
        </Wagmi_Client>
    )
}

export default EVM;