import { make_random_address } from "../contract_functions/core";

class Customer {

    on_change = null;

    constructor() {
        this.current = void 0;
    }

    init() {
        let customer = {
            secret_code: make_random_address()
        }
        this.save(customer);
    }

    add_id(customer_id) {
        this.current.customer_id = customer_id;
        this.save(this.current);
    }

    add_destination_address(destination_address) {
        this.current.destination_address = destination_address;
        this.save(this.current);
    }

    set_has_purchased() {
        this.current.has_purchased = true;
        this.save(this.current);
    }

    save(customer) {
        this.current = customer;
        let customer_string = JSON.stringify(this.current);
        localStorage.setItem("customer", customer_string);
        if (typeof this.on_change == "function") {
            this.on_change();
        }
    }

    get() {
        let customer_string = localStorage.getItem("customer");
        if (!customer_string) { return void 0; }
        this.current = JSON.parse(customer_string);
        return this.current;
    }

    delete() {
        this.current = void 0;
        localStorage.removeItem('customer');
    }

    force_load(uri_string) {
        let token_base64 = decodeURI(uri_string);
        let token_string = Buffer.from(token_base64, 'base64').toString('utf8');
        let customer = JSON.parse(token_string);
        this.save(customer);
    }

    get_hash() {
        let token_string = JSON.stringify(this.current);
        let token_base64 = Buffer.from(token_string).toString('base64');
        return encodeURI(token_base64);
    }

}

// Export as singleton
let instance = new Customer();
export default instance;