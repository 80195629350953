import { useEffect, useState } from "react";

import JSConfetti from 'js-confetti'

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import edit_icon from "../../assets/icons/edit.png";
import wallet_icon from "../../assets/icons/wallet.png";
import paste_icon from "../../assets/icons/paste.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png";

// ---- Data ----
import { currencies } from "../../assets/data/currencies";

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import useCustomer from "../../services/wallet/useCustomer";
import { init_wallet } from "../../services/wallet/core";
import customer_instance from "../../services/wallet/customer";
import { is_order_completed, wert_status } from "../../services/api";
import { trigger_event_crypto } from "../../services/analytics";
import { is_web_view, append_utm_without_reloading, check_web_auth_support } from "../../services/helper_functions";

function Pending_EVM({ parameters, set_parameters }) {

    let token = useToken();
    let customer = useCustomer();
    let { is_os_webview, is_android_webview } = is_web_view();

    async function create_wallet() {
        
        if (is_os_webview || is_android_webview) {
            if (parameters && parameters.payment_id) {
                if (parameters.is_wert) {
                    set_parameters({type: "force_browser_transfer", payment_id: parameters.payment_id, is_wert: true, hash: parameters.hash});
                }
                else if (parameters.is_tweed) {
                    set_parameters({type: "force_browser_transfer", payment_id: parameters.payment_id, is_tweed: true});
                }
                else {
                    set_parameters({type: "force_browser_transfer", payment_id: parameters.payment_id});
                }   
            }
            else {
                set_parameters({type: "force_browser_transfer"});
            }
            return;
        }
        else if (!window.PublicKeyCredential) {
            if (parameters && parameters.payment_id) {
                if (parameters.is_wert) {
                    set_parameters({type: "force_browser_transfer", mode: 1, payment_id: parameters.payment_id, is_wert: true, hash: parameters.hash});
                }
                else if (parameters.is_tweed) {
                    set_parameters({type: "force_browser_transfer", mode: 1, payment_id: parameters.payment_id, is_tweed: true});
                }
                else {
                    set_parameters({type: "force_browser_transfer", mode: 1, payment_id: parameters.payment_id});
                }
            }
            else {
                set_parameters({type: "force_browser_transfer", mode: 1});
            }
            
            return;
        }

        init_wallet();

        if (!token) {
            set_parameters({type:"set_username"});
        }
        else if (!token.username) {
            set_parameters({type:"set_username"});
        }
        else if (!token.credential_id) {
            set_parameters({type:"create_authenticator"});
        }
        else if (!token.confirmed) {
            set_parameters({type:"save_seed_phrase"});
        }
        
    }

    async function sign_in_w_seed() {

        if (is_os_webview || is_android_webview) {
            if (parameters && parameters.payment_id) {
                if (parameters.is_wert) {
                    set_parameters({type: "force_browser_transfer", payment_id: parameters.payment_id, is_wert: true, hash: parameters.hash});
                }
                else if (parameters.is_tweed) {
                    set_parameters({type: "force_browser_transfer", payment_id: parameters.payment_id, is_tweed: true});
                }
                else {
                    set_parameters({type: "force_browser_transfer", payment_id: parameters.payment_id});
                }  
            }
            else {
                set_parameters({type: "force_browser_transfer"});
            }
            return;
        }
        else if (!window.PublicKeyCredential) {
            if (parameters && parameters.payment_id) {
                if (parameters.is_wert) {
                    set_parameters({type: "force_browser_transfer", mode: 1, payment_id: parameters.payment_id, is_wert: true, hash: parameters.hash});
                }
                else if (parameters.is_tweed) {
                    set_parameters({type: "force_browser_transfer", mode: 1, payment_id: parameters.payment_id, is_tweed: true});
                }
                else {
                    set_parameters({type: "force_browser_transfer", mode: 1, payment_id: parameters.payment_id});
                }
            }
            else {
                set_parameters({type: "force_browser_transfer", mode: 1});
            }
            
            return;
        }

        set_parameters({type:"sign_in"});
    }

    let [complete, set_complete] = useState(false);

    useEffect(() => {
        customer_instance.set_has_purchased();

        if (parameters.payment_id && !parameters.is_wert && !parameters.is_tweed) {

            let inter = setInterval(async function() {

                let status = await is_order_completed(customer.customer_id, parameters.payment_id);
                if (status.order_completed) {
                    set_complete(true);
                    clearInterval(inter);
                }

            },10000);

            (async function() {
                let status = await is_order_completed(customer.customer_id, parameters.payment_id);
                if (status.order_completed) {
                    set_complete(true);
                    clearInterval(inter);
                }
            })();

            return () => {
                clearInterval(inter);
            };

        }

        if (parameters.force_success) {
            set_complete(true);
        }

    },[parameters]);

    useEffect(() => {

        if (parameters.payment_id && parameters.is_wert && parameters.hash) {

            let inter = setInterval(async function() {

                let status = await wert_status(customer.customer_id, parameters.payment_id, parameters.hash);
                if (status.order_completed) {
                    set_complete(true);
                    clearInterval(inter);
                }

            },10000);

            (async function() {
                let status = await wert_status(customer.customer_id, parameters.payment_id, parameters.hash);
                if (status.order_completed) {
                    set_complete(true);
                    clearInterval(inter);
                }
            })();

            return () => {
                clearInterval(inter);
            };

        }

        if (parameters.payment_id && parameters.is_tweed) {
            set_complete(true);
        }

    },[parameters]);

    useEffect(()=>{
        trigger_event_crypto("purchase", parameters.hint_amount, parameters.currency_index)
    },[]);

    useEffect(()=>{

        const jsConfetti = new JSConfetti();
        jsConfetti.addConfetti();

        append_utm_without_reloading(`pending=true`);

    },[]);

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Success</h2>
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                </div>
                
                <div className="medium_spacer"></div>
                <p>Congragulations on your purchase. {

                    token && token.confirmed && token.credential_id && token.username ?
                    <>
                        {complete ? "Your tokens should appear on your profile within a few minutes.":"We have detected your payment transaction."}
                    </> :
                    <>
                        Create a JetBolt wallet to receive your tokens.
                    </>

                    } </p>
                <div className="medium_spacer"></div>
                
                <p className="small_text">Status:</p>
                {
                    !complete &&
                    <>
                        <div className="small_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar" style={{width: complete?"100%":"60%"}}></div>
                        </div>
                    </>
                }
                <div className="small_spacer"></div>
                {complete ?
                <p className="small_text" style={{color: "var(--green_text)"}}>Purchase completed</p>:
                <p className="small_text">JetBolt tokens minting</p>
                }
                {
                    (!token || !(token.confirmed && token.credential_id && token.username)) ?
                    <>
                        
                        {!complete &&
                        <>
                            <div className="medium_spacer"></div>
                            <p>While we wait for your payment to go through, you can already get started and create your wallet to recieve your tokens.</p>
                        </>
                        }
                        <div className="small_spacer"></div>
                        <div className="medium_spacer"></div>
                        <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={create_wallet}>{is_android_webview ? "Continue in Chrome" : is_os_webview ? "Continue in Safari" : "Create Wallet"}</div>
                        {
                            !is_android_webview && !is_os_webview &&
                            <>
                                <div className="medium_spacer"></div>
                                <p>Already have a wallet? <u className="underlined_text" onClick={sign_in_w_seed}>Sign in with seed phrase</u></p>
                            </>
                        }
                        
                    </> :
                    <>
                        <div className="medium_spacer"></div>
                        { complete ?
                            <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={()=>window.location = "/profile"}>Visit Profile</div> :
                            <p>Tokens will automatically be deposited into @{token.username}. This can take a few minutes.</p>
                        }
                    </>
                }
                
            </div>
        </>
    )

}

export default Pending_EVM;