import { useState, useEffect, useRef } from "react";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png"
import show_seed_icon from "../../assets/icons/show_seed.png";
import paste_icon from "../../assets/icons/paste.png";

// ---- Services ----
import { init_wallet, check_for_username, add_username, authorized_referrer } from "../../services/wallet/core";
import { on_focus_input_ref, on_blur_input_ref } from "../../services/helper_functions";

function Sign_In({ parameters, set_parameters }) {

    // ---- Refs ----
    let seed_input_ref = useRef(null);
    let seed_input_outer_ref = useRef(null);

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    function seed_error() {
        let params = {
            title: "Oh No!",
            content: "The seed phrase you entered is invalid. It must be 12 words, each seperated by a space, and in lowercase.",
            buttons: [
                {
                    title: "Try Again",
                }
            ]
        }
        throw_error(params);
    }

    async function sign_in() {

        if (seed_input_ref.current) {
            let mnemonic = seed_input_ref.current.value;
            mnemonic = mnemonic.toLowerCase();
            mnemonic = mnemonic.replace(/[,;]+/g, ' ').replace(/\s+/g, ' ').trim();

            if (mnemonic.split(" ").length != 12) {
                seed_error();
                return;
            }

            try {

                init_wallet(mnemonic);

                try {

                    if (parameters.referrer) {
                        authorized_referrer(parameters.referrer);
                    }

                    let username = await check_for_username();

                    if (username && username != "") {
                        add_username(username, false);
                        set_parameters({type:"create_authenticator", close_callback: parameters.close_callback});
                    }
                    else {
                        set_parameters({type:"set_username", close_callback: parameters.close_callback});
                    }

                } catch (error) {
                    set_parameters({type:"set_username", close_callback: parameters.close_callback});
                }
                
            } catch (error) {
                // console.log(error);
                seed_error();
            }

        }

    }

    // ---- Back ----
    function back() {
        set_parameters({type: "login_required", close_callback: parameters.close_callback})
    }

    // ---- Paste ----
    async function paste(ref) {
        try {
            let text = await navigator.clipboard.readText();
            ref.current.value = text;
        } catch (error) {}
    }


    return (
        <>
            <div className="purchase_funnel_container">

                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Sign In</h2>
                        {/* <img className="help_icon" src={help_icon}/> */}
                        <img className="back_icon opacity_hover" src={left_icon} onClick={back}/>
                    </div>
                    <div className="medium_spacer"></div>
                </div>

                <div className="payment_input_container" ref={seed_input_outer_ref}>
                    <div className="payment_input_currency_selector">
                        <img className="payment_input_selector_icon" src={show_seed_icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                        
                    </div>
                        <div className="paste_input_button gray_button_hover" onClick={()=>paste(seed_input_ref)}>
                            <img className="paste_icon" src={paste_icon}/>
                            <div className="paste_text_inner">Paste</div>
                        </div>
                        <input ref={seed_input_ref} className="wallet_address_input" placeholder="enter seed phrase"
                         onFocus={()=>on_focus_input_ref(seed_input_outer_ref)} onBlur={()=>on_blur_input_ref(seed_input_outer_ref)}
                        autoComplete="off" autoCorrect="off" spellCheck="false" autoCapitalize="none"
                        />
                </div>

                <div className="small_spacer"></div>
                <p className="small_text">Enter seed words seperated by spaces all in lowercase.</p>
                
                <div className="medium_spacer"></div>
                <div className="payment_button title_font_family yellow_button_hover" onClick={sign_in}>Continue</div>

            </div>
        </>
    );

}

export default Sign_In;