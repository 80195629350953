import { useState, useEffect } from "react";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// ---- Icons ----
import help_icon from "../../assets/icons/help.png";
import left_icon from "../../assets/icons/left.png"

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import { confirm_seed } from "../../services/wallet/core";
import { add_destination_address } from "../../services/api";
import useCustomer from "../../services/wallet/useCustomer";
import customer_instance from "../../services/wallet/customer";

function Confirm_Seed_Phrase({ parameters, set_parameters, close }) {

    // ---- Customer Info ----
    let customer = useCustomer();

    // ---- Wallet Token ----
    let token = useToken();
    // let mnemonic = [...token.mnemonic.split(" ")];
    const [mnemonic, set_mnemonic] = useState(token.mnemonic.split(" "));

    // ---- Hooks ----
    let [selected_words, set_selected_words] = useState([]);
    let [available_words, set_available_words] = useState([mnemonic[11], mnemonic[0], mnemonic[1]]);

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    
    // ---- Functions ----
    async function save_seed(event) {

        event.target.style = "opacity: 0.25; pointer-events: none;";

        try {
            let expected = [mnemonic[0], mnemonic[1], mnemonic[11]];
            if (selected_words.join(' ') === expected.join(' ')) {
                // let cur_mnemonic = mnemonic;

                if (customer && customer.customer_id) {
                    await add_destination_address(customer.customer_id, customer.secret_code, token.wallet_address);
                    customer_instance.add_destination_address(token.wallet_address);
                }

                confirm_seed();
                // mnemonic = cur_mnemonic;

                if (window.location.pathname == "/iframe") {
                    // window.parent.postMessage({response:{status:"success",status_code:200,wallet_address:token.wallet_address,session_address:token.session_address}}, document.referrer);
                    close();
                    // window.parent.postMessage({ show:false }, document.referrer);
                }
                else {
                    close();
                    window.location = "/profile";
                }

                return;
            }
            else {
                let params = {
                    title: "Oh No!",
                    content: "It looks like the order you selected does not match the seed phrase.",
                    buttons: [
                        {
                            title: "Try Again",
                        },
                        {
                            title: "Show Seed Again",
                            callback: function() {
                                back();
                            }
                        }
                    ]
                }
                throw_error(params);
            }
        } catch (error) {}
        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    function back() {
        set_parameters({type:"save_seed_phrase", close_callback: parameters.close_callback});
    }

    function select_word(word) {
        set_selected_words([...selected_words, word]);
        set_available_words(available_words.filter(w => w !== word));
    }

    function remove_word(word) {
        let new_selected_words = selected_words.filter(w => w !== word);
        set_selected_words(new_selected_words);
        if ([mnemonic[11], mnemonic[0], mnemonic[1]].includes(word)) {
            set_available_words([...available_words, word]);
        }
    }

    return (
        <>
            <div className="purchase_funnel_container">

                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Confirm Seed</h2>
                        {/* <img className="help_icon" src={help_icon}/> */}
                        <img className="back_icon opacity_hover" src={left_icon} onClick={back}/>
                    </div>
                    <div className="medium_spacer"></div>
                </div>

                <p>Please the missing words from your seed phrase in the correct order.</p>
                <div className="medium_spacer"></div>
                {
                    mnemonic.map((item, index) => (
                        <div key={index} className={`seed_phrase_item notranslate ${(index+1) % 3 == 0 ? "no_margin":""} ${[0,1,11].includes(index)?"dashed blue_button_hover_full":"unselectable"}`}  style={{backgroundColor: (selected_words.length-1>=[0,1,11].indexOf(index)&&[0,1,11].includes(index))?"var(--blue_gray)":"transparent"}}>
                            <div className="seed_number_indicator">{(index+1)}.</div>
                            {
                                [0,1,11].includes(index) ?
                                <div className="seed_word_indicator" onClick={()=> remove_word(selected_words[[0,1,11].indexOf(index)])}>
                                    {selected_words[[0,1,11].indexOf(index)]}
                                </div> :
                                <div className="seed_word_indicator">{item}</div>
                            }
                            
                        </div>
                    ))
                }
                <div className="large_spacer"></div>
                {
                    available_words.map((word, index) => (
                        <div key={index} className={`seed_phrase_item notranslate blue_button_hover_full selectable ${(index+1) % 3 == 0 ? "no_margin":""}`} onClick={()=> select_word(word)}>
                            <div className="seed_word_indicator selectable">{word}</div>
                        </div>
                    ))
                }
                {
                    available_words.length == 0 &&
                    <>
                        <div className="large_spacer"></div>
                        <div className="medium_spacer"></div>
                    </>
                }
                <div className="medium_spacer"></div>
                <div className="payment_button title_font_family yellow_button_hover" onClick={save_seed}>Confirm</div>

            </div>
        </>
    );

}

export default Confirm_Seed_Phrase;