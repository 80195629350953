import { useEffect, useState, useRef } from "react";
import DOMPurify from 'dompurify';

// ---- Icons ----
import down_icon from "../../assets/icons/down.png";

// ---- Chart.js ----
import Chart from 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import { format } from 'date-fns';

// ---- Services -----
import { get_coin_by_id, get_coin_chart } from "../../services/coingecko_api";
import { get_chart } from "../../services/contract_functions/news";

function Cryptocurrency({ close, parameters, set_parameters }) {

    // ---- Timescales ----
    let time_scales = ["1D","7D","1M","1Y"];
    let [time_scale_index, set_time_scale_index] = useState(0);
    let [price_data, set_price_data] = useState([]);

    let [cryptocurrency_data, set_cryptocurrency_data] = useState(
        parameters.full_data
    );

    const filter_data_points = (data) => {
        return data.filter((_, index) => index % 10 === 0);
    };

    useEffect(() => {

        if (parameters.full_data && parameters.full_data.symbol) {
            (async function() {
                let price_data = [];
                let chart_data = await get_chart(parameters.full_data.symbol);
                for (let i = 0; i < chart_data.length; i++) {
                    price_data.push([
                        parseInt(chart_data[i][0]),
                        parseFloat(chart_data[i][1])
                    ]);
                }
                set_price_data(price_data);
            })();    
        }
        else {
            close();
            // (async function() {

            //     let data = await get_coin_by_id(parameters.id);
            //     set_cryptocurrency_data(data);

            //     let chart_data = await get_coin_chart(parameters.id, "1Y");
            //     set_price_data(chart_data.prices)

            //     let chart_data_precise = await get_coin_chart(parameters.id, "7D");
            //     set_price_data_precise(chart_data_precise.prices)

            // })();    
        }

        
    },[parameters]);

    let trend_chart_ref = useRef(null);
    let trend_chart_instance = useRef(null);

    useEffect(()=> {

        (async function() {

            let data = price_data;

            let end_time = Date.now();
            let start_time;
            if (time_scales[time_scale_index] == "1D") {
                start_time = end_time - 60*60*24*1000;
            }
            else if (time_scales[time_scale_index] == "7D") {
                start_time = end_time - 7*60*60*24*1000;
            }
            else if (time_scales[time_scale_index] == "1M") {
                start_time = end_time - 30*60*60*24*1000;
            }
            else if (time_scales[time_scale_index] == "1Y") {
                start_time = end_time - 365*60*60*24*1000;
            }

            let time_adjusted_data = [];
            for (let i = 0; i < data.length; i++) {
                let data_point = data[i];
                if (data_point[0] >= start_time) {

                    if (time_scale_index == 0) {
                        time_adjusted_data.push(data_point);
                    }
                    else if (time_scale_index == 1 && i % 3 == 0) {
                        time_adjusted_data.push(data_point);
                    }
                    else if (time_scale_index == 2) {
                        if (data_point[0] >= end_time - 7*60*60*24*1000) {
                            if (i % 10 == 0) {
                                time_adjusted_data.push(data_point);
                            }
                        }
                        else {
                            time_adjusted_data.push(data_point);
                        }
                    }
                    else if (time_scale_index == 3 && i % 5 == 0) {
                        if (data_point[0] >= end_time - 7*60*60*24*1000) {
                            if (i % 100 == 0) {
                                time_adjusted_data.push(data_point);
                            }
                        }
                        else {
                            time_adjusted_data.push(data_point);
                        }
                    }

                    
                }
            }

            let formatted_data = time_adjusted_data.map(item => ({
                x: new Date(item[0]),
                y: item[1]
            }));

            let chart_data = {
                datasets: [{
                    label: 'Price',
                    data: formatted_data,
                    borderColor: '#00c8ff',
                    backgroundColor: '#00c8ff',
                    fill: false,
                    pointRadius: 0
                }]
            };
            
            let chart_options = {
                scales: {
                    x: {
                        type: 'time',
                        time: {
                            unit: time_scales[time_scale_index] === "1D" ? 'hour' : 'day'
                        },
                        title: {
                            display: true,
                            text: 'Date'
                        }
                    },
                    y: {
                        title: {
                            display: true,
                            text: 'Price (USD)'
                        }
                    }
                },
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                        usePointStyle: true,
                        boxWidth: 20,
                        boxHeight: 10
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => `Price: ${context.parsed.y.toFixed(2)} USD`
                        }
                    }
                }
            };

            if (trend_chart_instance.current) {
                trend_chart_instance.current.destroy();
            }
    
            const ctx = trend_chart_ref.current.getContext('2d');
            trend_chart_instance.current = new Chart(ctx, {
                type: 'line',
                data: chart_data,
                options: chart_options
            });


        })();

    },[time_scale_index, price_data])

    let selected_pct_change = 0;
    let value_change = 0;
    if (cryptocurrency_data && cryptocurrency_data.percentage_price_changes) {
        selected_pct_change = parseFloat(cryptocurrency_data.percentage_price_changes[time_scale_index]);
        value_change = parseFloat(cryptocurrency_data.usd_price_changes[time_scale_index]);
    }


    // let selected_price_change = [
    //     cryptocurrency_data.market_data.price_change_percentage_24h,
    //     cryptocurrency_data.market_data.price_change_percentage_7d,
    //     cryptocurrency_data.market_data.price_change_percentage_30d,
    //     cryptocurrency_data.market_data.price_change_percentage_1y,
    // ][time_scale_index];

    // let value_change = (selected_price_change/100) * cryptocurrency_data.market_data.current_price.usd;

    function format_article(html_string) {
        html_string = `<p>${html_string}</p>`;
        html_string = html_string.replaceAll("<a","<a class='special_a underlined_text'");
        return DOMPurify.sanitize(html_string);
    }

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family" style={{opacity:0.25}}>Coin Summary</h2>
                        <img className="back_icon" src={down_icon} onClick={close}/>
                    </div>
                    <div className="medium_spacer"></div>
                    
                </div>

                <div className="title_relative_container">

                    {
                        cryptocurrency_data.name &&
                        <>
                            <div className="crypto_top_container">
                                <img className="crypto_top_icon" src={cryptocurrency_data.icon_url}/>
                                <h2 className="crypto_top_price">{cryptocurrency_data.name}</h2>
                                <div className="crypto_change_price">
                                    <a style={{color: value_change < 0 ? "var(--orange_text)":"var(--green_text)"}}>
                                    ${value_change.toFixed(2)} ({selected_pct_change.toFixed(2)}%)
                                    </a>
                                </div>
                            </div>
                            
                            <div className="chart_time_selector_container notranslate">
                                {
                                    time_scales.map((scale, index)=> (
                                        <div key={index} className={`chart_time_item ${time_scale_index==index?"chart_time_item_selected just_pointer":"gray_button_hover"}`} onClick={()=>set_time_scale_index(index)}>{scale}</div>
                                    ))
                                }
                            </div>
                        </>
                    }
                </div>

                <div className="medium_spacer"></div>
                {/* <div className='standard_chart_container notranslate'> */}
                    <canvas ref={trend_chart_ref} className="standard_chart"></canvas>
                {/* </div> */}

                <div className="medium_spacer"></div>
                <div dangerouslySetInnerHTML={{ __html: format_article(cryptocurrency_data.description) }}></div>

                <div className="medium_spacer"></div>
                <p>Source: Coingecko</p>
            </div>
        </>
    );

}

export default Cryptocurrency;