import { ethers } from 'ethers';
import { Buffer } from 'buffer';
import { fromBER } from 'asn1js';
import { getAlgorithmByOID, PublicKeyInfo } from 'pkijs';

export function string_to_eth_address(string) {
    let hash = ethers.keccak256(ethers.toUtf8Bytes(string));
    let address = '0x' + hash.substring(hash.length - 40);
    return address;
}

export function string_to_uint_array(str) {
    const utf8Encoder = new TextEncoder();
    return utf8Encoder.encode(str);
}

export function convert_DER_to_ECDSA(der_buffer) {
    let asn1 = fromBER(der_buffer);
    let public_key_info = new PublicKeyInfo({ schema: asn1.result });
    let algorithm_oid = public_key_info.algorithm.algorithmId;
    if (algorithm_oid !== "1.2.840.10045.2.1") {
        throw new Error('Only ECDSA keys are supported.');
    }
    let raw_public_key = public_key_info.subjectPublicKey.valueBlock.valueHex;
    let coordinate_length = raw_public_key.byteLength / 2;
    let offset = 0;
    if (raw_public_key.byteLength === 65 && new Uint8Array(raw_public_key)[0] === 0x04) {
        offset = 1;
    }

    let x = raw_public_key.slice(offset, offset + coordinate_length);
    let y = raw_public_key.slice(offset + coordinate_length);

    return [
        `0x${buffer_to_hex(x)}`,
        `0x${buffer_to_hex(y)}`
    ];
}

export function to_base_64(base64url) {
    base64url = base64url.toString();
    return pad_string(base64url)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");
}

export function to_buffer(base64url) {
    return Buffer.from(to_base_64(base64url), "base64");
}

export function decode_user_handle(handle) {
    let decoder = new TextDecoder('utf-8');
    return decoder.decode(handle);
}

function pad_string(input) {
    var segmentLength = 4;
    var stringLength = input.length;
    var diff = stringLength % segmentLength;
    if (!diff) {
        return input;
    }
    var position = stringLength;
    var padLength = segmentLength - diff;
    var paddedStringLength = stringLength + padLength;
    var buffer = Buffer.alloc(paddedStringLength);
    buffer.write(input);
    while (padLength--) {
        buffer.write("=", position++);
    }
    return buffer.toString();
}

function buffer_to_hex(buffer) {
    return Array.prototype.map.call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2)).join('');
}

export function hex_string_to_byte_array(hex_string) {

    // Ensure even number of characters
    if (hex_string.length % 2 !== 0) {
        throw new Error("Invalid hex string length");
    }

    const result = new Uint8Array(hex_string.length / 2);
    for (let i = 0; i < hex_string.length; i += 2) {
        result[i / 2] = parseInt(hex_string.substr(i, 2), 16);
    }
    return result;
}

export function byte_array_to_hex_string(buffer) {
    let byte_array = new Uint8Array(buffer);
    let hex_string = '';
    for (let i = 0; i < byte_array.length; i++) {
        hex_string += byte_array[i].toString(16).padStart(2, '0');
    }
    return hex_string;
}

export function generate_random_string(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

export function to_uint8array(data) {
    return new Uint8Array(data);
}
