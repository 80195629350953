import { useState, useEffect } from 'react';

function useAttribution() {

    const [sources, setSources] = useState(() => {
        const savedSources = localStorage.getItem('sources');
        return savedSources ? JSON.parse(savedSources) : [];
    });

    useEffect(() => {
        localStorage.setItem('sources', JSON.stringify(sources));
    }, [sources]);

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const sourceFromURL = searchParams.get('source');
        if (sourceFromURL) {
            add_source(sourceFromURL);
        }
    }, []); 

    const add_source = (source) => {
        setSources(prevSources => {
            if (!prevSources.includes(source)) {
                return [...prevSources, source];
            }
            return prevSources;
        });
    };

    return { sources, add_source };
}

export default useAttribution;