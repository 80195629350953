import axios from "axios";

export async function get_top_100() {
    let response = await axios.get("https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd");
    return response.data;
}

export async function get_trending() {
    let response = await axios.get("https://api.coingecko.com/api/v3/search/trending");
    return response.data;
}

export async function get_coin_by_id(id) {
    let response = await axios.get(`https://api.coingecko.com/api/v3/coins/${id}`);
    return response.data;
}

export async function get_coin_chart(id, time_scale) {

    let end_time = Math.floor(Date.now()/1000);
    let start_time;
    if (time_scale == "1D") {
        start_time = end_time - 60*60*24;
    }
    else if (time_scale == "7D") {
        start_time = end_time - 7*60*60*24;
    }
    else if (time_scale == "1M") {
        start_time = end_time - 30*60*60*24;
    }
    else if (time_scale == "1Y") {
        start_time = end_time - 365*60*60*24;
    }

    let response = await axios.get(`https://api.coingecko.com/api/v3/coins/${id}/market_chart/range?vs_currency=usd&from=${start_time}&to=${end_time}`);
    return response.data;

}