import { languages } from "../assets/data/languages";

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function fix_ui() {
    let translate_object = document.querySelector(".skiptranslate");
    if (translate_object) {
        document.body.style.top = "0px";
        translate_object.style = "position: fixed; width: 0px; opacity: 0;";
    }
}

setInterval(()=> {
    fix_ui()
},50);

export async function initialize_translator() {
    new window.google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
    fix_ui();
}

export async function set_translation(iso_code) {

    let element = document.getElementById('google_translate_element');
    let instance = element.querySelector(':scope > .goog-te-gadget');
    while (!instance) {
        await sleep(10);
    }

    let select_element = instance.querySelector('select.goog-te-combo');
    while (!select_element) {
        await sleep(10);
    }

    select_element.value = iso_code;
    select_element.dispatchEvent(new Event('change'));
    
    fix_ui();
}

export async function get_iso_code() {

    let element = document.getElementById('google_translate_element');
    let instance = element.querySelector(':scope > .goog-te-gadget');
    while (!instance) {
        await sleep(10);
    }

    let select_element = instance.querySelector('select.goog-te-combo');
    while (!select_element) {
        await sleep(10);
    }

    return select_element.value;
}

export async function set_translation_to_default() {
    let iso_code = navigator.languages[0].substring(0, 2);
    let default_language = languages.find(lang => lang.iso_code === iso_code);
    if (!default_language) {
        default_language = languages.find(lang => lang.iso_code === "en");
    }
    await set_translation(default_language.iso_code);
    return default_language;
}