import { useEffect, useState } from "react";

// ---- Services ----
import token_instance from "../services/wallet/token";
import useToken from "../services/wallet/useToken";
import { create_referrer_key, encrypt_token, load_referrer_token } from "../services/wallet/core";
import { sign_and_call_external, sign_and_message_session, verify_signature_session } from "../services/contract_functions/core";
import { append_query_params, sleep } from "../services/helper_functions";

// ---- Modals ----
import { useAppModals } from "../modals/app_modals";
import { useErrorPopUp } from "../modals/error_pop_up";
import { useLogin } from "../modals/login";

// ---- Data ----
import { skale_chains } from "../assets/data/skale_rpcs";

function I_Frame() {

    // ---- Modals ----
    let { open_app_modal } = useAppModals();
    let { open_login_funnel } = useLogin();

    // ---- Token ----
    let token = token_instance.current;
    function force_update_token() {
        token = token_instance.current;
    }

    function session_progress_update(status, value) {
        window.parent.postMessage({ session_progress: { status, value } }, document.referrer);
    }

    async function call_with_session(call_params) {

        let { is_skale_chain, address, abi, function_name, params, value, skale_chain_name, sfuel_contract, function_signature, rpc_url, chain_id, gas_limit, signature_mode } = call_params;

        try {

            if (!address || !abi || !function_name || !params) {
                window.parent.postMessage({ error: "gas_limit, address, abi, function_name, params, or value is missing" }, document.referrer);
                throw "Error"
            }

            if (!value) {
                value = 0;
            }

            if (!gas_limit) {
                gas_limit = 0n;
            }
            
            if (is_skale_chain) {
                if (skale_chain_name) {
                    if (!skale_chains[skale_chain_name]) {
                        window.parent.postMessage({ error: "Invalid skale_chain_name" }, document.referrer);
                        throw "Error"
                    }
                    else {
                        sfuel_contract = skale_chains[skale_chain_name].sfuel_contract;
                        function_signature = skale_chains[skale_chain_name].function_signature;
                        rpc_url = skale_chains[skale_chain_name].rpc_url;
                        chain_id = skale_chains[skale_chain_name].chain_id;
                    }
                }
                else {
                    if (!sfuel_contract || !function_signature || !rpc_url || !chain_id) {
                        window.parent.postMessage({ error: "Specify sfuel_contract, function_signature, rpc_url, and chain_id or simply use skale_chain_name field." }, document.referrer);
                        throw "Error"
                    }
                }
            }
            else if (!rpc_url || !chain_id) {
                window.parent.postMessage({ error: "rpc_url or chain_id is missing." }, document.referrer);
                throw "Error"
            }

            value = window.BigInt(value);
            gas_limit = window.BigInt(gas_limit);

            let transaction_response = await sign_and_call_external(is_skale_chain, address, gas_limit, abi, function_name, params, chain_id, value, rpc_url, sfuel_contract, function_signature, signature_mode, session_progress_update);

            window.parent.postMessage({response:{status:"success",status_code:200,transaction_response}}, document.referrer);

        } catch (error) {
            // console.log(error)
            window.parent.postMessage({response:{status:"fail",status_code:400,error}}, document.referrer);
        }

    }
    
    useEffect(() => {

        document.documentElement.style.backgroundColor = 'transparent';
        document.body.style.backgroundColor = 'transparent';    

        const hanlde_message = async (event) => {
            try {
                if (event.origin == document.referrer || `${event.origin}/` == document.referrer) {
                    if (event.data) {
                        if (event.data.type == "load_token") {
                            load_referrer_token(event.data.encrypted_shared_key, event.data.encrypted_token);
                            force_update_token();
                        }
                        if (event.data.type == "request_auth") {
                            let public_key = await create_referrer_key();
                            window.parent.postMessage({ public_key }, document.referrer);
                        }
                        if (event.data.type == "request_call_auth") {
                            if (token && token.authorized_referrers && token.authorized_referrers[document.referrer] == true) {
                                let signature = await sign_and_message_session(event.data.call_params);
                                window.parent.postMessage({ response: { status: "success", signature } }, document.referrer);
                            }
                            else {
                                window.parent.postMessage({ show:false, error: "Request authorization from user before making a call." }, document.referrer);
                            }
                        }
                        if (event.data.type == "session_call") {
                            if (token && token.authorized_referrers && token.authorized_referrers[document.referrer] == true) {
                                let call_params = event.data.call_params;
                                call_with_session(call_params);
                            }
                            else {
                                window.parent.postMessage({ show:false, error: "Request authorization from user before making a call." }, document.referrer);
                            }
                        }
                        if (event.data.type == "session_sign") {
                            if (token && token.authorized_referrers && token.authorized_referrers[document.referrer] == true) {
                                try {
                                    let signature = await sign_and_message_session(event.data.message);
                                    window.parent.postMessage({ response: { status: "success", signature } }, document.referrer);
                                } catch (error) {
                                    window.parent.postMessage({ response: { status:"fail", status_code: 400, error } }, document.referrer);
                                }
                            }
                        }
                    }
                }
            } catch (error) {}
        }
    
        window.addEventListener("message", hanlde_message);

        return () => {
            window.removeEventListener("message", hanlde_message);
        };

    },[]);

    useEffect(() => {

        

        (async function() {

            for (let i = 0; i < 3; i++) {

                try {
                    if (document.referrer) {
                        if (token && token.wallet_address) {
                            window.parent.postMessage({ has_wallet: true }, document.referrer);
                        }
                        else {
                            window.parent.postMessage({ has_wallet: false }, document.referrer);
                        }

                        if (token && token.authorized_referrers && token.authorized_referrers[document.referrer] == false) {
                            window.parent.postMessage({ authorized: false, error: "Blocked by user", wallet_address: "", session_address: "", username: "" }, document.referrer);
                        }
                        else if (token && token.authorized_referrers && token.authorized_referrers[document.referrer] == true) {
                            window.parent.postMessage({ authorized: true }, document.referrer);
                            if (token.wallet_address) {
                                window.parent.postMessage({ wallet_address: token.wallet_address }, document.referrer);
                            }
                            if (token.session_address) {
                                window.parent.postMessage({ session_address: token.session_address }, document.referrer);
                            }
                            if (token.username) {
                                window.parent.postMessage({ username: token.username }, document.referrer);
                            }
                        }
                        else {
                            window.parent.postMessage({ wallet_address: "", session_address: "", username: "" }, document.referrer);
                        }
                    }
                } catch (error) {}

                await sleep(1000);

            }

        })();

        

    },[token]);

    useEffect(() => {
        let url = new URL(window.location.href);
        let url_params = url.searchParams;
        let theme = url_params.get("theme");
        if (theme == "light") {
            import('../styles/light_them_override.css');
        }

        let request_auth = url_params.get("request_auth");
        let callback_url = url_params.get("callback_url");
        let public_key = url_params.get("public_key");

        if (request_auth && callback_url && public_key) {
            open_login_funnel({type:"iframe_login", close_callback: async ()=>{
                force_update_token();
                if (token && token.authorized_referrers && token.authorized_referrers[document.referrer] == true) {
                    let { encrypted_shared_key, encrypted_token } = await encrypt_token(public_key);
                    let new_url = append_query_params(callback_url, { encrypted_shared_key, encrypted_token, callback_type: "auth", status: "success" });
                    window.location = new_url;
                }
                else {
                    let new_url = append_query_params(callback_url, { encrypted_shared_key: "", encrypted_token: "", blocked: true, callback_type: "auth", status: "fail" });
                    window.location = new_url;
                }
            }});
        }

        
        let call_params = url_params.get("call_params");
        let signature = url_params.get("signature");

        if (token && call_params && signature && callback_url) {
            if (verify_signature_session(call_params, signature)) {
                call_params = JSON.parse(decodeURIComponent(call_params));
                let transaction_description = call_params.transaction_description;
                open_app_modal({ type: "approve_iframe_transaction", call_params, transaction_description, callback_url, close_callback: ()=>{
                    // window.parent.postMessage({show:false}, document.referrer);
                }});
            }
            else {
                let new_url = append_query_params(callback_url, { callback_type: "call", status: "fail", "function_name": call_params ? call_params.function_name: "" });
                window.location = new_url;
            }
        }
        

    },[]);

    return (<>
        
    </>);
    
}

export default I_Frame;