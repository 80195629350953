// ---- Icons ----
import credit_card_icon from "../currency_icons/credit_card.png";
import eth_icon from "../currency_icons/eth.png";
import btc_icon from "../currency_icons/btc.png";
import sol_icon from "../currency_icons/sol.png";
import bnb_icon from "../currency_icons/bnb.png";
import matic_icon from "../currency_icons/matic.png";
import usdt_icon from "../currency_icons/usdt.png";
import usdc_icon from "../currency_icons/usdc.png";


export let currencies = [
    {
        name: "Credit Card",
        short_display: "Card",
        symbol: "USD",
        icon: credit_card_icon,
        decimals: 6,
        default_value: 250,
        blockchain: "",
        is_evm: false,
        is_erc20: false,
        sig_figs: 2
    },
    {
        name: "Ethereum",
        short_display: "ETH",
        symbol: "ETH",
        icon: eth_icon,
        decimals: 18,
        default_value: 0.1,
        blockchain: "Ethereum (ERC20)",
        is_evm: true,
        is_erc20: false,
        chain_id: 1,
        sig_figs: 4
    },
    {
        name: "Bitcoin",
        short_display: "BTC",
        symbol: "BTC",
        icon: btc_icon,
        decimals: 8,
        default_value: 0.005,
        blockchain: "Bitcoin",
        is_evm: false,
        is_erc20: false,
        sig_figs: 6
    },
    {
        name: "Solana",
        short_display: "SOL",
        symbol: "SOL",
        icon: sol_icon,
        decimals: 9,
        default_value: 1,
        blockchain: "Solana",
        is_evm: false,
        is_erc20: false,
        sig_figs: 2
    },
    {
        name: "Binance Coin",
        short_display: "BNB",
        symbol: "BNB",
        icon: bnb_icon,
        decimals: 18,
        default_value: 0.5,
        blockchain: "BSC (BEP-20)",
        is_evm: true,
        is_erc20: false,
        chain_id: 56,
        sig_figs: 4
    },
    {
        name: "Polygon / Matic",
        short_display: "MATIC",
        symbol: "MATIC",
        icon: matic_icon,
        decimals: 18,
        default_value: 500,
        blockchain: "Polygon",
        is_evm: true,
        is_erc20: false,
        chain_id: 137,
        sig_figs: 2
    },
    {
        name: "USDT",
        short_display: "USDT",
        symbol: "USDT",
        icon: usdt_icon,
        decimals: 6,
        default_value: 250,
        blockchain: "Ethereum",
        is_evm: true,
        is_erc20: true,
        contract_address: "0xdac17f958d2ee523a2206206994597c13d831ec7",
        chain_id: 1,
        sig_figs: 2
    },
    {
        name: "USDC",
        short_display: "USDC",
        symbol: "USDC",
        icon: usdc_icon,
        decimals: 6,
        default_value: 250,
        blockchain: "Ethereum",
        is_evm: true,
        is_erc20: true,
        contract_address: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
        chain_id: 1,
        sig_figs: 2
    }
]