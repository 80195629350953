import { useEffect, useState } from "react";
import token_instance from "./token";

function useToken() {

    let [token, set_token] = useState(token_instance.get());

    useEffect(() => {

        const update_token = () => {
            set_token(token_instance.current);
        }

        token_instance.on_change = update_token;

        return () => {
            token_instance.on_change = null;
        }

    }, []);

    return token;

}

export default useToken;
