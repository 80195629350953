import { useEffect, useState, useRef } from "react";

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Jazzicon ----
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

// ---- Services ----
import { format_time_mm_ss, short_format_number  } from "../../services/helper_functions";
// import { unstake_tokens, claim_unstaked_tokens, claim_daily_rewards } from "../../services/contract_functions/token";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// ---- Icons ----
import down_icon from "../../assets/icons/down.png";

function Stake_Preview({ close, parameters, set_parameters }) {

    // ---- Modals ----
    let { throw_error } = useErrorPopUp();

    // ---- Errors ----
    function action_error() {
        throw_error({
            title: "Oh No!", content: "An error has occured. Please reload the page and try again", buttons: [{ title: "Okay" }]
        })
    }

    // ---- Functions ----
    async function _unstake_tokens(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";
        try {
            // await unstake_tokens(parameters.stake_data.stake_address);
        } catch (error) {
            action_error();
        }
        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    async function _claim_unstaked_tokens(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";
        try {
            // await claim_unstaked_tokens(parameters.stake_data.stake_address);
        } catch (error) {
            action_error();
        }
        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    async function _claim_daily_rewards(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";
        try {
            // await claim_daily_rewards(parameters.stake_data.stake_address);
        } catch (error) {
            action_error();
        }
        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    // ---- Timers ----
    let [claim_time, set_claim_time] = useState(0);
    let interval_ref = useRef(null);

    useEffect(() => {

        if (parameters && parameters.stake_data) {

            if (interval_ref.current != null) {
                clearInterval(interval_ref.current);
                interval_ref.current = null;
            }

            let timer_value = parameters.stake_data.next_claim_time || parameters.stake_data.withdraw_distance;

            if (timer_value != 0) {

                set_claim_time(timer_value)

                interval_ref.current = setInterval(() => {
                    set_claim_time(prev => {
                        let new_time = prev; 
                        if (new_time > 0) {
                            new_time--;
                        }
                        else {
                            new_time = 0;
                        }
                        return new_time;
                    });
                }, 1000);

            }

            return () => {
                if (interval_ref.current) {
                    clearInterval(interval_ref.current);
                }
            };
        }

    },[parameters])

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Staked Tokens</h2>
                        <img className="back_icon" src={down_icon} onClick={close}/>
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <div className="profile_picture">
                    {
                        parameters.stake_data && parameters.stake_data.stake_address && 
                        <Jazzicon diameter={100} seed={jsNumberForAddress(parameters.stake_data.stake_address)}/>
                    }
                    
                </div>
                <div className="profile_info_container">
                    {
                        parameters.stake_data && parameters.stake_data.stake_address && 
                        <>
                            <h2>{parameters.stake_data.lock_period == 0 ? "Instant Stake" : `${parameters.stake_data.lock_period} Days Stake`}</h2>
                            <p>
                                {parameters.stake_data.is_active && "Active"}
                                {!parameters.stake_data.is_active && !parameters.stake_data.is_is_withdrawn && "Pending"}
                                {!parameters.stake_data.is_active && parameters.stake_data.is_is_withdrawn && "Unstaked"}
                            </p>
                        </>
                    }
                </div>
                <div className="medium_spacer"></div>

                {
                    parameters.stake_data && parameters.stake_data.is_active &&
                    <>
                        {
                            claim_time == 0 ?
                            <div className="profile_send_button" onClick={_claim_daily_rewards}>Claim</div> :
                            <div className="profile_send_button">Claim in {format_time_mm_ss(claim_time)}</div>
                        }
                        
                        <div className="profile_receive_button" onClick={_unstake_tokens}>Unstake</div>
                    </>
                }

                {
                    parameters.stake_data && !parameters.stake_data.is_active && !parameters.stake_data.is_is_withdrawn &&
                    <>
                        {
                            claim_time == 0 ?
                            <div className="profile_send_button_full" onClick={_claim_unstaked_tokens}>Collect Tokens</div> :
                            <div className="profile_send_button_full">Collect in {format_time_mm_ss(claim_time)}</div>
                        }
                    </>
                }

                <div className="medium_spacer"></div>
                <div className="data_container special">
                    <div className="data_amount notranslate">{short_format_number(parameters.stake_data.amount_staked)}</div>
                    <div className="data_description">Amount Staked</div>
                </div>
                <div className="data_container data_container_second special">
                    <div className="data_amount notranslate">{short_format_number(parameters.stake_data.total_rewards)}</div>
                    <div className="data_description">Rewards Earned</div>
                </div>
                <div className="data_container special">
                    <div className="data_amount notranslate">{parameters.stake_data.sequential_claim}</div>
                    <div className="data_description">Sequential Claims</div>
                </div>
                <div className="data_container data_container_last special">
                    <div className="data_amount notranslate">{(100 * parameters.stake_data.total_rewards / parameters.stake_data.amount_staked).toFixed(2)} %</div>
                    <div className="data_description">ROI</div>
                </div>

            </div>
        </>
    );
}

export default Stake_Preview;