import React, {useState} from 'react'
// import QrReader from 'react-qr-scanner'

function QR_Scanner({ parameters, set_parameters }) {

    const [data, set_data] = useState(null);

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">QR Scanner</h2>
                    </div>
                    <div className="medium_spacer"></div>
                </div>

                {/* <QrReader
                    constraints={{ facingMode: "environment" }}
                    onResult={(result, error) => {
                    if (!!result) {

                        set_data(result?.text);
                    }

                    if (!!error) {
                        console.info(error);
                    }
                    }}
                    style={{ width: '100%', height: "200px" }}
                /> */}
                <p>{data}</p>

            </div>
        </>
    );

}

export default QR_Scanner;