import { useEffect } from "react";

// ---- Data ----
import { currencies } from "../../assets/data/currencies";

// ---- Icons ----
import logo_dark_icon from "../../assets/icons/logo_dark.png";
import right_icon from "../../assets/icons/right.png";
import down_icon from "../../assets/icons/down.png";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";
import { useCurrencySelector } from "../currency_selector";

// ---- Services ----
import { trigger_event } from "../../services/analytics";

function Widget({ close }) {

    // ---- Error Modal ----
    let { throw_error } = useErrorPopUp();

    // ---- Currency Modal ----
    let { open_currency_selector } = useCurrencySelector();

    useEffect(()=>{
        trigger_event("open_purchase_modal", 0)
    },[]);

    return (
        <div className="purchase_funnel_container">
            <div className="stick_text">
                <div className="medium_spacer"></div>
                <h2 className="central_text_indicator title_font_family">Buy Token</h2>
                <img className="back_icon" src={down_icon} onClick={close}/>
            </div>
            <div className="payment_method_container">
                <div className="payment_method_inner">
                    {
                        currencies.map((currency,index)=>(
                            <div key={index} className="payment_method_item">
                                <img className="payment_method_icon" src={currency.icon}/>
                                <div className="payment_method_label">{currency.name}</div>
                            </div>
                        ))
                    }
                    
                </div>
            </div>
            <div className="small_spacer"></div>
            <p className="small_text">You Pay:</p>
            <div className="small_spacer"></div>
            <div className="payment_input_container">
                <div className="payment_input_currency_selector" onClick={open_currency_selector}>
                    <img className="payment_input_selector_icon" src={currencies[0].icon}/>
                    <img className="payment_input_selector_extra_icon" src={right_icon}/>
                </div>
            </div>
            <div className="small_spacer"></div>
            <p className="small_text">You Receive:</p>
            <div className="small_spacer"></div>
            <div className="payment_input_container">
                <div className="payment_input_currency_selector">
                    <img className="payment_input_selector_icon" src={logo_dark_icon}/>
                    <img className="payment_input_selector_extra_icon" src={right_icon}/>
                </div>
            </div>
            <div className="medium_spacer"></div>
            <div className="payment_button title_font_family" >BUY NOW</div>
            <div className="medium_spacer"></div>
            <div className="small_text central_text_indicator">Buy over $100 and get 10% off</div>
            <div className="medium_spacer"></div>
            <div className="outer_progress_bar">
                <div className="inner_progress_bar"></div>
            </div>
            <div className="small_spacer"></div>
            <p className="small_text"></p>
            <div className="small_spacer"></div>
            <p className="small_text central_text_indicator">Current Discount: 5% | Next Discount: 10%</p>
            <div className="medium_spacer"></div>
            <div className="small_text central_text_indicator"><u>Full Discount Chart</u></div>
        </div>
    );

}

export default Widget;