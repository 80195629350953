import { useState, useEffect } from "react";

// ---- Lotie ----
import Lottie from 'lottie-react';

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png"
import profile_icon from "../../assets/icons/profile.png";
import animated_tick from "../../assets/icons/animated_tick.json";

// ---- Services ----
import { add_credential, init_session } from "../../services/wallet/core";
import { is_desktop } from "../../services/helper_functions";
import { make_inquiry } from "../../services/api"

function Create_Authenticator({ parameters, set_parameters }) {

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();
    let is_desk = is_desktop();

    function debug_error(logs) {
        let params = {
            title: "Oh No!",
            content: `It looks like something isn't working. Please help us fix this bug by sharing the following with our customer support: ${logs} ${window.navigator.userAgent}`,
            buttons: [
                {
                    title: "Report Bug",
                    callback: async function() {
                        await make_inquiry("antoine@100fire.com", "customer_id", "wallet_address", "debug", JSON.stringify(`${logs} ${window.navigator.userAgent}`, null, 2));
                    }
                },
                {
                    title: "Dismiss"
                }
            ]
        }
        throw_error(params);
    }

    async function create_authenticator() {
        set_is_loading(true);
        set_tx_pending(true);
        set_tx_status({status:"Waiting for creation", amount:0});
        try {
            await add_credential();

            try { await init_session(update_transaction_status); } catch (error) {}

            set_success_animation(true);
            setTimeout(()=>set_parameters({type: "save_seed_phrase", close_callback: parameters.close_callback}), 500);

        } catch (error) {

            if (error.name == "NotAllowedError") {
                
            }
            else {

                let error_info = {
                    name: error.name,
                    message: error.message,
                    stack: error.stack,
                    // Add other properties dynamically
                    ...error
                };

                debug_error(JSON.stringify(error_info, null, 2))

                console.log(error);

            }
            // await make_inquiry("antoine@100fire.com", "customer_id", "wallet_address", "debug", JSON.stringify(errorInfo, null, 2));
        }
        
        set_is_loading(false);
        set_tx_pending(false);
    }

    // ---- Tx Status ----
    let [is_loading, set_is_loading] = useState(false);
    let [success_animation, set_success_animation] = useState(false);
    let [tx_pending, set_tx_pending] = useState(false);
    let [tx_status, set_tx_status] = useState({status:"Connecting", amount:0})

    function update_transaction_status(status, amount) {
        set_tx_status({status, amount})
    }

    return (
        <>
            <div className="purchase_funnel_container">

                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Create Authenticator</h2>
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                    <div className="medium_spacer"></div>
                    
                </div>

                <p>Store your wallet securely using your device's in-built authentication system.</p>

                <div className="medium_spacer"></div>

                <div className={`payment_button title_font_family yellow_button_hover ${is_loading?"unselectable":""}`} onClick={create_authenticator}>
                    {success_animation ?  <Lottie animationData={animated_tick} loop={false} direction={-1} style={{ position: "absolute", paddingTop: "10px", left: "calc(50% - 25px)", width: "50px", height: "50px" }}/>
                    :
                        is_loading ?
                        "Saving...":
                        "Create"
                    }
                </div>
                
                {
                    tx_pending &&
                    <>
                        <div className="medium_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar" style={{width: `${tx_status.amount*100}%`}}></div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text">Status: {tx_status.status}</p>
                    </>
                }

                {
                    is_desk && !tx_pending && !is_loading &&
                    <>
                        <div className="small_spacer"></div>
                        <p>Having difficulty? <u className="underlined_text" onClick={()=>set_parameters({type: "force_browser_transfer", mode: 2})}>Continue on mobile</u></p>
                    </>
                }
            </div>
        </>
    );

}

export default Create_Authenticator;