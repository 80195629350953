import { useEffect, useState, useRef } from "react";

// ---- Lotie ----
import Lottie from 'lottie-react';

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import edit_icon from "../../assets/icons/edit.png";
import wallet_icon from "../../assets/icons/wallet.png";
import paste_icon from "../../assets/icons/paste.png";
import left_icon from "../../assets/icons/left.png";
import down_icon from "../../assets/icons/down.png";
import help_icon from "../../assets/icons/help.png";
import profile_icon from "../../assets/icons/profile.png";
import logo_dark_icon from "../../assets/icons/logo_dark.png";
import animated_tick from "../../assets/icons/animated_tick.json";

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import { transfer_by_username, transfer, balance_of } from "../../services/contract_functions/token";
import { is_valid_username, is_valid_ethereum_address, short_format_number, increment_ref, decrement_ref, on_focus_input_ref, on_blur_input_ref } from "../../services/helper_functions"

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// move to config
let is_presale = true;

function Send_Tokens({ close, parameters, set_parameters }) {

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    // ---- Refs ----
    let recipient_outer_ref = useRef(null);
    let token_amount_outer_ref = useRef(null);
    let recipient_ref = useRef(null);
    let token_amount_ref = useRef(null);

    // ---- Hooks ----
    let token = useToken();
    let [balance, set_balance] = useState(0);
    let [is_loading, set_is_loading] = useState(false);
    let [has_sufficient_balance, set_has_sufficient_balance] = useState(false);
    let [valid_recipient, set_valid_recipient] = useState(false);
    let [success_animation, set_success_animation] = useState(false);

    // ---- On Load ----
    useEffect(() => {

        if (token && token.wallet_address) {
            (async function() {
                let balance = await balance_of(token.wallet_address);
                set_balance(ethers.formatUnits(balance));
                token_amount_ref.current.value = 0;
            })();
        }

    },[token]);

    // ---- Input Value Changes ----
    function amount_changed() {
        if (token_amount_ref.current) {
            try {
                let amount = parseFloat(token_amount_ref.current.value);
                if (amount <= balance && amount > 0) {
                    set_has_sufficient_balance(true);
                }
                else {
                    set_has_sufficient_balance(false);
                }
            } catch (error) {
                set_has_sufficient_balance(false);
            }
        }
    }

    function recipient_changed() {
        if (recipient_ref.current) {
            let recipient = recipient_ref.current.value;
            if (recipient.startsWith("0x")) {
                if (is_valid_ethereum_address(recipient)) {
                    set_valid_recipient(true);
                }
                else {
                    set_valid_recipient(false);
                }
            }
            else {
                if (recipient.startsWith("@")) { recipient = recipient.replace("@",""); }
                if (is_valid_username(recipient)) {
                    set_valid_recipient(true);
                }
                else {
                    set_valid_recipient(false);
                }
            }
        }
    }

    // ---- Errors ----
    function transfer_error() {
        if (is_presale) {
            let params = {
                title: "Oh No!",
                content: "Transfers are dissabled during the token presale stage.",
                buttons: [
                    {
                        title: "Okay",
                    }
                ]
            }
            throw_error(params);
        }
        else {
            let params = {
                title: "Oh No!",
                content: "An error has occured during your transfer, please ensure you have sufficient funds and you are sending to a valid username or address.",
                buttons: [
                    {
                        title: "Okay",
                    }
                ]
            }
            throw_error(params);
        }
    }

    function invalid_address_error() {
        let params = {
            title: "Oh No!",
            content: "The address you have entered appears to be invalid.",
            buttons: [
                {
                    title: "Okay",
                }
            ]
        }
        throw_error(params);
    }

    function invalid_username_error() {
        let params = {
            title: "Oh No!",
            content: "The username you have entered appears to be invalid.",
            buttons: [
                {
                    title: "Okay",
                }
            ]
        }
        throw_error(params);
    }

    // ---- Handle Transfer ----
    async function send_tokens() {

        set_is_loading(true);
        set_tx_pending(true);
        set_tx_status({status:"Waiting for approval", amount:0});

        if (recipient_ref.current && token_amount_ref.current) {

            let amount = ethers.parseEther(token_amount_ref.current.value);
            let recipient = recipient_ref.current.value;

            if (recipient.startsWith("0x")) {
                if (is_valid_ethereum_address(recipient)) {
                    try {
                        await transfer(recipient, amount, update_transaction_status);
                        set_success_animation(true);
                    setTimeout(close, 500);
                    } catch (error) {
                        transfer_error();
                    }
                }
                else {
                    invalid_address_error();
                }
            }
            else {
                if (recipient.startsWith("@")) { recipient = recipient.replace("@",""); }
                if (is_valid_username(recipient)) {
                    try {
                        await transfer_by_username(recipient, amount, update_transaction_status);
                        set_success_animation(true);
                        setTimeout(close, 500);
                    } catch (error) {
                        transfer_error();
                    }
                }
                else {
                    invalid_username_error();
                }
            }
        }

        set_is_loading(false);
        set_tx_pending(false);

    }

    // ---- Auto Set Recipient ----
    useEffect(()=>{
        if (parameters && parameters.wallet_address) {
            recipient_ref.current.value = parameters.wallet_address;
            recipient_changed();
        }
    },[parameters]);

    // ---- Paste ----
    async function paste(ref) {
        try {
            let text = await navigator.clipboard.readText();
            ref.current.value = text;
            recipient_changed();
        } catch (error) {}
    }

    // ---- Tx Status ----
    let [tx_pending, set_tx_pending] = useState(false);
    let [tx_status, set_tx_status] = useState({status:"Waiting for approval", amount:0})

    function update_transaction_status(status, amount) {
        set_tx_status({status, amount})
    }

    // ---- Increment Intervals ----
    let increment_interval_ref = useRef(null);
    let speed_step = 50;
    let initial_speed = 500;
    let max_speed = 50;
    let step_size = 0;
    let max_step_size = 1;

    function start_interval(callback) {

        callback();

        let value = token_amount_ref.current.value;
        let slice_index = -1;
        let loop_count = value.slice(slice_index);
        let cur_speed = initial_speed;
        let cur_step_size = step_size;

        if (increment_interval_ref.current) {
            clearInterval(increment_interval_ref.current);
        }

        function interval_callback() {

            if (cur_step_size < max_step_size && loop_count != 0 && loop_count % 10 == 0) {
                cur_step_size += 1;
                loop_count += 1;
            }

            callback(cur_step_size);

            if (cur_speed > max_speed) {
                cur_speed -= speed_step;
                clearInterval(increment_interval_ref.current);
                increment_interval_ref.current = setInterval(interval_callback, cur_speed);
            }
            loop_count++;
        }

        increment_interval_ref.current = setInterval(interval_callback, cur_speed);
    }

    function stop_interval() {
        if (increment_interval_ref.current) {
            clearInterval(increment_interval_ref.current);
            increment_interval_ref.current = null;
        }
    }

    const is_touch_device = () => {
        return ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    };

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Send</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                        {/* <img className="back_icon" src={left_icon} onClick={()=>set_parameters({type:"evm", tab:1, is_edit: true})}/> */}
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                </div>

                
                <div className="medium_spacer"></div>
                <p className="small_text">Recipient:</p>
                <div className="small_spacer"></div>
                <div className="payment_input_container notranslate" ref={recipient_outer_ref}>
                    <div className="payment_input_currency_selector">
                        <img className="payment_input_selector_icon" src={profile_icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                    </div>
                    <div className="paste_input_button gray_button_hover" onClick={()=>paste(recipient_ref)}>
                        <img className="paste_icon" src={paste_icon}/>
                        <div className="paste_text_inner">Paste</div>
                    </div>
                    <input className="wallet_address_input" placeholder="@handle or address" ref={recipient_ref} onChange={recipient_changed}
                    onFocus={()=>on_focus_input_ref(recipient_outer_ref)} onBlur={()=>on_blur_input_ref(recipient_outer_ref)}
                    autoComplete="off" autoCorrect="off" spellCheck="false" autoCapitalize="none"
                    />
                </div>

                <div className="medium_spacer"></div>
                <p className="small_text">Amount:</p>
                <div className="small_spacer"></div>
                <div className="payment_input_container notranslate" ref={token_amount_outer_ref}>
                    <div className="payment_input_currency_selector">
                        <img className="payment_input_selector_icon" src={logo_dark_icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                    </div>

                    <input className="value_input extra" ref={token_amount_ref} onChange={amount_changed}
                    type="number" inputMode="decimal" 
                    onFocus={()=>on_focus_input_ref(token_amount_outer_ref)} onBlur={()=>on_blur_input_ref(token_amount_outer_ref)}
                    />

                    <div className="edit_input_button gray_button_hover" onClick={()=>token_amount_ref.current.focus()}>
                        <img className="edit_input_button_icon" src={edit_icon}/>
                    </div>

                    <div className="stepper_up_input_button gray_button_hover"
                    onMouseDown={ ()=> { if (!is_touch_device()) { start_interval((offset) => { increment_ref(token_amount_ref, offset); amount_changed(); }) } } }
                    onMouseUp={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                    onMouseLeave={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                    onTouchStart={ ()=> { if (is_touch_device()) { start_interval((offset) => { increment_ref(token_amount_ref, offset); amount_changed(); }) } } }
                    onTouchEnd={ ()=> { if (is_touch_device()) { stop_interval(); } } }
                    >+</div>
                    <div className="stepper_down_input_button gray_button_hover"
                    onMouseDown={ ()=> { if (!is_touch_device()) { start_interval((offset) => { decrement_ref(token_amount_ref, offset); amount_changed(); }) } } }
                    onMouseUp={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                    onMouseLeave={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                    onTouchStart={ ()=> { if (is_touch_device()) { start_interval((offset) => { decrement_ref(token_amount_ref, offset); amount_changed(); }) } } }
                    onTouchEnd={ ()=> { if (is_touch_device()) { stop_interval(); } } }
                    >-</div>
                    
                    {/* <div className="up_input_button gray_button_hover" onClick={()=>{increment_ref(token_amount_ref); amount_changed(); }}>+</div>
                    <div className="down_input_button gray_button_hover" onClick={()=>{decrement_ref(token_amount_ref); amount_changed(); }}>-</div> */}
                </div>
                <div className="medium_spacer"></div>
                <p className="small_text">Available Balance: <a className="notranslate">{short_format_number(balance)} JetBolt</a></p>
                

                <div className="medium_spacer"></div>
                <div className={`payment_button yellow_button_hover title_font_family ${(!valid_recipient || !has_sufficient_balance || is_loading)?"unselectable":""}`} onClick={send_tokens}>{success_animation ?  <Lottie animationData={animated_tick} loop={false} direction={-1} style={{ position: "absolute", paddingTop: "10px", left: "calc(50% - 25px)", width: "50px", height: "50px" }}/> : "Send Now"}</div>

                {
                    tx_pending &&
                    <>
                        <div className="medium_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar" style={{width: `${tx_status.amount*100}%`}}></div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text">Status: {tx_status.status}</p>
                    </>
                }

            </div>
        </>
    );
}

export default Send_Tokens;