import { useRef, useState, useEffect } from "react";

import { useSwipeable } from 'react-swipeable';

// ---- Icons ----
import left_arrow_icon from "../assets/icons/left_arrow_black.png";
import right_arrow_icon from "../assets/icons/right_arrow_black.png";

// ---- Modals ----
import { usePurchaseFunnel } from "../modals/purchase_funnel";

// ---- Data ----
import { lootboxes } from "../assets/data/lootboxes";

function Lootbox_Item({index, selected_index, set_selected_index, item_content}) {
    return (
        <div className={`features_item ${(index==selected_index+1||index==selected_index-1)?"features_item_unselected":""} ${(index>=selected_index+2||index<=selected_index-2)?"features_item_unselected_far":""} ${index>=selected_index+2?"fade_item_right":""} ${index<=selected_index-2?"fade_item_left":""}  ${index==selected_index?"yellow_glow":"black_glow"}`}
            onClick={()=>set_selected_index(index)}
            >
            <div className="features_item_title title_font_family">{lootboxes[index].title}</div>
            <div className="feature_main_image">
                <img className="feature_image" src={item_content.image}/>
                <div className="fade_out_black"></div>
            </div>
            <div className="feature_text_item_container">
                <div className="feature_text_item_price">
                    <p className="price_text">US$ {lootboxes[index].usd_threshold}</p>
                    <p className="price_text_strike"><strike>US$ {lootboxes[index].usd_threshold * ((100+lootboxes[index].discount_amount)/100)}</strike></p>
                </div>
                <div className="feature_text_item price_text_discount">Get {lootboxes[index].discount} Extra Tokens</div>
            </div>
            
        </div>
    )
}


function Looboxes({ hard_stop, geo_error }) {

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function buy_lootbox() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "lootbox_currency", lootbox_index: selected_index});
        }
    }


    let [selected_index, set_selected_index] = useState(2);

    function increment_index() {
        if (selected_index < lootboxes.length-1) {
            set_selected_index(selected_index+1);
        }
    }

    function decrement_index() {
        if (selected_index > 0) {
            set_selected_index(selected_index-1);
        }
    }

    // ---- Swipes ----
    const swipe_handlers = useSwipeable({
        onSwipedLeft: () => {
            increment_index();
        },
        onSwipedRight: () => {
            decrement_index();
        },
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    });

    // ---- Refs ----
    let scroll_view_ref = useRef(null);

    // ---- On Change ----

    function handle_carousel() {
        if (scroll_view_ref.current) {
            scroll_view_ref.current.scroll({
                left: (2655-scroll_view_ref.current.offsetWidth)/2 + 295*(selected_index-2) - 10,
                behavior: 'smooth'
            });
        }
    }

    useEffect(()=> {
        handle_carousel();

    }, [selected_index]);

    useEffect(() => {
        let prev_width = 0;
        window.addEventListener('resize', function() {

            if (prev_width == window.innerWidth) {
                return;
            }

            prev_width = window.innerWidth;
            handle_carousel();

        });
    },[]);

    useEffect(() => {
        if (scroll_view_ref.current) {

            let div = scroll_view_ref.current;

            const prevent_scroll = (event) => {
                event.preventDefault();
            };

            let startX = 0;

            const handleWheel = (event) => {
                if (Math.abs(event.deltaX) > 0) {
                    event.preventDefault();
                }
            };

            const handleTouchStart = (event) => {
                startX = event.touches[0].pageX;
            };

            const handleTouchMove = (event) => {
                const moveX = event.touches[0].pageX;
                const diffX = moveX - startX;

                if (Math.abs(diffX) > Math.abs(event.touches[0].pageY - event.touches[0].clientY)) {
                    event.preventDefault();
                }
            };

            const handleKeyDown = (event) => {
                if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
                    event.preventDefault();
                }
            };

            // Add event listeners
            div.addEventListener('wheel', handleWheel, { passive: false });
            div.addEventListener('touchstart', handleTouchStart, { passive: false });
            div.addEventListener('touchmove', handleTouchMove, { passive: false });
            div.addEventListener('keydown', handleKeyDown, { passive: false });

            // Cleanup function
            return () => {
                div.removeEventListener('wheel', handleWheel);
                div.removeEventListener('touchstart', handleTouchStart);
                div.removeEventListener('touchmove', handleTouchMove);
                div.removeEventListener('keydown', handleKeyDown);
            };
        }
    })

    let hook_ref = useRef(null);

    useEffect(() => {

        let url = new URL(window.location.href);
        let url_params = url.searchParams;
        let scroll_to_lootbox = url_params.get("scroll_to_alphabox");
        if (scroll_to_lootbox) {
            if (hook_ref.current) {
                window.scrollTo({
                    top: hook_ref.current.offsetTop,
                    behavior: 'smooth'
                });
            }
        }

    },[]);

    return (
        <>
            <div className="large_spacer" ref={hook_ref}></div>
            <div className="lined_background_light">
                <div className="diagonal_background">
                    <div className="page_container">
                        <div className="safe_inner">
                            
                            <div className="large_spacer"></div>
                            <h1 className="title_font_family center_mobile">Alpha Boxes</h1>
                            <div className="medium_spacer"></div>
                            <p className="large_text center_mobile">Benefit from our discounted pricing by purchasing an Alpha Box. Each Alpha Box has discounted pricing, ensuring you get more bang for your buck.</p>
                            <div className="large_spacer"></div>

                            <div {...swipe_handlers}>

                                <div className="features_container" ref={scroll_view_ref}>
                                    <div className="features_container_inner" style={{width: "2635px"}}>
                                        <div className="features_item zero_opacity"></div>
                                        <div className="features_item zero_opacity"></div>
                                        {
                                            lootboxes.map((item, index) => (
                                                <Lootbox_Item key={index} index={index} selected_index={selected_index} set_selected_index={set_selected_index} item_content={item}/>
                                            ))
                                        }
                                        <div className="features_item zero_opacity"></div>
                                        <div className="features_item zero_opacity"></div>
                                    </div>
                                    
                                </div>
                                <div className="features_outer">
                                    <div className="overlay_hooks_container">
                                        <div className="hook_left"></div>
                                        <div className="hook_left hook_transform_top_right"></div>
                                        <div className="hook_left hook_transform_bottom_right"></div>
                                        <div className="hook_left hook_transform_bottom_left"></div>
                                    </div>
                                    <div className={`overlay_buy_button yellow_button_hover title_font_family ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_lootbox}>BUY ALPHA BOX</div>
                                    <div className={`overlay_left_button yellow_button_hover yello_background ${selected_index == 0?"button_deselected":""}`} onClick={decrement_index}>
                                        <img className="inner_arrow_icon" src={left_arrow_icon}/>
                                    </div>
                                    <div className={`overlay_right_button yellow_button_hover yello_background ${selected_index == 4?"button_deselected":""}`} onClick={increment_index}>
                                        <img className="inner_arrow_icon" src={right_arrow_icon}/>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="large_spacer"></div>
                    
                </div>
                <div className="fade_grid"></div>
            </div>
            <div className="large_spacer"></div>
        </>
    )
}

export default Looboxes;