// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import edit_icon from "../../assets/icons/edit.png";
import wallet_icon from "../../assets/icons/wallet.png";
import paste_icon from "../../assets/icons/paste.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png";

// ---- Data ----
import { currencies } from "../../assets/data/currencies";


function Manual_EVM({ parameters, set_parameters }) {

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Pay Manually</h2>
                        <img className="back_icon" src={left_icon} onClick={()=>set_parameters({type:"evm", tab:1, is_edit: true})}/>
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                </div>
                
                <div className="medium_spacer"></div>
                <p>Navigate to your wallet 0x0237...2398 and pay 0.1 ETH to the address below.</p>
                <div className="medium_spacer"></div>
                <p className="small_text">Pay Manually:</p>
                <div className="small_spacer"></div>
                <div className="payment_input_container_short">
                    <div className="payment_input_currency_selector_short">
                        <img className="payment_input_selector_icon" src={currencies[1].icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                    </div>
                    <img className="payment_edit_icon" src={edit_icon}/>
                    <div className="value_indicator">0.1 ETH</div>
                </div>
                <div className="small_spacer"></div>
                <p className="small_text">Pay To:</p>
                <div className="small_spacer"></div>
                <div className="payment_input_container">
                    <div className="payment_input_currency_selector">
                        <img className="payment_input_selector_icon" src={wallet_icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                    </div>
                    <div className="paste_input_button">
                        <img className="paste_icon" src={paste_icon}/>
                        <div className="paste_text_inner">Copy</div>
                    </div>
                    <input className="wallet_address_input" placeholder="0xb794f5ea0ba39494ce839613fffba74279579268"/>
                </div>
                <div className="small_spacer"></div>
                <p className="small_text">Status:</p>
                <div className="small_spacer"></div>
                <div className="outer_progress_bar">
                    <div className="inner_progress_bar"></div>
                </div>
                <div className="small_spacer"></div>
                <p className="small_text">Listenning for transactions</p>
                <div className="small_spacer"></div>
            </div>
        </>
    )

}

export default Manual_EVM;