
// ---- Icons ----
import logo_dark_icon from "../assets/icons/logo_dark.png";
import terms_icon from "../assets/icons/terms.png";
import privacy_icon from "../assets/icons/privacy.png";
import help_icon from "../assets/icons/help.png";
import twitter_icon from "../assets/icons/twitter.png";
import telegram_icon from "../assets/icons/telegram.png";
import github_icon from "../assets/icons/github.png";
import email_icon from "../assets/icons/email.png";
import whitepaper_icon from "../assets/icons/whitepaper.png";
import audits_icon from "../assets/icons/audits.png";

// ---- External Links ----
import external_links from "../configs/external_links.json";

// ---- Modals ----
import { useContactUs } from "../modals/contact_us";
import { useTelegram } from "../modals/telegram";

function Footer() {

    let { open_contact_us } = useContactUs();
    let { open_telegram } = useTelegram();


    return (
        <>
            <div className="large_spacer"></div>
            <div className="black_full_background">
                    <div className="page_container">
                        <div className="safe_inner">
                            <div className="large_spacer"></div>
                            
                            <div className="large_spacer"></div>
                            <div className="one_third_section">
                                <img className="footer_logo" src={logo_dark_icon}/>
                                <div className="footer_text_logo title_font_family">JetBolt</div>
                                <div className="medium_spacer"></div>
                                <p>Powering Next Gen Crypto</p>
                                <div className="footer_text_container">© JetBolt Copyright 2024</div>
                                <div className="footer_text_container">All Rights Reserved</div>
                            </div>
                            <div className="one_third_section">
                                <div className="bubble_text">JOIN US</div>
                                <div className="footer_text_container" onClick={()=>window.open(external_links.twitter)}>
                                    <img className="footer_icon" src={twitter_icon}/>
                                    <u className="underlined_text">Twitter</u>
                                </div>
                                <div className="footer_text_container" onClick={open_telegram}>
                                    <img className="footer_icon" src={telegram_icon}/>
                                    <u className="underlined_text">Telegram</u>
                                </div>
                                <div className="footer_text_container" onClick={()=>window.open(external_links.github)}>
                                    <img className="footer_icon" src={github_icon}/>
                                    <u className="underlined_text">Github</u>
                                </div>
                                <div className="footer_text_container" onClick={()=>open_contact_us()}>
                                    <img className="footer_icon" src={email_icon}/>
                                    <u className="underlined_text">Contact</u>
                                </div>
                            </div>
                            <div className="one_third_section">
                                <div className="bubble_text">DOCUMENTATION</div>

                                <div className="footer_text_container" onClick={()=>window.open(external_links.whitepaper)}>
                                    <img className="footer_icon" src={whitepaper_icon}/>
                                    <u className="underlined_text">Whitepaper</u>
                                </div>
                                <div className="footer_text_container" onClick={()=>window.open(external_links.audit)}>
                                    <img className="footer_icon" src={audits_icon}/>
                                    <u className="underlined_text">Security and Audits</u>
                                </div>
                                <div className="footer_text_container" onClick={()=>window.open(external_links.terms)}>
                                    <img className="footer_icon" src={terms_icon}/>
                                    <u className="underlined_text">Terms and Conditions</u>
                                </div>
                                <div className="footer_text_container" onClick={()=>window.open(external_links.privacy)}>
                                    <img className="footer_icon" src={privacy_icon}/>
                                    <u className="underlined_text">Privacy Policy</u>
                                </div>
                                <div className="footer_text_container" onClick={()=>window.open(external_links.guides)}>
                                    <img className="footer_icon" src={help_icon}/>
                                    <u className="underlined_text">Help Docs</u>
                                </div>
                            </div>
                            <div className="large_spacer"></div>
                            <div className="large_spacer"></div>
                            <p className="small_text">Disclaimer: By visiting this website, using our services, or buying JetBolt, you agree to our <u onClick={()=>window.open(external_links.terms)} className="underlined_text">Terms of Service</u> and <u onClick={()=>window.open(external_links.privacy)} className="underlined_text">Privacy Policy</u>. You also warrant that you have read and fully understood the <u onClick={()=>window.open(external_links.disclaimer)} className="underlined_text">Disclaimer</u> about the risks of purchasing cryptocurrencies like JetBolt. By completing a purchase, you also declare that you are not a citizen or resident of any banned country, or any other country where the purchase of JetBolt may be prohibited by law. A complete list of banned countries is <u className="underlined_text" onClick={()=>window.open(external_links.blocked_countries)}>available here.</u> Our list of blocked countries and terms of service may be subject to updates, so please check the latest version.
                            <br/><br/>
                            JetBolt is not an investment; it is created as an experimental new cryptocurrency. JetBolt tokens are not currently resellable. They are only usable as an ecosystem asset. There is no guarantee they will ever be resellable or tradable on any market. We do not guarantee or represent that JetBolt holds any intrinsic value, that it will increase in price after the presale, or that it will have any value after the presale. We make no representations or warranties regarding JetBolt and are not liable for any losses or errors that may occur during its use. All users should exercise caution and buy JetBolt entirely at their own risk.
                            <br/><br/>
                            By using the JetBolt platform and product, you accept it "as is", acknowledging that we may not update, enhance, or maintain it regularly. The services and interface may be unavailable or discontinued at any time.</p>
                            <div className="large_spacer"></div>
                        </div>
                    </div>
                    
            </div>
            
        </>
    )
}

export default Footer;