import { ethers } from "ethers";
import config from "../../configs/config.json";
import crypto from "crypto-browserify";

let default_rpc_url = config.rpc_url;
let default_rpc_provider = new ethers.JsonRpcProvider(default_rpc_url);

export async function mineFreeGas(gasAmount, address, rpc_provider=default_rpc_provider) {
    const nonce = await rpc_provider.getTransactionCount(address);
    const nonceHash = window.BigInt('0x' + ethers.solidityPackedKeccak256(['uint256'], [nonce]).slice(2));
    const addressHash = window.BigInt('0x' + ethers.solidityPackedKeccak256(['address'], [address]).slice(2));
    const nonceAddressXOR = nonceHash ^ addressHash; // XOR for BigInt
    const maxNumber = (window.BigInt(2) ** window.BigInt(256)) - window.BigInt(1);
    const divConstant = maxNumber / window.BigInt(1); // Assuming DIFFICULTY is 1, replace with the actual difficulty if not

    let candidate;
    while (true) {
        candidate = window.BigInt('0x' + crypto.randomBytes(32).toString('hex'));
        const candidateHash = window.BigInt('0x' + ethers.solidityPackedKeccak256(['uint256'], [candidate.toString()]).slice(2));
        const resultHash = nonceAddressXOR ^ candidateHash; // XOR for BigInt
        const externalGas = divConstant / resultHash;
        if (externalGas >= window.BigInt(gasAmount)) {
            break;
        }
    }
    return candidate;
}

export async function get_gas_price(rpc_provider=default_rpc_provider) {
    let gas_price = (await rpc_provider.getFeeData()).gasPrice
    return gas_price;
}

export async function get_gas_price_eip1559(rpc_provider=default_rpc_provider) {
    let latest_block = await rpc_provider.getBlock("latest");
    let base_fee_per_gas = latest_block.baseFeePerGas;
    let max_priority_fee_per_gas = ethers.parseUnits('2', 'gwei');
    let max_fee_per_gas = base_fee_per_gas.mul(2).add(max_priority_fee_per_gas);
    
    return {
        max_priority_fee_per_gas,
        max_fee_per_gas
    };
}

export async function get_gas_limit_for_contract_call(wallet_address, private_key, contract_address, contract_name, function_name, parameters, value, rpc_provider=default_rpc_provider) {
    let contract_json = await import(`../../assets/contract_abis/${contract_name}.sol/${contract_name}.json`);
    let contract_abi = contract_json.abi;
    let wallet = new ethers.Wallet(private_key, rpc_provider);
    let contract = new ethers.Contract(contract_address, contract_abi, wallet);
    let gas_limit = await contract[function_name].estimateGas(...parameters, { from: wallet_address, value });
    return gas_limit;
}

export async function get_gas_limit_for_contract_call_w_abi(wallet_address, private_key, contract_address, contract_abi, function_name, parameters, value, rpc_provider=default_rpc_provider) {
    let wallet = new ethers.Wallet(private_key, rpc_provider);
    let contract = new ethers.Contract(contract_address, contract_abi, wallet);
    let gas_limit = await contract[function_name].estimateGas(...parameters, { from: wallet_address, value });
    return gas_limit;
}

export function prepare_transfer(recipient, value, gas_limit, gas_price, chain_id) {
    return {
        to: recipient,
        value,
        gasLimit: gas_limit,
        gasPrice: gas_price,
        chainId: chain_id
    };
}

export function prepare_transfer_eip1559(recipient, value, gas_limit, max_priority_fee_per_gas, max_fee_per_gas, chain_id) {
    return {
        to: recipient,
        value,
        gasLimit: gas_limit,
        maxPriorityFeePerGas: max_priority_fee_per_gas,
        maxFeePerGas: max_fee_per_gas,
        chainId: chain_id
    };
}

export async function prepare_contract_call(contract_address, private_key, contract_name, function_name, parameters, gas_limit, gas_price, chain_id, value, rpc_provider=default_rpc_provider) {
    let contract_json = await import(`../../assets/contract_abis/${contract_name}.sol/${contract_name}.json`);
    let contract_abi = contract_json.abi;
    let wallet = new ethers.Wallet(private_key, rpc_provider);
    let contract = new ethers.Contract(contract_address, contract_abi, wallet);
    return {
        to: contract_address,
        gasLimit: gas_limit,
        gasPrice: gas_price,
        data: contract.interface.encodeFunctionData(function_name, parameters),
        chainId: chain_id,
        value
    };
}

export async function prepare_contract_call_w_abi(contract_address, private_key, contract_abi, function_name, parameters, gas_limit, gas_price, chain_id, value, rpc_provider=default_rpc_provider) {
    let wallet = new ethers.Wallet(private_key, rpc_provider);
    let contract = new ethers.Contract(contract_address, contract_abi, wallet);
    return {
        to: contract_address,
        gasLimit: gas_limit,
        gasPrice: gas_price,
        data: contract.interface.encodeFunctionData(function_name, parameters),
        chainId: chain_id,
        value
    };
}

export async function prepare_contract_call_eip1559(private_key, contract_address, contract_name, function_name, parameters, gas_limit, max_priority_fee_per_gas, max_fee_per_gas, chain_id, rpc_provider=default_rpc_provider) {
    let contract_json = await import(`../../assets/contract_abis/${contract_name}.sol/${contract_name}.json`);
    let contract_abi = contract_json.abi;
    let wallet = new ethers.Wallet(private_key, rpc_provider);
    let contract = new ethers.Contract(contract_address, contract_abi, wallet);
    return {
        to: contract_address,
        gasLimit: gas_limit,
        maxPriorityFeePerGas: max_priority_fee_per_gas,
        maxFeePerGas: max_fee_per_gas,
        data: contract.interface.functions[function_name].encode(parameters),
        chainId: chain_id
    };
}

export async function sign_and_send_transaction(private_key, wallet_address, unsigned_transaction, rpc_provider=default_rpc_provider) {

    // Initialize an ethers wallet
    let wallet = new ethers.Wallet(private_key, rpc_provider);

    // Get the latest nonce and append to transaction data
    unsigned_transaction.nonce = await rpc_provider.getTransactionCount(wallet_address);

    // Sign the transaction
    let signed_transaction = await wallet.populateTransaction(unsigned_transaction);

    // Submit the transaction
    let transaction_response = await wallet.sendTransaction(signed_transaction);

    return transaction_response;

}

export async function read_contract(contract_address, contract_name, function_name, parameters, rpc_provider=default_rpc_provider) {
    let contract_json = await import(`../../assets/contract_abis/${contract_name}.sol/${contract_name}.json`);
    let contract_abi = contract_json.abi;
    let contract = new ethers.Contract(contract_address, contract_abi, rpc_provider);
    let read_response = await contract[function_name](...parameters);
    return read_response;
}

export async function wait_for_hash(hash, rpc_provider=default_rpc_provider) {
    // await rpc_provider.waitForTransaction(hash);
    let receipt = await rpc_provider.waitForTransaction(hash);
    if (receipt && receipt.status === 0) {
        throw new Error(`Transaction failed: ${hash}`);
    }
}