

import telegram_icon from "../assets/icons/telegram.png";
import { useTelegram } from "../modals/telegram";

function Telegram_Support() {

    let { open_telegram } = useTelegram();

    return (
        <div className="tg_support opacity_hover" onClick={open_telegram}>
            <img className="tg_support_icon" src={telegram_icon}/>
        </div>
    )
}

export default Telegram_Support;