import { useEffect } from "react";

// ---- Components ----
import Sales_Widget_Inner from "../../components/sales_widget_inner";

// ---- Icons ----
import down_icon from "../../assets/icons/down.png";

// ---- Services ----
import { trigger_event } from "../../services/analytics";


function Buy_Direct({ close }) {

    useEffect(()=>{
        trigger_event("open_purchase_modal", 0)
    },[]);

    return (
        <div className="purchase_funnel_container">
            <div className="stick_text">
                <div className="medium_spacer"></div>
                <div style={{position: "relative"}}>
                <h2 className="central_text_indicator title_font_family">Buy Token</h2>
                <img className="back_icon" src={down_icon} onClick={close}/>
                </div>
            </div>
            <Sales_Widget_Inner/>
        </div>
    );
}

export default Buy_Direct;