import { useEffect, useState, useRef } from "react";

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Jazzicon ----
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

// ---- Icons ----
import more_icon from "../../assets/icons/more.png";
import qr_scan_black_icon from "../../assets/icons/qr_scan_black.png";
import down_icon from "../../assets/icons/down.png";
import help_icon from "../../assets/icons/help.png";

// ---- Chart.js ----
import Chart from 'chart.js/auto';

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import { add_friend, accept_friend, decline_friend, remove_friend, favorite, unfavorite, get_profile_from } from "../../services/contract_functions/friends";
import { get_profile_statistics, stake_tokens } from "../../services/contract_functions/token";
import { get_chart_data } from "../../services/contract_functions/logs";
import { shorten_crypto_string, get_labels_for_time_scale, short_format_number } from "../../services/helper_functions";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

function Profile({ close, parameters, set_parameters }) {

    // ---- Modals ----
    let { throw_error } = useErrorPopUp();
    
    function send_tokens() {
        set_parameters({type: "send_tokens", wallet_address: profile.username ? `@${profile.username}` : parameters.wallet_address})
    }

    // ---- Functions ----
    function action_error() {
        throw_error({
            title: "Oh No!", content: "An error has occured. Please reload the page and try again", buttons: [{ title: "Okay", callback: close }]
        })
    }

    function unminted_tokens() {
        throw_error({
            title: "Zero Balance?", content: "An error has occured. Please reload the page and try again", buttons: [{ title: "Okay", callback: close }]
        });
    }

    async function _add_friend(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";

        set_tx_pending(true);
        set_details("Send user a friend request.");
        set_tx_status({status:"Waiting for approval", amount:0});
        set_parameters({type: "approve_transaction", details: "Send user a friend request.", cb: {tx_pending: true, tx_status: {status:"Waiting for approval", amount:0}}});

        try {
            await add_friend(profile.wallet_address, update_transaction_status);
            update_transaction_status("",1,true);
            setTimeout(()=>{
                load_profile();
                close();
            },500);
        } catch (error) {
            action_error();
            close();
        }

        set_tx_pending(false);

        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    async function _accept_friend(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";

        set_tx_pending(true);
        set_details("Accept user's friend request.");
        set_tx_status({status:"Waiting for approval", amount:0});
        set_parameters({type: "approve_transaction", details: "Accept user's friend request.", cb: {tx_pending: true, tx_status: {status:"Waiting for approval", amount:0}}});

        try {
            await accept_friend(profile.request_address, update_transaction_status);
            update_transaction_status("",1,true);
            setTimeout(()=>{
                load_profile();
                close();
            },500);
        } catch (error) {
            action_error();
            close();
        }

        set_tx_pending(false);

        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    async function _decline_friend(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";

        set_tx_pending(true);
        set_details("Decline user's friend request.");
        set_tx_status({status:"Waiting for approval", amount:0});
        set_parameters({type: "approve_transaction", details: "Decline user's friend request.", cb: {tx_pending: true, tx_status: {status:"Waiting for approval", amount:0}}});

        try {
            await decline_friend(profile.request_address, update_transaction_status);
            update_transaction_status("",1,true);
            setTimeout(()=>{
                load_profile();
                close();
            },500);
        } catch (error) {
            action_error();
            close();
        }

        set_tx_pending(false);

        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    async function _remove_friend(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";

        set_tx_pending(true);
        set_details("Remove user from friends.");
        set_tx_status({status:"Waiting for approval", amount:0});
        set_parameters({type: "approve_transaction", details: "Remove user from friends.", cb: {tx_pending: true, tx_status: {status:"Waiting for approval", amount:0}}});

        try {
            await remove_friend(profile.wallet_address, update_transaction_status);
            update_transaction_status("",1,true);
            setTimeout(()=>{
                load_profile();
                close();
            },500);
        } catch (error) {
            action_error();
            close();
        }

        set_tx_pending(false);

        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    async function _favorite(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";

        set_tx_pending(true);
        set_details("Add user to favorites.");
        set_tx_status({status:"Waiting for approval", amount:0});
        set_parameters({type: "approve_transaction", details: "Add user to favorites.", cb: {tx_pending: true, tx_status: {status:"Waiting for approval", amount:0}}});

        try {
            await favorite(profile.wallet_address, update_transaction_status);
            update_transaction_status("",1,true);
            setTimeout(()=>{
                load_profile();
                close();
            },500);
        } catch (error) {
            action_error();
            close();
        }

        set_tx_pending(false);

        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    async function _unfavorite(event) {
        event.target.style = "opacity: 0.25; pointer-events: none;";

        set_tx_pending(true);
        set_details("Remove user from favorites.");
        set_tx_status({status:"Waiting for approval", amount:0});
        set_parameters({type: "approve_transaction", details: "Remove user from favorites.", cb: {tx_pending: true, tx_status: {status:"Waiting for approval", amount:0}}});

        try {
            await unfavorite(profile.wallet_address, update_transaction_status);
            update_transaction_status("",1,true);
            setTimeout(()=>{
                load_profile();
                close();
            },500);
        } catch (error) {
            action_error();
            close();
        }

        set_tx_pending(false);

        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    // ---- Tx Status ----
    let [tx_pending, set_tx_pending] = useState(false);
    let [tx_status, set_tx_status] = useState({status:"Waiting for approval", amount:0})
    let [details, set_details] = useState("");

    function update_transaction_status(status, amount, success_tx=false) {
        set_tx_status({status, amount});
        set_parameters({type: "approve_transaction", details, cb: {tx_pending: true, tx_status: {status, amount}}, success_tx});
    }

    // ---- Token ----
    let token = useToken();

    // ---- Hooks ----
    let [stats, set_stats] = useState({
        available_tokens: 0n,
        staked_tokens: 0n
    });
    
    let [profile, set_profile] = useState({
        friends_count: 0n,
        favorites_count: 0n,
        favorited_by_count: 0n
    });

    // ---- Load Profile ----
    async function load_profile() {
        if (token && token.wallet_address && parameters && parameters.wallet_address) {
            let stats_keys = ["available_tokens", "staked_tokens", "rewards_earned", "rewards_from_friends", "rewards_to_friends"];
            let profile_keys = ["wallet_address", "username", "friends_count", "favorites_count", "favorited_by_count"];
            
            let stats = {};
            let profile = {};

            let stats_raw = await get_profile_statistics(parameters.wallet_address);
            let profile_raw = await get_profile_from(parameters.wallet_address, token.wallet_address);

            for (let key of stats_keys) {
                stats[key] = stats_raw[key];
            }

            set_stats(stats);

            let profile_info_raw = profile_raw[0];

            for (let key of profile_keys) {
                profile[key] = profile_info_raw[key];
            }

            set_profile(profile);
            profile["status_code"] = Number(profile_raw[1]);
            profile["request_address"] = profile_raw[2]

            // console.log(profile);

            
        }
    }

    // ---- On Load ----
    useEffect(()=> {

        load_profile();

    },[token, parameters]);

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Profile</h2>
                        <img className="back_icon" src={down_icon} onClick={close}/>
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <div className="profile_picture notranslate">
                    {
                        parameters.wallet_address && 
                        <Jazzicon diameter={100} seed={jsNumberForAddress(parameters.wallet_address)}/>
                    }
                    
                </div>
                <div className="profile_info_container notranslate">
                    {
                        profile.wallet_address && 
                        <>
                            <h2>{profile.username ? `@${profile.username}` : "Unamed User"}</h2>
                            <p><u>{shorten_crypto_string(profile.wallet_address)}</u></p>
                        </>
                    }
                </div>
                <div className="medium_spacer"></div>


                {
                    profile && profile.status_code == 0 && profile.wallet_address != token.wallet_address &&
                    <>
                        <div className="profile_send_button blue_button_hover" onClick={send_tokens}>Send Tokens</div>
                        <div className="profile_receive_button" onClick={_add_friend}>Add Friend</div>
                    </>
                }

                {
                    profile && profile.status_code == 1 &&
                    <>
                        <div className="profile_send_button blue_button_hover" onClick={send_tokens}>Send Tokens</div>
                        <div className="profile_receive_button" style={{opacity: "0.25"}}>Request Sent</div>
                    </>
                }

                {
                    profile && profile.status_code == 2 &&
                    <>
                        <div className="profile_send_button_full blue_button_hover" onClick={send_tokens}>Send Tokens</div>
                        <div className="medium_spacer"></div>
                        <div className="profile_send_button blue_button_hover" onClick={_accept_friend}>Accept Friend</div>
                        <div className="profile_receive_button gray_button_hover" onClick={_decline_friend}>Decline Friend</div>
                    </>
                }

                {
                    profile && profile.status_code == 3 &&
                    <>
                        <div className="profile_send_button_full blue_button_hover" onClick={send_tokens}>Send Tokens</div>
                        <div className="medium_spacer"></div>
                        <div className="profile_send_button blue_button_hover" onClick={_favorite}>Favorite</div>
                        <div className="profile_receive_button gray_button_hover" onClick={_remove_friend}>Remove Friend</div>
                    </>
                }

                {
                    profile && profile.status_code == 4 &&
                    <>
                        <div className="profile_send_button_full blue_button_hover" onClick={send_tokens}>Send Tokens</div>
                        <div className="medium_spacer"></div>
                        <div className="profile_send_button blue_button_hover" onClick={_unfavorite}>Unfavorite</div>
                        <div className="profile_receive_button gray_button_hover" onClick={_remove_friend}>Remove Friend</div>
                    </>
                }

                <div className="medium_spacer"></div>
                <div className="data_container special">
                    <div className="data_amount notranslate">{short_format_number(ethers.formatUnits(stats.available_tokens + stats.staked_tokens))}</div>
                    <div className="data_description">Total Tokens</div>
                </div>
                <div className="data_container data_container_second special">
                    <div className="data_amount notranslate">{short_format_number(ethers.formatUnits(stats.available_tokens))}</div>
                    <div className="data_description">Available Tokens</div>
                </div>
                <div className="data_container special">
                    <div className="data_amount notranslate">{Number(profile.friends_count)}</div>
                    <div className="data_description">Friends</div>
                </div>
                <div className="data_container data_container_last special">
                    <div className="data_amount notranslate">{Number(profile.favorites_count)} / {Number(profile.favorited_by_count)}</div>
                    <div className="data_description">Favorites</div>
                </div>
            </div>
        </>
    );

}

export default Profile;