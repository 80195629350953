import { useEffect, useState, useRef } from "react";

// ---- Lotie ----
import Lottie from 'lottie-react';

// ---- Icons ----
import animated_tick from "../../assets/icons/animated_tick_green.json";

function Approve_Transaction({ parameters, set_parameters }) {

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">{parameters.success_tx ? "Sucess!" : "Approve Transaction"}</h2>
                    </div>
                </div>
                <div className="medium_spacer"></div>
                
                {
                    parameters.success_tx ?
                    <center>
                        <Lottie animationData={animated_tick} loop={false} direction={1} style={{ marginLeft: "", width: "100px", height: "100px" }}/>
                    </center>
                    :
                    parameters.cb.tx_pending &&
                    <>
                        <p>{parameters.details}</p>
                        <div className="small_spacer"></div>
                        <div className="medium_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar" style={{width: `${parameters.cb.tx_status.amount*100}%`}}></div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text">Status: {parameters.cb.tx_status.status}</p>
                    </>
                }
            </div>
        </>
    );    

}

export default Approve_Transaction;