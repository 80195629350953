

// ---- Icons ----
import instant_icon from "../../assets/icons/instant.png";
import day_icon from "../../assets/icons/1_day.png";
import week_icon from "../../assets/icons/1_week.png";
import month_icon from "../../assets/icons/1_month.png";
import right_icon from "../../assets/icons/right.png";
import down_icon from "../../assets/icons/down.png";

// ---- Services ----
import { convert_12_hr_format } from "../../services/helper_functions";

// ---- Data ----
import { time_icons } from "../../assets/data/time";

function Email_Time({ close, parameters, set_parameters }) {

    function select_duration(time_index) {
        set_parameters({type: "email_preferences", time_index, close_callback: parameters.close_callback, email_value: parameters.email_value})
    }

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Email Time</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <p>Select the time of day in your local time you would like to receive your email reminders.</p>
                <div className="medium_spacer"></div>
                {
                    time_icons.map((item,index)=>(
                        <div key={index} className="friend_list_item gray_button_hover" onClick={()=>select_duration(index)}>
                            <img className="live_sales_icon" src={item}/>
                            <div className="live_sales_amount_label">{convert_12_hr_format(index)} {Intl.DateTimeFormat().resolvedOptions().timeZone} Time</div>
                            <div className="decline_button">
                                <img className="inner_icon" src={right_icon}/>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default Email_Time;