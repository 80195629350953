import React, { useRef, useEffect, useState } from 'react';

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Components ----
import SEO from "../components/seo";
import App_Nav from "../components/app_nav";

// ---- Jazzicon ----
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

// ---- Icons ----
import more_icon from "../assets/icons/more.png";
import qr_scan_black_icon from "../assets/icons/qr_scan_black.png";
import instant_icon from "../assets/icons/instant.png";
import day_icon from "../assets/icons/1_day.png";
import week_icon from "../assets/icons/1_week.png";
import month_icon from "../assets/icons/1_month.png";
import right_icon from "../assets/icons/right.png";
import help_icon from "../assets/icons/help.png"

// ---- Chart.js ----
import Chart from 'chart.js/auto';

// ---- Services ----
import useToken from "../services/wallet/useToken";
import { get_profile } from "../services/contract_functions/friends";
import { get_profile_statistics, get_stake, claim_rewards } from "../services/contract_functions/token";
import { get_chart_data } from "../services/contract_functions/logs";
import { format_time_mm_ss, get_labels_for_time_scale, short_format_number } from "../services/helper_functions";
import { get_email_preferences } from "../services/api";

// ---- Modals ----
import { useAppModals } from "../modals/app_modals";

let lock_period_to_icon = {
    0: instant_icon,
    1: day_icon,
    7: week_icon,
    30: month_icon
}

function Staking_Page() {

    let trend_chart_ref = useRef(null);
    let trend_chart_instance = useRef(null);

    // ---- Modals ----
    let { open_app_modal, close_open_app_modal } = useAppModals();

    // ---- Functions ----
    function stake_tokens() {
        open_app_modal({type:"stake_amount", close_callback: ()=>{
            load_profile_stats();
            // load_user_stakes();
            load_chart_data();
            request_email();
        }});
    }

    function unstake_tokens() {
        open_app_modal({type:"unstake_amount", close_callback: ()=>{
            load_profile_stats();
            // load_user_stakes();
            load_chart_data();
        }});
    }

    function stake_preview(stake_data) {
        open_app_modal({type:"stake_preview", stake_data, close_callback: ()=>{
            load_profile_stats();
            // load_user_stakes();
            load_chart_data();
        }});
    }

    function update_transaction_status(status, amount, success_tx=false) {
        open_app_modal({type: "approve_transaction", details: "Claim daily staking rewards.", cb: {tx_pending: true, tx_status: {status, amount}}, success_tx});
    }
    
    async function claim_daily_rewards() {
        update_transaction_status("Waiting for approval",0);
        try {
            await claim_rewards(update_transaction_status);

            update_transaction_status("",1,true);
            setTimeout(()=>{
                close_open_app_modal();
                window.location = "/explore"
                // close_open_app_modal();
                // load_profile_stats();
                // // load_user_stakes();
                // load_chart_data();
                // request_email();
            },500);

        } catch (error) {
            close_open_app_modal();
        }
    }

    // ---- Timescales ----
    let time_scales = ["1D","7D","1M","1Y"];
    let [time_scale_index, set_time_scale_index] = useState(2);
    
    // ---- Token ----
    let token = useToken();

    // ---- Hooks ----
    let [stats, set_stats] = useState({
        available_tokens: 0n,
        staked_tokens: 0n,
        rewards_earned: 0n,
        rewards_from_friends: 0n,
        rewards_to_friends: 0n,
        sequential_claim: 0n,
        sequential_multiplier: 0,
        claim_distance: 0
    });

    let [stakes, set_stakes] = useState([]);
    let [claimable_count, set_claimable_count] = useState(0);
    

    // ---- Plot Graph ----

    let [data, set_data] = useState({});

    async function load_chart_data() {

        if (token && token.wallet_address) {
            let colors = ["#00c8ff", "#9458f8", "#00bc97"];
            let data_keys = ["total_staked", "total_rewards", "total_rewards_from_friends"];
            let data_raw = await get_chart_data(token.wallet_address, time_scale_index, data_keys);

            let labels = get_labels_for_time_scale(time_scale_index);

            let datasets = [];
            for (let i = 0; i < data_keys.length; i++) {
                let dataset_raw = data_raw[i];
                let dataset = [];
                for (let x = 0; x < labels.length; x++) {
                    dataset.push(ethers.formatUnits(dataset_raw[x]));
                }
                datasets.push({
                    label: data_keys[i],
                    data: dataset,
                    fill: false,
                    borderColor: colors[i],
                    backgroundColor: colors[i],
                    tension: 0.1,
                    type: 'line',
                });
            }

            let data = { labels, datasets }

            set_data(data);
        }
    }

    useEffect(() => {

        if (trend_chart_instance.current) {
            trend_chart_instance.current.destroy();
        }

        let ctx = trend_chart_ref.current.getContext('2d');

        trend_chart_instance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                ...data,
            },
            options: {
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'center',
                        labels: {
                            color: 'white',
                            padding: 20,
                            paddingTop: 50,
                            font: {
                                size: 14,
                                family: 'Roboto Mono, monospace',
                                weight: 'bold'
                            }
                        },
                        usePointStyle: true,
                        boxWidth: 20,
                        boxHeight: 10
                    },
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    x: {
                        ticks: {
                            color: 'gray'
                        },
                        grid: {
                            color: 'rgba(80, 80, 80, 0.25)',
                            borderColor: 'rgba(80, 80, 80, 0.25)',
                        }
                    },
                    y: {
                        ticks: {
                            color: 'gray',
                        },
                        grid: {
                            color: 'rgba(80, 80, 80, 0.25)',
                            borderColor: 'rgba(80, 80, 80, 0.25)',
                        }
                    }
                },
            }
        });

    },[data]);

    // ---- Load Profile Stats ----
    async function load_profile_stats() {
        if (token && token.wallet_address) {

            let stats_keys = ["available_tokens", "staked_tokens", "pending_tokens", "rewards_earned", "rewards_from_friends", "rewards_to_friends"];

            let stats = {
                sequential_claim: 0n,
                sequential_multiplier: 0,
                claim_distance: 0
            };

            let stats_raw = await get_profile_statistics(token.wallet_address);
            for (let key of stats_keys) {
                stats[key] = stats_raw[key];
            }

            let stake_raw = await get_stake(token.wallet_address);

            if (stake_raw[1].amount_staked > 0n) {
                stats.sequential_claim = stake_raw[1].sequential_claim;
                stats.sequential_multiplier = Number(stake_raw[1].sequential_claim) / 100;
                stats.claim_distance = Number(stake_raw[1].last_claim_time) + (24 * 60 * 60) - Number(stake_raw[0])
                set_stats(stats);
                set_claim_distance(stats.claim_distance);
            }

            set_stats(stats);

            let skip_staking_guide = localStorage.getItem("skip_staking_guide");

            if (!skip_staking_guide) {
                open_app_modal({type:"staking_guide"});
            }

        }
    }

    async function request_email() {
        let preferences = await get_email_preferences(token.wallet_address);
        if (!preferences) {
            open_app_modal({type:"email_preferences", time_index: 9});
        }
    }

    // ---- Timers ----
    let [index_to_interval, set_index_to_interval] = useState({});

    let [claim_distance, set_claim_distance] = useState(0);
    let interval_ref = useRef(null);

    useEffect(()=> {

        if (interval_ref.current != null) {
            clearInterval(interval_ref.current);
            interval_ref.current = null;
        }

        if (claim_distance > 0) {

            interval_ref.current = setInterval(() => {

                set_claim_distance(prev_claim_distance => {
                    let new_claim_distance = prev_claim_distance-1;
                    return new_claim_distance;
                })

            }, 1000);

        }
        else {

        }

        return () => {
            if (interval_ref.current) {
                clearInterval(interval_ref.current);
            }
        };

    },[claim_distance])

    // useEffect(() => {

    //     if (interval_ref.current != null) {
    //         clearInterval(interval_ref.current);
    //         interval_ref.current = null;
    //     }

    //     let timers = {};

    //     for (let i = 0; i < stakes.length; i++) {
    //         let timer_value = stakes[i].next_claim_time || stakes[i].withdraw_distance;
    //         if (timer_value != 0) {
    //             timers[i] = timer_value;
    //         }
    //     }

    //     set_index_to_interval(timers); 

    //     interval_ref.current = setInterval(() => {
    //         set_index_to_interval(prev_timers => {
    //             let new_timers = { ...prev_timers };
    //             Object.keys(new_timers).forEach(key => {
    //                 if (new_timers[key] > 0) {
    //                     new_timers[key]--;
    //                 } else {
    //                     delete new_timers[key];
    //                 }
    //             });
    //             return new_timers;
    //         })
    //     }, 1000);

    //     return () => {
    //         if (interval_ref.current) {
    //             clearInterval(interval_ref.current);
    //         }
    //     };

    // }, [stakes]);



    // ---- Load Stakes ----


    async function load_user_stakes(stats_copy) {
        if (token && token.wallet_address) {

            // let stake_raw = await get_stake(token.wallet_address);

            // if (stake_raw[1].amount_staked > 0n) {
            //     stats_copy.sequential_claim = stake_raw[1].sequential_claim;
            //     stats_copy.sequential_multiplier = Number(stake_raw[1].sequential_claim) * 0.25 / 30;
            //     stats_copy.claim_distance = Number(stake_raw[1].last_claim_time) + (24 * 60 * 60) - Number(stake_raw[0])
            //     set_stats(stats_copy);
            //     set_claim_distance(stats_copy.claim_distance);
            // }



            /*

            let stakes_raw = await get_user_stakes(token.wallet_address);
            let stakes = [];
            let claimable_count = 0;

            for (let i = 0; i < stakes_raw[1].length; i++) {
                let stake = stakes_raw[1][i];
                let next_claim_time = 0;
                let withdraw_distance = 0;

                if (Number(stakes_raw[0]) < Number(stake.last_claim_time) + (24 * 60 * 60)) {
                    next_claim_time = Number(stake.last_claim_time) + (24 * 60 * 60) - Number(stakes_raw[0]);
                }
                else {
                    claimable_count++;
                }

                if (!stake.is_active) {
                    if (Number(stakes_raw[0]) < Number(stake.withdraw_time)) {
                        withdraw_distance = Number(stake.withdraw_time) - Number(stakes_raw[0]);
                    }
                    else {
                        claimable_count++;
                    }
                }

                if (stake.is_active || !stake.is_withdrawn) {
                    stakes.push({
                        amount_staked: ethers.formatUnits(stake.amount_staked),
                        total_rewards: ethers.formatUnits(stake.total_rewards),
                        sequential_claim: Number(stake.sequential_claim),
                        lock_period: Number(stake.lock_period),
                        is_active: stake.is_active,
                        is_withdrawn: stake.is_withdrawn,
                        icon: lock_period_to_icon[Number(stake.lock_period)],
                        next_claim_time,
                        withdraw_distance,
                        stake_address: stake.stake_address
                    })
                }
                
            }

            set_stakes(stakes);
            set_claimable_count(claimable_count);

            */
        }
    }

    // ---- On Load ----
    useEffect(()=> {
        load_profile_stats();
        // load_user_stakes();
        load_chart_data();
    },[token]);

    useEffect(()=> {
        load_chart_data();
    },[time_scale_index]);

    useEffect(() => {
        return () => {
            Object.values(index_to_interval).forEach(timer => {
                clearInterval(timer.interval);
            });
        };
    }, []);
    
    return (
        <>
            <SEO parameters={
                {
                    title: "JetBolt Staking",
                    description: "Earning cryptocurrency has never been so easy. JetBolt has created an awesome new staking system that rewards those who participate the most.",
                    url: "https://jetbolt.io/staking"
                }
            }/>
            <App_Nav selected_index={2} selected_index_mobile={1}>
                <div className="small_spacer"></div>
                <div className="title_relative_container">
                    <h1 className="title_font_family">Staking</h1>
                    <div className="mobile_app_only">
                        <div className="more_button gray_button_hover" onClick={()=>open_app_modal({type:"staking_guide"})}>
                            <img className="inner_icon_abs" src={help_icon}/>
                        </div>
                    </div>
                    <div className="desktop_transfer_container desktop_app_only">
                        {/* <div className="right_align_button yellow_button_hover" onClick={stake_tokens}>Stake</div> */}
                        <div className="profile_send_button blue_button_hover" onClick={stake_tokens}>Stake</div>
                        <div className="profile_receive_button gray_button_hover" onClick={unstake_tokens}>Unstake</div>
                        <div className="profile_guide_button gray_button_hover"  onClick={()=>open_app_modal({type:"staking_guide"})}>
                            <img className="inner_icon_abs" src={help_icon}/>
                        </div>
                    </div>
                    
                </div>
               
                <div className="medium_spacer"></div>

                <div className="data_container">
                    <div className="data_amount notranslate">{short_format_number(ethers.formatUnits(stats.staked_tokens))}</div>
                    <div className="data_description">Staked Tokens</div>
                </div>
                <div className="data_container data_container_second">
                    <div className="data_amount notranslate">{short_format_number(ethers.formatUnits(stats.rewards_earned))}</div>
                    <div className="data_description">Tokens Earned</div>
                </div>
                <div className="data_container">
                    <div className="data_amount notranslate">{short_format_number(ethers.formatUnits(stats.rewards_from_friends))}</div>
                    <div className="data_description">Rewards From Favorites</div>
                </div>
                {/* <div className="data_container data_container_last">
                    <div className="data_amount notranslate">{short_format_number(ethers.formatUnits(stats.rewards_to_friends))}</div>
                    <div className="data_description">Rewards To Favorites</div>
                </div> */}

                {/* <div className="data_container">
                    <div className="data_amount notranslate">{Number(stats.sequential_claim)}</div>
                    <div className="data_description">Sequential Claims</div>
                </div> */}
                <div className="data_container data_container_last">
                    <div className="data_amount notranslate">+{(100 * stats.sequential_multiplier).toFixed(2)}%</div>
                    <div className="data_description">Claim Streak Bonus</div>
                </div>

                <div className="mobile_app_only">
                    <div className="medium_spacer"></div>
                    <div className="profile_send_button" onClick={stake_tokens}>Stake</div>
                    <div className="profile_receive_button" onClick={unstake_tokens}>Unstake</div>
                </div>

                

                {
                    stats.staked_tokens > 0n &&
                    <>
                        <div className="medium_spacer"></div>
                        {
                            claim_distance <= 0 ?
                            <div className="large_claim_countdown_button available yellow_button_hover" onClick={claim_daily_rewards}>Claim Rewards</div> :
                            <div className="large_claim_countdown_button">Claim Rewards in {format_time_mm_ss(claim_distance)}</div>
                        }
                    </>
                }

                <div className="medium_spacer"></div>

                
                
                

                <div className="medium_spacer"></div>
                {
                    stakes.length > 0 &&
                    <>
                        <h3>Staked Tokens</h3>
                        <div className="medium_spacer"></div>
                        {
                            stakes.map((item,index)=>(
                                <div key={index} className="stake_list_item" onClick={()=>stake_preview(item)}>
                                    <img className="stake_icon" src={item.icon}/>
                                    
                                    <div className="live_sales_amount_label notranslate">{short_format_number(item.amount_staked)} JETPAW</div>
                                    {
                                        item.is_active &&
                                        <div className="stake_countdown">Claim rewards in {index_to_interval[index] && format_time_mm_ss(index_to_interval[index])}</div>
                                    }
                                    {
                                        !item.is_active && !item.is_withdrawn &&
                                        <div className="stake_countdown">Unstake tokens in {index_to_interval[index] && format_time_mm_ss(index_to_interval[index])}</div>
                                    }
                                    <img className="right_icon_stake" src={right_icon}/>
                                </div>
                            ))
                        }
                    </>
                }
                
                {
                    claimable_count > 0 &&
                    <div className="claim_all_button">
                        Collect All ({claimable_count})
                    </div>
                }
                <div className="medium_spacer"></div>



                <div className="title_relative_container">
                    <h3>Staking Chart</h3>
                    <div className="chart_time_selector_container notranslate">
                        {
                            time_scales.map((scale, index)=> (
                                <div key={index} className={`chart_time_item ${time_scale_index==index?"chart_time_item_selected just_pointer":"gray_button_hover"}`} onClick={()=>set_time_scale_index(index)}>{scale}</div>
                            ))
                        }
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <div className='standard_chart_container notranslate'>
                    <canvas ref={trend_chart_ref} className="standard_chart"></canvas>
                </div>
                <div className="medium_spacer"></div>
            </App_Nav>
        </>
    )
}

export default Staking_Page;