import { useState } from "react";

// ---- Images ----
import second_cat from "../assets/images/second_cat.png";
import second_cat_overlay from "../assets/images/second_cat_overlay.png";

// ---- Modals ----
import { useContactUs } from "../modals/contact_us";

// ---- External Links ----
import external_links from "../configs/external_links.json";

let faq_questions = [
    {
        question: "What is the JetBolt token price at presale?",
        answer: "At the start of the presale, US$ 1 was worth 100 JetBolt Tokens. The price increases daily by 0.045% based on the original price. This is capped at a maximum of 25% total price increase over the entire presale. Additional discounts are available for different purchase tiers all the way up to 25%. The price of JetBolt during the presale in terms of other cryptocurrencies may vary and fluctuate depending on their price relative to the US Dollar."
    },
    {
        question: "When will the JetBolt presale end?",
        answer: "The JetBolt presale will end automatically once all the JetBolt presale tokens are sold. The team may end the presale early at their sole discretion."
    },
    {
        question: "I purchased JetBolt tokens but they have not yet appeared in my wallet",
        answer: <>
        After your first purchase of JetBolt tokens you will be instructed on how to create a JetBolt non-custodial wallet. Once this is done, your JetBolt tokens will be minted to that wallet address automatically and visible from your profile page.
        <br/><br/>
        If your tokens do not appear after over 1 hour of your purchase please contact us with the button below and we will help you resolve this issue ASAP.
        </>
    },
    {
        question: "Someone claiming to be from the JetBolt team has contacted me out of the blue",
        answer: "The JetBolt team will never contact you out of the blue. Please only contact support@jetbolt.io for support. We DO NOT offer customer support on Twitter, Telegram, or Discord."
    },
    {
        question: "Is JetBolt a good and safe investment?",
        answer: <>
        No it is not. JetBolt is an experimental new cryptocurrency ecosystem. Like all new cryptocurrencies JetBolt is extremely risky. We strongly advise you to only spend sums of money you can afford to lose. A more detailed analysis of some of the risks associated with JetBolt are <u className="underlined_text" onClick={()=>window.open(external_links.disclaimer)}>available here</u>. Moreover, JetBolt tokens are not currently resellable. They are only usable as an ecosystem asset. There is no guarantee they will ever be resellable or tradable on any market.
        </>
    },
]

function FAQ() {

    let { open_contact_us } = useContactUs();

    let [selected_index, set_selected_index] = useState(-1);

    function handle_selection(index) {
        if (selected_index == index) {
            set_selected_index(-1);
        }
        else {
            set_selected_index(index);
        }
    }

    return (
        <div className="page_container">
            <div className="safe_inner">
                <div className="large_spacer"></div>
                <h1 className="title_font_family">FAQ</h1>
                <div className="medium_spacer"></div>
                <div className="faq_image_container">
                    <div className="lined_background radial_mask"></div>
                    <img className="faq_image" src={second_cat}/>
                    <img className="faq_pulse" src={second_cat_overlay}/>
                </div>
                <div className="faq_items_container">
                    {
                        faq_questions.map((item,index)=>(
                            <div key={index} className={`faq_item gray_button_hover ${index==faq_questions.length-1?"faq_item_last":""}`} onClick={()=>handle_selection(index)}>
                                <div className="plus_icon_container">
                                    <div className="plus_horizontal_bar"></div>
                                    <div className={`plus_vertical_bar ${selected_index==index?"open":""}`}></div>
                                </div>
                                <div className={`faq_title ${selected_index==index?"open":""}`}>{item.question}</div>
                                <div className={`faq_description ${selected_index==index?"open":""}`}>{item.answer}</div>
                            </div>
                        ))
                    }
                </div>
                <div className="large_spacer"></div>
                <div className="inline_button_left blue_button_hover" onClick={open_contact_us}>Contact Us</div>
                <div className="inline_button_right gray_button_hover" onClick={()=>window.open(external_links.guides)}>Help Docs</div>
                <div className="large_spacer"></div>
            </div>
        </div>
    )
}

export default FAQ;