import { ethers } from 'ethers';
import config from "../../configs/config.json";
import { mineFreeGas, prepare_contract_call, sign_and_send_transaction } from "./transaction_builder";

let rpc_url = config.rpc_url;
let default_rpc_provider = new ethers.JsonRpcProvider(rpc_url);

// europa main : 0x6a627842
// other: 0x0c11dedd

export async function request_gas(wallet_address, rpc_provider=default_rpc_provider, sfuel_contract=config.sfuel_contract, function_signature="0x0c11dedd") {

    let random_wallet = ethers.Wallet.createRandom();
    let wallet = new ethers.Wallet(random_wallet.privateKey, rpc_provider);
    let gas_limit = 100_000n;
    let gas_price = await mineFreeGas(gas_limit, wallet.address);
    
    let response = await wallet.sendTransaction({
        to: sfuel_contract,
        data: `${function_signature}000000000000000000000000${wallet_address.substring(2)}`,
        gasLimit: 100_000,
        gasPrice: gas_price
    });

    await rpc_provider.waitForTransaction(response.hash, 1);
}

export async function get_gas_remaining(wallet_address, rpc_provider=default_rpc_provider) {
    let wallet_balance = await rpc_provider.getBalance(wallet_address);
    return wallet_balance;
}

export async function request_gas_if_needed(wallet_address, gas_price, gas_limit, rpc_provider=default_rpc_provider, sfuel_contract=config.sfuel_contract, function_signature="0x0c11dedd") {
    let total_gas_needed = gas_price * gas_limit;
    let wallet_balance = await rpc_provider.getBalance(wallet_address);
    if (wallet_balance < total_gas_needed) {
        await request_gas(wallet_address, rpc_provider, sfuel_contract, function_signature);
    }
}