import { useState, useEffect, useRef } from "react";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";
import { useCurrencySelector } from "../currency_selector";

// ---- Icons ----
import logo_dark_icon from "../../assets/icons/logo_dark.png";
import right_icon from "../../assets/icons/right.png";
import edit_icon from "../../assets/icons/edit.png";
import wallet_icon from "../../assets/icons/wallet.png";
import paste_icon from "../../assets/icons/paste.png";
import left_icon from "../../assets/icons/left.png";
import help_icon from "../../assets/icons/help.png";
import down_icon from "../../assets/icons/down.png";

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Data ----
import { currencies } from "../../assets/data/currencies";
import { open_wallet_deep_link, deep_link_wallets } from "../../assets/data/deep_link_wallets";

// ---- WAGMI ----
import { useAccount, useConnect } from 'wagmi';
import { wagmi_config, Wagmi_Client } from "../../configs/wagmi_config";
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { disconnect, switchChain, writeContract, sendTransaction } from '@wagmi/core';

// ---- Jazzicon ----
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

// ---- Services ----
import { format_time_mm_ss, shorten_crypto_string, append_utm_without_reloading, is_web_view, is_desktop } from "../../services/helper_functions";
import { get_order_payins, find_customer } from "../../services/api";
import { trigger_event_crypto } from "../../services/analytics";
import useCustomer from "../../services/wallet/useCustomer";
import { get_username } from "../../services/contract_functions/friends";
import { forget_wallets } from "../../services/wallet/core";

let usdt_abi = [
    {"constant":false,"inputs":[{"name":"_to","type":"address"},{"name":"_value","type":"uint256"}],"name":"transfer","outputs":[],"payable":false,"stateMutability":"nonpayable","type":"function"}
]

function Pay_EVM_Component({ close, parameters, set_parameters }) {

    let customer = useCustomer();
    let [destination_mint, set_destination_mint] = useState(null);

    let { is_os_webview, is_android_webview } = is_web_view();
    let is_desk = is_desktop();

    useEffect(()=>{

        // if (is_os_webview || is_android_webview) {
            if (customer && customer.customer_id) {
                (async function() {
                    let customer_info = await find_customer(customer.customer_id);
                    if (customer_info.destination_address) {
                        let username = await get_username(customer_info.destination_address);
                        if (username) {
                            set_destination_mint(username);
                        }
                        else {
                            set_destination_mint(shorten_crypto_string(customer_info.destination_address));
                        }
                    }
                })();
            }
        // }

    },[customer]);

    function logout() {
        let params = {
            title: "Logout",
            content: "Are you sure you want to logout? Make sure you have saved your seed phrase before doing this.",
            buttons: [
                {
                    title: "Logout",
                    callback: function() {
                        forget_wallets();
                        window.location.reload();
                    }
                },
                {
                    title: "Cancel"
                }
            ]
        }
        throw_error(params);
    }

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();
    let { open_currency_selector } = useCurrencySelector();

    // ---- States ----
    let [tab, set_tab] = useState(currencies[parameters.currency_index].is_evm && (currencies[parameters.currency_index].is_erc20 || window.ethereum) ? 0:1);

    // ---- Wallet Connection ----
    const { isConnected, address, chain }  = useAccount();
    const { connect, connectors, isLoading } = useConnect();

    const injected_connector = connectors[0];
    const web3Modal = useWeb3Modal();

    async function connect_wallet() {
        if (window.ethereum) {
            await connect({ connector: injected_connector });
        }
        else {
            await web3Modal.open();
        }
    }

    async function disconnect_wallet() {
        await disconnect(wagmi_config);
    }

    useEffect(()=>{
        trigger_event_crypto("begin_checkout", parameters.hint_amount, parameters.currency_index)
    },[]);

    useEffect(()=>{
        if (isConnected) {
            trigger_event_crypto("connect_wallet", parameters.hint_amount, parameters.currency_index)
        }
    },[isConnected]);

    // ---- Pay Wallet ----
    async function pay_handler(event) {

        event.target.style = "opacity: 0.25; pointer-events: none;";

        let currency = currencies[parameters.currency_index];
        let target_chain = currency.chain_id;
        if (!isConnected) {
            connect_wallet();
        }
        else if (chain.id == target_chain) {
            
            try {

                let tx;

                if (currency.is_erc20) {
                    tx = await writeContract(wagmi_config, {
                        address: currency.contract_address,
                        abi: usdt_abi,
                        functionName: 'transfer',
                        args: [
                            parameters.payin_address,
                            ethers.parseUnits(parameters.hint_amount.toString(), currency.decimals)
                        ],
                        chainId: target_chain
                    })
                }
                else {
                    tx = await sendTransaction(wagmi_config, {
                        to: parameters.payin_address,
                        value: ethers.parseEther(parameters.hint_amount.toString()),
                        chainId: target_chain
                    });    
                }

                set_parameters({type:"pending_evm", payment_id: parameters.payment_id, hint_amount: parameters.hint_amount, currency_index: parameters.currency_index});

            } catch (error) {
                // console.log(error);

                let params = {
                    title: "Oh No!",
                    content: `Something isnt right. Please make sure you have enough ${currencies[parameters.currency_index].symbol} to pay for this transaction.`,
                    buttons: [
                        {
                            title: "Okay"
                        }
                    ]
                }
                throw_error(params);
            }

        }
        else {
            try {
                await switchChain(wagmi_config, {chainId: currencies[parameters.currency_index].chain_id});
            } catch (error) {
                // console.log(error);
            }
        }

        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    // ---- Copy ----
    let [has_copied, set_has_copied] = useState(false);

    useEffect(()=>{

        if (has_copied) {
            setTimeout(function() {
                set_has_copied(false);
            },2000);
        }

    },[has_copied]);

    function copy_address() {
        navigator.clipboard.writeText(parameters.payin_address);
        set_has_copied(true);
    }

    // ---- Deadline ----
    let [increase_time, set_increase_time] = useState(0);
    let interval_ref = useRef(null);

    useEffect(() => {

        if (interval_ref.current != null) {
            clearInterval(interval_ref.current);
            interval_ref.current = null;
        }

        let date_object = new Date(parameters.deadline_at);
        let deadline_timestamp = Math.floor(date_object.getTime() / 1000);
        let time_now = Math.floor(Date.now() / 1000);
        let time_to = deadline_timestamp - time_now;
        set_increase_time(time_to);

        interval_ref.current = setInterval(() => {
            set_increase_time(prev => {
                let new_time = prev; 
                if (new_time > 0) {
                    new_time--;
                }
                else {
                    new_time = 0;
                }
                return new_time;
            });
        }, 1000);

        return () => {
            if (interval_ref.current) {
                clearInterval(interval_ref.current);
            }
        };

    },[parameters]);

    useEffect(() => {

        let inter = setInterval(async function() {

            let payins = await get_order_payins(parameters.payment_id);
            if (payins.length > 0) {
                clearInterval(inter);
                set_parameters({type:"pending_evm", payment_id: parameters.payment_id, hint_amount: parameters.hint_amount, currency_index: parameters.currency_index});
            }

        },10000);

        return () => {
            clearInterval(inter);
        };

    },[parameters]);

    useEffect(() => {

        if (parameters.payment_id) {
            append_utm_without_reloading(`type=crypto&payment_id=${parameters.payment_id}`);
        }

    },[parameters]);

    let [rounded_figure, set_rounded_figure] = useState(parameters.hint_amount);

    useEffect(()=>{
        if (parameters && parameters.hint_amount && parameters.currency_index) {
            set_rounded_figure(
                parseFloat(parameters.hint_amount).toFixed(currencies[parameters.currency_index].sig_figs)
            )
        }
    },[parameters])

    // console.log(parameters.deadline_at);

    return (
        <>
            <div className="purchase_funnel_container">

                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Buy Token</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                    {
                        currencies[parameters.currency_index].is_evm &&
                        <>
                            <div className="small_spacer"></div>
                            <div className="tab_selector">
                                {
                                    (currencies[parameters.currency_index].is_erc20 || window.ethereum) ?
                                    <>
                                        <div className={`tab_inner ${tab==0?"tab_selected":"tab_special_hover"}`} onClick={()=>set_tab(0)}>Pay in Wallet</div>
                                        <div className={`tab_inner ${tab==1?"tab_selected":"tab_special_hover"}`} onClick={()=>set_tab(1)}>Pay Address</div>
                                    </> :
                                    <>
                                        <div className={`tab_inner ${tab==1?"tab_selected":"tab_special_hover"}`} onClick={()=>set_tab(1)}>Pay Address</div>
                                        <div className={`tab_inner ${tab==0?"tab_selected":"tab_special_hover"}`} onClick={()=>set_tab(0)}>Pay in Wallet</div>
                                        
                                    </>
                                }
                                
                            </div>
                        </>
                    }
                </div>

                {
                    tab == 0 &&
                    <>
                        <div className="medium_spacer"></div>
                        {isConnected ?
                            chain.id == currencies[parameters.currency_index].chain_id ?
                            <p>Press pay to complete your purchase in your wallet app. Your JetBolt tokens will automatically be minted after payment.</p> :
                            <p>Switch to the {currencies[parameters.currency_index].blockchain} newtork to complete your purchase.</p> :
                            <p>Connect your Web3 wallet to pay. If you have crypto, but no Web3 wallet you can use the pay address feature.</p>
                        }
                        <div className="medium_spacer"></div>
                        <p>Payment deadline: {format_time_mm_ss(increase_time)}</p>
                        <div className="medium_spacer"></div>
                        <div className="payment_button yellow_button_hover title_font_family" onClick={pay_handler}>{
                            isConnected ?
                                chain.id == currencies[parameters.currency_index].chain_id ?
                                    "Pay Now" :
                                    "Switch Network" :
                                isLoading ?
                                "Connecting..." :
                                "Connect Wallet"
                        }</div>

                        {
                            currencies[parameters.currency_index].is_evm &&
                            <>
                                <div className="medium_spacer"></div>
                                <p className="small_text">Don't have a Wallet? <u className="underlined_text" onClick={()=>set_tab(1)}>Pay Without Connecting</u></p>
                            </>
                        }

                        {
                            isConnected &&
                            <>
                                <div className="medium_spacer"></div>
                                <p className="small_text">Connected address: {shorten_crypto_string(address)}</p>
                                <p className="small_text"><u className="underlined_text" onClick={disconnect_wallet}>Disconnect Wallet</u></p>
                            </>
                        }

                        {
                            destination_mint &&
                            <>
                                {
                                    !isConnected ?
                                    <div className="medium_spacer"></div> :
                                    <div className="small_spacer"></div>
                                }
                                <p className="small_text">Minting to: @{destination_mint}</p>
                                <p className="small_text"><u className="underlined_text" onClick={logout}>Logout</u></p>
                            </>
                        }

                        {
                            !window.ethereum && !(is_os_webview || is_android_webview ) && !is_desk &&
                            <>
                                <div className="medium_spacer"></div>
                                <p className="small_text">Or you can open JetBolt from your wallet app's browser:</p>
                                <div className="small_spacer"></div>
                                {/* <div className="payment_method_container">
                                    <div className="payment_method_inner" style={{width: "863px"}}> */}
                                        {
                                            deep_link_wallets.map((wallet,index)=>(
                                                <div key={index} className="payment_method_item gray_button_hover" onClick={()=>open_wallet_deep_link(wallet.name, parameters.payment_id, currencies[parameters.currency_index].chain_id)}
                                                    style={{marginBottom: "10px"}}
                                                >
                                                    <img className="payment_method_icon" src={wallet.icon}/>
                                                    <div className="payment_method_label">{wallet.name}</div>
                                                </div>
                                            ))
                                        }
{/* 
                                        <div key={deep_link_wallets.length} className="payment_method_item gray_button_hover" onClick={connect_wallet}>
                                            <img className="payment_method_icon" src={wallet_icon}/>
                                            <div className="payment_method_label">More Wallets</div>
                                        </div>
                                         */}
                                    {/* </div>
                                </div> */}
                            </>
                        }

                    </>
                }

                {
                    tab == 1 &&
                    <>
                        <div className="medium_spacer"></div>
                        <p>Send <b className="important_text">{rounded_figure} {currencies[parameters.currency_index].symbol}</b> to the address listed below. Your JetBolt tokens will automatically be minted after payment.</p>
                        
                        <div className="medium_spacer"></div>
                        <div className="payment_input_container">
                            <div className="payment_input_currency_selector">
                                <img className="payment_input_selector_icon" src={wallet_icon}/>
                                <img className="payment_input_selector_extra_icon" src={right_icon}/>
                            </div>
                            <div className="paste_input_button" onClick={copy_address}>
                                <img className="paste_icon" src={paste_icon}/>
                                <div className="paste_text_inner gray_button_hover">Copy</div>
                            </div>
                            <input className="wallet_address_input" value={parameters.payin_address}/>
                        </div>

                        {
                            has_copied &&
                            <>
                                <div className="small_spacer"></div>
                                <p style={{color: "var(--green_text)"}}>Address Copied!</p>
                            </>
                        }

                        <div className="medium_spacer"></div>
                        <p>Payment deadline: {format_time_mm_ss(increase_time)}</p>
                        <div className="medium_spacer"></div>
                        <p className="small_text">Status:</p>
                        <div className="small_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar"></div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text">Listenning for transactions</p>
                        
                        {
                            destination_mint &&
                            <>
                                <div className="medium_spacer"></div>
                                <p>Minting to: @{destination_mint}</p>
                                <p><u className="underlined_text" onClick={logout}>Logout</u></p>
                            </>
                        }

                        <div className="medium_spacer"></div>
                        <p className="small_text" style={{color: "gray"}}>1. Only pay in {currencies[parameters.currency_index].symbol} on the {currencies[parameters.currency_index].blockchain} blockchain. Funds sent on the wrong network cannot be recovered.</p>
                        <div className="small_spacer"></div>
                        <p className="small_text" style={{color: "gray"}}>2. Only pay once to this address. We will automatically accept any amount ranging from US$ 10 to US$ 10,000 in equivalent {currencies[parameters.currency_index].symbol}.</p>
                    </>
                }

                <div className="small_spacer"></div>
                <p className="smallest_text grayed_text">Disclaimer: JetBolt is not a secure investment. The JetBolt team does not guarantee that the tokens hold intrinsic value, will appreciate, or maintain any value after the presale. By purchasing, you acknowledge buying JetBolt tokens for ecosystem use, not as an investment, and you accept the risk of losing the entire value of your purchase.</p>

            </div>
        </>
    );

}

function Pay_EVM({ close, parameters, set_parameters }) {
    return (
        <Wagmi_Client>
            <Pay_EVM_Component close={close} parameters={parameters} set_parameters={set_parameters}/>
        </Wagmi_Client>
    )
}

export default Pay_EVM;