import axios from "axios";
import { ethers } from "ethers";
import config from "../configs/config.json";
import contract_addresses from "../configs/contract_addresses.json"
import { short_format_number } from "../services/helper_functions";

import mint_icon from "../assets/icons/mint.png";
import transfer_icon from "../assets/icons/transfer.png";
import add_friend_icon from "../assets/icons/add_friend.png";
import stake_icon from "../assets/icons/stake.png";
import unstake_icon from "../assets/icons/unstake.png";
import claim_icon from "../assets/icons/claim.png";
import create_icon from "../assets/icons/create.png";
import rename_icon from "../assets/icons/rename.png";
import unfriend_icon from "../assets/icons/unfriend.png";
import favorite_icon from "../assets/icons/favorite.png";
import unfavorite_icon from "../assets/icons/unfavorite.png";
import left_icon from "../assets/icons/left.png";
import burn_tokens_icon from "../assets/icons/burn_tokens.png"
import banned_icon from "../assets/icons/banned.png"
import unbanned_icon from "../assets/icons/unbanned.png"

let icon_map = {
    mint_tokens: mint_icon,
    stake_tokens: stake_icon,
    unstake_tokens: unstake_icon,
    claim_unstaked_tokens: unstake_icon,
    claim_tokens: claim_icon,
    reward_friend: claim_icon,
    transfer: transfer_icon,
    transfer_from: transfer_icon,
    set_username: rename_icon,
    add_friend: add_friend_icon,
    accept_friend: add_friend_icon,
    remove_friend: unfriend_icon,
    decline_friend: unfriend_icon,
    favorite: favorite_icon,
    unfavorite: unfavorite_icon,
    burn_tokens: burn_tokens_icon,
    banned: banned_icon,
    unbanned: unbanned_icon
}

let event_abi = ["event Generic_Event(address indexed concerned_party, string function_name, uint256 balance_change, bool incremented_balance, address indexed tokens_from, address indexed tokens_to, string extra, uint64 timestamp)"];
let event_signature = "Generic_Event(address,string,uint256,bool,address,address,string,uint64)";
let event_interface = new ethers.Interface(event_abi);

let contract_addresses_array = [];
for (let [contract_name, contract_address] of Object.entries(contract_addresses)) {
    contract_addresses_array.push(contract_address);
}

async function parse_tx_log(hash) {
    let logs = await axios.get(`${config.block_explorer_api}/api/v2/transactions/${hash}/logs`);
    
    for (let y = 0; y < logs.data.items.length; y++) {
        let log = logs.data.items[y];
        if (log.address.hash == contract_addresses.Logs) {
            let decoded_data = event_interface.decodeEventLog(event_signature, log.data);
            let tx = {
                function_name: decoded_data.function_name,
                icon: icon_map[decoded_data.function_name],
                extra: decoded_data.balance_change == 0n ? decoded_data.extra : `${decoded_data.incremented_balance?"+":"-"}${short_format_number(ethers.formatUnits(decoded_data.balance_change))}`,
                date: Number(decoded_data.timestamp)
            }
            return tx;
        }
    }
}

export async function get_transaction_history_slow(address) {

    let txs = [];

    let response = await axios.get(`${config.block_explorer_api}/api/v2/addresses/${address}/transactions`);
    let transactions = response.data.items;

    for (let i = 0; i < transactions.length; i++) {
        let transaction = transactions[i];
        let hash = transaction.hash;
        let contract_address_to = transaction.to.hash;
        let contract_address_from = transaction.to.hash;
        if (contract_addresses_array.includes(contract_address_to) || contract_addresses_array.includes(contract_address_from) ) {
            let tx = await parse_tx_log(hash);
            if (tx) { txs.push (tx); }
        } 
    }

    response = await axios.get(`${config.block_explorer_api}/api/v2/addresses/${address}/token-transfers`);
    transactions = response.data.items;

    for (let i = 0; i < transactions.length; i++) {
        let transaction = transactions[i];
        let hash = transaction.tx_hash;
        let contract_address = transaction.token.address;
        if (contract_addresses.Token == contract_address) {
            let tx = await parse_tx_log(hash);
            if (tx) { txs.push (tx); }
        }
    }

    txs.sort((a, b) => b.date - a.date);
    return txs;
}

export async function get_transaction_history(address) {
    const responses = await Promise.all([
        axios.get(`${config.block_explorer_api}/api/v2/addresses/${address}/transactions`),
        axios.get(`${config.block_explorer_api}/api/v2/addresses/${address}/token-transfers`)
    ]);

    const transactionPromises = [];

    responses.forEach((response, index) => {
        response.data.items.forEach(transaction => {
            if (index === 0) {
                const { hash, to: { hash: contract_address_to }, from: { hash: contract_address_from } } = transaction;
                if (contract_addresses_array.includes(contract_address_to) || contract_addresses_array.includes(contract_address_from)) {
                    transactionPromises.push(parse_tx_log(hash));
                }
            } else {
                const { tx_hash: hash, token: { address: contract_address } } = transaction;
                if (contract_addresses.Token === contract_address) {
                    transactionPromises.push(parse_tx_log(hash));
                }
            }
        });
    });

    let txs = (await Promise.all(transactionPromises)).filter(tx => tx);
    txs.sort((a, b) => b.date - a.date);

    return txs;
}