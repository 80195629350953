import time_00 from "../../assets/time_icons/time_00.png";
import time_01 from "../../assets/time_icons/time_01.png";
import time_02 from "../../assets/time_icons/time_02.png";
import time_03 from "../../assets/time_icons/time_03.png";
import time_04 from "../../assets/time_icons/time_04.png";
import time_05 from "../../assets/time_icons/time_05.png";
import time_06 from "../../assets/time_icons/time_06.png";
import time_07 from "../../assets/time_icons/time_07.png";
import time_08 from "../../assets/time_icons/time_08.png";
import time_09 from "../../assets/time_icons/time_09.png";
import time_10 from "../../assets/time_icons/time_10.png";
import time_11 from "../../assets/time_icons/time_11.png";
import time_12 from "../../assets/time_icons/time_12.png";
import time_13 from "../../assets/time_icons/time_13.png";
import time_14 from "../../assets/time_icons/time_14.png";
import time_15 from "../../assets/time_icons/time_15.png";
import time_16 from "../../assets/time_icons/time_16.png";
import time_17 from "../../assets/time_icons/time_17.png";
import time_18 from "../../assets/time_icons/time_18.png";
import time_19 from "../../assets/time_icons/time_19.png";
import time_20 from "../../assets/time_icons/time_20.png";
import time_21 from "../../assets/time_icons/time_21.png";
import time_22 from "../../assets/time_icons/time_22.png";
import time_23 from "../../assets/time_icons/time_23.png";
import time_24 from "../../assets/time_icons/time_24.png";

export let time_icons = [
  time_00,
  time_01,
  time_02,
  time_03,
  time_04,
  time_05,
  time_06,
  time_07,
  time_08,
  time_09,
  time_10,
  time_11,
  time_12,
  time_13,
  time_14,
  time_15,
  time_16,
  time_17,
  time_18,
  time_19,
  time_20,
  time_21,
  time_22,
  time_23,
  time_24,
];

