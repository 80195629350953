import axios from 'axios';
import config from "../configs/config.json";

// "https://api.jetpaw.co"

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const custom_axios = axios.create({
    baseURL: config.api_endpoint,
});

const retry_request = async (error, retry_count = 3) => {
    if (retry_count <= 0) {
      throw new Error('Max retries reached');
    }
  
    await sleep(1000);
  
    try {
      const config = {
        ...error.config,
        retry: true,
      };
      return await custom_axios(config);
    } catch (err) {
      if (err.response && err.response.status !== 429) {
        return retry_request(err, retry_count - 1);
      }
      throw err;
    }
  };
  
custom_axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
        if (error.response && error.response.status === 429) {
            alert('You are making requests too quickly. Please slow down.');
        }
        // if (error.response && error.response.status !== 429 && !error.config.retry) {
        //     return retry_request(error);
        // }
        return Promise.reject(error);
    }
);

export async function update_exchange_rate() {
    // try {
    //     let response = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=ethereum,matic-network,solana,bitcoin&vs_currencies=usd`);
    //     let exchange_rates = {
    //         USD: 1,
    //         ETH: response.data.ethereum.usd,
    //         BTC: response.data.bitcoin.usd,
    //         SOL: response.data.solana.usd,
    //         MATIC: response.data['matic-network'].usd,
    //         USDT: 1,
    //         USDC: 1
    //     };
    //     return exchange_rates;
    // } catch (error) {
    //     return {
    //         USD: 1,
    //         ETH: 3000,
    //         BTC: 70000,
    //         SOL: 200,
    //         MATIC: 0.7,
    //         USDT: 1,
    //         USDC: 1
    //     };   
    // }

    let response = await custom_axios.get(`/minting/get_exchange_rates`);
    return response.data;
}

export async function create_customer(secret_code, hint_amount, currency_index, sources) {
    let response = await custom_axios.post(`/minting/create_customer`, { secret_code, hint_amount, currency_index, sources });
    return response.data;
}

export async function create_payment(customer_id, hint_amount, currency_index) {
    let response = await custom_axios.post('/minting/create_payment', { customer_id, hint_amount, currency_index });
    return response.data;
}

export async function has_unclaimed_tokens(customer_id) {
    let response = await custom_axios.post('/minting/has_unclaimed_tokens', { customer_id });
    return response.data;
}

export async function add_destination_address(customer_id, secret_code, destination_address) {
    let response = await custom_axios.post('/minting/add_destination_address', { customer_id, secret_code, destination_address });
    return response.data;
}

export async function add_sources(customer_id, sources) {
    let response = await custom_axios.post('/minting/add_sources', { customer_id, sources });
    return response.data;
}

export async function find_customer(customer_id = null, destination_address = null) {
    let response = await custom_axios.post('/minting/find_customer', { customer_id, destination_address });
    return response.data;
}

export async function find_customer_orders(customer_id) {
    let response = await custom_axios.post('/minting/find_customer_orders', { customer_id });
    return response.data;
}

export async function get_order_payins(payment_id) {
    let response = await custom_axios.post('/minting/get_order_payins', { payment_id });
    return response.data;
}

export async function is_order_completed(customer_id, payment_id) {
    let response = await custom_axios.post('/minting/is_order_completed', { customer_id, payment_id });
    return response.data;
}

export async function pay_card(payment_method_id, amount, customer_id) {
    let response = await custom_axios.post('/minting/pay_card', { payment_method_id, amount, customer_id });
    return response.data;
}

export async function get_nonce(wallet_address) {
    let response = await custom_axios.post('/notifications/get_nonce', { wallet_address });
    return response.data;
}

export async function update_email_preferences(wallet_address, email_address, utc_index, status, nonce, signature) {
    let response = await custom_axios.post('/notifications/update_email_preferences', { wallet_address, email_address, utc_index, status, nonce, signature });
    return response.data;
}

export async function get_email_preferences(wallet_address) {
    let response = await custom_axios.post('/notifications/get_email_preferences', { wallet_address });
    return response.data;
}

export async function make_inquiry(email_address, customer_id, wallet_address, subject, message) {
    let response = await custom_axios.post('/contact/make_inquiry', { email_address, customer_id, wallet_address, subject, message });
    return response.data;
}

export async function check_spend(customer_id, currency_symbol) {
    let response = await custom_axios.post('/minting/check_spend', { customer_id, currency_symbol });
    return response.data;
}

export async function get_order_info(customer_id, payment_id) {
    let response = await custom_axios.post('/minting/get_order_info', { customer_id, payment_id });
    return response.data;
}


export async function pay_wert(amount, customer_id) {
    let response = await custom_axios.post('/minting/pay_wert', { amount, customer_id });
    return response.data;
}

export async function wert_status(customer_id, order_id, hash) {
    let response = await custom_axios.post('/minting/wert_status', { customer_id, order_id, hash });
    return response.data;
}

export async function get_total_sales() {
    let response = await custom_axios.get('/minting/get_total_sales');
    return response.data;
}