// ---- Images ----
import token_image from "../assets/images/token.png";
import token_overlay_image from "../assets/images/token_overlay.png";

// ---- Modals ----
import { usePurchaseFunnel } from "../modals/purchase_funnel";


function Earn_Item({index}) {
    return (
        <div className={`earn_item_container ${index==3?"no_right_margin":""} ${index==3?"clip_top_right":""} ${index==0?"clip_bottom_left":""}`}>

            {/* <div className="hook_left_light"></div> */}
            {/* <div className="hook_left_light hook_transform_top_right"></div> */}
            {/* <div className="hook_left_light hook_transform_bottom_right"></div> */}
            {/* <div className="hook_left_light hook_transform_bottom_left"></div> */}
            
            <div className="earn_icon_container">
                
            </div>
            <div className="medium_spacer"></div>
            <h3 className="central_text_indicator">Buy Token</h3>
            <div className="medium_spacer"></div>
            <p className="central_text_indicator">Purchase some token to get started</p>
            <div className="large_spacer"></div>
            <div className={`earn_inner_hide ${index==3?"clip_top_right":""} ${index==0?"clip_bottom_left":""}`}></div>
        </div>
    )
}
/*
function Earn() {
    return (
        <>
            <div className="large_spacer"></div>
            <div className="page_container">
                <div className="safe_inner">
                    <h1 className="central_text_indicator">How to Earn</h1>
                    <div className="large_spacer"></div>
                    {
                        [1,2,3,4].map((item,index)=>(
                            <Earn_Item key={index} index={index}/>
                        ))
                    }
                </div>
            </div>
            <div className="large_spacer"></div>
            <div className="center_text_container">
                <p className="large_text">Some explanation text in large font and really freaking simple. Should be only this long.</p>
                <div className="large_spacer"></div>
                <div className="inline_button_left">Buy Now</div>
                <div className="inline_button_right">Whitepaper</div>
            </div>
            <div className="large_spacer"></div>
        </>
        
    )
}
*/

function Earn({ hard_stop, geo_error }) {

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function buy_direct() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "buy_direct"});
        }
    }

    return (
        <div className="page_container">
            <div className="safe_inner">
                <div className="hide_overflow_container">
                    <div className="radial_glow_extra_top_blue right"/>
                </div>
                <div className="extra_connector">
                    <div className="horitzontal_line"></div>
                    <div className="left_box_decor"></div>
                    <div className="right_box_decor"></div>
                    <div className="center_box_decor"></div>
                </div>
                <div className="trapeze_container_bottom">
                    
                    <div className="earn_description_container">
                        <h1 className="title_font_family">JetBolt Staking</h1>
                        <div className="medium_spacer"></div>
                        <p className="large_text">JetBolt has created an awesome new staking system that rewards users who participate the most.</p>
                        <div className="medium_spacer"></div>
                        <div className="step_container">
                            <div className="step_indicator_container">1</div>
                            <div className="step_text_container">Purchase JetBolt tokens</div>
                        </div>
                        <div className="step_container">
                            <div className="step_indicator_container">2</div>
                            <div className="step_text_container">Stake your tokens</div>
                        </div>
                        <div className="step_container">
                            <div className="step_indicator_container">3</div>
                            <div className="step_text_container">Login daily and claim rewards</div>
                        </div>
                        <div className="step_container">
                            <div className="step_indicator_container extra">+</div>
                            <div className="step_text_container">Connect with friends and increase rewards</div>
                        </div>
                        <div className="small_spacer"></div>
                        <div className={`inline_button_left blue_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>Buy Now</div>
                        <div className="inline_button_right gray_button_hover" onClick={()=>window.location="/profile"}>Launch App</div>

                        
                    </div>
                    <div className="earn_container">

                        <div className="token_ill_container">
                            <div className="lined_background radial_mask">
                                
                            </div>

                            <div className="full_inner">
                                <div className="token_ill_inner_container">
                                    <div className="radial_glow_extra_coin"></div>

                                    <div className="tokens_container">
                                        <img className="token_image" src={token_image}/>
                                        <img className="token_overlay_image" src={token_overlay_image}/>
                                    </div>
                                    
                                   
                                    
                                    <div className="hook_left_blue"></div>
                                    <div className="hook_left_blue hook_transform_top_right"></div>
                                    <div className="hook_left_blue hook_transform_bottom_right"></div>
                                    <div className="hook_left_blue hook_transform_bottom_left"></div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div className="middle_line"></div>
                    {/* <div className="top_box_decor"></div> */}
                    <div className="bottom_box_decor"></div>
                </div>
                <div className="large_spacer"></div>
               
            </div>
        </div>
    )
}

export default Earn;