import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Icons ----
import right_icon from "../assets/icons/right.png";

// ---- Jazzicon ----
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

// ---- Components ----
import SEO from "../components/seo";
import App_Nav from "../components/app_nav";

// ---- Modals ----
import { useAppModals } from "../modals/app_modals";

// ---- Services -----
import { short_date, shorten_crypto_string, short_format_number } from "../services/helper_functions";
// import { get_all_news } from "../services/news_api";
import {  get_articles, get_tokens } from "../services/contract_functions/news";
import { get_leaderboard } from "../services/contract_functions/logs";
import { get_top_100, get_trending } from "../services/coingecko_api";

function Explore_Page() {

    // ---- Hooks ----
    let [tab, set_tab] = useState(0);

    // ---- Modals ----
    let { open_app_modal, close_open_app_modal } = useAppModals();

    const { article_address, title_string } = useParams();

    let [news_data, set_news_data] = useState([]);
    let [top_tokens, set_top_tokens] = useState([]);
    let [trending_tokens, set_trending_tokens] = useState([]);
    let [top_profiles, set_top_profiles] = useState([]);

    useEffect(() => {

        if (article_address) {
            if (title_string == "currency") {
                // open_cryptocurrency(article_address);
                reset_url();
            }
            else {
                open_article(article_address, title_string);
            }
            
        }

    },[article_address]);

    async function load_articles() {

        try {
            let articles = await get_articles(0, 20);

            let cleaned_news = [];

            for (let i = 0; i < articles.length; i++) {
                let article = articles[i];
                let article_keys = ["article_address", "title", "description", "news_url", "image_url", "content", "source_name", "sentiment", "tickers", "date"];
                let cleaned = {};
                for (let x = 0; x < article_keys.length; x++) {
                    let key = article_keys[x];
                    cleaned[key] = article[key];
                }
                cleaned.path = article.title.toLowerCase().replaceAll(" ","-");
                cleaned_news.push(cleaned);
            }

            set_news_data(cleaned_news);
        } catch (error) {}

    }

    async function load_tokens() {
        try {
            
            let top = await get_top_100();

            let items = [];

            for (let i = 0; i < 10; i++) {
                let raw = top[i];
                let item = {
                    icon_url: raw.image,
                    symbol: raw.symbol,
                    usd_price: raw.current_price,
                    price_change_percentage_24h: raw.price_change_percentage_24h
                };
                items.push(item);
            }

            set_top_tokens(items);

            let trending = await get_trending();
            trending = trending.coins;

            items = [];

            for (let i = 0; i < trending.length; i++) {
                let raw = trending[i].item;
                let item = {
                    icon_url: raw.thumb,
                    symbol: raw.symbol,
                    usd_price: raw.price_btc * top[0].current_price,
                    price_change_percentage_24h: raw.data.price_change_percentage_24h.usd
                };
                items.push(item);
            }

            set_trending_tokens(items);

            // let tokens = await get_tokens();
            // set_top_tokens(tokens[0]);
            // set_trending_tokens(tokens[1]);
        } catch (error) {}
    }

    async function load_leaderboard() {
        try {
            
            let leaderboard = await get_leaderboard();
            let filtered_leaderboard = [];
            for (let i = 0; i < leaderboard.length; i++) {
                let user = leaderboard[i];
                if (user.user_address != "0x0000000000000000000000000000000000000000" && user.balance != 0n) {
                    filtered_leaderboard.push({
                        user_address: user.user_address,
                        balance: user.balance,
                        username: user.username,
                    });
                }
            }

            filtered_leaderboard.sort((a, b) => {
                return Number(b.balance) - Number(a.balance);
            });

            set_top_profiles(filtered_leaderboard);

        } catch (error) {
            // console.log(error)
        }
    }

    useEffect(() => {

        load_articles();
        load_tokens();
        load_leaderboard();

    },[]);

    // ---- Functions ----
    function reset_url() {
        let current_url = new URL(window.location.href);
        let new_url = `${current_url.origin}/explore`;
        window.history.pushState({ path: new_url }, '', new_url);
    }

    function open_article(article_address, title_string, index=-1) {
        let params = {type: "article", article_address, close_callback:reset_url};
        if (index >= 0) {
            let full_data = news_data[index];
            params.full_data = full_data;
        }
        open_app_modal(params);
        let current_url = new URL(window.location.href);
        let new_url = `${current_url.origin}/explore/${article_address}/${encodeURI(title_string)}`;
        window.history.pushState({ path: new_url }, '', new_url);
    }

    function open_cryptocurrency(index,is_trending) {
        let full_data = top_tokens[index];
        if (is_trending) {
            full_data = trending_tokens[index];
        }
        let params = {type: "cryptocurrency", full_data, close_callback:reset_url};
        open_app_modal(params);
        let current_url = new URL(window.location.href);
        let new_url = `${current_url.origin}/explore/${full_data.symbol}/currency`;
        window.history.pushState({ path: new_url }, '', new_url);
    }

    function open_profile(wallet_address) {
        open_app_modal({type: "profile", wallet_address })
    }

    return (
        <>
            <SEO parameters={
                {
                    title: "JetBolt Explore",
                    description: "Explore the latest cryptocurrency news and trends curated for your by cutting edge Artificial Intelligence (AI).",
                    url: "https://jetbolt.io/explore"
                }
            }/>
            <App_Nav selected_index={1} selected_index_mobile={4}>

                <div className="small_spacer"></div>

                <div className="tab_selector">
                    <div className={`tab_inner large ${tab==0?"tab_selected":"tab_special_hover"}`} onClick={()=>set_tab(0)}>News</div>
                    <div className={`tab_inner large ${tab==1?"tab_selected":"tab_special_hover"}`} onClick={()=>set_tab(1)}>Leaderboard</div>
                </div>

                {
                    tab==0 && 
                    <>

                    <div className="small_spacer"></div>
                    <div className="title_relative_container">
                        <h2 className="title_font_family">Top Coins</h2>
                    </div>
                    <div className="medium_spacer"></div>

                    <div className="horizontal_ticker_container">
                        <div className="horitzontal_ticker_inner">
                        {   
                            top_tokens.slice(0,10).map((item,index)=>(
                                <>
                                {
                                    item.symbol && 
                                    // onClick={()=>open_cryptocurrency(index,false)}> gray_button_hover
                                    <div key={index} className="ticker_container"> 
                                        <img src={item.icon_url} className="ticker_item_container"/>
                                        <div className="ticker_text_indicator">{item.symbol.toUpperCase()} <a className="grayed_text">${item.usd_price}</a></div>
                                        {/* { item.percentage_price_changes.length > 0 &&
                                            <div className="ticker_percentage_change_indicator"><a style={{color: parseFloat(item.percentage_price_changes[0]) < 0 ? "var(--orange_text)":"var(--green_text)"}}>{parseFloat(item.percentage_price_changes[0]).toFixed(2)}%</a></div>
                                        } */}
                                        <div className="ticker_percentage_change_indicator"><a style={{color: parseFloat(item.price_change_percentage_24h) < 0 ? "var(--orange_text)":"var(--green_text)"}}>{parseFloat(item.price_change_percentage_24h).toFixed(2)}%</a></div>
                                    </div>
                                }
                                </>
                            ))
                        }
                        </div>
                    </div>
                    <div className="medium_spacer"></div>
                    <div className="title_relative_container">
                        <h2 className="title_font_family">Trending Coins</h2>
                    </div>
                    <div className="medium_spacer"></div>

                    <div className="horizontal_ticker_container">
                        <div className="horitzontal_ticker_inner" style={{width: `${(trending_tokens.length*220-20)}px`}}>
                        {
                            trending_tokens.map((item,index)=>(
                                <>
                                {
                                    item.symbol &&
                                    // onClick={()=>open_cryptocurrency(index,true)}> gray_button_hover
                                    <div key={index} className="ticker_container">
                                        <img src={item.icon_url} className="ticker_item_container"/>
                                        <div className="ticker_text_indicator">{item.symbol.toUpperCase()} <a className="grayed_text">${item.usd_price}</a></div>
                                        {/* { item.percentage_price_changes.length > 0 &&
                                            <div className="ticker_percentage_change_indicator"><a style={{color: parseFloat(item.percentage_price_changes[0]) < 0 ? "var(--orange_text)":"var(--green_text)"}}>{parseFloat(item.percentage_price_changes[0]).toFixed(2)}%</a></div>
                                        } */}
                                        <div className="ticker_percentage_change_indicator"><a style={{color: parseFloat(item.price_change_percentage_24h) < 0 ? "var(--orange_text)":"var(--green_text)"}}>{parseFloat(item.price_change_percentage_24h).toFixed(2)}%</a></div>
                                    </div>
                                }
                                </>
                            ))
                        }
                        </div>
                    </div>
                    <div className="medium_spacer"></div>
{/* 
                <div className="title_relative_container">
                    <h1 className="title_font_family">Trending NFTs</h1>
                </div>
                <div className="medium_spacer"></div>

                <div className="horizontal_ticker_container">
                    <div className="horitzontal_ticker_inner" style={{width: `${(trending_tokens.nfts.length*210-10)}px`}}>
                    {
                        trending_tokens.nfts.map((item,index)=>(
                            <div key={index} className="ticker_container">
                                <img src={item.thumb} className="ticker_item_container"/>
                                <div className="ticker_text_indicator">{item.native_currency_symbol.toUpperCase()} <a className="grayed_text">${item.floor_price_in_native_currency}</a></div>
                                <div className="ticker_percentage_change_indicator"><a style={{color: item.floor_price_24h_percentage_change < 0 ? "var(--orange_text)":"var(--green_text)"}}>{item.floor_price_24h_percentage_change.toFixed(2)}%</a></div>
                            </div>
                        ))
                    }
                    </div>
                </div>
                <div className="medium_spacer"></div> */}

                    <div className="title_relative_container">
                        <h2 className="title_font_family">Latest News</h2>
                    </div>
                    <div className="medium_spacer"></div>

                
                    <div className="news_grid">
                    {
                        news_data.map((item,index)=> (
                            <div key={index} className={`news_item_container gray_button_hover ${index%2!=0?"right":""}`}
                                onClick={()=>open_article(item.article_address, item.path, index)}
                            >
                                <div className="news_image_container">
                                    <img className="news_image" src={item.image_url}/>
                                </div>
                                
                                <div className="small_spacer"></div>
                                <div className="news_text_container">
                                    <h2 className="three_lines_text">{item.title}</h2>
                                    <p className="three_lines_text">{item.description}</p>
                                    <div className="small_spacer"></div>
                                    <p>Date: {short_date(Number(item.date))}</p>
                                    <p>Sentiment: <a style={{color: item.sentiment == "Negative"?"var(--orange_text)":item.sentiment == "Positive"?"var(--green_text)":"var(--purple_text)"}}>{item.sentiment}</a></p>
                                    <p>Source: {item.source_name}</p>
                                </div>
                                <div className="small_spacer"></div>
                            </div>
                        ))
                    }
                    </div>
                    </>
                }
                {
                    tab==1 &&
                    <>
                        <div className="small_spacer"></div>
                        {
                            top_profiles.map((item,index)=>(
                                <div key={index} className="friend_list_item gray_button_hover" onClick={()=>open_profile(item.user_address)}>
                                    <div className="live_sales_icon">
                                        <Jazzicon diameter={30} seed={jsNumberForAddress(item.user_address)}/>
                                    </div>
                                    <div className="live_sales_amount_label">{item.username ? item.username : shorten_crypto_string(item.user_address)} <a className="grayed_text">({short_format_number(ethers.formatUnits(item.balance))} JetBolt)</a></div>
                                    <div className="decline_button">
                                        <img className="inner_icon" src={right_icon}/>
                                    </div>
                                </div>
                            ))
                        }
                    </>
                }

                
                <div className="medium_spacer"></div>
            </App_Nav>

        </>
    )
}

export default Explore_Page;