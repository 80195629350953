
// ---- Icons ----

import metamask_icon from "../icons/metamask.png";
import trustwallet_icon from "../icons/trustwallet.png";
import coinbase_icon from "../icons/coinbase.png";
import okx_icon from "../icons/okx.png";
import bitget_icon from "../icons/bitget.png";
import binance_wallet_icon from "../icons/binance_wallet.png";

import customer_instance from "../../services/wallet/customer";

export let deep_link_wallets = [
    {
        name: "Metamask",
        icon: metamask_icon,
    },
    {
        name: "OKX Wallet",
        icon: okx_icon
    },
    {
        name: "Coinbase",
        icon: coinbase_icon,
    },
    {
        name: "Trust Wallet",
        icon: trustwallet_icon,
    },
    {
        name: "Bitget Wallet",
        icon: bitget_icon,
    },
    {
        name: "Binance Wallet",
        icon: binance_wallet_icon,
    }
];

const getDeepLink_Binance = (url, chainId) => {
    const base = 'bnc://app.binance.com/mp/app'
    const appId = 'yFK5FCqYprrXDiVFbhyRx7'
  
    const startPagePath = window.btoa('/pages/browser/index')
    const startPageQuery = window.btoa(`url=${url}&defaultChainId=${chainId}`)
    const deeplink = `${base}?appId=${appId}&startPagePath=${startPagePath}&startPageQuery=${startPageQuery}`
    const dp = window.btoa(deeplink)
    const http = `https://app.binance.com/en/download?_dp=${dp}`
    // return { http, bnc: deeplink }
    return deeplink;
}
  

export function open_wallet_deep_link(wallet_name, payment_id, chain_id) {

    let url = new URL(window.location.href);
    let url_params = url.searchParams;

    let site = `https://jetbolt.io/?load_from=${customer_instance.get_hash()}$delim$${payment_id}`;

    // if (parameters.extra) {
    //     site += parameters.extra;
    // }

    // if (url_params.get("referral")) {
    //     let referral = url_params.get("referral");
    //     site += `&referral=${referral}`;
    // }

    // if (url_params.get("source")) {
    //     let source = url_params.get("source");
    //     site += `&source=${source}`;
    // }

    if (wallet_name == "Metamask") {
        window.open(`https://metamask.app.link/dapp/${site.replace("https://","")}`)
    }
    if (wallet_name == "OKX Wallet") {
        window.open("https://www.okx.com/download?deeplink=" + encodeURIComponent("okx://wallet/dapp/url?dappUrl=" + encodeURIComponent(site)));
    }
    if (wallet_name == "Coinbase") {
        window.open(`https://go.cb-w.com/dapp?cb_url=${site}`);
    }
    if (wallet_name == "Trust Wallet") {
        window.open(`https://link.trustwallet.com/open_url?url=${site}`);
    }
    if (wallet_name == "Bitget Wallet") {
        window.open(`https://bkcode.vip?action=dapp&url=${encodeURIComponent(site)}`);
    }
    if (wallet_name == "Binance Wallet") {
        window.open(getDeepLink_Binance(site, chain_id));
    }

}