import React, { createContext, useContext, useState, useEffect } from "react";

// ---- Icons ----
import right_icon from "../assets/icons/right.png";
import down_icon from "../assets/icons/down.png";

// ---- Data ----
import { languages } from "../assets/data/languages";

// ---- Services ----
import { initialize_translator, set_translation } from "../services/language";

const ModalContext = createContext();

export function useLanguageSelector() {
    return useContext(ModalContext);
}

export function Language_Selector({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);

    let open_language_selector = (modal_parameters) => {
        document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
    }

    let close_language_selector = () => {
        set_animate_out(true);
        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
    }

    let context_value = {
        open_language_selector,
        close_language_selector
    }

    async function choose_language(language) {
        console.log("HERE")
        await initialize_translator();
        await set_translation(language.iso_code);
        close_language_selector();
    }

    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">
                    <div className="outer_modal_click" onClick={close_language_selector}></div>
                    <div className={`language_selector_container white_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="stick_text">
                            <div className="medium_spacer"></div>
                            <div style={{position: "relative"}}>
                                <h2 className="central_text_indicator title_font_family">Select Language</h2>
                                <img className="back_icon opacity_hover" style={{left: "20px"}} src={down_icon} onClick={close_language_selector}/>
                            </div>
                            <div className="medium_spacer"></div>
                            <p className="error_text_content">Select your preferred language from the list below</p>
                            <div className="medium_spacer"></div>
                        </div>

                        <div className="language_list_container notranslate">
                            {
                                languages.map((language, index) => (
                                    <div className="language_item gray_button_hover" onClick={()=>choose_language(language)}>
                                        <img className="language_icon" src={language.icon}/>
                                        <div className="language_amount_label">{language.native_name}</div>
                                        <img className="language_arrow_right" src={right_icon}/>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="small_spacer"></div>
                    </div>
                </div>
            }
        </ModalContext.Provider>
    )

}