import React, { useRef, useEffect, useState } from 'react';

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Components ----
import SEO from "../components/seo";
import App_Nav from "../components/app_nav";
import Loading_Wheel from "../components/loading";

// ---- Jazzicon ----
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';

// ---- Icons ----
import more_icon from "../assets/icons/more.png";
import history_icon from "../assets/icons/history.png";
import qr_scan_black_icon from "../assets/icons/qr_scan_black.png";
import settings_icon from "../assets/icons/settings.png";

// ---- Chart.js ----
import Chart from 'chart.js/auto';

// ---- Services ----
import useToken from "../services/wallet/useToken";
import { get_profile } from "../services/contract_functions/friends";
import { get_profile_statistics, stake_tokens } from "../services/contract_functions/token";
import { get_chart_data } from "../services/contract_functions/logs";
import { shorten_crypto_string, get_labels_for_time_scale, short_format_number } from "../services/helper_functions";

// ---- Modals ----
import { useAppModals } from "../modals/app_modals";

// ---- Config ----
import config from "../configs/config.json";

function Profile_Page() {

    // ---- Modals ----
    let { open_app_modal } = useAppModals();

    function receive_tokens() {
        open_app_modal({type:"receive_tokens"});
    }

    function send_tokens() {
        open_app_modal({type:"send_tokens"});
    }

    function qr_scanner() {
        open_app_modal({type:"qr_scanner"});
    }

    let chart_ref = useRef(null);
    let chart_instance = useRef(null);

    // ---- Plot Graph ----

    let [data, set_data] = useState({});

    useEffect(() => {

        if (chart_instance.current) {
            chart_instance.current.destroy();
        }

        let ctx = chart_ref.current.getContext('2d');

        chart_instance.current = new Chart(ctx, {
            type: 'bar',
            data: {
                ...data,
            },
            options: {
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        align: 'center',
                        labels: {
                            color: 'white',
                            padding: 20,
                            paddingTop: 50,
                            font: {
                                size: 14,
                                family: 'Roboto Mono, monospace',
                                weight: 'bold'
                            }
                        },
                        usePointStyle: true,
                        boxWidth: 20,
                        boxHeight: 10
                    },
                },
                maintainAspectRatio: false,
                responsive: true,
                scales: {
                    x: {
                        ticks: {
                            color: 'gray'
                        },
                        grid: {
                            color: 'rgba(80, 80, 80, 0.25)',
                            borderColor: 'rgba(80, 80, 80, 0.25)',
                        }
                    },
                    y: {
                        ticks: {
                            color: 'gray',
                        },
                        grid: {
                            color: 'rgba(80, 80, 80, 0.25)',
                            borderColor: 'rgba(80, 80, 80, 0.25)',
                        }
                    }
                },
            }
        });

    },[data]);

    // ---- Hooks ----
    let [stats, set_stats] = useState({
        available_tokens: 0n,
        staked_tokens: 0n
    });

    let [profile, set_profile] = useState({
        friends_count: 0n,
        favorites_count: 0n,
        favorited_by_count: 0n
    });

    // ---- Token ----
    let token = useToken();
    let [is_loading, set_is_loading] = useState(true);
    
    // ---- On Load ----
    useEffect(()=> {

        if (token && token.wallet_address) {

            (async function() {

                // ---- Load Profile Data ----
                let stats_keys = ["available_tokens", "staked_tokens", "rewards_earned", "rewards_from_friends", "rewards_to_friends"];
                let profile_keys = ["wallet_address", "username", "friends_count", "favorites_count", "favorited_by_count"];
                
                let stats = {};
                let profile = {};

                let stats_raw = await get_profile_statistics(token.wallet_address);
                let profile_raw = await get_profile(token.wallet_address);

                for (let key of stats_keys) {
                    stats[key] = stats_raw[key];
                }

                set_stats(stats);

                for (let key of profile_keys) {
                    profile[key] = profile_raw[key];
                }

                set_profile(profile);
                set_is_loading(false);

            })();

        }

        

    },[token]);


    // ---- Timescales ----
    let time_scales = ["1D","7D","1M","1Y"];
    let [time_scale_index, set_time_scale_index] = useState(2);

    // ---- On Load + Timescale Change ----
    useEffect(()=> {

        if (token && token.wallet_address) {

            (async function() {

                let colors = ["#00c8ff", "#9458f8", "#00bc97"];
                let data_keys = ["available_balance", "total_staked"];
                let data_raw = await get_chart_data(token.wallet_address, time_scale_index, data_keys);

                let labels = get_labels_for_time_scale(time_scale_index);

                let datasets = [];
                for (let i = 0; i < data_keys.length; i++) {
                    let dataset_raw = data_raw[i];
                    let dataset = [];
                    for (let x = 0; x < labels.length; x++) {
                        dataset.push(ethers.formatUnits(dataset_raw[x]));
                    }
                    datasets.push({
                        label: data_keys[i],
                        data: dataset,
                        fill: false,
                        borderColor: colors[i],
                        backgroundColor: colors[i],
                        tension: 0.1,
                        type: 'line',
                    });
                }

                let data = { labels, datasets }

                set_data(data);
            })();
        }

    },[token, time_scale_index]);


    return (
        <>
            <SEO parameters={
                {
                    title: "JetBolt Profile",
                    description: "View your JetBolt profile. Track your account balances and transaction history.",
                    url: "https://jetbolt.io/profile"
                }
            }/>
            <App_Nav selected_index={0} selected_index_mobile={0}>
                {/* {is_loading && <Loading_Wheel/>} */}
                <div className="small_spacer"></div>
                <div className="title_relative_container">
                    <h1 className="title_font_family">Profile</h1>
                    <div className="mobile_app_only">
                        <div className="more_button gray_button_hover" onClick={()=>window.location = "/settings"}>
                            <img className="inner_icon_abs" src={settings_icon}/>
                        </div>
                        <div className="history_button gray_button_hover" onClick={()=>window.location = "/history"}>
                            <img className="inner_icon_abs" src={history_icon}/>
                        </div>
                        {/* <div className="scan_button" onClick={qr_scanner}>
                            <img className="inner_icon_abs" src={qr_scan_black_icon}/>
                        </div> */}
                    </div>
                    <div className="desktop_transfer_container desktop_app_only">
                        <div className="profile_send_button blue_button_hover" onClick={send_tokens}>Send</div>
                        <div className="profile_receive_button gray_button_hover" onClick={receive_tokens}>Receive</div>
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <div className="profile_picture">
                    {
                        profile.wallet_address && 
                        <Jazzicon diameter={100} seed={jsNumberForAddress(profile.wallet_address)}/>
                    }
                    
                </div>
                <div className="profile_info_container notranslate">
                    {
                        profile.wallet_address && 
                        <>
                            <h2>{profile.username ? `@${profile.username}` : "Unamed User"}</h2>
                            <p className="underlined_text" onClick={receive_tokens}><u>{shorten_crypto_string(profile.wallet_address)}</u></p>
                        </>
                    }
                </div>
                <div className="mobile_app_only">
                    <div className="medium_spacer"></div>
                    <div className="profile_send_button" onClick={send_tokens}>Send</div>
                    <div className="profile_receive_button" onClick={receive_tokens}>Receive</div>
                </div>

                {
                    stats.available_tokens + stats.staked_tokens == 0n && !is_loading &&
                    <>
                        <div className="medium_spacer"></div>
                        <div className="extra_indicator_box">Why showing 0 Tokens? If you just purchased tokens but your total shows as 0 tokens, please refresh the page. It may take a few minutes for your tokens to show up.</div>
                    </>
                }

                <div className="medium_spacer"></div>
                <div className="data_container">
                    <div className="data_amount notranslate">{short_format_number(ethers.formatUnits(stats.available_tokens + stats.staked_tokens))}</div>
                    <div className="data_description">Total Tokens</div>
                </div>
                <div className="data_container data_container_second">
                    <div className="data_amount notranslate">{short_format_number(ethers.formatUnits(stats.available_tokens))}</div>
                    <div className="data_description">Available Tokens</div>
                </div>
                <div className="data_container">
                    <div className="data_amount notranslate">{Number(profile.friends_count)}</div>
                    <div className="data_description">Friends</div>
                </div>
                <div className="data_container data_container_last">
                    <div className="data_amount notranslate">{Number(profile.favorites_count)} / {Number(profile.favorited_by_count)}</div>
                    <div className="data_description">Favorites</div>
                </div>
                <div className="medium_spacer"></div>
                <div className="title_relative_container">
                    <h3>Balance Chart</h3>
                    <div className="chart_time_selector_container notranslate">
                        {
                            time_scales.map((scale, index)=> (
                                <div key={index} className={`chart_time_item ${time_scale_index==index?"chart_time_item_selected just_pointer":"gray_button_hover"}`} onClick={()=>set_time_scale_index(index)}>{scale}</div>
                            ))
                        }
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <div className='standard_chart_container notranslate'>
                    <canvas ref={chart_ref} className="standard_chart"></canvas>
                </div>
                <div className="medium_spacer"></div>
            </App_Nav>
        </>
    )
}

export default Profile_Page;