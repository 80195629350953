
// ---- Images ----
import lootbox_0 from "../images/lootbox_0.png";
import lootbox_1 from "../images/lootbox_1.png";
import lootbox_2 from "../images/lootbox_2.png";
import lootbox_3 from "../images/lootbox_3.png";
import lootbox_4 from "../images/lootbox_4.png";

export let lootboxes = [
    {
        title: "Starter",
        discount: "5%",
        usd_threshold: 100,
        discount_amount: 5,
        image: lootbox_0
    },
    {
        title: "Advanced",
        discount: "10%",
        usd_threshold: 250,
        discount_amount: 10,
        image: lootbox_1
    },
    {
        title: "Elite",
        discount: "15%",
        usd_threshold: 500,
        discount_amount: 15,
        image: lootbox_2
    },
    {
        title: "Legendary",
        discount: "20%",
        usd_threshold:  1000,
        discount_amount: 20,
        image: lootbox_3
    },
    {
        title: "Ultimate",
        discount: "25%",
        usd_threshold:  5000,
        discount_amount: 25,
        image: lootbox_4
    }
]
