import { useEffect, useState } from "react";
import { add_thousand_separator } from "../services/helper_functions";
import { get_total_sales } from "../services/api"

// ---- Components ----
import Sales_Widget_Inner from "./sales_widget_inner";

function Sales_Widget({ hard_stop, geo_error }) {


    let [total_sales, set_total_sales] = useState("");
    useEffect(()=>{
        (async function() {
            let response = await get_total_sales();
            set_total_sales(`US$${add_thousand_separator(response.total_sales.toFixed(0))} SOLD`);
        })();
    },[])

    return (

        <div className="sales_widget_container yellow_glow">
            <h2 className="title_font_family" style={{textAlign: "center"}}>BUY COIN</h2>
            <p style={{textAlign: "center", color: "var(--primary_blue)"}}><b className="title_font_family">{total_sales}</b></p>
            <Sales_Widget_Inner hard_stop={hard_stop} geo_error={geo_error}/>
        </div>
    )

}

export default Sales_Widget;