import React, { useRef, useEffect } from 'react';

// ---- Icons ----
import logo_dark_icon from "../assets/icons/logo_dark.png";

// ---- Chart.js ----
import Chart from 'chart.js/auto';

// ---- Modals ----
import { usePurchaseFunnel } from "../modals/purchase_funnel";

// ---- External Links ----
import external_links from "../configs/external_links.json";

// const logo_plugin = {
//     id: 'logo_plugin',
//     afterDraw: (chart, args, options) => {
//         const ctx = chart.ctx;
//         const image = new Image();
//         image.src = logo_dark_icon;
//         image.onload = () => {
//             let size = 100;
//             console.log(chart.canvas)
//             const x = chart.canvas.getBoundingClientRect().width / 2 - size / 2;
//             const y = chart.canvas.getBoundingClientRect().height / 2 - size / 2 - 30;
//             ctx.drawImage(image, x, y, size, size);
//         };
//     }
// };

// Chart.register(logo_plugin);

let labels = [
    {
        name: 'Pre-Sale – 40.3%',
        color: '#ffec71'
    },
    {
        name: 'Liquidity & Reserve – 8.1%',
        color: '#00b5fa'
    },
    {
        name: 'Staking Rewards – 40.3%',
        color: '#00bc97'
    },
    {
        name: 'Team – 8.1%',
        color: '#9458f8'
    },
    {
        name: 'Incentives & Rewards – 3.2%',
        color: '#ff4220'
    }
]

const data = {
    labels: ['Pre-Sale', 'Liquidity Reserve', 'Staking Rewards', 'Team'],
    datasets: [{
        label: 'Distribution',
        data: [40.3, 8.1, 40.3, 8.1, 3.2],
        backgroundColor: [
            '#ffec71',
            '#00b5fa',
            '#00bc97',
            '#9458f8',
            '#ff4220'
        ],
        borderColor: '#121319', // Dark border for each segment
        borderWidth: 0,
        hoverOffset: 4 // Slightly larger on hover
    }]
};

function Tokenomics({ hard_stop, geo_error }) {

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function buy_direct() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "buy_direct"});
        }
    }

    let chart_ref = useRef(null);
    let chart_instance = useRef(null);
    let label_canvas_refs = useRef([]);

    const create_pattern = (color, ctx) => {

        return color;

        const patternCanvas = document.createElement('canvas');
        const patternContext = patternCanvas.getContext('2d');

        // Set the dimensions of the pattern
        patternCanvas.width = 10;
        patternCanvas.height = 10;

        // Draw lines on the pattern
        patternContext.strokeStyle = color;
        patternContext.lineWidth = 2;
        patternContext.beginPath();
        patternContext.moveTo(10, 0);
        patternContext.lineTo(0, 10);
        patternContext.stroke();

        return ctx.createPattern(patternCanvas, 'repeat');
    };

    useEffect(() => {

        draw_chart();

        return () => {
            if (chart_instance.current) {
                chart_instance.current.destroy();
            }
        };

    }, []);

    function draw_chart() {
        if (chart_instance.current) {
            chart_instance.current.destroy();
        }

        let ctx = chart_ref.current.getContext('2d');

        // Apply the pattern to each color in the dataset
        let patterns = data.datasets[0].backgroundColor.map(color => create_pattern(color, ctx));
        
        chart_instance.current = new Chart(ctx, {
            type: 'doughnut',
            data: {
                ...data,
                datasets: [{
                    ...data.datasets[0],
                    backgroundColor: patterns
                }]
            },
            options: {
                cutout: '80%',  // This creates the hole in the middle
                responsive: true,
                plugins: {
                    legend: {
                        display: false,
                        // position: 'bottom',
                        // align: 'center',  // Can be 'start', 'center', or 'end'
                        // labels: {
                        //     color: 'white',
                        //     padding: 20,
                        //     paddingTop: 50,
                        //     font: {
                        //         size: 14,
                        //         family: 'Roboto Mono, monospace',
                        //         weight: 'bold'
                        //     }
                        // }
                    },
                    tooltip: {
                        enabled: false  // Disable tooltips
                    },
                    datalabels: {
                        display: false  // Assuming you're using the datalabels plugin
                    }
                    
                },
                layout: {
                    padding: {
                        top: 10,
                        right: 10,
                        bottom: 10,
                        left: 10
                    }
                },
            }
        });
    }

    useEffect(() => {
        let prev_width = 0;
        window.addEventListener('resize', function() {

            if (prev_width == window.innerWidth) {
                return;
            }

            prev_width = window.innerWidth;
            draw_chart();

        });
    },[]);

    useEffect(() => {
        labels.forEach((item, index) => {
            if (label_canvas_refs.current[index]) {
                const ctx = label_canvas_refs.current[index].getContext('2d');
                const pattern = create_pattern(item.color, ctx);
                ctx.fillStyle = pattern;
                ctx.fillRect(0, 0, label_canvas_refs.current[index].width, label_canvas_refs.current[index].height);
            }
        });
    }, []); 

    return (
        <div className="page_container">
            <div className="safe_inner">
                <div className="radial_glow_extra_top_blue"/>
                <div className="trapeze_container_top">
                    <div className="live_sales_description_container">
                        <h1 className="title_font_family">Tokenomics</h1>
                        <div className="medium_spacer"></div>
                        <p className="large_text">
                        Explore JetBolt's tokenomics—simple and effective. Our smart contracts are fully audited, enhancing safety and transparency. Dive into our whitepaper to discover more about how JetBolt works.</p>
                        <div className="medium_spacer"></div>
                        <div className={`inline_button_left blue_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>Buy Now</div>
                        <div className="inline_button_right gray_button_hover" onClick={()=>window.open(external_links.whitepaper)}>Whitepaper</div>
                    </div>
                    <div className="live_sales_container">
                        {/* <div className="small_text central_text_indicator">Max Tokens</div>
                        <div className="central_text_indicator"><b>1,000,000,000</b></div> */}
                        <div className="canvas_container">
                            <canvas ref={chart_ref} className="no_events canvas_opacity"></canvas>
                            <img className="canvas_logo" src={logo_dark_icon}/>
                        </div>
                        <div className="custom_label_container">
                            {
                                labels.map((item, index)=>(
                                    
                                    <div key={index} className="text_left_align">
                                        <canvas className="label_color_container" ref={(el) => label_canvas_refs.current[index] = el}></canvas>
                                        {item.name}<br/>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="middle_line"></div>
                    <div className="top_box_decor"></div>
                    {/* <div className="bottom_box_decor"></div> */}
                </div>
               
            </div>
        </div>
    )
}

export default Tokenomics;