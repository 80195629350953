import React, { useEffect, useState } from "react";

// ---- Components ----
import App_Nav from "../components/app_nav";
import SEO from "../components/seo";

// ---- Icons ----
import search_icon from "../assets/icons/search.png";
import right_icon from "../assets/icons/right.png";

import notification_icon from "../assets/icons/notification.png";
import rename_icon from "../assets/icons/rename.png";
import show_seed_icon from "../assets/icons/show_seed.png";
import logout_icon from "../assets/icons/logout.png";
import help_icon from "../assets/icons/help.png";
import email_icon from "../assets/icons/email.png";
import warning_icon from "../assets/icons/warning.png";
import terms_icon from "../assets/icons/terms.png";
import privacy_icon from "../assets/icons/privacy.png";
import left_icon from "../assets/icons/left.png";
import burn_tokens_icon from "../assets/icons/burn_tokens.png";

// ---- Servies ----
import useToken from "../services/wallet/useToken";
import { forget_wallets } from "../services/wallet/core";
import { get_nonce, get_email_preferences, update_email_preferences } from "../services/api";
import { sign_and_message } from "../services/contract_functions/core";

// ---- Modals ----
import { useErrorPopUp } from "../modals/error_pop_up";
import { useAppModals } from "../modals/app_modals";
import { Contact_Us, useContactUs } from "../modals/contact_us";

// ---- External Links ----
import external_links from "../configs/external_links.json";

function More_Page() {

    // ---- Token ----
    let token = useToken();

    // ---- Hooks ----
    let [notification_on, set_notification_on] = useState(false);
    let [nonce, set_nonce] = useState(0);

    // ---- Modals ----
    let { open_app_modal, close_open_app_modal } = useAppModals();
    let { throw_error } = useErrorPopUp();
    let { open_contact_us } = useContactUs();

    // ---- Functions ----
    function logout() {
        let params = {
            title: "Logout",
            content: "Are you sure you want to logout? Make sure you have saved your seed phrase before doing this.",
            buttons: [
                {
                    title: "Logout",
                    callback: function() {
                        forget_wallets();
                        window.location.reload();
                    }
                },
                {
                    title: "Cancel"
                }
            ]
        }
        throw_error(params);
    }
    
    function show_seed() {
        open_app_modal({type: "show_seed"});
    }

    function show_private_key() {
        open_app_modal({type: "show_private_key"});
    }

    function burn_tokens() {
        open_app_modal({type: "burn_amount"});
    }

    function change_username() {
        open_app_modal({type: "change_username"});
    }

    function email_preferences() {
        open_app_modal({type:"email_preferences", time_index: 9, close_callback: load_notification_status});
    }

    // ---- Notifications ----
    async function change_notification_mode(notification_on) {
        if (nonce > 0) {
            if (notification_on) {
                update_notification_status(true);
            }
            else {
                update_notification_status(false);
            }
        }
        else if (notification_on) {
            email_preferences();
        }
        set_notification_on(notification_on);
    }

    async function update_notification_status(status) {
        try {
            let email_address = void 0;
            let utc_index = void 0;
            let wallet_address = token.wallet_address;
            let message = JSON.stringify({wallet_address, email_address, utc_index, status, nonce});
            update_transaction_status("Waiting for approval", 0);
            let signature = await sign_and_message(message, update_transaction_status);
            update_transaction_status("Saving...", 0.7);
            await update_email_preferences(wallet_address, email_address, utc_index, status, nonce, signature);
            update_transaction_status("",1,true);
            setTimeout(()=>{
                close_open_app_modal();
            },500);
            let nonce_raw = await get_nonce(wallet_address);
            set_nonce(nonce_raw.nonce+1);
        } catch (error) {
            close_open_app_modal();
        }
    }

    async function load_notification_status() {
        if (token && token.wallet_address) {
            let preferences = await get_email_preferences(token.wallet_address);
            if (preferences) {
                set_notification_on(preferences.status);
                set_nonce(preferences.nonce+1);
            }
            else {
                set_notification_on(false);
            }
        }
    }

    async function update_transaction_status(status, amount, success_tx=false) {
        open_app_modal({type: "approve_transaction", details: "Update email notification settings", cb: {tx_pending: true, tx_status: {status, amount}}, success_tx});
    }

    useEffect(()=> {
        load_notification_status();
    },[]);

    return (
        <>
            <SEO parameters={
                {
                    title: "JetBolt Settings",
                    description: "Edit your JetBolt account settings. Change your email notification settings, view your seedphrase, burn tokens, logout, and more.",
                    url: "https://jetbolt.io/settings"
                }
            }/>
            <App_Nav selected_index={5}>
                <div className="small_spacer"></div>
                <div className="title_relative_container">
                    <h1 className="title_font_family">
                        <div className="extra_back_button gray_button_hover" onClick={()=>window.location="/profile"}>
                            <img className="extra_back_button_icon" src={left_icon}/>
                        </div>
                        More
                    </h1>
                </div>
                <div className="medium_spacer"></div>
                <h3>Settings</h3>
                <div className="medium_spacer"></div>
                <div className="friend_list_item">
                    <img className="live_sales_icon" src={notification_icon}/>
                    <div className="live_sales_amount_label">Email notifications</div>
                    <div className={`switch_container opacity_hover ${notification_on?"on":"off"}`} onClick={()=>change_notification_mode(!notification_on)}>
                        <div className={`switch_button ${notification_on?"on":"off"}`}></div>
                    </div>
                </div>
                {
                    notification_on &&
                    <div className="friend_list_item gray_button_hover" onClick={email_preferences}>
                        <img className="live_sales_icon" src={email_icon}/>
                        <div className="live_sales_amount_label">Email preferences</div>
                        <div className="decline_button">
                            <img className="inner_icon" src={right_icon}/>
                        </div>
                    </div>
                }
                {/* <div className="friend_list_item" onClick={change_username}>
                    <img className="live_sales_icon" src={rename_icon}/>
                    <div className="live_sales_amount_label">Change username</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div> */}
                <div className="friend_list_item gray_button_hover" onClick={show_seed}>
                    <img className="live_sales_icon" src={show_seed_icon}/>
                    <div className="live_sales_amount_label">View seed phrase</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>
                <div className="friend_list_item gray_button_hover" onClick={show_private_key}>
                    <img className="live_sales_icon" src={show_seed_icon}/>
                    <div className="live_sales_amount_label">View private key</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>
                <div className="friend_list_item gray_button_hover" onClick={burn_tokens}>
                    <img className="live_sales_icon" src={burn_tokens_icon}/>
                    <div className="live_sales_amount_label">Burn tokens</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>
                <div className="friend_list_item gray_button_hover" onClick={logout}>
                    <img className="live_sales_icon" src={logout_icon}/>
                    <div className="live_sales_amount_label">Logout</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>

                <div className="medium_spacer"></div>
                <h3>Help</h3>
                <div className="medium_spacer"></div>
                <div className="friend_list_item gray_button_hover" onClick={()=>window.open(external_links.guides)}>
                    <img className="live_sales_icon" src={help_icon}/>
                    <div className="live_sales_amount_label">Help docs</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>
                <div className="friend_list_item gray_button_hover" onClick={open_contact_us}>
                    <img className="live_sales_icon" src={email_icon}/>
                    <div className="live_sales_amount_label">Contact us</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>

                <div className="medium_spacer"></div>
                <h3>Legal</h3>
                <div className="medium_spacer"></div>
                <div className="friend_list_item gray_button_hover" onClick={()=>window.open(external_links.disclaimer)}>
                    <img className="live_sales_icon" src={warning_icon}/>
                    <div className="live_sales_amount_label">Disclaimer</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>
                <div className="friend_list_item gray_button_hover" onClick={()=>window.open(external_links.terms)}>
                    <img className="live_sales_icon" src={terms_icon}/>
                    <div className="live_sales_amount_label">Terms of use</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>
                <div className="friend_list_item gray_button_hover" onClick={()=>window.open(external_links.privacy)}>
                    <img className="live_sales_icon" src={privacy_icon}/>
                    <div className="live_sales_amount_label">Privacy policy</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>


                
            </App_Nav>
        </>
    )
}

export default More_Page;