import { useEffect, useState } from "react";
import { QRCodeSVG } from 'qrcode.react';

// ---- Icons ----
import paste_icon from "../../assets/icons/paste.png";
import right_icon from "../../assets/icons/right.png";
import website_icon from "../../assets/icons/website.png";
import left_icon from "../../assets/icons/left.png";

// ---- Services ----
import { is_web_view, is_desktop } from "../../services/helper_functions";
import customer_instance from "../../services/wallet/customer";
import useCustomer from "../../services/wallet/useCustomer";

function Force_Browser_Transfer({ parameters, set_parameters }) {

    let customer = useCustomer();

    let [has_copied, set_has_copied] = useState(false);

    useEffect(()=>{

        if (has_copied) {
            setTimeout(function() {
                set_has_copied(false);
            },2000);
        }

    },[has_copied]);

    let { is_os_webview, is_android_webview } = is_web_view();
    let is_desk = is_desktop();

    let uri_string = "";
    if (customer && customer.customer_id) {
        uri_string = `force_load_customer=${customer_instance.get_hash()}`;
        if (parameters.payment_id) {
            uri_string += `&payment_id=${parameters.payment_id}`
        }
        if (parameters.is_wert) {
            uri_string += `&hash=${parameters.hash}`;
        }
        if (parameters.is_tweed) {
            uri_string += `&is_tweed=true`;
        }
        // $delim$${payment_id}
    }

    function copy_address() {
        navigator.clipboard.writeText(`https://jetbolt.io/?${uri_string}`);
        set_has_copied(true);
    }

    function back() {
        set_parameters({type:"create_authenticator"});
    }

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">{
                            parameters.mode == 2 ?
                            "Continue on Mobile" :
                            parameters.mode == 1 ?
                                is_desk ?
                                "Continue on Mobile" :
                                "Continue in Browser" :
                            `Open In ${is_os_webview?"Safari":"Chrome"}`}
                        </h2>
                    </div>
                    {
                        parameters.mode == 2 &&
                        <img className="back_icon opacity_hover" src={left_icon} onClick={back}/>
                    }
                    <div className="medium_spacer"></div>
                </div>
                {
                    parameters.mode == 2 ?
                    <p>Please copy the link below or scan the QR code from a mobile device or another device that has WebAuthN enabled.</p> :
                    parameters.mode == 1 ?
                        is_desk ?
                        <p>We have detected that your browser is not compatible with WebAuthN authentication. Please copy the link below or scan the QR code from a mobile device or another device that has WebAuthN enabled.</p> :
                        <p>We have detected that you are not in your native device browser. Please copy the link below and open it in your main device web browser.</p> :
                    <p>We have detected that you are not in your native device browser. Please copy the link below and open it in {is_os_webview?"Safari":"Chrome"}.</p>
                }
                {
                    (parameters.mode == 1 || parameters.mode == 2) && is_desk &&
                    <>
                        <div className="medium_spacer"></div>
                        <QRCodeSVG value={`https://jetbolt.io/?${uri_string}`} bgColor={"white"} fgColor={"var(--background_color)"} width={"100%"} size={250}/>
                    </>
                }
                <div className="medium_spacer"></div>
                <div className="payment_input_container">
                    <div className="payment_input_currency_selector">
                        <img className="payment_input_selector_icon" src={website_icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                    </div>
                    <div className="paste_input_button" onClick={copy_address}>
                        <img className="paste_icon" src={paste_icon}/>
                        <div className="paste_text_inner gray_button_hover">Copy</div>
                    </div>
                    <input className="wallet_address_input" value={`https://jetbolt.io/?${uri_string}`}/>
                </div>
                {
                    has_copied &&
                    <>
                        <div className="small_spacer"></div>
                        <p style={{color: "var(--green_text)"}}>Address Copied!</p>
                    </>
                }
                {
                    parameters.mode != 1 && parameters.mode != 2 && is_android_webview &&
                    <>
                        <div className="medium_spacer"></div>
                        <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={()=>window.open(`intent://jetbolt.io/?${uri_string}#Intent;scheme=https;action=android.intent.action.VIEW;end`)}>Open In Chrome</div>
                    </>
                }
            </div>
        </>
    );
}

export default Force_Browser_Transfer;