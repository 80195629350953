import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// ---- Pages ----
import Home_Page from "./pages/home_page";
import Profile_Page from "./pages/profile_page";
import Staking_Page from "./pages/staking_page";
import Friends_Page from "./pages/friends_page";
import History_Page from "./pages/history_page";
import More_Page from "./pages/more_page";
import Explore_Page from "./pages/explore_page";
import I_Frame from "./pages/i_frame";

// ---- Styles ----
import "./styles/common.css";
import "./styles/colors.css";
import "./styles/buttons.css";
import "./styles/font_sizes.css";
import "./styles/containers.css";
import "./styles/header.css";
import "./styles/top_content.css";
import "./styles/sales_widget.css";
import "./styles/featured_in.css";
import "./styles/features.css";
import "./styles/live_sales.css";
import "./styles/lootboxes.css";
import "./styles/faq.css";
import "./styles/earn.css";
import "./styles/footer.css";
import "./styles/error_pop_up.css";
import "./styles/language_selector.css";
import "./styles/purchase_funnel.css";
import "./styles/app_containers.css";
import "./styles/friends_list.css";
import "./styles/history_list.css";
import "./styles/profile.css";
import "./styles/seed_phrases.css";
import "./styles/more.css";
import "./styles/loading.css";
import "./styles/tokenomics.css";
import "./styles/explore.css";
import "./styles/why_jetpaw.css";
import "./styles/develop.css";

// ---- Modals ----
import { Error_Pop_Up } from "./modals/error_pop_up";
import { Language_Selector } from "./modals/language_selector";
import { Currency_Selector } from "./modals/currency_selector";
import { Purchase_Funnel } from "./modals/purchase_funnel";
import { Login } from "./modals/login";
import { App_Modals } from './modals/app_modals';
import { Discount_Charts } from './modals/discount_charts';
import { Geo_Block } from "./modals/geo_block";
import { Contact_Us } from './modals/contact_us';
import { Banned } from "./modals/banned";
import { GDPR } from "./modals/gdpr";
import { Telegram } from "./modals/telegram";

// ---- Hovers ----
let user_agent = navigator.userAgent || navigator.vendor;

if (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(user_agent.toLowerCase())) {
  import('./styles/hovers_mobile.css');
} else {
  import("./styles/hovers.css");
}

console.log('%cWait!', 'color: red; font-size: 50px; text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;');
console.log("%cIf someone told you to copy/paste something here, there's an 11/10 chance you're being scammed.","font-size: 18px;");
console.log("%cPasting anything in here could give attackers access to your JetBolt account.","font-size: 18px; color: red; font-weight: bold;");

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Geo_Block>
    <GDPR>
      <Error_Pop_Up>
        <Telegram>
          <Contact_Us>
            <Banned>
              <Language_Selector>
                <Currency_Selector>
                  <Login>
                    <Discount_Charts>
                      <Purchase_Funnel>
                        <App_Modals>
                          <React.StrictMode>
                            <BrowserRouter>
                              <Routes>
                                <Route path="/" element={<Home_Page />}/>
                                <Route path="/profile" element={<Profile_Page />}/>
                                <Route path="/staking" element={<Staking_Page />}/>
                                <Route path="/friends" element={<Friends_Page />}/>
                                <Route path="/history" element={<History_Page />}/>
                                <Route path="/settings" element={<More_Page />}/>
                                <Route path="/explore" element={<Explore_Page />}/>
                                <Route path="/news" element={<Explore_Page />}/>
                                <Route path="/news/:article_address/:title_string" element={<Explore_Page />}/>
                                <Route path="/explore/:article_address/:title_string" element={<Explore_Page />}/>
                                <Route path="/iframe" element={<I_Frame />}/>
                                <Route path="/twitter" element={<Navigate to="/?source=twitter" replace />} />
                                <Route path="/telegram" element={<Navigate to="/?open_telegram=true" replace />} />
                              </Routes>
                            </BrowserRouter>
                          </React.StrictMode>
                        </App_Modals>
                      </Purchase_Funnel>
                      </Discount_Charts>
                  </Login>
                </Currency_Selector>
              </Language_Selector>
            </Banned>
          </Contact_Us>
        </Telegram>
      </Error_Pop_Up>
    </GDPR>
  </Geo_Block>
);

reportWebVitals();
