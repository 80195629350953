import React, { createContext, useContext, useState, useEffect } from "react";

// ---- Services ----
import { get_is_banned } from "../services/contract_functions/token";
import useToken from "../services/wallet/useToken";

// ---- Modals ----
import { useContactUs } from "../modals/contact_us";

const ModalContext = createContext();

export function useBanned() {
    return useContext(ModalContext);
}

export function Banned({ children }) {

    let { open_contact_us } = useContactUs();

    let token = useToken();

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);

    let open_banned = (modal_parameters) => {
        document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_banned = () => {
        set_animate_out(true);

        setTimeout(() => {
            document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
    }

    let context_value = {
        open_banned,
        close_banned
    }

    useEffect(()=> {
        (async function() {

            if (token && token.wallet_address) {
                let is_banned = await get_is_banned(token.wallet_address);
                if (is_banned) {
                    open_banned();
                }
            }
            
        })();
    },[]);


    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="background_shader">
                    <div className={`language_selector_container yellow_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="purchase_funnel_container">
                            <div className="stick_text">
                                <div className="medium_spacer"></div>
                                <div style={{position: "relative"}}>
                                    <h2 className="central_text_indicator title_font_family">Banned</h2>
                                </div>
                                <div className="medium_spacer"></div>
                            </div>

                            <p>You have been banned from using JetPaw for violating our Terms of Use.</p>
                            <div className="small_spacer"></div>
                            <p>If you believe that this is an error you may contact us and we will review your case.</p>
                            <div className="medium_spacer"></div>
                            <div className="payment_button yellow_glow title_font_family yellow_button_hover" onClick={open_contact_us}>Contact Us</div>
                            <div className="medium_spacer"></div>
                            <div className="title_font_family payment_button_dull opacity_hover" onClick={()=>window.location="https://google.com"}>Exit Site</div>
                            {/* <div className="medium_spacer"></div> */}
                        </div>
                    </div>
                    
                </div>
            }
        </ModalContext.Provider>
    )


}