import { view_call } from "./core";
import { ethers } from "ethers";
import { skale_chains } from "../../assets/data/skale_rpcs";

let contract_name = "News";
let rpc_provider = new ethers.JsonRpcProvider(skale_chains.calypso_testnet.rpc_url);


// ---- Getter Functions ----
export async function get_article(article_address) {
    let response = await view_call(contract_name, "get_article", [article_address], rpc_provider);
    return response;
}

export async function get_articles(page_number, articles_per_page) {
    let response = await view_call(contract_name, "get_articles", [page_number, articles_per_page], rpc_provider);
    return response;
}

export async function get_articles_by_source(source_name, page_number, articles_per_page) {
    let response = await view_call(contract_name, "get_articles_by_source", [source_name, page_number, articles_per_page], rpc_provider);
    return response;
}

export async function get_articles_by_ticker(ticker, page_number, articles_per_page) {
    let response = await view_call(contract_name, "get_articles_by_ticker", [ticker, page_number, articles_per_page], rpc_provider);
    return response;
}

export async function get_tokens() {
    let response = await view_call(contract_name, "get_tokens", [], rpc_provider);
    return response;
}

export async function get_chart(symbol) {
    let response = await view_call(contract_name, "get_chart", [symbol], rpc_provider);
    return response;
}
