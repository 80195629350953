import { useState, useEffect, useRef } from "react";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// ---- Icons ----
import down_icon from "../../assets/icons/down.png";
import credit_card_icon from "../../assets/icons/credit_card.png";
import expiration_icon from "../../assets/icons/expiration.png";
import cvc_icon from "../../assets/icons/cvc.png";

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Configs ----
import config from "../../configs/config.json";

// ---- Stripe ----
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';

// ---- Servies ----
import { pay_card, find_customer } from "../../services/api";
import { on_focus_input_ref, on_blur_input_ref, shorten_crypto_string, is_web_view } from "../../services/helper_functions";
import useCustomer from "../../services/wallet/useCustomer";
import { trigger_event } from "../../services/analytics";
import { get_username } from "../../services/contract_functions/friends";
import { forget_wallets } from "../../services/wallet/core";

const stripe_promise = loadStripe(config.stripe_key);

function Pay_Card_Inner({ close, parameters, set_parameters }) {

    let customer = useCustomer();

    let [destination_mint, set_destination_mint] = useState(null);

    // let { is_os_webview, is_android_webview } = is_web_view();
    useEffect(()=>{

        // if (is_os_webview || is_android_webview) {
            if (customer && customer.customer_id) {
                (async function() {
                    let customer_info = await find_customer(customer.customer_id);
                    if (customer_info.destination_address) {
                        let username = await get_username(customer_info.destination_address);
                        if (username) {
                            set_destination_mint(username);
                        }
                        else {
                            set_destination_mint(shorten_crypto_string(customer_info.destination_address));
                        }
                    }
                })();
            }
        // }

    },[customer]);

    function logout() {
        let params = {
            title: "Logout",
            content: "Are you sure you want to logout? Make sure you have saved your seed phrase before doing this.",
            buttons: [
                {
                    title: "Logout",
                    callback: function() {
                        forget_wallets();
                        window.location.reload();
                    }
                },
                {
                    title: "Cancel"
                }
            ]
        }
        throw_error(params);
    }

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    function show_card_error() {
        let params = {
            title: "Oh No!",
            content: `Something isnt right. Please make sure you have entered your card details correctly. If this keeps happening, please try reducing the purchase amount or checking with your bank.`,
            buttons: [
                {
                    title: "Okay"
                }
            ]
        }
        throw_error(params);
    }

    // ---- Stripe ----
    const stripe = useStripe();
    const elements = useElements();

    const element_options = {
        style: {
            base: {
                color: 'white',
                fontFamily: 'Poppins, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '18px',
                lineHeight: "30px",
                textAlign: "right",
                // '::placeholder': {
                //     color: '#aab7c4',
                // },
            },
            invalid: {
                color: '#ff4220',
                iconColor: '#ff4220',
            },
        },
    };

    useEffect(()=>{
        trigger_event("begin_checkout", parameters.hint_amount);
    },[]);

    // ---- Refs ----
    let card_number_outer = useRef(null);
    let expiration_outer = useRef(null);
    let cvc_outer = useRef(null);

    // ---- Handle Payment ----
    
    async function handle_payment(event) {

        event.target.style = "opacity: 0.25; pointer-events: none;";

        if (stripe && elements) {
            try {
                const card_element = elements.getElement(CardNumberElement);
                const { error, paymentMethod } = await stripe.createPaymentMethod({
                    type: 'card',
                    card: card_element,
                });

                if (error) {
                    show_card_error();
                }
                else {
                    try {

                        let response = await pay_card(paymentMethod.id, parameters.hint_amount, customer.customer_id);
                        if (response.payment_intent.status === 'requires_action') {
                            const { error: confirmError } = await stripe.confirmCardPayment(response.payment_intent.client_secret);
                            if (confirmError) {
                                // console.log(confirmError.message);
                                show_card_error();
                            } else {
                                // console.log('Payment succeeded');
                                set_parameters({type:"pending_evm", force_success: true, hint_amount: parameters.hint_amount, currency_index: 0});
                            }
                        } else {
                            // console.log('Payment succeeded');
                            set_parameters({type:"pending_evm", force_success: true, hint_amount: parameters.hint_amount, currency_index: 0});
                        }

                    } catch (error) {
                        show_card_error();
                    }
                }
            } catch (error) {
                show_card_error();
            }
        }

        event.target.style = "opacity: 1; pointer-events: auto;";
    }

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Buy Token</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                        {/* <img className="help_icon" src={help_icon}/> */}
                    </div>
                    
                </div>
                <div className="medium_spacer"></div>

                <form>

                    <p className="small_text">Card Number:</p>
                    <div className="small_spacer"></div>
                    <div className="payment_input_container" ref={card_number_outer}>
                        <div className="payment_input_currency_selector_short">
                            <img className="payment_input_selector_icon" src={credit_card_icon}/>
                        </div>
                        <div className="inner_payment_rel">
                            <CardNumberElement options={element_options} onFocus={()=>on_focus_input_ref(card_number_outer)} onBlur={()=>on_blur_input_ref(card_number_outer)}/>
                        </div>
                    </div>

                    <div className="small_spacer"></div>

                    <div className="payment_half_container left">
                        <p className="small_text">Expiration:</p>
                        <div className="small_spacer"></div>
                        <div className="payment_input_container" ref={expiration_outer}>
                            <div className="payment_input_currency_selector_short">
                                <img className="payment_input_selector_icon" src={expiration_icon}/>
                            </div>
                            <div className="inner_payment_rel">
                                <CardExpiryElement options={element_options} onFocus={()=>on_focus_input_ref(expiration_outer)} onBlur={()=>on_blur_input_ref(expiration_outer)}/>
                            </div>
                        </div>
                    </div>

                    <div className="payment_half_container">
                        <p className="small_text">CVC:</p>
                        <div className="small_spacer"></div>
                        <div className="payment_input_container" ref={cvc_outer}>
                            <div className="payment_input_currency_selector_short">
                                <img className="payment_input_selector_icon" src={cvc_icon}/>
                            </div>
                            <div className="inner_payment_rel">
                                <CardCvcElement options={element_options} onFocus={()=>on_focus_input_ref(cvc_outer)} onBlur={()=>on_blur_input_ref(cvc_outer)}/>
                            </div>
                        </div>
                    </div>

                    <div className="medium_spacer"></div>
                    <div className="payment_button yellow_button_hover title_font_family" onClick={handle_payment}>
                        BUY NOW
                    </div>

                </form>

                {
                    destination_mint &&
                    <>
                        <div className="medium_spacer"></div> 
                        <p>Minting to: @{destination_mint}</p>
                        <p><u className="underlined_text" onClick={logout}>Logout</u></p>
                    </>
                }

                <div className="small_spacer"></div>
                <p className="smallest_text grayed_text">Disclaimer: JetBolt is not a secure investment. The JetBolt team does not guarantee that the tokens hold intrinsic value, will appreciate, or maintain any value after the presale. By purchasing, you acknowledge buying JetBolt tokens for ecosystem use, not as an investment, and you accept the risk of losing the entire value of your purchase.</p>

            </div>
        </>
    );

}

function Pay_Card({ close, parameters, set_parameters }) {
    return (
        <Elements stripe={stripe_promise}>
            <Pay_Card_Inner close={close} parameters={parameters} set_parameters={set_parameters}/>
            {/* <PaymentElement/> */}
        </Elements>
    )
}

export default Pay_Card;