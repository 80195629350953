import { useEffect, useState, useRef } from "react";

// ---- Lotie ----
import Lottie from 'lottie-react';

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import { increase_stake, balance_of } from "../../services/contract_functions/token";
import { on_focus_input_ref, on_blur_input_ref, convert_12_hr_format, is_valid_email_address } from "../../services/helper_functions";
import { sign_and_message } from "../../services/contract_functions/core";
import { get_nonce, update_email_preferences, get_email_preferences } from "../../services/api";

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import logo_dark from "../../assets/icons/logo_dark.png"
import down_icon from "../../assets/icons/down.png";
import animated_tick from "../../assets/icons/animated_tick.json";
import paste_icon from "../../assets/icons/paste.png";
import email_icon from "../../assets/icons/email.png";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

import { call } from "viem/actions";

// ---- Data ----
import { time_icons } from "../../assets/data/time";

// ---- External Links ----
import external_links from "../../configs/external_links.json";



function Email_Preferences({ close, parameters, set_parameters }) {

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    // ---- Refs ----
    let email_address_ref = useRef(null);
    let email_address_outer_ref = useRef(null);

    // ---- Hooks ----
    let token = useToken();
    let [is_valid_email, set_is_valid_email] = useState(false);
    let [is_loading, set_is_loading] = useState(false);
    let [success_animation, set_success_animation] = useState(false);
    let [nonce, set_nonce] = useState(0);

    // ---- Input Value Changes ----
    function email_changed() {
        if (email_address_ref.current) {
            let value = email_address_ref.current.value;
            set_is_valid_email(is_valid_email_address(value));
        }
    }

    // ---- Configure Nonce ----
    useEffect(()=> {

        (async function() {

            let nonce_raw = await get_nonce(token.wallet_address);
            set_nonce(nonce_raw.nonce+1);

            let current_preferences = await get_email_preferences(token.wallet_address);
            if (current_preferences) {
                email_address_ref.current.value = current_preferences.email_address;
                set_is_valid_email(true);
            }

        })();

    },[]);

    // ---- Functions ----
    async function update_email() {

        set_is_loading(true);
        set_tx_pending(true);
        set_tx_status({status:"Waiting for approval", amount:0});
        try {
            if (email_address_ref.current) {

                let email_address = email_address_ref.current.value;
                if (email_address.includes("*")) {
                    email_address = void 0;
                }
                let local_date = new Date();
                local_date.setHours(parameters.time_index, 0, 0, 0);
                let local_offset_mins = local_date.getTimezoneOffset();
                let local_offset_hrs = local_offset_mins / 60;
                let rounded_offset = Math.round(local_offset_hrs);
                let utc_index = parameters.time_index - rounded_offset;
                if (utc_index < 0) {
                    utc_index += 24;
                } else if (utc_index >= 24) {
                    utc_index -= 24;
                }

                let wallet_address = token.wallet_address;
                let status = true;

                let message = JSON.stringify({wallet_address, email_address, utc_index, status, nonce});
                let signature = await sign_and_message(message, update_transaction_status);

                set_tx_status({status:"Saving...", amount:0.7});
                await update_email_preferences(wallet_address, email_address, utc_index, status, nonce, signature);

                set_success_animation(true);
                setTimeout(close, 500);

            }
        } catch (error) {}

        set_is_loading(false);
        set_tx_pending(false);

    }

    // ---- Tx Status ----
    let [tx_pending, set_tx_pending] = useState(false);
    let [tx_status, set_tx_status] = useState({status:"Waiting for approval", amount:0})

    function update_transaction_status(status, amount) {
        set_tx_status({status, amount})
    }

    // ---- Paste ----
    async function paste(ref) {
        try {
            let text = await navigator.clipboard.readText();
            ref.current.value = text;
            email_changed();
        } catch (error) {}
    }

    // ---- Updating Email on CB ----
    useEffect(() => {

        if (parameters.email_value) {
            email_address_ref.current.value = parameters.email_value;
            email_changed();
        }
        
    },[parameters.email_value])

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Email Reminders</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <p>Get notified daily to claim your token rewards.</p>
                <div className="medium_spacer"></div>
                <p className="small_text">Email Address:</p>
                <div className="small_spacer"></div>
                <div className="payment_input_container notranslate" ref={email_address_outer_ref}>
                    <div className="payment_input_currency_selector">
                        <img className="payment_input_selector_icon" src={email_icon}/>
                        <img className="payment_input_selector_extra_icon" src={right_icon}/>
                    </div>
                    <div className="paste_input_button gray_button_hover" onClick={()=>paste(email_address_ref)}>
                        <img className="paste_icon" src={paste_icon}/>
                        <div className="paste_text_inner">Paste</div>
                    </div>
                    <input className="wallet_address_input" placeholder="Email address" ref={email_address_ref} onChange={email_changed}
                    type="email"
                    onFocus={()=>on_focus_input_ref(email_address_outer_ref)} onBlur={()=>on_blur_input_ref(email_address_outer_ref)}/>
                </div>

                <div className="medium_spacer"></div>
                <p className="small_text">Time of Day:</p>
                <div className="small_spacer"></div>
                <div className="friend_list_item gray_button_hover" onClick={()=>set_parameters({type: "email_time", close_callback: parameters.close_callback, email_value: email_address_ref.current.value})}>
                    <img className="live_sales_icon" src={time_icons[parameters.time_index]}/>
                    <div className="live_sales_amount_label">{convert_12_hr_format(parameters.time_index)} {Intl.DateTimeFormat().resolvedOptions().timeZone} Time</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div>
                
                <div className="medium_spacer"></div>
                <div className={`payment_button title_font_family yellow_button_hover ${(!is_valid_email || is_loading)?"unselectable":""}`} onClick={update_email}>{success_animation ?  <Lottie animationData={animated_tick} loop={false} direction={-1} style={{ position: "absolute", paddingTop: "10px", left: "calc(50% - 25px)", width: "50px", height: "50px" }}/> : "Save"}</div>

                {
                    tx_pending &&
                    <>
                        <div className="medium_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar" style={{width: `${tx_status.amount*100}%`}}></div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text">Status: {tx_status.status}</p>
                    </>
                }

                <div className="small_spacer"></div>
                <p className="grayed_text small_text">By subscribing to this newsletter, you hereby consent to our <u className="underlined_text" onClick={()=>window.open(external_links.privacy)}>Privacy Policy</u>.</p>

            </div>
        </>
    );
}

export default Email_Preferences;