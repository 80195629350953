import { useEffect, useState, useRef } from "react";

// ---- Modals ----
import { usePurchaseFunnel } from "../modals/purchase_funnel";

// ---- External Links ----
import external_links from "../configs/external_links.json";


const keywords = ['import', 'from', 'function', 'let', 'return', 'const', 'var', 'export', 'default','onClick'];
const classes = ['JetBolt', 'Child_Object', 'Parent_Object', 'button', 'p'];
const main_functions = ['useJetBolt', 'useEffect', 'register_callback', 'call_contract', 'request_authorization', 'login', 'call_my_contract','log','console']
const params = ['sample_contract', 'is_authorized', 'wallet_address', 'request_authorization', 'abi', 'description', 'contract_address', 'function_name', 'parameters', 'status'];

const Type_Writer = ({ text, section_ref, speed = 10 }) => {
    const [displayedText, setDisplayedText] = useState('');
    let [is_loaded, set_is_loaded] = useState(false);

    useEffect(() => {

        if (!is_loaded) {

            let index = 0;
            let intervalId;

            const observer = new IntersectionObserver((entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {

                    intervalId = setInterval(() => {
                        if (index < text.length) {
                          setDisplayedText(prev => prev + text[index]);
                          index++;
                        } else {
                          clearInterval(intervalId);
                        }

                        section_ref.current.scrollTop = section_ref.current.scrollHeight;
                    }, speed);

                    set_is_loaded(true);
                    observer.disconnect();
                }
            }, {
                rootMargin: '100px',
                threshold: 0.1
            });

            if (section_ref.current) {
                observer.observe(section_ref.current);
            }

            return () => {
                if (section_ref.current) {
                    observer.unobserve(section_ref.current);
                }
                // if (intervalId) {
                //     clearInterval(intervalId);
                // }
            };
        }

    },[text, speed, is_loaded]);
  
    // useEffect(() => {
    //   let index = 0;
    //   const intervalId = setInterval(() => {
    //     if (index < text.length) {
    //       setDisplayedText(prev => prev + text[index]);
    //       index++;
    //     } else {
    //       clearInterval(intervalId);
    //     }
    //   }, speed);
  
    //   return () => clearInterval(intervalId);
    // }, [text, speed]);
  
    // Split the displayed text into words and wrap with spans as needed
    const formattedText = displayedText.split(/(\n|\t|\s+|,|=|<\/?|\.|!|>|;|\(|\)|{|})/).map((word, i) => {


        if (!word || word == undefined || word == "undefined") return null;
      if (keywords.includes(word.trim())) {
        return <span key={i} className="dev_red">{word}</span>;
      } else if (classes.includes(word.trim())) {
        return <span key={i} className="dev_yellow">{word}</span>;
      } else if (main_functions.includes(word.trim())) {
        return <span key={i} className="dev_blue_dark">{word}</span>;
      }  else if (params.includes(word.trim())) {
        return <span key={i} className="dev_blue">{word}</span>;
      } else if (/^".*"$|^'.*'$/.test(word)) {
        return <span key={i} className="dev_green">{word}</span>;
      } else if (word === '\t') {
        return <span key={i} className="dev_indent"></span>; // Replace with non-breaking spaces for indentation
      } else if (word === '\n') {
        return <br key={i} />;
      } else {
        return word;
      }

      
    });
  
    return <span>{formattedText.filter(Boolean)}</span>;
};

let code_snippet = `iimport { JetBolt, useJetBolt } from "JetBolt";
import { useEffect } from "react";
import sample_contract from "sample_contract_path";

function Child_Object {
\tlet { is_authorized, wallet_address, request_authorization, call_contract, register_callback } = useJetBolt();

\tfunction login() {
\t\trequest_authorization();
\t}

\tfunction call_my_contract(description, contract_address, function_name, parameters) {
\t\tlet abi = sample_contract.abi;
\t\tcall_contract(description, contract_address, abi, function_name, parameters);
\t}

\tuseEffect(() => {
\t\tregister_callback("auth", (status) => {
\t\t\tconsole.log("auth_callback", status);
\t\t});

\t\tregister_callback("call", (status) => {
\t\t\tconsole.log("call_callback", status);
\t\t});
\t},[]);

\treturn (
\t\t{ !is_authorized && <button onClick={login}></button> }
\t\t{ is_authorized && <p>Wallet Address: {wallet_address}</p>}
\t\t{ is_authorized && <button onClick={()=>call_my_contract(...)}>Call Some Function</button> }
\t);
}

function Parent_Object {
\treturn (
\t\t<JetBolt>
\t\t\t</Child_Object>
\t\t</JetBolt>
\t);
}

export default Parent_Object;`


function Develop({ hard_stop, geo_error }) {

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function buy_direct() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "buy_direct"});
        }
    }

    // ---- Refs ----
    let section_ref = useRef(null);

    return (
        <div className="page_container">
            <div className="safe_inner">
                <div className="full_outter_box">
                    <div className="develop_lhs">
                        <div className="develop_main_title_box">
                            <h1 className="title_font_family">Develop on JetBolt</h1>
                        </div>
                        <div className="develop_code_example">
                            <div className="lined_background radial_mask"></div>
                            <div className="develop_code_inner">
                                <div className="develop_code_itself" ref={section_ref}>

                                    <Type_Writer text={code_snippet} section_ref={section_ref}/>

                                    {/* <a className="dev_red">import</a> {`{ JetBolt, useJetBolt }`} <a className="dev_red">from</a> <a className="dev_green">"JetBolt"</a>;
                                    <br/>
                                    <a className="dev_red">import</a> {`{ useEffect }`} <a className="dev_red">from</a> <a className="dev_green">"react"</a>;
                                    <br/>
                                    <a className="dev_red">import</a> sample_contract <a className="dev_red">from</a> <a className="dev_green">"./contract.json"</a>;
                                    <br/><br/>
                                    <a className="dev_red">function</a> <a className="dev_blue_dark">Child_Object</a> {`{`}
                                    <br/>
                                    <span className="dev_indent"><a className="dev_red">let</a> {`{ is_authorized, wallet_address, request_authorization, call_contract, register_callback }`} = <a className="dev_blue">useJetBolt</a>();</span>
                                    <br/><br/>
                                    <span className="dev_indent"><a className="dev_red">function</a> <a className="dev_blue">login</a>() {`{`}</span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><a className="dev_blue">request_authorization</a>();</span></span>
                                    <br/>
                                    <span className="dev_indent">{`}`}</span>
                                    <br/><br/>
                                    <span className="dev_indent"><a className="dev_red">function</a> <a className="dev_blue">call_my_contract</a>(description, contract_address, function_name, parameters) {`{`}</span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><a className="dev_red">let</a> abi = sample_contract.abi;</span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><a className="dev_blue">call_contract</a>(description, contract_address, abi, function_name, parameters);</span></span>
                                    <br/>
                                    <span className="dev_indent">{`}`}</span>
                                    <br/><br/>
                                    <span className="dev_indent"><a className="dev_blue">useEffect</a>(() {`=> {`}</span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><a className="dev_blue">register_callback</a>("auth", (status) {`=> {`}</span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><span className="dev_indent">console.log('Authorization callback', status);</span></span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent">{`});`}</span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><a className="dev_blue">register_callback</a>("auth", (status) {`=> {`}</span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><span className="dev_indent">console.log('Authorization callback', status);</span></span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent">{`});`}</span></span>
                                    <br/>
                                    <span className="dev_indent">{`},[]`})</span>
                                    <br/><br/>
                                    <span className="dev_indent"><a className="dev_red">return</a>  (</span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent">{`<>`}</span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><span className="dev_indent">{`{ !is_authorized && <button onClick={login}></button> }`}</span></span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><span className="dev_indent">{`{ is_authorized && <p>Wallet Address: {wallet_address}</p>}`}</span></span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><span className="dev_indent">{`{ is_authorized && <button onClick={()=>call_my_contract(...)}>Call Some Function</button> }`}</span></span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent">{`</>`}</span></span>
                                    <br/>
                                    <span className="dev_indent">);</span>
                                    <br/>
                                    {`}`}
                                    <br/><br/>
                                    <a className="dev_red">function</a> <a className="dev_blue_dark">Parent_Object</a> {`{`}
                                    <br/>
                                    <span className="dev_indent"><a className="dev_red">return</a> (</span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent">{`<JetBolt>`}</span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent"><span className="dev_indent">{`</Child_Object>`}</span></span></span>
                                    <br/>
                                    <span className="dev_indent"><span className="dev_indent">{`</JetBolt>`}</span></span>
                                    <br/>
                                    <span className="dev_indent">);</span>
                                    <br/>
                                    {`}`}
                                    <br/><br/>
                                    <a className="dev_red">export default</a> <a className="dev_blue_dark">Parent_Object</a>; */}
                                </div>
                                <div className="hook_left_white"></div>
                                <div className="hook_left_white hook_transform_top_right"></div>
                                <div className="hook_left_white hook_transform_bottom_right"></div>
                                <div className="hook_left_white hook_transform_bottom_left"></div>
                            </div>
                        </div>
                    </div>
                    <div className="develop_rhs">
                        <div className="develop_right_text_box">
                            <h2 className="title_font_family yellow_text">Get Started in Minutes</h2>
                            <div className="medium_spacer"></div>
                            <p>Our easy to integrate library allows developers to build JetBolt dApps in minutes.</p>
                            <div className="medium_spacer"></div>
                            <p className="grayed_text">Don't miss the chance to build the next generation of gas free blockchain applications.</p>
                            <div className="medium_spacer"></div>
                            <u className="underlined_text" onClick={()=>window.open(external_links.github)}>→ Get started now</u>
                        </div>
                        <div className="develop_bottom_right">
                            <h2 className="title_font_family">Join the Growing Community of Developers</h2>
                            <div className="medium_spacer"></div>
                            <div className={`inline_button_left blue_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>Buy Now</div>
                        <div className="inline_button_right gray_button_hover" onClick={()=>window.open(external_links.github)}>Github</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="large_spacer"></div>
        </div>
    );

}

export default Develop;