export async function generate_key_pair() {
    const keyPair = await window.crypto.subtle.generateKey({
      name: "RSA-OAEP",
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: "SHA-256",
    }, true, ["encrypt", "decrypt"]);
  
    const publicKey = await window.crypto.subtle.exportKey("spki", keyPair.publicKey);
    const privateKey = await window.crypto.subtle.exportKey("pkcs8", keyPair.privateKey);
  
    const base64PublicKey = btoa(String.fromCharCode(...new Uint8Array(publicKey)));
    const base64PrivateKey = btoa(String.fromCharCode(...new Uint8Array(privateKey)));
  
    return {
      public_key: encodeURIComponent(base64PublicKey),
      private_key: encodeURIComponent(base64PrivateKey)
    };
} 

export async function encrypt_with_public_key(base64PublicKey, data) {
    // Decode the public key from Base64
    const binaryPublicKey = atob(decodeURIComponent(base64PublicKey));
    const publicKeyBuffer = new Uint8Array(binaryPublicKey.length);
    for (let i = 0; i < binaryPublicKey.length; i++) {
      publicKeyBuffer[i] = binaryPublicKey.charCodeAt(i);
    }
  
    // Import the public key for encryption
    const publicKey = await window.crypto.subtle.importKey(
      "spki",
      publicKeyBuffer,
      {
        name: "RSA-OAEP",
        hash: "SHA-256"
      },
      true,
      ["encrypt"]
    );
  
    // Encrypt the data directly if it's already a Uint8Array
    const encrypted = await window.crypto.subtle.encrypt(
      { name: "RSA-OAEP" },
      publicKey,
      data  // Assuming data is already a Uint8Array
    );
    // Convert encrypted data to Base64 for safe transport
    return encodeURIComponent(btoa(String.fromCharCode(...new Uint8Array(encrypted))));
}

export async function decrypt_with_private_key(base64PrivateKey, encryptedData) {
    // Decode the private key from Base64
    const binaryPrivateKey = atob(decodeURIComponent(base64PrivateKey));
    const privateKeyBuffer = new Uint8Array(binaryPrivateKey.length);
    for (let i = 0; i < binaryPrivateKey.length; i++) {
      privateKeyBuffer[i] = binaryPrivateKey.charCodeAt(i);
    }
  
    // Import the private key for decryption
    const privateKey = await window.crypto.subtle.importKey(
      "pkcs8",
      privateKeyBuffer,
      {
        name: "RSA-OAEP",
        hash: "SHA-256"
      },
      true,
      ["decrypt"]
    );
  
    // Decode the encrypted data from Base64
    const decodedEncryptedData = atob(decodeURIComponent(encryptedData));
    const encryptedBuffer = new Uint8Array(decodedEncryptedData.length);
    for (let i = 0; i < decodedEncryptedData.length; i++) {
      encryptedBuffer[i] = decodedEncryptedData.charCodeAt(i);
    }
  
    // Decrypt the data
    const decrypted = await window.crypto.subtle.decrypt(
      { name: "RSA-OAEP" },
      privateKey,
      encryptedBuffer
    );
    // Assuming the decrypted data should be treated as raw binary data
    return new Uint8Array(decrypted);
}
