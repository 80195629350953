// ---- WAGMI ----
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { http, createConfig, WagmiProvider } from 'wagmi'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { mainnet, polygon, bsc, avalanche } from 'wagmi/chains'
import { injected, walletConnect } from 'wagmi/connectors'

let projectId = 'b4fcdaeb8de0f73b1b573d9e1c74be79'

export const wagmi_config = createConfig({
  chains: [mainnet, polygon, bsc, avalanche],
  connectors: [
    injected(),
    walletConnect({ projectId }),
  ],
  transports: {
    [mainnet.id]: http(),
    [polygon.id]: http(),
    [bsc.id]: http(),
    [avalanche.id]: http(),
  },
});

const queryClient = new QueryClient()
createWeb3Modal({
  wagmiConfig: wagmi_config,
  projectId,
});

export function Wagmi_Client({ children }) {
  return (
    <WagmiProvider config={wagmi_config}>
      <QueryClientProvider client={queryClient}>
        { children }
      </QueryClientProvider> 
    </WagmiProvider>
  )
}

