import React, { createContext, useContext, useState, useEffect } from "react";

// ---- Services ----
import { check_if_eu } from "../services/location";

// ---- External Links ----
import external_links from "../configs/external_links.json";

const ModalContext = createContext();

export function useGDPR() {
    return useContext(ModalContext);
}

export function GDPR({ children }) {

    let [show_modal, set_show_modal] = useState(false);
    let [animate_out, set_animate_out] = useState(false);
    let [parameters, set_parameters] = useState(null);
    let [login_mode, set_login_mode] = useState(0);

    let open_gdpr = (modal_parameters) => {
        // document.body.style.overflow = 'hidden';

        set_show_modal(true);
        set_parameters(modal_parameters);
        try { window.$crisp.push(['do', 'chat:hide']); } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "none"; } catch (error) {}
    }

    let close_gdpr = () => {
        set_animate_out(true);

        localStorage.setItem("skip_gdpr", "true");

        setTimeout(() => {
            // document.body.style.overflow = 'visible';
            set_show_modal(false);
            set_animate_out(false);
        }, 250);
        try { window.$crisp.push(['do', 'chat:show']);; } catch (error) {}
        try { document.querySelector(".tg_support").style.display = "block"; } catch (error) {}
    }

    let context_value = {
        open_gdpr,
        close_gdpr
    }

    useEffect(()=> {
        (async function() {

            if (window.location.pathname != "/iframe") {
                let skip_gdpr = localStorage.getItem("skip_gdpr");
                if (!skip_gdpr) {

                    try {

                        let is_eu = await check_if_eu();
                        if (is_eu) {
                            open_gdpr();
                        }

                    } catch (error) {
                        // console.log(error);
                    }
                }
            }

        })();
    },[]);


    return (
        <ModalContext.Provider value={context_value}>
            { children }
            { show_modal &&
                <div className="empty_shade">
                    <div className={`small_pop_up_container white_glow ${animate_out ? 'hide' : ''}`}>
                        <div className="purchase_funnel_container">
                            <div className="stick_text">
                                <div className="small_spacer"></div>
                                <div style={{position: "relative"}}>
                                    <h3 className="central_text_indicator title_font_family">Cookies</h3>
                                </div>
                                <div className="small_spacer"></div>
                            </div>

                            <p className="small_text" style={{pointerEvents: "all"}}>We use cookies to improve your browsing experience. You can learn more about this in our <u className="underlined_text" onClick={()=>window.open(external_links.privacy)}>Privacy Policy</u>.</p>
                            <div className="small_spacer"></div>
                            <div className="small_button_pop yellow_glow title_font_family yellow_button_hover" onClick={close_gdpr}>Accept</div>
                            <div className="title_font_family small_button_pop dull opacity_hover" onClick={()=>window.open(external_links.privacy)}>Learn More</div>
                            {/* <div className="medium_spacer"></div> */}
                        </div>
                    </div>
                    
                </div>
            }
        </ModalContext.Provider>
    )


}