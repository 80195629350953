import { view_call } from "./core";

let contract_name = "Logs"

// ---- Getter Functions ----

export async function get_transaction_history(concerned_party, page_number, transactions_per_page) {
    let response = await view_call(contract_name, "get_transaction_history", [concerned_party, page_number, transactions_per_page]);
    return response;
}

export async function get_chart_data(concerned_party, period_index, data_names) {
    let response = await view_call(contract_name, "get_chart_data", [concerned_party, period_index, data_names]);
    return response;
}

export async function get_leaderboard() {
    let response = await view_call(contract_name, "get_leaderboard", []);
    return response;
}