import { useEffect, useState, useRef } from "react";

// ---- Lotie ----
import Lottie from 'lottie-react';

// ---- Icons ----
import down_icon from "../../assets/icons/down.png";
import animated_tick from "../../assets/icons/animated_tick.json";

// ---- Services ----
import { sign_and_call_external, sign_and_message } from "../../services/contract_functions/core";
import { append_query_params } from "../../services/helper_functions";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";

// ---- Data ----
import { skale_chains } from "../../assets/data/skale_rpcs";

function Approve_IFrame_Transaction({ close, parameters, set_parameters }) {

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    // ---- Hooks ----
    let [is_loading, set_is_loading] = useState(false);
    let [success_animation, set_success_animation] = useState(false);

    // ---- Tx Status ----
    let [tx_pending, set_tx_pending] = useState(false);
    let [tx_status, set_tx_status] = useState({status:"Waiting for approval", amount:0})

    function update_transaction_status(status, amount) {
        set_tx_status({status, amount})
    }

    function fail_callback(callback_type) {
        setTimeout(()=>{
            let callback_url = parameters.callback_url;
            let new_url = append_query_params(callback_url, { callback_type, status: "fail", "function_name": parameters.call_params ? parameters.call_params.function_name: "" });
            window.location = new_url;
        },1000);
    }

    function success_callback(callback_type, hash) {
        setTimeout(()=>{
            let callback_url = parameters.callback_url;
            let new_url = append_query_params(callback_url, { callback_type, status: "success", "function_name": parameters.call_params ? parameters.call_params.function_name: "", hash });
            window.location = new_url;
        },1000);
    }

    async function call_transaction() {

        set_is_loading(true);
        set_tx_pending(true);
        set_tx_status({status:"Waiting for approval", amount:0});

        let { is_skale_chain, address, abi, function_name, params, value, skale_chain_name, sfuel_contract, function_signature, rpc_url, chain_id, gas_limit, signature_mode } = parameters.call_params;

        try {

            if (!address || !abi || !function_name || !params) {
                // window.parent.postMessage({ error: "gas_limit, address, abi, function_name, params, or value is missing" }, document.referrer);
                throw "Error";
            }

            if (!value) {
                value = 0;
            }

            if (!gas_limit) {
                gas_limit = 0n;
            }
            
            if (is_skale_chain) {
                if (skale_chain_name) {
                    if (!skale_chains[skale_chain_name]) {
                        // window.parent.postMessage({ error: "Invalid skale_chain_name" }, document.referrer);
                        throw "Error";
                    }
                    else {
                        sfuel_contract = skale_chains[skale_chain_name].sfuel_contract;
                        function_signature = skale_chains[skale_chain_name].function_signature;
                        rpc_url = skale_chains[skale_chain_name].rpc_url;
                        chain_id = skale_chains[skale_chain_name].chain_id;
                    }
                }
                else {
                    if (!sfuel_contract || !function_signature || !rpc_url || !chain_id) {
                        // window.parent.postMessage({ error: "Specify sfuel_contract, function_signature, rpc_url, and chain_id or simply use skale_chain_name field." }, document.referrer);
                        throw "Error"
                    }
                }
            }
            else if (!rpc_url || !chain_id) {
                // window.parent.postMessage({ error: "rpc_url or chain_id is missing." }, document.referrer);
                throw "Error"
            }

            value = window.BigInt(value);
            gas_limit = window.BigInt(gas_limit);

            let transaction_response = await sign_and_call_external(is_skale_chain, address, gas_limit, abi, function_name, params, chain_id, value, rpc_url, sfuel_contract, function_signature, signature_mode, update_transaction_status)
            set_success_animation(true);
            setTimeout(close, 500);
            // window.parent.postMessage({response:{status:"success",status_code:200,transaction_response}}, document.referrer);
            success_callback("call", transaction_response.hash);

        } catch (error) {
            console.log(error);
            // window.parent.postMessage({response:{status:"fail",status_code:400,error}}, document.referrer);
            // console.log(error);
            generic_error();
            // fail_callback("call");
        }

        set_is_loading(false);
        set_tx_pending(false);

    }

    async function sign_message() {

        set_is_loading(true);
        set_tx_pending(true);
        set_tx_status({status:"Waiting for approval", amount:0});
        let { message } = parameters.call_params;
        try {
            let signature = await sign_and_message(message, update_transaction_status);
            set_success_animation(true);
            setTimeout(close, 500);
            success_callback("signature", signature);
            // window.parent.postMessage({response:{status:"success",status_code:200,signature}}, document.referrer);
        } catch (error) {
            fail_callback("signature");
            // window.parent.postMessage({response:{status:"fail",status_code:400,error}}, document.referrer);
            generic_error();
        }

        set_is_loading(false);
        set_tx_pending(false);

    }

    function reject_transaction() {
        fail_callback("call");
        // window.parent.postMessage({response:{status:"fail",status_code:401}}, document.referrer);
        close();
    }

    // ---- Errors ----
    function generic_error() {
        let params = {
            title: "Oh No!",
            content: "An error has occured during your request.",
            buttons: [
                {
                    title: "Okay",
                    callback: ()=>{
                        close(); fail_callback("call");
                    }
                }
            ]
        }
        throw_error(params);
    }

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Approve Transaction</h2>
                        {/* <img className="back_icon opacity_hover" src={down_icon} onClick={close}/> */}
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <p>{parameters.transaction_description}</p>
                <div className="medium_spacer"></div>
                {
                    parameters.call_params.signature_mode == "signature" ?
                    <div className={`payment_button yellow_button_hover title_font_family ${is_loading?"unselectable":""}`} onClick={sign_message}>{success_animation ?  <Lottie animationData={animated_tick} loop={false} direction={-1} style={{ position: "absolute", paddingTop: "10px", left: "calc(50% - 25px)", width: "50px", height: "50px" }}/> : "Sign Message"}</div> :
                    <div className={`payment_button yellow_button_hover title_font_family ${is_loading?"unselectable":""}`} onClick={call_transaction}>{success_animation ?  <Lottie animationData={animated_tick} loop={false} direction={-1} style={{ position: "absolute", paddingTop: "10px", left: "calc(50% - 25px)", width: "50px", height: "50px" }}/> : "Approve"}</div>
                }
                {
                    !tx_pending && !success_animation &&
                    <>
                        <div className="medium_spacer"></div>
                        <div className="title_font_family payment_button_dull opacity_hover" onClick={reject_transaction}>Reject</div>
                    </>
                }
                {
                    tx_pending &&
                    <>
                        <div className="medium_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar" style={{width: `${tx_status.amount*100}%`}}></div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text">Status: {tx_status.status}</p>
                    </>
                }
            </div>
        </>
    );
}

export default Approve_IFrame_Transaction;