

// ---- Icons ----
import instant_icon from "../../assets/icons/instant.png";
import day_icon from "../../assets/icons/1_day.png";
import week_icon from "../../assets/icons/1_week.png";
import month_icon from "../../assets/icons/1_month.png";
import right_icon from "../../assets/icons/right.png";
import down_icon from "../../assets/icons/down.png";

let lock_periods = [
    {
        name: "Instant",
        period: 0,
        multiplier: 1,
        icon: instant_icon
    },
    {
        name: "1 Day",
        period: 1,
        multiplier: 2,
        icon: day_icon
    },
    {
        name: "7 Days",
        period: 7,
        multiplier: 3,
        icon: week_icon
    },
    {
        name: "30 Days",
        period: 30,
        multiplier: 4,
        icon: month_icon
    }
]

function Staking_Duration({ close, parameters, set_parameters }) {

    function select_duration(lock_period) {
        set_parameters({type: "stake_amount", lock_period, close_callback: parameters.close_callback})
    }

    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Stake Tokens</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <p>Select the withdrawl delay for your staked coins. The longer the withdrawl delay the higher your staking rewards.</p>
                <div className="medium_spacer"></div>
                {
                    lock_periods.map((item,index)=>(
                        <div key={index} className="friend_list_item gray_button_hover" onClick={()=>select_duration(item)}>
                            <img className="live_sales_icon" src={item.icon}/>
                            <div className="live_sales_amount_label">{item.name}</div>
                            <div className="decline_button">
                                <img className="inner_icon" src={right_icon}/>
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    );
}

export default Staking_Duration;