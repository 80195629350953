import { useEffect, useState } from "react";
import customer_instance from "./customer";

function useCustomer() {

    let [customer, set_customer] = useState(customer_instance.get());

    useEffect(() => {

        const update_customer = () => {
            set_customer(customer_instance.current);
        }

        customer_instance.on_change = update_customer;

        return () => {
            customer_instance.on_change = null;
        }

    });

    return customer;

}

export default useCustomer;