import { useEffect, useState, useRef } from "react";

// ---- Lotie ----
import Lottie from 'lottie-react';

// ---- Ethers ----
import { ethers } from "ethers";

// ---- Services ----
import useToken from "../../services/wallet/useToken";
import { burn, balance_of } from "../../services/contract_functions/token";
import { short_format_number, increment_ref, decrement_ref } from "../../services/helper_functions";

// ---- Icons ----
import right_icon from "../../assets/icons/right.png";
import logo_dark from "../../assets/icons/logo_dark.png"
import down_icon from "../../assets/icons/down.png";
import animated_tick from "../../assets/icons/animated_tick.json";
import edit_icon from "../../assets/icons/edit.png";

// ---- Modals ----
import { useErrorPopUp } from "../error_pop_up";
import { on_focus_input_ref, on_blur_input_ref } from "../../services/helper_functions";
import { call } from "viem/actions";

function Burn_Amount({ close, parameters, set_parameters }) {

    // ---- Modal ----
    let { throw_error } = useErrorPopUp();

    // ---- Refs ----
    let token_amount_ref = useRef(null);
    let token_amount_outer_ref = useRef(null);

    // ---- Hooks ----
    let token = useToken();
    let [balance, set_balance] = useState(0);
    let [is_loading, set_is_loading] = useState(false);
    let [has_sufficient_balance, set_has_sufficient_balance] = useState(false);
    let [stake_all, set_stake_all] = useState(true);
    let [success_animation, set_success_animation] = useState(false);

    // ---- On Load ----
    useEffect(() => {

        if (token && token.wallet_address) {
            (async function() {
                let balance = await balance_of(token.wallet_address);
                set_balance(balance);
                // if (balance != 0n) {
                //     token_amount_ref.current.value = ethers.formatUnits(balance / 10n);
                //     set_has_sufficient_balance(true);
                // }
                // else {
                //     token_amount_ref.current.value = 0;
                // }

                if (balance != 0n) {
                    set_has_sufficient_balance(true);
                }

                if (token_amount_ref.current) {
                    token_amount_ref.current.value = 0;
                }
            })();
        }

    },[token]);

    useEffect(() => {

        if (token_amount_ref.current && stake_all == false) {
            token_amount_ref.current.value = 0;
            set_has_sufficient_balance(false);
        }
        else if (balance != 0n) {
            set_has_sufficient_balance(true);
        }

    },[stake_all])

    // ---- Input Value Changes ----
    function amount_changed() {
        if (token_amount_ref.current || stake_all) {
            try {
                let amount;
                
                if (stake_all) {
                    amount = balance;
                }
                else {
                    amount = ethers.parseEther(token_amount_ref.current.value);
                }
                
                if (amount <= balance && amount > 0n) {
                    set_has_sufficient_balance(true);
                }
                else {
                    set_has_sufficient_balance(false);
                }
            } catch (error) {
                set_has_sufficient_balance(false);
            }
        }
    }

    // ---- Errors ----
    function staking_error() {
        let params = {
            title: "Oh No!",
            content: "An error has occured. Please make sure you have sufficient tokens.",
            buttons: [
                {
                    title: "Okay",
                }
            ]
        }
        throw_error(params);
    }

    // ---- Functions ----
    async function _stake_tokens() {
        set_is_loading(true);
        set_tx_pending(true);
        set_tx_status({status:"Waiting for approval", amount:0});
        if (token_amount_ref.current || stake_all) {
            let amount;

            if (stake_all) {
                amount = balance;
            }
            else {
                amount = ethers.parseEther(token_amount_ref.current.value);
            }

            try {
                await burn(amount, update_transaction_status);
                set_success_animation(true);
                setTimeout(close, 500);
            } catch (error) {
                staking_error();
            }
        }
        set_is_loading(false);
        set_tx_pending(false);
    }

    function edit_lock_period() {
        set_parameters({type: "staking_duration"});
    }

    // ---- Tx Status ----
    let [tx_pending, set_tx_pending] = useState(false);
    let [tx_status, set_tx_status] = useState({status:"Waiting for approval", amount:0})

    function update_transaction_status(status, amount) {
        set_tx_status({status, amount})
    }

    // ---- Increment Intervals ----
    let increment_interval_ref = useRef(null);
    let speed_step = 50;
    let initial_speed = 500;
    let max_speed = 50;
    let step_size = 0;
    let max_step_size = 1;

    function start_interval(callback) {

        callback();

        let value = token_amount_ref.current.value;
        let slice_index = -1;
        let loop_count = value.slice(slice_index);
        let cur_speed = initial_speed;
        let cur_step_size = step_size;

        if (increment_interval_ref.current) {
            clearInterval(increment_interval_ref.current);
        }

        function interval_callback() {

            if (cur_step_size < max_step_size && loop_count != 0 && loop_count % 10 == 0) {
                cur_step_size += 1;
                loop_count += 1;
            }

            callback(cur_step_size);

            if (cur_speed > max_speed) {
                cur_speed -= speed_step;
                clearInterval(increment_interval_ref.current);
                increment_interval_ref.current = setInterval(interval_callback, cur_speed);
            }
            loop_count++;
        }

        increment_interval_ref.current = setInterval(interval_callback, cur_speed);
    }

    function stop_interval() {
        if (increment_interval_ref.current) {
            clearInterval(increment_interval_ref.current);
            increment_interval_ref.current = null;
        }
    }

    const is_touch_device = () => {
        return ('ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0);
    };
    
    return (
        <>
            <div className="purchase_funnel_container">
                <div className="stick_text">
                    <div className="medium_spacer"></div>
                    <div className="title_container">
                        <h2 className="central_text_indicator title_font_family">Burn Tokens</h2>
                        <img className="back_icon opacity_hover" src={down_icon} onClick={close}/>
                    </div>
                </div>
                <div className="medium_spacer"></div>
                <p>Warning: It is impossible to retrieve burnt tokens. You will irreversibly destroy your tokens.</p>
                <div className="medium_spacer"></div>

                <div className="friend_list_item">
                    <img className="live_sales_icon" src={logo_dark}/>
                    <div className="live_sales_amount_label">Burn All</div>
                    <div className={`switch_container opacity_hover ${stake_all?"on":"off"}`} onClick={()=>set_stake_all(!stake_all)}>
                        <div className={`switch_button ${stake_all?"on":"off"}`}></div>
                    </div>
                </div>

                {
                    !stake_all &&
                    <>
                        <div className="small_spacer"></div>
                    

                        <p className="small_text">Burn Amount:</p>
                        <div className="small_spacer"></div>
                        <div className="payment_input_container" ref={token_amount_outer_ref}>
                            <div className="payment_input_currency_selector">
                                <img className="payment_input_selector_icon" src={logo_dark}/>
                                <img className="payment_input_selector_extra_icon" src={right_icon}/>
                            </div>
                            <input className="value_input extra" ref={token_amount_ref} onChange={amount_changed}
                            type="number" inputMode="decimal" onFocus={()=>on_focus_input_ref(token_amount_outer_ref)} onBlur={()=>on_blur_input_ref(token_amount_outer_ref)}
                            />
                            {/* <div className="currency_label">CAT</div> */}

                            <div className="edit_input_button gray_button_hover" onClick={()=>token_amount_ref.current.focus()}>
                                <img className="edit_input_button_icon" src={edit_icon}/>
                            </div>

                            <div className="stepper_up_input_button gray_button_hover"
                            onMouseDown={ ()=> { if (!is_touch_device()) { start_interval((offset) => { increment_ref(token_amount_ref, offset); amount_changed(); }) } } }
                            onMouseUp={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                            onMouseLeave={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                            onTouchStart={ ()=> { if (is_touch_device()) { start_interval((offset) => { increment_ref(token_amount_ref, offset); amount_changed(); }) } } }
                            onTouchEnd={ ()=> { if (is_touch_device()) { stop_interval(); } } }
                            >+</div>
                            <div className="stepper_down_input_button gray_button_hover"
                            onMouseDown={ ()=> { if (!is_touch_device()) { start_interval((offset) => { decrement_ref(token_amount_ref, offset); amount_changed(); }) } } }
                            onMouseUp={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                            onMouseLeave={ ()=> { if (!is_touch_device()) { stop_interval(); } } }
                            onTouchStart={ ()=> { if (is_touch_device()) { start_interval((offset) => { decrement_ref(token_amount_ref, offset); amount_changed(); }) } } }
                            onTouchEnd={ ()=> { if (is_touch_device()) { stop_interval(); } } }
                            >-</div>

                            {/* <div className="up_input_button gray_button_hover"
                            
                            onMouseDown={() => start_interval((offset) => { increment_ref(token_amount_ref, offset); amount_changed(); })}
                            onMouseUp={stop_interval}
                            onMouseLeave={stop_interval}
                            onTouchStart={() => start_interval((offset) => { increment_ref(token_amount_ref, offset); amount_changed(); })}
                            onTouchEnd={stop_interval}
                            >+</div>
                            <div className="down_input_button gray_button_hover"

                            onMouseDown={() => start_interval((offset) => { decrement_ref(token_amount_ref, offset); amount_changed(); })}
                            onMouseUp={stop_interval}
                            onMouseLeave={stop_interval}
                            onTouchStart={() => start_interval((offset) => { decrement_ref(token_amount_ref, offset); amount_changed(); })}
                            onTouchEnd={stop_interval}
                            >-</div> */}
                        </div>

                    </>
                }
                <div className="small_spacer"></div>
                <p className="small_text">Available Balance: {short_format_number(ethers.formatUnits(balance))} JetBolt</p>
                
                {/* <div className="medium_spacer"></div>
                <p className="small_text">Staking Duration:</p>
                <div className="small_spacer"></div>
                <div className="friend_list_item gray_button_hover" onClick={edit_lock_period}>
                    <img className="live_sales_icon" src={parameters.lock_period.icon}/>
                    <div className="live_sales_amount_label">{parameters.lock_period.name}</div>
                    <div className="decline_button">
                        <img className="inner_icon" src={right_icon}/>
                    </div>
                </div> */}
                
                <div className="medium_spacer"></div>
                <div className={`payment_button title_font_family yellow_button_hover ${(!has_sufficient_balance || is_loading)?"unselectable":""}`} onClick={_stake_tokens}>{success_animation ?  <Lottie animationData={animated_tick} loop={false} direction={-1} style={{ position: "absolute", paddingTop: "10px", left: "calc(50% - 25px)", width: "50px", height: "50px" }}/> : "Burn Tokens"}</div>

                {
                    tx_pending &&
                    <>
                        <div className="medium_spacer"></div>
                        <div className="outer_progress_bar">
                            <div className="inner_progress_bar" style={{width: `${tx_status.amount*100}%`}}></div>
                        </div>
                        <div className="small_spacer"></div>
                        <p className="small_text">Status: {tx_status.status}</p>
                    </>
                }

            </div>
           
            {/* <div className="medium_spacer"></div> */}

           
        </>
    );
}

export default Burn_Amount;