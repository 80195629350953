import { Buffer } from 'buffer';

class Token {

    on_change = null;

    constructor() {
        this.current = void 0;
        // this.init_backup();
    }

    init(mnemonic) {
        let token = {
            mnemonic,
            confirmed: false,
            referrers: {}
        }
        this.save(token);
    }

    update_username(username) {
        this.current.username = username;
        this.save(this.current);
    }

    update_sessions(session_address, session_private_key) {
        this.current.session_address = session_address;
        this.current.session_private_key = session_private_key;
        this.save(this.current);
    }

    update_credential(credential_id, encrypted_mnemonic, wallet_address) {
        this.current.credential_id = credential_id;
        this.current.encrypted_mnemonic = encrypted_mnemonic;
        this.current.wallet_address = wallet_address;
        this.save(this.current);
    }

    create_referrer_key(private_key) {
        if (!this.current) {
            this.current = {};
        }
        this.current.referrer_key = private_key;
        this.save(this.current);
    }

    authorized_referrer(referrer) {
        if (!this.current.authorized_referrers) {
            this.current.authorized_referrers = {};
        }
        this.current.authorized_referrers[referrer] = true;
        this.save(this.current);
    }

    block_referrer(referrer) {
        if (!this.current.authorized_referrers) {
            this.current.authorized_referrers = {};
        }
        this.current.authorized_referrers[referrer] = false;
        this.save(this.current);
    }

    confirm() {
        this.current.mnemonic = "";
        this.current.confirmed = true;
        this.save(this.current);
    }

    save(token) {
        this.current = token;
        let token_string = JSON.stringify(this.current);
        let token_base64 = Buffer.from(token_string).toString('base64');
        localStorage.setItem("token", token_base64);
        if (typeof this.on_change == "function") {
            this.on_change();
        }

        // if (this.current.confirmed === true) {
        //     this.backup_cookie(token_base64);
        // }
        // this.backup_save(token)
    }

    get() {
        let token_base64 = localStorage.getItem("token");
        if (!token_base64) {
            return void 0;
        }
        // this.fetch_from_cookie();
        // if (!token_base64) {
        //     token_base64 = this.fetch_from_cookie();
        //     if (!token_base64) {
        //         return void 0;
        //     }
        // }
        let token_string = Buffer.from(token_base64, 'base64').toString('utf8');

        try {
            this.current = JSON.parse(token_string);
        } catch (error) {}

        return this.current;
    }

    delete() {
        this.current = void 0;
        localStorage.removeItem('token');
        document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=None; Secure; Path=/`;
    }

    // backup_cookie(token_base64) {
    //     let expiry_date = new Date(); 
    //     expiry_date.setFullYear(expiry_date.getFullYear() + 100); 
    //     document.cookie = `token=${encodeURIComponent(token_base64)}; expires=${expiry_date.toUTCString()}; SameSite=None; Secure; Path=/`;
    // }

    // fetch_from_cookie() {
    //     let cookie_value = document.cookie.split('; ').find(row => row.startsWith("token="))?.split('=')[1];
    //     return cookie_value ? decodeURIComponent(cookie_value) : void 0;
    // }

}

// Export as singleton
let instance = new Token();
export default instance;