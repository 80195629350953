import { useState, useRef, useEffect } from "react";

// ---- Icons ----
import zero_gas_icon from "../assets/icons/zero_gas.png";
import lightning_fast_icon from "../assets/icons/lightning_fast.png";
import easy_to_earn_icon from "../assets/icons/easy_to_earn.png";
import finished_product_icon from "../assets/icons/finished_product.png";
import custom_wallet_icon from "../assets/icons/custom_wallet.png"
import ai_powered_icon from "../assets/icons/ai_powered.png"
import dev_friendly_icon from "../assets/icons/dev_friendly.png"
import self_custody_icon from "../assets/icons/self_custody.png"

// ---- Modals ----
import { usePurchaseFunnel } from "../modals/purchase_funnel";

function Why_JetPaw({ hard_stop, geo_error }) {

    // ---- Purchase Funnel ----
    let { open_purchase_funnel } = usePurchaseFunnel();

    function buy_direct() {
        if (hard_stop) {
            geo_error();
        }
        else {
            open_purchase_funnel({type: "buy_direct"});
        }
    }

    let [show_extra, set_show_extra] = useState(false);

    let extra_container = useRef(null);

    useEffect(()=>{

        let height = extra_container.current.scrollHeight;
        extra_container.current.style.height = show_extra ? `${height}px` : "0px";

    },[show_extra]);

    return (
        <div className="page_container">
            <div className="safe_inner">
                <div className="large_spacer"></div>
                <h1 className="title_font_family center_desktop">Why JetBolt?</h1>
                <div className="large_spacer"></div>
                <div className="why_component">
                    <div className="why_image_container">
                        <img className="why_image" src={zero_gas_icon}/>
                    </div>
                    <div className="medium_spacer only_desktop"></div>
                    <div className="why_text">
                        <h3 className="title_font_family yellow_text">Zero-Gas</h3>
                        <div className="medium_spacer only_desktop"></div>
                        <p>JetBolt is changing the game by pioneering a cryptocurrency with no gas or transaction fees.</p>
                    </div>
                </div>
                <div className="medium_spacer only_mobile"></div>
                <div className="why_component">
                    <div className="why_image_container">
                        <img className="why_image" src={lightning_fast_icon}/>
                    </div>
                    <div className="medium_spacer only_desktop"></div>
                    <div className="why_text">
                        <h3 className="title_font_family yellow_text">Lightning Fast</h3>
                        <div className="medium_spacer only_desktop"></div>
                        <p>Tired of waiting for slow transactions? JetBolt leverages Skale network to provide instant finality.</p>
                    </div>
                </div>
                <div className="medium_spacer only_mobile"></div>
                <div className="why_component">
                    <div className="why_image_container">
                        <img className="why_image" src={easy_to_earn_icon}/>
                    </div>
                    <div className="medium_spacer only_desktop"></div>
                    <div className="why_text">
                        <h3 className="title_font_family yellow_text">Easy To Earn</h3>
                        <div className="medium_spacer only_desktop"></div>
                        <p>Earning crypto has never been this easy. JetBolt rewards the most active community members.</p>
                    </div>
                </div>
                <div className="medium_spacer only_mobile"></div>
                <div className="why_component">
                    <div className="why_image_container">
                        <img className="why_image" src={finished_product_icon}/>
                    </div>
                    <div className="medium_spacer only_desktop"></div>
                    <div className="why_text">
                        <h3 className="title_font_family yellow_text">Finished Product</h3>
                        <div className="medium_spacer only_desktop"></div>
                        <p>JetBolt's ecosystem and features are already completed, audited, and available from day one!</p>
                    </div>
                </div>

                <div ref={extra_container} className={`extra_container ${show_extra?"show":""}`}> 
                    <div className="medium_spacer"></div>
                    <div className="why_component">
                        <div className="why_image_container">
                            <img className="why_image" src={custom_wallet_icon}/>
                        </div>
                        <div className="medium_spacer only_desktop"></div>
                        <div className="why_text">
                            <h3 className="title_font_family yellow_text">Custom Wallet</h3>
                            <div className="medium_spacer only_desktop"></div>
                            <p>JetBolt has its own purpose built friendly web wallet. It's so easy to use, even your grandma can use it.</p>
                        </div>
                    </div>
                    <div className="medium_spacer only_mobile"></div>
                    <div className="why_component">
                        <div className="why_image_container">
                            <img className="why_image" src={ai_powered_icon}/>
                        </div>
                        <div className="medium_spacer only_desktop"></div>
                        <div className="why_text">
                            <h3 className="title_font_family yellow_text">AI Features</h3>
                            <div className="medium_spacer only_desktop"></div>
                            <p>JetBolt leverages the power of AI to deliver currated news and insights to its users, enahncing the experience.</p>
                        </div>
                    </div>
                    <div className="medium_spacer only_mobile"></div>
                    <div className="why_component">
                        <div className="why_image_container">
                            <img className="why_image" src={dev_friendly_icon}/>
                        </div>
                        <div className="medium_spacer only_desktop"></div>
                        <div className="why_text">
                            <h3 className="title_font_family yellow_text">Developer Friendly</h3>
                            <div className="medium_spacer only_desktop"></div>
                            <p>JetBolt has been designed for developers. You can integrate JetBolt into your dApp in just a few minutes.</p>
                        </div>
                    </div>
                    <div className="medium_spacer only_mobile"></div>
                    <div className="why_component">
                        <div className="why_image_container">
                            <img className="why_image" src={self_custody_icon}/>
                        </div>
                        <div className="medium_spacer only_desktop"></div>
                        <div className="why_text">
                            <h3 className="title_font_family yellow_text">Self Custody</h3>
                            <div className="medium_spacer only_desktop"></div>
                            <p>There's no TGE and no lockup. You get your JetBolt tokens immediately after purchasing them.</p>
                        </div>
                    </div>
                </div>
                
                <div className="large_spacer"></div>
                <center>
                <div className={`inline_button_left yellow yellow_button_hover ${hard_stop===null?"temp_unselectable":""}`} onClick={buy_direct}>Buy Now</div>
                <div className="inline_button_right gray_button_hover" onClick={()=>set_show_extra(!show_extra)}>Show {show_extra ? "Less": "More"}</div>
                </center>
                <div className="large_spacer"></div>
            </div>
        </div>
    );

}

export default Why_JetPaw;