import { ethers } from "ethers";
import { mineFreeGas, get_gas_price, get_gas_limit_for_contract_call, get_gas_limit_for_contract_call_w_abi, prepare_contract_call, prepare_contract_call_w_abi, sign_and_send_transaction, read_contract, wait_for_hash } from "../wallet/transaction_builder";
import { unlock_wallet } from "../wallet/core";
import contract_addresses from "../../configs/contract_addresses.json";
import config from "../../configs/config.json";
import { request_gas_if_needed } from "../wallet/request_gas";
import token_instance from "../wallet/token";

let chain_id = config.chain_id;

function update_status(callback, status, amount) {
    if (callback) {
        try {
            callback(status, amount);
        } catch (error) {
            // console.log(error)
        }
    }
}

export async function call_contract_with_private_key(wallet_address, private_key, contract_name, function_name, parameters, value, callback) {

    // let contract_address = contract_addresses[contract_name];
    // let gas_limit = await get_gas_limit_for_contract_call(wallet_address, private_key, contract_address, contract_name, function_name, parameters, value);
    // let gas_price = await mineFreeGas(gas_limit, wallet_address);
    // let unsigned_transaction = await prepare_contract_call(contract_address, private_key, contract_name, function_name, parameters, gas_limit, gas_price, chain_id, value);
    // let transaction_response = await sign_and_send_transaction(private_key, wallet_address, unsigned_transaction);
    // return transaction_response;

    update_status(callback, "Estimating gas", 0.2);

    let contract_address = contract_addresses[contract_name];
    let gas_price = await get_gas_price();

    update_status(callback, "Validating transaction", 0.4);
    let gas_limit = await get_gas_limit_for_contract_call(wallet_address, private_key, contract_address, contract_name, function_name, parameters, value);

    update_status(callback, "Mining gas", 0.5);
    await request_gas_if_needed(wallet_address, gas_price, gas_limit);

    update_status(callback, "Preparing transaction", 0.6);
    let unsigned_transaction = await prepare_contract_call(contract_address, private_key, contract_name, function_name, parameters, gas_limit, gas_price, chain_id, value);

    update_status(callback, "Sending transaction", 0.7);
    let transaction_response = await sign_and_send_transaction(private_key, wallet_address, unsigned_transaction);

    update_status(callback, "Confirming transaction", 0.8);
    await wait_for_hash(transaction_response.hash);

    update_status(callback, "Transaction confirmed", 1);

    return transaction_response;
}

export async function sign_and_call(contract_name, function_name, parameters, value, callback) {
    let { wallet_address, private_key } = await unlock_wallet();
    return call_contract_with_private_key(wallet_address, private_key, contract_name, function_name, parameters, value, callback);
}

export async function sign_and_message(message, callback) {
    let { private_key } = await unlock_wallet();
    update_status(callback, "Signing message", 0.5);
    let wallet = new ethers.Wallet(private_key);
    let signature = await wallet.signMessage(message);
    return signature;
}

export async function sign_and_message_session(message, callback) {
    let token = token_instance.get();
    let private_key = token.session_private_key;
    update_status(callback, "Signing message", 0.5);
    let wallet = new ethers.Wallet(private_key);
    let signature = await wallet.signMessage(message);
    return signature;
}

export async function verify_signature_session(message, signature) {
    let token = token_instance.get();
    let recovered_address = ethers.verifyMessage(message, signature);
    return token.session_address === recovered_address;
}

export async function sign_and_call_session(contract_name, function_name, parameters, value, callback) {
    let token = token_instance.get();
    let wallet_address = token.session_address;
    let private_key = token.session_private_key;
    return call_contract_with_private_key(wallet_address, private_key, contract_name, function_name, parameters, value, callback);
}

export async function view_call(contract_name, function_name, parameters, rpc_provider=void 0) {
    let contract_address = contract_addresses[contract_name];
    if (rpc_provider) {
        return await read_contract(contract_address, contract_name, function_name, parameters, rpc_provider);
    }
    else {
        return await read_contract(contract_address, contract_name, function_name, parameters);
    }
    
}

export function make_random_address() {
    let random_wallet = ethers.Wallet.createRandom();
    return random_wallet.address;
}

export async function sign_and_call_external(is_skale_chain, address, gas_limit, abi, function_name, parameters, chain_id, value, rpc_url, sfuel_contract, function_signature, signature_mode, callback) {

    let _wallet_address;
    let _private_key;

    if (signature_mode == "session") {
        _wallet_address = token_instance.current.session_address;
        _private_key = token_instance.current.session_private_key;
    }
    else {
        let { wallet_address, private_key } = await unlock_wallet();
        _wallet_address = wallet_address;
        _private_key = private_key;
    }
    

    let rpc_provider = new ethers.JsonRpcProvider(rpc_url);

    update_status(callback, "Estimating gas", 0.2);
    let gas_price = await get_gas_price(rpc_provider);

    update_status(callback, "Validating transaction", 0.4);

    if (gas_limit <= 0n) {
        gas_limit = await get_gas_limit_for_contract_call_w_abi(_wallet_address, _private_key, address, abi, function_name, parameters, value, rpc_provider);
    }

    if (is_skale_chain) {
        update_status(callback, "Mining gas", 0.5);
        await request_gas_if_needed(_wallet_address, gas_price, gas_limit, rpc_provider, sfuel_contract, function_signature);
    }

    update_status(callback, "Preparing transaction", 0.6);
    let unsigned_transaction = await prepare_contract_call_w_abi(address, _private_key, abi, function_name, parameters, gas_limit, gas_price, chain_id, value, rpc_provider)

    update_status(callback, "Sending transaction", 0.7);
    let transaction_response = await sign_and_send_transaction(_private_key, _wallet_address, unsigned_transaction, rpc_provider);
    
    update_status(callback, "Confirming transaction", 0.8);
    await wait_for_hash(transaction_response.hash, rpc_provider)

    update_status(callback, "Transaction confirmed", 1);
    return transaction_response;

}