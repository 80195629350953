import React, { useState, useEffect } from 'react';

// ---- Assets ----
import audited_by_image from "../assets/images/audited_by.png";
import grant_recipient from "../assets/images/grant_recipient.png";


let usps = [
    "ULTRA FAST",
    "STAKING",
    "ZERO GAS",
    "EASIEST",
    "NO FEES",
    "AI POWERED"
]

function Top_Content() {

    // ---- States ----
    let [current_usp, set_current_usp] = useState(usps.length-1);
    let [next_usp, set_next_usp] = useState(0);

    // ---- Hooks ----
    useEffect(() => {
        let interval = setInterval(() => {

            let newCurrentUsp = (current_usp + 1) % usps.length;
            let newNextUsp = (newCurrentUsp + 1) % usps.length;

            set_current_usp(newCurrentUsp);
            set_next_usp(newNextUsp);

        }, 3000);
    
        return () => clearInterval(interval);
    }, [next_usp]);

    return (
        <div className="top_content_container">
            <div className="large_spacer"></div>

            {/* <div className="radial_glow_top"></div> */}

            <div className="usp_container top_usp_container notranslate">
                THE
                <div className="changing_container">
                    <div key={usps[current_usp]} className="changing_text_0">{usps[next_usp]}</div>
                    
                </div>
                <div className="changing_container">
                    <div key={usps[next_usp]} className="changing_text_1">{usps[current_usp]}</div>
                </div>
                
            </div>
            <div className="small_spacer"></div>
            <div className="usp_container notranslate" style={{width: "auto"}}>CRYPTO COIN</div>
            <div className="medium_spacer"></div>
            <p className="large_text center_mobile add_indent">JetBolt aims to power the next generation of crypto apps. Join now and be part of the future of crypto.</p>
            {/* <div className="only_desktop">
                <div className="medium_spacer"></div>
                <div className="inline_button_left blue_button_hover">How to Buy</div>
                <div className="inline_button_right gray_button_hover">Whitepaper</div>
            </div> */} 
            <div className="medium_spacer"></div>

            <img className="inline_badges" src={audited_by_image}/>
            <img className="inline_badges right" src={grant_recipient}/>
        </div>
    )
}

export default Top_Content;